import React, { Component } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, styled, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

export interface IAccordionItem {
  id: string
  title: string
  description: string
}

interface IProps {
  data: IAccordionItem[]
}

interface IState {
  expanded: string | false
}

export default class UIAccordion extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { expanded: props.data[0]?.id ? props.data[0].id : false }
  }

  handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    this.setState({ expanded: isExpanded ? panel : false })
  }

  render() {
    return (
      <div>
        {this.props.data.map((x) => (
          <AccordionCustom expanded={this.state.expanded === x.id} onChange={this.handleChange(x.id)}>
            <AccordionSummary
              expandIcon={this.state.expanded === x.id ? <RemoveIcon /> : <AddIcon sx={{ color: '#007CFF' }} />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography variant='subtitle1'>{x.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='subtitle2'>{x.description}</Typography>
            </AccordionDetails>
          </AccordionCustom>
        ))}
      </div>
    )
  }
}

const AccordionCustom = styled(Accordion)({
  '&.Mui-expanded': {
    borderRadius: '4px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px'
  },
  '&.Mui-expanded .MuiAccordionSummary-content .MuiTypography-root': {
    fontWeight: 600,
    color: 'var(--color-title)'
  }
})

import { Theme } from '@emotion/react'
import { SxProps } from '@mui/material'

function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export function AuthStringAvatar(name: string, sx?: SxProps<Theme>) {
  let tmps = name?.split(' ') ?? ['@']
  const name1 = tmps[0] && tmps[0][0] ? tmps[0][0] : '@'
  const name2 = tmps[1] && tmps[1][0] ? tmps[1][0] : ''
  return {
    sx: { ...sx, bgcolor: stringToColor(name) },
    children: `${name1?.toLocaleUpperCase() ?? ''}${name2?.toLocaleUpperCase() ?? ''}`
  }
}

import GraphqlCore from '@coreprj/graphql'
import { ETableLogicOperator, TFieldMapping, TFieldMappingDeep } from './types'
import { GetFiledConfig, GetFiledConfigs, MapGraphqlFilterOperator } from './helper'
import { ITableFilterItem, TablePaginationModel, ITableSortItem, ITableQuickSearch } from './types'
import { ELogic, EOperator, EOrder, IModelFilter, ITableTemplateState, PropFilter, PropOrderBy } from '../../Table'

namespace TableToRequestFilter {
  interface IBase<R> {
    build: () => R
  }

  class DataCollection<TModel> {
    protected storePageInfo: TablePaginationModel = { page: 0, pageSize: 25 }
    pageInfo = (value?: TablePaginationModel) => {
      Object.assign(this.storePageInfo, value)
      return this
    }

    protected storeSort: { [key in keyof TModel]?: ITableSortItem<TModel> } = {}
    sort = (value?: ITableSortItem<TModel>) => {
      if (!!value) {
        this.storeSort[value.field] = value
      }
      return this
    }

    protected storeFilter: ITableFilterItem<TModel>[] = []
    filter = (value?: ITableFilterItem<TModel>) => {
      if (!!value) {
        this.storeFilter.push(value)
      }
      return this
    }

    protected storeQuickSearch: ITableQuickSearch<TModel> = {}
    quickSearch = (value?: ITableQuickSearch<TModel>) => {
      if (!!value) {
        this.storeQuickSearch = value
      }
      return this
    }

    fromTable = (tableInfo: ITableTemplateState<TModel>, selectKeys: (keyof TModel)[]) => {
      this.pageInfo(tableInfo.PageInfo)
      tableInfo.GridSortModel?.forEach((x) => this.sort(x as ITableSortItem<TModel>))
      tableInfo.FilterModel?.items.forEach((x) => {
        const lgo = tableInfo.FilterModel?.logicOperator as any as ETableLogicOperator
        this.filter({
          id: x.id,
          field: x.field as keyof TModel,
          operator: parseInt(x.operator),
          value: x.value,
          logicOperator: lgo
        })
      })
      this.quickSearch({ items: tableInfo.FilterModel?.quickFilterValues, selectKeys })
      return this
    }
  }

  interface ISwaggerParam<TModel> {
    acceptedFileds: (keyof TModel)[]
    fieldMapping?: { [key in keyof TModel]?: { field: keyof TModel } }
  }

  export class Swagger<TModel> extends DataCollection<TModel> implements IBase<Partial<IModelFilter<TModel>>> {
    private config: ISwaggerParam<TModel>
    constructor(config: ISwaggerParam<TModel>) {
      super()
      this.config = config
    }

    private getField = (key: any): keyof TModel | undefined => {
      const { acceptedFileds, fieldMapping } = this.config
      const field = acceptedFileds.find((x) => x === key)
      const mField = fieldMapping?.[key as keyof TModel]
      return mField?.field || (!!field ? field : undefined)
    }

    private handleSorts = (store: ITableSortItem<TModel>[]): PropOrderBy<TModel>[] => {
      return store.reduce<PropOrderBy<TModel>[]>((a, b) => {
        const field = this.getField(b?.field)
        if (!!field && !!b) {
          a.push({ FieldName: field, Type: b.sort === 'asc' ? EOrder.Ascending : EOrder.Descending })
        }
        return a
      }, [])
    }

    private handleFilters = (store: ITableFilterItem<TModel>[]): PropFilter<TModel>[] => {
      return store.reduce<PropFilter<TModel>[]>((a, b) => {
        const field = this.getField(b.field)
        if (!!field && !!b) {
          const lg = b?.logicOperator === 'and' ? ELogic.And : ELogic.Or
          a.push({ FieldName: field, Value: b.value, Operator: b.operator, Logic: lg })
        }
        return a
      }, [])
    }

    private handleQuickSearch = (store: ITableQuickSearch<TModel>): PropFilter<TModel>[] => {
      if (!store.selectKeys) return []
      return store.selectKeys.reduce<PropFilter<TModel>[]>((a, key) => {
        store.items?.forEach((x) => {
          a.push({ FieldName: key, Value: x, Operator: EOperator.Contains, Logic: ELogic.Or })
        })
        return a
      }, [])
    }

    private getPropFilters = (sf: ITableFilterItem<TModel>[], sqs: ITableQuickSearch<TModel>): PropFilter<TModel>[] => {
      const pfs: PropFilter<TModel>[] = []
      const filters = this.handleFilters(sf)
      if (filters.length > 1) {
        pfs.push({ Scopes: filters, Logic: ELogic.And })
      } else if (filters.length === 1) {
        pfs.push({ ...filters[0], Logic: ELogic.And })
      }
      const quickSearch = this.handleQuickSearch(sqs)
      if (quickSearch.length > 1) {
        pfs.push({ Scopes: quickSearch, Logic: ELogic.And })
      } else if (quickSearch.length === 1) {
        pfs.push({ ...quickSearch[0], Logic: ELogic.And })
      }
      return pfs
    }

    build = (): Partial<IModelFilter<TModel>> => {
      const sorts = Object.values(this.storeSort) as ITableSortItem<TModel>[]
      const filterState: IModelFilter<TModel> = {
        PropFilters: this.getPropFilters(this.storeFilter, this.storeQuickSearch),
        Page: this.storePageInfo.page,
        Amount: this.storePageInfo.pageSize,
        PropOrders: this.handleSorts(sorts)
      }
      return filterState
    }
  }

  interface IGraphqlParam<TModel, TGraphQlModel extends Object> {
    fieldMapping: TFieldMapping<TModel, TGraphQlModel>
    fieldMappingDeep?: TFieldMappingDeep<TModel, TGraphQlModel>
  }

  export class Graphql<TModel, TGraphQlModel extends Object>
    extends DataCollection<TModel>
    implements IBase<GraphqlCore.IRequestParamResult<TGraphQlModel>>
  {
    private config: IGraphqlParam<TModel, TGraphQlModel>
    constructor(config: IGraphqlParam<TModel, TGraphQlModel>) {
      super()
      this.config = config
    }

    private handlePageInfo = (rp: GraphqlCore.RequestParam<TGraphQlModel>, store: TablePaginationModel) => {
      if (!store) return rp
      return rp.take(store.pageSize).skip(store.page * store.pageSize)
    }

    private handleSorts = (rp: GraphqlCore.RequestParam<TGraphQlModel>, store: ITableSortItem<TModel>[]) => {
      store?.forEach((x) => {
        const config = GetFiledConfig<TModel, TGraphQlModel>(x?.field as keyof TModel, this.config.fieldMapping)
        if (!!config) {
          rp.sort(config.field, { direction: x?.sort === 'asc' ? 'ASC' : 'DESC' })
        }
      })
      return rp
    }

    private handleFilters = (rp: GraphqlCore.RequestParam<TGraphQlModel>, store: ITableFilterItem<TModel>[]) => {
      store?.forEach((x) => {
        const { fieldMapping, fieldMappingDeep } = this.config
        const configs = GetFiledConfigs(x?.field as keyof TModel, fieldMapping, fieldMappingDeep)
        configs.forEach((config) => {
          let value = x.value
          if (config.type === 'double') {
            value = parseFloat(value).toFixed(1)
          } else if (config.type === 'int') {
            value = Math.floor(parseInt(value))
          }
          const logic = x.logicOperator === ETableLogicOperator.And ? 'And' : 'Or'
          MapGraphqlFilterOperator({ requestParam: rp, config, value, operator: x.operator, options: { logic } })
        })
      })
      return rp
    }

    private handleQuickSearch = (rp: GraphqlCore.RequestParam<TGraphQlModel>, store: ITableQuickSearch<TModel>) => {
      store.selectKeys?.forEach((k) => {
        const fieldConfigs = GetFiledConfigs(k, this.config.fieldMapping, this.config.fieldMappingDeep)
        fieldConfigs.forEach((f) => {
          store.items?.forEach((i) => {
            rp.filterContains(f.field, i)
          })
        })
      })
      return rp
    }

    private handleFilterAll = (rp: GraphqlCore.RequestParam<TGraphQlModel>, sf: ITableFilterItem<TModel>[], sqs: ITableQuickSearch<TModel>) => {
      if (sf.length > 1) {
        rp.scope((q) => this.handleFilters(q, sf))
      } else if (sf.length === 1) {
        this.handleFilters(rp, this.storeFilter)
      }
      rp.scope((q) => this.handleQuickSearch(q, sqs), { logic: 'And' })
    }

    build = () => {
      let rp = GraphqlCore.createRequestBuilder<TGraphQlModel>({ ignoreEmpty: true })
      this.handlePageInfo(rp, this.storePageInfo)
      this.handleSorts(rp, Object.values(this.storeSort) as ITableSortItem<TModel>[])
      this.handleFilterAll(rp, this.storeFilter, this.storeQuickSearch)
      return rp.build()
    }
  }
}
export default TableToRequestFilter

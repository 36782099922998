import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Box, Container } from '@mui/material'
import { RouteKey } from '@internal/route'
import { CreateHocLazy } from '@lib/ReduxBase'
import { OrderDetailContent } from '@shared/Pages/Order'
import UIStyleds from '@shared/UIStyleds'
import LayoutModalRoute from '@lib/Layout/ModalRoute'
import { IOrderDetailProps } from './Redux/type'
import { mapDispatchToProps, mapStateToProps } from './Redux/Map'

interface IProps extends IOrderDetailProps {}

class ViewBase extends Component<IProps> {
  render() {
    return (
      <UIStyleds.WrapScroll sx={{ mx: '-10px' }}>
        <Container maxWidth={false} sx={{ mt: '24px' }}>
          <OrderDetailContent key={new Date().getTime().toString()} data={this.props.dataDetail} />
        </Container>
        <Box sx={{ height: '10rem' }} />
      </UIStyleds.WrapScroll>
    )
  }
}

export * from './Redux/Thunks'
export { default as OrderDetailSlice } from './Redux/Slice'

const OrderDetailLazy = CreateHocLazy(ViewBase)
export const OrderDetailMapRedux = connect(mapStateToProps, mapDispatchToProps)(OrderDetailLazy)
export const OrderDetailModal: React.FC = () => (
  <LayoutModalRoute.Wrapper size='lg' title='Order detail' back={[RouteKey.Profile, RouteKey.Order]}>
    <OrderDetailMapRedux />
  </LayoutModalRoute.Wrapper>
)

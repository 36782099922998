import { ServiceBase, CreateHttpService } from '@lib/Http'
import { EOpenType, IItemInfo, PreviewInfo } from './type'

class UploadServiceBase extends ServiceBase {
  constructor() {
    super(CreateHttpService(`${window.location.origin}/api/v1/user/Upload`))
  }

  private urlGetPreviewInfo = 'GetPreviewInfo'
  getPreviewInfo = async (fileId: string) => {
    const res = await this.TryGet<PreviewInfo>(this.urlGetPreviewInfo, { params: { fileId } })
    if (res?.Type === EOpenType.audio || res?.Type === EOpenType.video || res?.Type === EOpenType.image) {
      if (!res.content.startsWith('/')) {
        res.content = '/' + res.content
      }
    }
    return res
  }

  private urlDownload = '/api/v1/user/Upload/Download'
  Download = async (file: IItemInfo) => {
    let fileName = file.Name
    if (!fileName.endsWith(file.Extension)) {
      fileName = `${file.Name}.${file.Extension}`
    }
    const href = `${this.urlDownload}?fileId=${file.DriveFileId}`
    const a = document.createElement('a')
    a.href = href
    a.setAttribute('download', fileName)
    a.click()
    URL.revokeObjectURL(href)
  }
}
const UploadService = new UploadServiceBase()
export default UploadService

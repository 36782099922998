import { NoInfer } from 'react-redux'
import { createEntityAdapter, createSlice, Slice, SliceCaseReducers } from '@reduxjs/toolkit'
import { ActionReducerMapBuilder, EntityAdapter, IdSelector, ValidateSliceCaseReducers } from '@reduxjs/toolkit'
import { ISliceBase, ELazyStatus } from './types'
import { CRUDActionRedux } from './CRUDActionRedux'

interface ICreateCRUDSliceOption<TModel, TSliceState extends ISliceBase<TModel>, CaseReducers extends SliceCaseReducers<TSliceState>> {
  name: string
  selectId?: IdSelector<TModel>
  Initial?: (state: ISliceBase<TModel>) => TSliceState
  extraReducers?: (builder: ActionReducerMapBuilder<NoInfer<TSliceState>>, adapter: EntityAdapter<TModel>) => void
  reducers?: ValidateSliceCaseReducers<TSliceState, CaseReducers>
}
const CreateCRUDSlice2 = <TModel>() => {
  return <CR extends SliceCaseReducers<TSliceState>, TSliceState extends ISliceBase<TModel> = ISliceBase<TModel>>(
    options: ICreateCRUDSliceOption<TModel, TSliceState, CR>
  ) => {
    const SliceAdapter = createEntityAdapter<TModel>({
      selectId: options.selectId
    })
    // Define the initial state using that type

    const Initial = options.Initial ? options.Initial : (x: any) => x
    const initialState: TSliceState = Initial({
      Status: ELazyStatus.Loading,
      Data: SliceAdapter.getInitialState()
    })

    const ModelSlice = createSlice({
      name: options.name,
      // `createSlice` will infer the state type from the `initialState` argument
      initialState,
      reducers: {
        ...CRUDActionRedux(SliceAdapter),
        ...(options.reducers ?? {})
      },
      extraReducers: (builder) => options.extraReducers && options.extraReducers(builder, SliceAdapter)
    })
    return ModelSlice as Slice<TSliceState, ReturnType<typeof CRUDActionRedux> & CR, string>
  }
}
export default CreateCRUDSlice2

import { EPriceType } from '@shared/Types'
import { IPricingConfigDTO } from './type'

export const PriceConfigs: IPricingConfigDTO[] = [
  {
    type: EPriceType.Media,
    info: {
      name: 'Media package',
      description: 'For editorial use in social media, youtube videos, blogs & websites.'
    }
  },
  {
    type: EPriceType.Pack,
    info: {
      name: 'Pack package',
      description: 'Pack package description'
    },
    disabled: true
  }
]

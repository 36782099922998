import { IPrice } from '@shared/Types'
import { CRUDServiceBase } from '@lib/Http'

class PriceServiceBase extends CRUDServiceBase<IPrice, number> {
  constructor() {
    super(`/api/v1/guest/Price`)
  }
}
const PriceService = new PriceServiceBase()
export default PriceService

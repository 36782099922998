import { ISubmission } from '@shared/Types'
import { TableQueryParam } from '@lib/Helpers'
import { RootState, AppDispatch } from '@internal/redux'
import { fetchSubmissionThunk } from './redux.thunks'
import { ISubmissionMapDispatchRedux, ISubmissionMapStateRedux } from './redux.types'
import SubmissionSlice, { DefaultTableInfo } from './redux.slice'

export const mapStateToProps = (state: RootState): ISubmissionMapStateRedux => ({
  Status: state.SubmissionSlice.Status,
  stateRedux: state.SubmissionSlice,
  tableInfo: state.SubmissionSlice.tableInfo
})

type TRequest = { abort: () => void }
const FetchAccessory: { request?: TRequest } = {}
export const mapDispatchToProps = (dispatch: AppDispatch): ISubmissionMapDispatchRedux => ({
  FetchData: () => {
    const tableInfo = TableQueryParam.getParam<ISubmission>(DefaultTableInfo)
    return dispatch(fetchSubmissionThunk({ tableInfo }))
  },
  onTableChange: (key, value, details) => {
    if (key === 'FilterModel') {
      dispatch(SubmissionSlice.actions.onChange({ key, value, details }))
    }
    FetchAccessory.request?.abort()
    FetchAccessory.request = dispatch(fetchSubmissionThunk({ tableChange: { key, value: value, details } }))
  }
})

import type { AcceptableVariables, UnresolvedVariables, FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';
import type {MediaType} from '../enums';
import type {MediaStatus} from '../enums';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface MediaFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'Media', T, TVariables> {

    on<XName extends ImplementationType<'Media'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): MediaFetcher<
        XName extends 'Media' ?
        T & X :
        WithTypeName<T, ImplementationType<'Media'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'Media'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): MediaFetcher<T, TVariables>;


    readonly __typename: MediaFetcher<T & {__typename: ImplementationType<'Media'>}, TVariables>;


    readonly id: MediaFetcher<T & {readonly "id": string}, TVariables>;

    "id+"<
        XAlias extends string = "id", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"id", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): MediaFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~id": MediaFetcher<Omit<T, 'id'>, TVariables>;


    readonly userCreatedId: MediaFetcher<T & {readonly "userCreatedId": string}, TVariables>;

    "userCreatedId+"<
        XAlias extends string = "userCreatedId", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"userCreatedId", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): MediaFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~userCreatedId": MediaFetcher<Omit<T, 'userCreatedId'>, TVariables>;


    readonly name: MediaFetcher<T & {readonly "name"?: string}, TVariables>;

    "name+"<
        XAlias extends string = "name", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"name", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~name": MediaFetcher<Omit<T, 'name'>, TVariables>;


    readonly description: MediaFetcher<T & {readonly "description"?: string}, TVariables>;

    "description+"<
        XAlias extends string = "description", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"description", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~description": MediaFetcher<Omit<T, 'description'>, TVariables>;


    readonly duration: MediaFetcher<T & {readonly "duration": number}, TVariables>;

    "duration+"<
        XAlias extends string = "duration", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"duration", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): MediaFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: number} : 
                {readonly [key in XAlias]: number}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~duration": MediaFetcher<Omit<T, 'duration'>, TVariables>;


    readonly size: MediaFetcher<T & {readonly "size": number}, TVariables>;

    "size+"<
        XAlias extends string = "size", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"size", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): MediaFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: number} : 
                {readonly [key in XAlias]: number}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~size": MediaFetcher<Omit<T, 'size'>, TVariables>;


    readonly extension: MediaFetcher<T & {readonly "extension"?: string}, TVariables>;

    "extension+"<
        XAlias extends string = "extension", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"extension", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~extension": MediaFetcher<Omit<T, 'extension'>, TVariables>;


    readonly resourceId: MediaFetcher<T & {readonly "resourceId"?: string}, TVariables>;

    "resourceId+"<
        XAlias extends string = "resourceId", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"resourceId", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~resourceId": MediaFetcher<Omit<T, 'resourceId'>, TVariables>;


    readonly type: MediaFetcher<T & {readonly "type": MediaType}, TVariables>;

    "type+"<
        XAlias extends string = "type", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"type", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): MediaFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: MediaType} : 
                {readonly [key in XAlias]: MediaType}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~type": MediaFetcher<Omit<T, 'type'>, TVariables>;


    readonly status: MediaFetcher<T & {readonly "status": MediaStatus}, TVariables>;

    "status+"<
        XAlias extends string = "status", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"status", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): MediaFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: MediaStatus} : 
                {readonly [key in XAlias]: MediaStatus}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~status": MediaFetcher<Omit<T, 'status'>, TVariables>;


    readonly reason: MediaFetcher<T & {readonly "reason"?: string}, TVariables>;

    "reason+"<
        XAlias extends string = "reason", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"reason", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~reason": MediaFetcher<Omit<T, 'reason'>, TVariables>;


    readonly deliveryId: MediaFetcher<T & {readonly "deliveryId"?: string}, TVariables>;

    "deliveryId+"<
        XAlias extends string = "deliveryId", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"deliveryId", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~deliveryId": MediaFetcher<Omit<T, 'deliveryId'>, TVariables>;


    categoryDetail<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'CategoryDetail', X, XVariables>
    ): MediaFetcher<
        T & {readonly "categoryDetail"?: X}, 
        TVariables & XVariables & MediaArgs["categoryDetail"]
    >;

    categoryDetail<
        XArgs extends AcceptableVariables<MediaArgs['categoryDetail']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'CategoryDetail', X, XVariables>
    ): MediaFetcher<
        T & {readonly "categoryDetail"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaArgs['categoryDetail']>
    >;

    categoryDetail<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "categoryDetail", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'CategoryDetail', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"categoryDetail", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaArgs["categoryDetail"] & XDirectiveVariables
    >;

    categoryDetail<
        XArgs extends AcceptableVariables<MediaArgs['categoryDetail']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "categoryDetail", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'CategoryDetail', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"categoryDetail", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaArgs['categoryDetail']> & XDirectiveVariables
    >;


    categoryDetails<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'CategoryDetail', X, XVariables>
    ): MediaFetcher<
        T & {readonly "categoryDetails"?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables
    >;

    categoryDetails<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "categoryDetails", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'CategoryDetail', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"categoryDetails", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaFetcher<
        T & {readonly [key in XAlias]?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    mediaDelivery<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'MediaDelivery', X, XVariables>
    ): MediaFetcher<
        T & {readonly "mediaDelivery"?: X}, 
        TVariables & XVariables
    >;

    mediaDelivery<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "mediaDelivery", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'MediaDelivery', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"mediaDelivery", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    readonly dateCreated: MediaFetcher<T & {readonly "dateCreated": string}, TVariables>;

    "dateCreated+"<
        XAlias extends string = "dateCreated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateCreated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): MediaFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateCreated": MediaFetcher<Omit<T, 'dateCreated'>, TVariables>;


    readonly dateUpdated: MediaFetcher<T & {readonly "dateUpdated": string}, TVariables>;

    "dateUpdated+"<
        XAlias extends string = "dateUpdated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateUpdated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): MediaFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateUpdated": MediaFetcher<Omit<T, 'dateUpdated'>, TVariables>;


    categories<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Category', X, XVariables>
    ): MediaFetcher<
        T & {readonly "categories"?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables
    >;

    categories<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "categories", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Category', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"categories", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaFetcher<
        T & {readonly [key in XAlias]?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    user<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'ApplicationUser', X, XVariables>
    ): MediaFetcher<
        T & {readonly "user"?: X}, 
        TVariables & XVariables
    >;

    user<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "user", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'ApplicationUser', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"user", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;
}

export const media$: MediaFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "Media", 
            "OBJECT", 
            [], 
            [
                {
                    category: "ID", 
                    name: "id"
                }, 
                "userCreatedId", 
                {
                    category: "SCALAR", 
                    name: "name", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "description", 
                    undefinable: true
                }, 
                "duration", 
                "size", 
                {
                    category: "SCALAR", 
                    name: "extension", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "resourceId", 
                    undefinable: true
                }, 
                "type", 
                "status", 
                {
                    category: "SCALAR", 
                    name: "reason", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "deliveryId", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "categoryDetail", 
                    argGraphQLTypeMap: {id: 'ID!'}, 
                    targetTypeName: "CategoryDetail", 
                    undefinable: true
                }, 
                {
                    category: "LIST", 
                    name: "categoryDetails", 
                    targetTypeName: "CategoryDetail", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "mediaDelivery", 
                    targetTypeName: "MediaDelivery", 
                    undefinable: true
                }, 
                "dateCreated", 
                "dateUpdated", 
                {
                    category: "LIST", 
                    name: "categories", 
                    targetTypeName: "Category", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "user", 
                    targetTypeName: "ApplicationUser", 
                    undefinable: true
                }
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const media$$ = 
    media$
        .id
        .userCreatedId
        .name
        .description
        .duration
        .size
        .extension
        .resourceId
        .type
        .status
        .reason
        .deliveryId
        .dateCreated
        .dateUpdated
;

export interface MediaArgs {

    readonly categoryDetail: {
        readonly id: string
    }
}

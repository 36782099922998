import { RouteKey } from '@internal/route'
import { EMediaType } from '@shared/Types'
import { CreateRoutePath } from '@lib/RouteBase'
import store from '@internal/redux'
import * as IconsMaterial from '@mui/icons-material'
import { putFilterExplore } from '../../Views/Explore'

interface IFooterNavConfigs {
  title: string
  items: { name: string; key: string; onClick?: () => void }[]
}

export const FooterNavConfigs: IFooterNavConfigs[] = [
  {
    title: 'Our product',
    items: [
      {
        name: 'Videos',
        key: CreateRoutePath(RouteKey.Explore, { query: { mediaType: EMediaType.Video } }),
        onClick: () => putFilterExplore(store.dispatch)('mediaType', EMediaType.Video)
      },
      {
        name: 'Audios',
        key: CreateRoutePath(RouteKey.Explore, { query: { mediaType: EMediaType.Audio } }),
        onClick: () => putFilterExplore(store.dispatch)('mediaType', EMediaType.Audio)
      }
    ]
  },
  {
    title: 'Support',
    items: [
      { name: 'Ticket', key: CreateRoutePath([RouteKey.Profile, RouteKey.Ticket]) },
      { name: 'FQAs', key: CreateRoutePath(RouteKey.FQAs) },
      { name: 'Pricing', key: CreateRoutePath(RouteKey.Pricing) },
      { name: 'Submit Media', key: CreateRoutePath(RouteKey.Submit) },
      { name: 'Privacy Policy', key: CreateRoutePath(RouteKey.PrivacyPolicy) },
      { name: 'Term of Submission', key: CreateRoutePath(RouteKey.TermsOfSubmission) }
    ]
  }
]

interface IFooterSocialConfigs {
  link: string
  icon: JSX.Element
}

const footerSocialColor = '#606060'

export const FooterSocialConfigs: IFooterSocialConfigs[] = [
  { link: 'http://google.com', icon: <IconsMaterial.YouTube sx={{ color: footerSocialColor }} /> },
  { link: 'http://google.com', icon: <IconsMaterial.Instagram sx={{ color: footerSocialColor }} /> },
  { link: 'http://google.com', icon: <IconsMaterial.Twitter sx={{ color: footerSocialColor }} /> },
  { link: 'http://google.com', icon: <IconsMaterial.Pinterest sx={{ color: footerSocialColor }} /> },
  { link: 'http://google.com', icon: <IconsMaterial.Reddit sx={{ color: footerSocialColor }} /> }
]

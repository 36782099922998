import React, { Component } from 'react'
import { TableFormater } from '@lib/Helpers'
import { CreateInfoBar } from '@shared/UIStyleds'
import { Box, Collapse, Divider, SxProps, Theme } from '@mui/material'
import { IBillingDTO } from './types'
import { BillingStatusCell } from './StatusCell'

interface IInfoSectionProps {
  in: boolean
  data?: Partial<IBillingDTO>
  client?: boolean
  after?: JSX.Element
}
export default class BillingInfoBar extends Component<IInfoSectionProps> {
  render() {
    const InfoBar = CreateInfoBar<IBillingDTO>({
      DateCreated: {
        label: 'Date created',
        sx: { minWidth: '120px' },
        valueFormater: (value) => TableFormater.formatDate(value)
      },
      ...(this.props.client === true ? {} : { DisplayName: { label: 'User', sx: { minWidth: '50px' } } }),
      Type: { label: 'Type', sx: { minWidth: '50px' } },
      Total: { label: 'Total (USD)', valueFormater: (value) => TableFormater.formatCurrency(value) },
      Status: {
        label: 'Status',
        element: (x) => {
          if (!x.data?.Status) return <></>
          return (
            <>
              <Divider variant='middle' orientation='vertical' flexItem sx={{ my: '4px' }} />
              <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                <BillingStatusCell data={x.data.Status} />
              </Box>
            </>
          )
        }
      }
    })
    return (
      <Collapse in={this.props.in} unmountOnExit>
        <InfoBar data={this.props.data} sx={{ mb: '9px' }} options={{ after: this.props.after }} />
      </Collapse>
    )
  }
}

export const BillingInfoBarStatus: React.FC<React.PropsWithChildren<{ sx?: SxProps<Theme> }>> = ({ sx, children }) => {
  return (
    <>
      <Divider variant='middle' orientation='vertical' flexItem sx={{ my: '4px' }} />
      <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', ...sx }}>{children}</Box>
    </>
  )
}

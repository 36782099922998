import React, { Component } from 'react'
import { IFormBase } from '@lib/Forms'
import { IMediaDelivery } from '@shared/Types'
import { Box, Button, styled, Typography } from '@mui/material'

interface IProps extends IFormBase<IMediaDelivery> {
  title: string
  icon: JSX.Element
}
export default class CustomAction extends Component<IProps> {
  render() {
    return (
      <WrapAction>
        {this.props.icon}
        <Typography variant='subtitle2' component='h6' sx={{ fontWeight: 600, flex: 1, color: '#848484' }}>
          {this.props.title}
        </Typography>
        <Button variant='contained' type='submit' size='small'>
          Submit
        </Button>
      </WrapAction>
    )
  }
}
const WrapAction = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '0 12px',
  boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
  position: 'sticky',
  bottom: 0,
  backgroundColor: '#fff',
  zIndex: 1,
  height: '48px',
  gap: '6px'
})

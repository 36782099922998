import type { FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface BusinessInformationFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'BusinessInformation', T, TVariables> {

    on<XName extends ImplementationType<'BusinessInformation'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): BusinessInformationFetcher<
        XName extends 'BusinessInformation' ?
        T & X :
        WithTypeName<T, ImplementationType<'BusinessInformation'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'BusinessInformation'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): BusinessInformationFetcher<T, TVariables>;


    readonly __typename: BusinessInformationFetcher<T & {__typename: ImplementationType<'BusinessInformation'>}, TVariables>;


    readonly id: BusinessInformationFetcher<T & {readonly "id": number}, TVariables>;

    "id+"<
        XAlias extends string = "id", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"id", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): BusinessInformationFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: number} : 
                {readonly [key in XAlias]: number}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~id": BusinessInformationFetcher<Omit<T, 'id'>, TVariables>;


    readonly userId: BusinessInformationFetcher<T & {readonly "userId"?: string}, TVariables>;

    "userId+"<
        XAlias extends string = "userId", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"userId", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): BusinessInformationFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~userId": BusinessInformationFetcher<Omit<T, 'userId'>, TVariables>;


    readonly companyName: BusinessInformationFetcher<T & {readonly "companyName"?: string}, TVariables>;

    "companyName+"<
        XAlias extends string = "companyName", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"companyName", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): BusinessInformationFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~companyName": BusinessInformationFetcher<Omit<T, 'companyName'>, TVariables>;


    readonly position: BusinessInformationFetcher<T & {readonly "position"?: string}, TVariables>;

    "position+"<
        XAlias extends string = "position", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"position", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): BusinessInformationFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~position": BusinessInformationFetcher<Omit<T, 'position'>, TVariables>;


    readonly address: BusinessInformationFetcher<T & {readonly "address"?: string}, TVariables>;

    "address+"<
        XAlias extends string = "address", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"address", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): BusinessInformationFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~address": BusinessInformationFetcher<Omit<T, 'address'>, TVariables>;


    readonly taxId: BusinessInformationFetcher<T & {readonly "taxId"?: string}, TVariables>;

    "taxId+"<
        XAlias extends string = "taxId", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"taxId", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): BusinessInformationFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~taxId": BusinessInformationFetcher<Omit<T, 'taxId'>, TVariables>;


    user<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'ApplicationUser', X, XVariables>
    ): BusinessInformationFetcher<
        T & {readonly "user"?: X}, 
        TVariables & XVariables
    >;

    user<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "user", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'ApplicationUser', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"user", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): BusinessInformationFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    readonly dateCreated: BusinessInformationFetcher<T & {readonly "dateCreated": string}, TVariables>;

    "dateCreated+"<
        XAlias extends string = "dateCreated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateCreated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): BusinessInformationFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateCreated": BusinessInformationFetcher<Omit<T, 'dateCreated'>, TVariables>;


    readonly dateUpdated: BusinessInformationFetcher<T & {readonly "dateUpdated": string}, TVariables>;

    "dateUpdated+"<
        XAlias extends string = "dateUpdated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateUpdated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): BusinessInformationFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateUpdated": BusinessInformationFetcher<Omit<T, 'dateUpdated'>, TVariables>;
}

export const businessInformation$: BusinessInformationFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "BusinessInformation", 
            "OBJECT", 
            [], 
            [
                {
                    category: "ID", 
                    name: "id"
                }, 
                {
                    category: "SCALAR", 
                    name: "userId", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "companyName", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "position", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "address", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "taxId", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "user", 
                    targetTypeName: "ApplicationUser", 
                    undefinable: true
                }, 
                "dateCreated", 
                "dateUpdated"
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const businessInformation$$ = 
    businessInformation$
        .id
        .userId
        .companyName
        .position
        .address
        .taxId
        .dateCreated
        .dateUpdated
;

import React, { Component } from 'react'
import { Box, styled } from '@mui/material'
import { HelperService } from '@shared/Services'
import { IPrice, IPriceJsonContent } from '@shared/Types'

interface IProps {
  data: IPrice
}

export class PriceItem extends Component<IProps> {
  render() {
    return (
      <Box>
        <BasicInfo sx={{ borderBottom: `8px solid ${this.getColor()}` }}>
          <LicenseText>{this.props.data.Name}</LicenseText>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '9px' }}>
            <PriceText>$ {this.props.data.Price}</PriceText>
            <Text sx={{ fontSize: '16px', marginBottom: '9px' }}>/per clip</Text>
          </Box>
          <Text sx={{ marginTop: '12px' }}>{this.props.data.Description}</Text>
        </BasicInfo>
        {this.renderBottomContent()}
      </Box>
    )
  }

  renderBottomContent = () => {
    const jsonContent: IPriceJsonContent = HelperService.ParsePriceJsonContent(this.props.data.JsonContent)
    return (
      <WrapContent>
        <BoxPreview component='p' dangerouslySetInnerHTML={{ __html: jsonContent.Content ?? '' }} />
      </WrapContent>
    )
  }

  getColor = (defaultValue?: string): string => {
    const jsonContent: IPriceJsonContent = HelperService.ParsePriceJsonContent(this.props.data.JsonContent)
    return jsonContent.Color ?? defaultValue ?? '#818181'
  }
}

const BoxPreview = styled(Box)({
  all: 'revert',
  '& *': {
    all: 'revert'
  }
})

const BasicInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  padding: '50px 36px',
  borderRadius: '6px',
  backgroundColor: '#fdfdfd',
  border: '1px solid rgb(216, 216, 216)',
  transition: '0.3s',
  height: '275px',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    maxWidth: '360px'
  }
}))

const WrapContent = styled(Box)({
  fontFamily: "'Exo 2', sans-serif",
  marginTop: '12px',
  padding: '9px 18px ',
  borderRadius: '6px',
  backgroundColor: '#fdfdfd'
})

const LicenseText = styled('h5')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '22px',
  fontWeight: 500
})

const PriceText = styled('h3')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '48px',
  fontWeight: 700
})

const Text = styled('span')({
  display: 'inline-block',
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 500,
  color: '#606060'
})

import React, { Component } from 'react'
import { RouteKey } from '@internal/route'
import { CreateRoutePath } from '@lib/RouteBase'
import { UIButtonBack } from '@shared/UIStyleds'
import { Box, Container, Grid, styled } from '@mui/material'

interface IProps {
  content: JSX.Element
  summaryReport: JSX.Element
}
export default class UILayout extends Component<IProps> {
  render() {
    const backPath = CreateRoutePath(RouteKey.Explore)
    return (
      <Container sx={{ pt: '64px', pb: '128px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Box sx={{ minHeight: '50vh' }}>
              <Title sx={{ mb: '5px' }}>Cart</Title>
              <Text18>Please check this information is correct</Text18>
              {this.props.content}
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <UIButtonBack to={backPath}>Continue shopping</UIButtonBack>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            {this.props.summaryReport}
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: { xs: 'block', md: 'none' } }}>
            <UIButtonBack to={backPath}>Continue shopping</UIButtonBack>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

const Title = styled('h1')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '32px',
  fontWeight: 700,
  color: '#606060'
})

const Text18 = styled('p')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '18px',
  fontWeight: 500,
  color: '#606060'
})

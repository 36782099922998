import { ISubmission } from '@shared/Types'
import { ISelectWithApiOption } from '@lib/Forms/Inputs'
import { ELogic, EOperator, PropFilter } from '@lib/Table'
import SubmissionService from './SubmissionService'
export interface ISelectReponse {
  Id: string
  Name: string
}
class SelectServiceBase {
  fetchSubmissions = async (value?: string, signal?: AbortSignal): Promise<ISelectWithApiOption[]> => {
    let PropFilters: PropFilter<ISubmission>[] | undefined = undefined
    if (!!value) {
      PropFilters = [{ FieldName: 'Title', Value: value.toString(), Operator: EOperator.Contains, Logic: ELogic.Or }]
      try {
        if (!isNaN(parseInt(`${value ?? ''}`))) {
          PropFilters.push({ FieldName: 'Id', Value: value.toString(), Operator: EOperator.Contains, Logic: ELogic.Or })
        }
      } catch (error) {}
    }
    const res = await SubmissionService.All({ Page: 0, Amount: 50, PropFilters: PropFilters?.reverse() }, signal, { delay: 0 })
    return (res?.Data ?? []).map<ISelectWithApiOption>((item, index) => ({ Id: item.Id.toString(), Name: `[${item.Email}] ${item.Title}` }))
  }
}
const SelectService = new SelectServiceBase()
export default SelectService

export enum EAppName {
  ClientApp = 'ClientApp',
  ClientAdmin = 'ClientAdmin'
}

export enum EGraphqlSite {
  Admin = 'admin',
  Manage = 'manage',
  User = 'user'
}

import { IMedia } from '@shared/Types'
import { FetchDelay } from '@lib/Helpers'
import { ServiceBase, CreateHttpService } from '@lib/Http'
import { IMediaFilterDTO } from './types'
import MediaMappingService from './service.mapping'

interface IFetchFilterResult {
  Amount: number
  Page: number
  Total: number
  Data: IMedia[]
}

class ServiceMediaBase extends ServiceBase {
  constructor() {
    super(CreateHttpService(`${process.env.REACT_APP_API_URI}/api/v1/guest/Media`))
  }
  private urlAll = '/All'
  filter = async (filter: IMediaFilterDTO, signal?: AbortSignal): Promise<IFetchFilterResult> => {
    return FetchDelay<IFetchFilterResult>(async () => {
      try {
        const filterReq = MediaMappingService.parseMedia(filter)
        const res = await this.PushNotify(this.Post<IFetchFilterResult>, this.urlAll, filterReq, { signal })
        return res
      } catch (error) {
        return { Products: [], Amount: 0, Data: [], Page: 0, Total: 0 } as IFetchFilterResult
      }
    }, 1000)
  }
}
const MediaService = new ServiceMediaBase()
export default MediaService

import React, { Component } from 'react'
import { ELazyStatus } from '@lib/ReduxBase'
import { ScrollToBottom } from '@lib/Helpers'
import { Box, Divider, Fade, styled, Typography } from '@mui/material'
import { ITicket, ETicketStatus, ITicketConversation } from '@shared/Types'
import UIStyleds from '@shared/UIStyleds'
import ContentTimeline from './ContentTimeline'
import { FormSend, IFormSendData } from './Forms'

interface IProps {
  data?: ITicket
  status?: ELazyStatus
  requestedId?: string
  onSubmit: (value: Partial<IFormSendData>) => Promise<void>
  renderRepresentIcon: (item: ITicketConversation) => JSX.Element | undefined
}

export default class TicketDetailContent extends Component<IProps> {
  refWrapScroll: HTMLDivElement | null = null
  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const check2 = this.props.data?.TicketConversations?.length !== prevProps.data?.TicketConversations?.length
    if (this.props.requestedId !== prevProps.requestedId || check2) {
      setTimeout(() => {
        if (!this.refWrapScroll) return
        ScrollToBottom(this.refWrapScroll, 450) // milliseconds for scroll duration
      }, 100)
    }
  }
  render() {
    return (
      <>
        <CustomWrapScroll>
          <UIStyleds.WrapScrollRef
            ref={(ref) => (this.refWrapScroll = ref)}
            sx={{ display: 'flex', alignItems: 'flex-end', marginRight: '4px', '& > div': { paddingRight: '9px' } }}
          >
            <ContentTimeline status={this.props.status} data={this.props.data?.TicketConversations} renderIcon={this.props.renderRepresentIcon} />
          </UIStyleds.WrapScrollRef>
        </CustomWrapScroll>
        <WrapBottom>
          <Fade in={this.props.status === ELazyStatus.Loaded}>
            <Box>{this.renderFormSend()}</Box>
          </Fade>
        </WrapBottom>
      </>
    )
  }
  renderFormSend = () => {
    switch (this.props.data?.Status) {
      case ETicketStatus.Closed:
        return (
          <>
            <Divider flexItem sx={{ width: '100%' }} />
            <NotifyText variant='subtitle1'>Ticket has closed</NotifyText>
          </>
        )
      case ETicketStatus.Open:
        return <FormSend key={new Date().getTime().toString()} onSubmit={this.props.onSubmit} />
      default:
        return <></>
    }
  }
}
const CustomWrapScroll = styled(Box)({
  display: 'flex',
  flex: 1,
  backgroundColor: '#fbfbfb',
  borderTopLeftRadius: '9px',
  borderTopRightRadius: '9px',
  paddingLeft: '12px'
})
const WrapBottom = styled(Box)(({ theme }) => ({
  backgroundColor: '#fbfbfb',
  borderBottomLeftRadius: '9px',
  borderBottomRightRadius: '9px',
  overflow: 'hidden',
  padding: '9px 12px',
  boxShadow: theme.app.boxShadow[1]
}))
const NotifyText = styled(Typography)({
  fontStyle: 'italic',
  paddingTop: '12px',
  color: '#d32f2f',
  fontWeight: 600,
  width: '100%',
  textAlign: 'center'
})

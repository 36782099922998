import { ICart } from '@shared/Types'
import { ELazyStatus } from '@lib/ReduxBase'
import { ECheckOutValidateStatus } from '@shared/Types'
import { CartLocalStorage } from '@internal/shareds/LocalStorages'
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { ICartStateSlice } from './redux.types'
import { fetchCartThunk, fetchCheckOutValidateThunk, removeAllCartThunk } from './redux.thunks'

const CartSliceAdapter = createEntityAdapter<ICart>({ selectId: (x) => x.Id })

const initialCarts = (): EntityState<ICart> => {
  const carts = CartLocalStorage.getData()
  return carts.reduce<EntityState<ICart>>(
    (a, b) => {
      a.ids.push(b.Id)
      a.entities[b.Id] = b
      return a
    },
    { ids: [], entities: {} }
  )
}

// Define the initial state using that type
const initialState: ICartStateSlice = {
  Status: ELazyStatus.Loading,
  checkOutStatus: ECheckOutValidateStatus.Pending,
  carts: CartSliceAdapter.getInitialState(initialCarts())
}

const CartSlice = createSlice({
  name: 'CartSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<ELazyStatus>) => {
      state.Status = action.payload
    },
    addCart: (state, action: PayloadAction<ICart>) => {
      CartSliceAdapter.upsertOne(state.carts, action.payload)
      CartLocalStorage.setData(Object.values(state.carts.entities) as ICart[])
    },
    updateCart: (state, action: PayloadAction<ICart>) => {
      CartSliceAdapter.updateOne(state.carts, { id: action.payload.Id, changes: action.payload })
      CartLocalStorage.setData(Object.values(state.carts.entities) as ICart[])
    },
    removeCart: (state, action: PayloadAction<string>) => {
      CartSliceAdapter.removeOne(state.carts, action.payload)
      CartLocalStorage.setData(Object.values(state.carts.entities) as ICart[])
    },
    removeAllCart: (state) => {}
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Loaded

        CartSliceAdapter.removeAll(state.carts)
        CartSliceAdapter.addMany(state.carts, action.payload.carts)
      })
      .addCase(fetchCartThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Error
      })
      .addCase(fetchCartThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Status = ELazyStatus.Loading
        state.checkOutStatus = ECheckOutValidateStatus.Pending
      })

    builder
      .addCase(fetchCheckOutValidateThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        if (action.payload) state.checkOutStatus = ECheckOutValidateStatus.Valid
        else state.checkOutStatus = ECheckOutValidateStatus.Pending
      })
      .addCase(fetchCheckOutValidateThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.checkOutStatus = ECheckOutValidateStatus.Pending
      })
      .addCase(fetchCheckOutValidateThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.checkOutStatus = ECheckOutValidateStatus.Loading
      })

    builder
      .addCase(removeAllCartThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        CartSliceAdapter.removeAll(state.carts)
        CartLocalStorage.removeAll()
      })
      .addCase(removeAllCartThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
      })
  }
})
export default CartSlice

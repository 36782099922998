import { AppDispatch, RootState } from '@internal/redux'
import { QueryParam } from '@lib/Helpers'
import { fetchOrderDetailThunk } from './Thunks'
import { IOrderDetailMapDispatchRedux, IOrderDetailMapStateRedux, IOrderDetailRequired } from './type'

export const mapStateToProps = (state: RootState): IOrderDetailMapStateRedux => ({
  Status: state.OrderDetailSlice.Status,
  dataDetail: state.OrderDetailSlice.dataDetail
})

export const mapDispatchToProps = (dispatch: AppDispatch): IOrderDetailMapDispatchRedux => ({
  FetchData: () => {
    const id = QueryParam.Gets<IOrderDetailRequired>('id').id ?? ''
    return dispatch(fetchOrderDetailThunk({ id: id.toString() }))
  }
})

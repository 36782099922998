import { ApiAlertContext } from '@lib/ApiContext'
import { createAsyncThunk } from '@reduxjs/toolkit'
import OrderService, { OrderMapping } from '@internal/services/OrderService'
import { ICurrentDetailBase, IOrderDetailRequired } from './type'

// ========= ========= ========= Main Redux ========= ========= =========
interface IParamsThunk extends IOrderDetailRequired {}

interface IResponseThunk extends ICurrentDetailBase {}

export const fetchOrderDetailThunk = createAsyncThunk<IResponseThunk, IParamsThunk | undefined>('fetchOrderDetailThunk', async (param, thunkAPI) => {
  // const state = thunkAPI.getState() as { OrderDetailSlice: IOrderDetailStateSlice }
  if (!param?.id) {
    ApiAlertContext.ApiAlert?.PushError('Order not found!')
    return {}
  }
  const result = await OrderService.GraphQLSingle({ id: param.id }, thunkAPI.signal)
  const dataResponse: IResponseThunk = { dataDetail: OrderMapping.order(result) }
  return dataResponse
})

import { IOrder } from '@shared/Types'
import { RootState } from '@internal/redux'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { MergeObjects, TableQueryParam } from '@lib/Helpers'
import { MapTableData, MapTableInfo, TableReduxType } from '@lib/Table'
import OrderService, { OrderMapping } from '@internal/services/OrderService'
import { DefaultTableInfo } from './redux.slice'

interface IParamThunk extends TableReduxType.ThunkParam<IOrder> {}
interface IResponseThunk extends TableReduxType.ThunkReponse<IOrder> {}
export const fetchOrderThunk = createAsyncThunk<IResponseThunk, IParamThunk | undefined>('fetchOrderThunk', async (param, thunkAPI) => {
  const state = thunkAPI.getState() as RootState
  const tableInfoState = MergeObjects(state.OrderSlice.tableInfo, param?.tableInfo)
  const tableInfoMap = MapTableInfo(tableInfoState, param?.tableChange)

  const filter = OrderMapping.tableInfoToFilter(tableInfoMap)
  const res = await OrderService.GraphQLAll(filter, thunkAPI.signal)

  const tableInfo = MapTableData(tableInfoMap, { Data: OrderMapping.orders(res?.items), Total: res?.totalItems })
  TableQueryParam.setParam(tableInfo, DefaultTableInfo)
  return { tableInfo }
})

import React, { Component } from 'react'
import './index.css'
interface VideoViewProps {
  videoUrl: string
}
interface VideoViewState {
  horizontal?: boolean
}
export default class VideoView extends Component<VideoViewProps, VideoViewState> {
  constructor(props: VideoViewProps) {
    super(props)
    this.state = {}
  }

  onPrevent: React.MouseEventHandler<HTMLVideoElement> = (e) => {
    e.stopPropagation()
  }

  onLoad: React.ReactEventHandler<HTMLVideoElement> = (event) => {
    const video = event.target as HTMLVideoElement
    this.setState({ horizontal: video.videoWidth > video.videoHeight })
  }

  render() {
    const dimension =
      this.state.horizontal === undefined
        ? {}
        : this.state.horizontal
        ? { width: '100%', height: 'auto' }
        : { width: 'auto', height: '100%' }
    return (
      <div className='video-view-container'>
        <div style={{ maxWidth: '1000px', display: 'flex', alignItems: 'center', height: '100%' }}>
          <video onLoadedMetadata={this.onLoad} onMouseDown={this.onPrevent} style={dimension} controls controlsList='nodownload'>
            <source src={this.props.videoUrl} type='video/mp4' />
            <track kind='captions' label='English' src='captions_en.vtt' default />
          </video>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import { Stack } from '@mui/material'
import { connect } from 'react-redux'
import { CreateHocLazy } from '@lib/ReduxBase'
import { IHomeReduxProps } from './redux.types'
import { mapDispatchToProps, mapStateToProps } from './redux.map'
import OurPartner from './ui/OurPartner'
import Categories from './ui/Categories'
import SectionSearch from './ui/SectionSearch'
import VideoGalleries from './ui/VideoGalleries'
import AudioGalleries from './ui/AudioGalleries'
import PriceContent from '../Pricing/price.content'

interface IProps extends IHomeReduxProps {}
class ViewBase extends Component<IProps> {
  render() {
    return (
      <Stack sx={{ backgroundColor: '#FFFFFF', overflowX: 'hidden', gap: '75px' }}>
        <SectionSearch />
        <OurPartner />
        <Categories audios={this.props.StateSlice.AudioCategory} videos={this.props.StateSlice.VideoCategory} />
        <VideoGalleries
          videoPopular={this.props.StateSlice.VideoPopular}
          videoNew={this.props.StateSlice.VideoNew}
          prices={this.props.StateSlice.Prices}
          onAddToCart={this.props.addToCart}
        />
        <AudioGalleries data={this.props.StateSlice.AudioHot ?? []} />
        <PriceContent data={this.props.StateSlice.Prices ?? []} isId />
      </Stack>
    )
  }
}

export * from './redux.thunks'
export { default as HomeSlice } from './redux.slice'

const HocLazyInstance = CreateHocLazy(ViewBase)
export const HomeMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)

import type { AcceptableVariables, UnresolvedVariables, FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';
import type {OrderStatus} from '../enums';
import type {OrderPayment} from '../enums';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface OrderFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'Order', T, TVariables> {

    on<XName extends ImplementationType<'Order'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): OrderFetcher<
        XName extends 'Order' ?
        T & X :
        WithTypeName<T, ImplementationType<'Order'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'Order'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): OrderFetcher<T, TVariables>;


    readonly __typename: OrderFetcher<T & {__typename: ImplementationType<'Order'>}, TVariables>;


    readonly id: OrderFetcher<T & {readonly "id": string}, TVariables>;

    "id+"<
        XAlias extends string = "id", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"id", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): OrderFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~id": OrderFetcher<Omit<T, 'id'>, TVariables>;


    readonly userId: OrderFetcher<T & {readonly "userId": string}, TVariables>;

    "userId+"<
        XAlias extends string = "userId", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"userId", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): OrderFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~userId": OrderFetcher<Omit<T, 'userId'>, TVariables>;


    readonly name: OrderFetcher<T & {readonly "name": string}, TVariables>;

    "name+"<
        XAlias extends string = "name", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"name", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): OrderFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~name": OrderFetcher<Omit<T, 'name'>, TVariables>;


    readonly description: OrderFetcher<T & {readonly "description"?: string}, TVariables>;

    "description+"<
        XAlias extends string = "description", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"description", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): OrderFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~description": OrderFetcher<Omit<T, 'description'>, TVariables>;


    readonly referenceId: OrderFetcher<T & {readonly "referenceId": string}, TVariables>;

    "referenceId+"<
        XAlias extends string = "referenceId", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"referenceId", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): OrderFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~referenceId": OrderFetcher<Omit<T, 'referenceId'>, TVariables>;


    readonly status: OrderFetcher<T & {readonly "status": OrderStatus}, TVariables>;

    "status+"<
        XAlias extends string = "status", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"status", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): OrderFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: OrderStatus} : 
                {readonly [key in XAlias]: OrderStatus}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~status": OrderFetcher<Omit<T, 'status'>, TVariables>;


    readonly method: OrderFetcher<T & {readonly "method": OrderPayment}, TVariables>;

    "method+"<
        XAlias extends string = "method", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"method", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): OrderFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: OrderPayment} : 
                {readonly [key in XAlias]: OrderPayment}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~method": OrderFetcher<Omit<T, 'method'>, TVariables>;


    orderDetail<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'OrderDetail', X, XVariables>
    ): OrderFetcher<
        T & {readonly "orderDetail"?: X}, 
        TVariables & XVariables & OrderArgs["orderDetail"]
    >;

    orderDetail<
        XArgs extends AcceptableVariables<OrderArgs['orderDetail']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'OrderDetail', X, XVariables>
    ): OrderFetcher<
        T & {readonly "orderDetail"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, OrderArgs['orderDetail']>
    >;

    orderDetail<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "orderDetail", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'OrderDetail', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"orderDetail", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): OrderFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & OrderArgs["orderDetail"] & XDirectiveVariables
    >;

    orderDetail<
        XArgs extends AcceptableVariables<OrderArgs['orderDetail']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "orderDetail", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'OrderDetail', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"orderDetail", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): OrderFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, OrderArgs['orderDetail']> & XDirectiveVariables
    >;


    orderDetails<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'OrderDetail', X, XVariables>
    ): OrderFetcher<
        T & {readonly "orderDetails"?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables
    >;

    orderDetails<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "orderDetails", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'OrderDetail', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"orderDetails", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): OrderFetcher<
        T & {readonly [key in XAlias]?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    readonly transactionId: OrderFetcher<T & {readonly "transactionId"?: string}, TVariables>;

    "transactionId+"<
        XAlias extends string = "transactionId", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"transactionId", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): OrderFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~transactionId": OrderFetcher<Omit<T, 'transactionId'>, TVariables>;


    readonly transactionInfo: OrderFetcher<T & {readonly "transactionInfo"?: string}, TVariables>;

    "transactionInfo+"<
        XAlias extends string = "transactionInfo", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"transactionInfo", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): OrderFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~transactionInfo": OrderFetcher<Omit<T, 'transactionInfo'>, TVariables>;


    readonly dateCreated: OrderFetcher<T & {readonly "dateCreated": string}, TVariables>;

    "dateCreated+"<
        XAlias extends string = "dateCreated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateCreated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): OrderFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateCreated": OrderFetcher<Omit<T, 'dateCreated'>, TVariables>;


    readonly dateUpdated: OrderFetcher<T & {readonly "dateUpdated": string}, TVariables>;

    "dateUpdated+"<
        XAlias extends string = "dateUpdated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateUpdated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): OrderFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateUpdated": OrderFetcher<Omit<T, 'dateUpdated'>, TVariables>;


    user<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'ApplicationUser', X, XVariables>
    ): OrderFetcher<
        T & {readonly "user"?: X}, 
        TVariables & XVariables
    >;

    user<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "user", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'ApplicationUser', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"user", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): OrderFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;
}

export const order$: OrderFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "Order", 
            "OBJECT", 
            [], 
            [
                {
                    category: "ID", 
                    name: "id"
                }, 
                "userId", 
                "name", 
                {
                    category: "SCALAR", 
                    name: "description", 
                    undefinable: true
                }, 
                "referenceId", 
                "status", 
                "method", 
                {
                    category: "REFERENCE", 
                    name: "orderDetail", 
                    argGraphQLTypeMap: {id: 'ID!'}, 
                    targetTypeName: "OrderDetail", 
                    undefinable: true
                }, 
                {
                    category: "LIST", 
                    name: "orderDetails", 
                    targetTypeName: "OrderDetail", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "transactionId", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "transactionInfo", 
                    undefinable: true
                }, 
                "dateCreated", 
                "dateUpdated", 
                {
                    category: "REFERENCE", 
                    name: "user", 
                    targetTypeName: "ApplicationUser", 
                    undefinable: true
                }
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const order$$ = 
    order$
        .id
        .userId
        .name
        .description
        .referenceId
        .status
        .method
        .transactionId
        .transactionInfo
        .dateCreated
        .dateUpdated
;

export interface OrderArgs {

    readonly orderDetail: {
        readonly id: string
    }
}

import { SvgMediaLink } from '@shared/Icons'
import { IMediaDelivery } from '@shared/Types'
import { getDeliveryStatus } from '@shared/Services'
import { CreateInputPasteLinks, CreateInputTextMultiline, CreateSelectSimple } from '@lib/Forms/Inputs'
import { CreateFormGridLayout, FormValidator, SingleRuleValidate, IFormGridLayoutConfig } from '@lib/Forms'
import SourceIcon from '@mui/icons-material/Source'
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined'
import { ICON_SIZE } from './helper'
import CustomAction from './CustomAction'
import SelectResourceId from './SelectResourceId'

interface IParam {
  disabledStatus?: boolean
}

const PasteLink = CreateInputPasteLinks<IMediaDelivery>()
const InputTextMul = CreateInputTextMultiline<IMediaDelivery>({ options: { multiline: { minRows: 3, maxRows: 5 } } })
const SelectSimple = CreateSelectSimple<IMediaDelivery>()

export const GetFormUrlType = (param?: IParam) => {
  const status: IFormGridLayoutConfig<IMediaDelivery> = {
    key: 'Status',
    label: 'Status',
    inputElement: (p) => <SelectSimple {...p} options={getDeliveryStatus()} />,
    alwaysDisplayed: (x) => !!x
  }
  return CreateFormGridLayout<IMediaDelivery>({
    configs: [
      { key: 'Name', label: 'Name', options: { defaultValue: new Date().getTime().toString() } },
      { key: 'ResourceId', inputElement: SelectResourceId },
      ...(param?.disabledStatus === true ? [] : [status]),
      { key: 'Description', label: 'Description', inputElement: InputTextMul },
      { key: 'Content', label: 'Enter all repository links (maximum of 10 links)', inputElement: PasteLink }
    ],
    validate: new FormValidator({
      Name: { Rules: [{ rule: SingleRuleValidate.Required }] },
      ResourceId: { Rules: [{ rule: SingleRuleValidate.Required }] },
      Description: { Rules: [{ rule: SingleRuleValidate.Required }] },
      Content: { Rules: [{ rule: SingleRuleValidate.Required, message: 'The Repository url is required' }] }
    }),
    options: {
      action: (p) => <CustomAction title='Add repository links' icon={<SvgMediaLink sx={{ fontSize: ICON_SIZE.small }} fill={'#848484'} />} {...p} />
    }
  })
}

export const GetFormUploadType = (param?: IParam) => {
  const status: IFormGridLayoutConfig<IMediaDelivery> = {
    key: 'Status',
    label: 'Status',
    inputElement: (p) => <SelectSimple {...p} options={getDeliveryStatus()} />,
    alwaysDisplayed: (x) => !!x
  }
  return CreateFormGridLayout<IMediaDelivery>({
    configs: [
      { key: 'Name', label: 'Name', options: { defaultValue: new Date().getTime().toString() } },
      { key: 'ResourceId', inputElement: SelectResourceId },
      ...(param?.disabledStatus === true ? [] : [status]),
      { key: 'Description', label: 'Description', inputElement: InputTextMul }
    ],
    validate: new FormValidator({
      Name: { Rules: [{ rule: SingleRuleValidate.Required }] },
      ResourceId: { Rules: [{ rule: SingleRuleValidate.Required }] },
      Description: { Rules: [{ rule: SingleRuleValidate.Required }] }
    }),
    options: {
      action: (p) => (
        <CustomAction
          title='For internal use only.'
          icon={<DriveFolderUploadOutlinedIcon sx={{ fontSize: ICON_SIZE.small, color: '#848484' }} />}
          {...p}
        />
      )
    }
  })
}

export const GetFormInternalType = (param?: IParam) => {
  const status: IFormGridLayoutConfig<IMediaDelivery> = {
    key: 'Status',
    label: 'Status',
    inputElement: (p) => <SelectSimple {...p} options={getDeliveryStatus()} />,
    alwaysDisplayed: (x) => !!x
  }
  return CreateFormGridLayout<IMediaDelivery>({
    configs: [
      { key: 'Name', label: 'Name', options: { defaultValue: new Date().getTime().toString() } },
      ...(param?.disabledStatus === true ? [] : [status]),
      { key: 'Description', label: 'Description', inputElement: InputTextMul }
    ],
    validate: new FormValidator({
      Name: { Rules: [{ rule: SingleRuleValidate.Required }] },
      Description: { Rules: [{ rule: SingleRuleValidate.Required }] }
    }),
    options: {
      action: (p) => <CustomAction title='Internal' icon={<SourceIcon sx={{ fontSize: ICON_SIZE.small, color: '#848484' }} />} {...p} />
    }
  })
}

import { IPrice } from '@shared/Types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import PricePackageService from './services/PricePackageService'

interface IResults {
  pricePackages: IPrice[]
}

export const fetchPricingThunk = createAsyncThunk<IResults>('fetchPricingThunk', async (_, thunkAPI) => {
  const response = await PricePackageService.all(thunkAPI.signal)
  return { pricePackages: response }
})

import { CreateLayoutMakeup } from '@lib/Layout/Makeup'

interface ILayoutMakeup {
  PaymentHeaderRight: string
  TicketDetailTitle: string
  TicketDetailAction: string
  TopRightDeliveryCreate: string
  OrderDetailHeaderRight: string
  SubmissionSetitngsHeaderRight: string
  DeliveryDetail: string
}
export const LayoutMakeupInstance = CreateLayoutMakeup<ILayoutMakeup>({
  keyMapper: {
    PaymentHeaderRight: '',
    TicketDetailTitle: '',
    TicketDetailAction: '',
    TopRightDeliveryCreate: '',
    OrderDetailHeaderRight: '',
    SubmissionSetitngsHeaderRight: '',
    DeliveryDetail: ''
  }
})

import React, { Component } from 'react'
import { Modal, Box, Backdrop, styled } from '@mui/material'

type TCloseModal = (reason?: 'backdropClick' | 'escapeKeyDown') => void

interface IProps {
  open: boolean
  onClose: TCloseModal
}

type TProps = React.PropsWithChildren<IProps>

export default class CustomModal extends Component<TProps> {
  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={(_, reason) => this.props.onClose(reason)}
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 300 } }}
      >
        <Wrapper>{this.props.children}</Wrapper>
      </Modal>
    )
  }
}

const Wrapper = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  outline: 'none'
})

import { ITicket } from '@shared/Types'
import { PayloadAction } from '@reduxjs/toolkit'
import { GridCallbackDetails } from '@mui/x-data-grid'
import { CreateCRUDSlice2, ELazyStatus } from '@lib/ReduxBase'
import { ITableTemplateState, TableInitialSliceRedux } from '@lib/Table'
import { ITicketStateSlice } from './redux.types'
import { fetchTicketThunk } from './redux.thunks'

export const DefaultTableInfo = TableInitialSliceRedux<ITicket>({ GridSortModel: [{ field: 'DateCreated', sort: 'desc' }] })

const TicketSlice = CreateCRUDSlice2<ITicket>()({
  name: 'TicketSlice',
  selectId: (x) => x.Id,
  Initial: (x) => {
    const data: ITicketStateSlice = { ...x, tableInfo: DefaultTableInfo }
    return data
  },
  extraReducers: (builder, adapter) => {
    builder
      .addCase(fetchTicketThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Loaded
        state.tableInfo = action.payload.tableInfo
      })
      .addCase(fetchTicketThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Error
        state.tableInfo.isLoading = true
      })
      .addCase(fetchTicketThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Status = ELazyStatus.Loading
        state.tableInfo.isLoading = true
      })
  },
  reducers: {
    onChange: (state, action: PayloadAction<{ key: keyof ITableTemplateState<ITicket>; value: any; details: GridCallbackDetails }>) => {
      state.tableInfo[action.payload.key] = action.payload.value
      state.tableInfo.details = action.payload.details
    }
  }
})
export default TicketSlice

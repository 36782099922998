import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { IconSuccess } from '@lib/Component'
import { Button, Container, Stack, Typography } from '@mui/material'

export default class CheckOutComplete extends Component {
  render() {
    return (
      <Container>
        <Stack sx={{ alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }}>
          <IconSuccess />
          <Typography variant='h1' sx={{ textTransform: 'upercase' }}>
            Checkout success!
          </Typography>
          <Typography variant='subtitle1'> Thank you for purchasing our products, have a good day</Typography>
          <Button color='primary' variant='outlined' sx={{ minWidth: '220px', mt: '24px' }} component={Link} to='/'>
            Continue
          </Button>
        </Stack>
      </Container>
    )
  }
}

import { EMediaType } from '@shared/Types'
import { ELazyStatus } from '@lib/ReduxBase'
import { ICategory, IMedia } from '@shared/Types'
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IExploreStateSlice } from './redux.types'
import { IMediaFilterDTO, TFilterField } from './types'
import { fetchExploreFirstThunk, fetchExploreThunk } from './redux.thunks'
import MediaMappingService from './service.mapping'

const MediaSliceAdapter = createEntityAdapter<IMedia>({ selectId: (x) => x.Id })
const CategoriesSliceAdapter = createEntityAdapter<ICategory>({ selectId: (x) => x.Id })

// Define the initial state using that type
const initialState: IExploreStateSlice = {
  Status: ELazyStatus.Loading,
  medias: MediaSliceAdapter.getInitialState(),
  categories: CategoriesSliceAdapter.getInitialState(),
  filter: MediaMappingService.initilal(),
  mediaInfo: {},
  prices: []
}

const ExploreSlice = createSlice({
  name: 'ExploreSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<ELazyStatus>) => {
      state.Status = action.payload
    },
    updateFilter: (state: any, action: PayloadAction<TFilterField<IMediaFilterDTO>>) => {
      if (action.payload.key === 'mediaType') {
        state.filter = MediaMappingService.byChangeMediaType(action.payload.value as EMediaType)
      } else {
        state.filter[action.payload.key] = action.payload.value
      }
      MediaMappingService.setQueryParams(state.filter)
    },
    updateFilters: (state: any, action: PayloadAction<TFilterField<IMediaFilterDTO>[]>) => {
      action.payload.forEach((item) => {
        if (item.key === 'mediaType') {
          state.filter = { ...state.filter, ...MediaMappingService.byChangeMediaType(item.value as EMediaType) }
        } else {
          state.filter[item.key] = item.value
        }
      })
      MediaMappingService.setQueryParams(state.filter)
    },
    fetchFromQueryParams: (state) => {
      state.filter = MediaMappingService.initilal()
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExploreThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Loaded

        CategoriesSliceAdapter.removeAll(state.categories)
        CategoriesSliceAdapter.addMany(state.categories, action.payload.categories)

        MediaSliceAdapter.removeAll(state.medias)
        MediaSliceAdapter.addMany(state.medias, action.payload.medias)
        state.mediaInfo.total = action.payload.mediaTotal
      })
      .addCase(fetchExploreThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Error
      })
      .addCase(fetchExploreThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Status = ELazyStatus.Loading
      })

    builder
      .addCase(fetchExploreFirstThunk.fulfilled, (state, action) => {
        if (state.requestedFirstId !== action.meta.requestId) return
        state.prices = action.payload.prices
      })
      .addCase(fetchExploreFirstThunk.pending, (state, action) => {
        state.requestedFirstId = action.meta.requestId
      })
  }
})
export default ExploreSlice

import { AppDispatch, RootState } from '@internal/redux'
import { ISubmitMediaMapDispatchRedux, ISubmitMediaMapStateRedux } from './types'
import SubmitMediaSlice from './Slice'

export const mapStateToProps = (state: RootState): ISubmitMediaMapStateRedux => ({
  Status: state.SubmitMediaSlice.Status,
  submitModel: state.SubmitMediaSlice.submitModel
})

export const mapDispatchToProps = (dispatch: AppDispatch): ISubmitMediaMapDispatchRedux => ({
  updateSubmitModel: (value) => {
    dispatch(SubmitMediaSlice.actions.updateSubmitModel(value))
  }
})

import { FC } from 'react'
import { Box, styled } from '@mui/material'
import { IMediaDTO } from './type'

interface IProps {
  data: IMediaDTO
}

export const MediaVideoPlayer: FC<IProps> = (props) => {
  const { ResourceData = [] } = props.data
  let steamUrl = ''
  if (ResourceData.length > 0 && ResourceData[0].StreamUrl) steamUrl = ResourceData[0].StreamUrl
  return (
    <Box sx={{ position: 'relative', paddingBottom: '62%' }}>
      <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
        {steamUrl && <CustomVideo src={steamUrl} controls={true} sx={{ boxShadow: 2 }} />}
      </Box>
    </Box>
  )
}

const CustomVideo = styled('video')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  backgroundColor: '#979797'
})

export default MediaVideoPlayer

import React, { Component } from 'react'
import { EMediaType } from '@shared/Types'
import withNavigate, { INavigateProps } from '@lib/Hocs/withNavigation'
import { QueryParam } from '@lib/Helpers'
import TheatersIcon from '@mui/icons-material/Theaters'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import { TMenuConfig } from './MediaTypeMenu'
import { RouteKey } from '../../../../Routers'
import FormSearch, { TInfoConfig } from './FormSearch'

export * from './type'

const MenuConfig: TMenuConfig = {
  [EMediaType.Video]: { display: 'Videos', icon: <TheatersIcon /> },
  [EMediaType.Audio]: { display: 'Audios', icon: <MusicNoteIcon /> }
}

const InfoConfig: TInfoConfig = {
  [EMediaType.Video]: {
    title: 'VIDEO COLLECTION',
    subTitle: 'Authentic media, new updates constantly, high quality videos and high quality audio',
    lead: 'Try video url or keyword. e.g: the dogs'
  },
  [EMediaType.Audio]: {
    title: 'AUDIO COLLECTION',
    subTitle: '10,000+ unique media product items, copyright support in the most popular social networks: youtube, facebook, spotify...',
    lead: 'Try audio url or keyword. e.g: dreamy'
  }
}

class SectionSearch extends Component<INavigateProps> {
  render() {
    return (
      <FormSearch
        menuConfig={MenuConfig}
        info={InfoConfig}
        onSubmit={async (value) => {
          const query = QueryParam.stringify({ searchKey: value.Value, mediaType: value.MediaType })
          this.props.navigate(`${RouteKey.Explore}${query}`)
        }}
      />
    )
  }
}
export default withNavigate(SectionSearch)

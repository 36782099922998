import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'

interface IProps {
  onChange?: (pathname: string) => void
}
const HookChangeParameter: FC<IProps> = (props) => {
  const location = useLocation()
  React.useEffect(() => {
    if (!props.onChange) return
    props.onChange(location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])
  return null
}
export default HookChangeParameter

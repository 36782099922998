import React, { Component } from 'react'
import { Box, BoxProps, SxProps, Theme, Typography, styled } from '@mui/material'

export type TStatusCellConfig<TEnum extends string> = { [key in TEnum]: SxProps<Theme> }

interface IOptions {
  sxInner?: SxProps<Theme>
}

const CreateStatusCell = function <TEnum extends string>(config: TStatusCellConfig<TEnum>, options?: IOptions) {
  interface IProps extends Pick<BoxProps, 'onClick'> {
    data: TEnum
    title?: string
    sx?: SxProps<Theme>
  }
  class StatusCell extends Component<React.PropsWithChildren<IProps>> {
    render() {
      return (
        <Wrapper sx={this.props.sx} onClick={this.props.onClick}>
          <CustomTypography sx={this.getSxProps()}>{this.props.title || this.props.data}</CustomTypography>
        </Wrapper>
      )
    }

    getSxProps = () => {
      const sx: SxProps<Theme> = Object.assign({}, config[this.props.data], options?.sxInner)
      return sx
    }
  }
  return StatusCell
}
export default CreateStatusCell

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%'
})

const CustomTypography = styled(Typography)({
  borderRadius: '4px',
  fontSize: '0.8rem',
  padding: '4px 9px',
  fontWeight: 600,
  minWidth: '70px',
  textAlign: 'center',
  color: '#ffffff'
})

import React, { Component } from 'react'
import { ClickAwayListener, Box, IconButton, Divider, styled, SxProps, Theme } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

interface IOption {
  smalled?: boolean
}

interface IProps {
  superSmalled?: boolean
  gray?: boolean
  renderChild?: (onClick: () => void, options?: IOption) => JSX.Element
  onSubmit?: () => void
  disabled?: boolean
}

interface IState {
  isCheck: boolean
}

export class ConfirmButton extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { isCheck: false }
  }
  render() {
    return (
      <ClickAwayListener onClickAway={this.handleClickCancel}>
        <Wrap sx={this.getSxWrap()}>
          {this.renderContent()}
          {this.state.isCheck && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
              <IconButton color="success" size="small" onClick={this.handleClickDone}>
                <DoneIcon sx={{ color: '#2e7d32 !important' }} />
              </IconButton>
              <Divider orientation="vertical" flexItem />
              <IconButton color="error" size="small" onClick={this.handleClickCancel}>
                <CloseIcon sx={{ color: '#d32f2f !important' }} />
              </IconButton>
            </Box>
          )}
        </Wrap>
      </ClickAwayListener>
    )
  }

  renderContent = () => {
    if (this.state.isCheck) return <></>
    if (this.props.renderChild) return this.props.renderChild(this.handlingClick)
    return (
      <IconButton size="small" color="error" onClick={this.handlingClick} disabled={this.props.disabled}>
        <DeleteOutlineIcon />
      </IconButton>
    )
  }

  handleClickDone = () => {
    this.props.onSubmit && this.props.onSubmit()
    this.setState({ isCheck: false })
  }

  handleClickCancel = () => {
    this.setState({ isCheck: false })
  }

  handlingClick = () => {
    this.setState({ isCheck: true })
  }

  getSxWrap = (): SxProps<Theme> => {
    let sxSvg: SxProps<Theme> = {}
    if (this.props.superSmalled) {
      sxSvg = { ...sxSvg, fontSize: '1.1rem' }
    }

    if (this.props.gray) {
      sxSvg = { ...sxSvg, color: '#767676' }
    }

    return { '& svg': sxSvg } as SxProps<Theme>
  }
}

const Wrap = styled(Box)({
  height: '32px',
  display: 'flex',
  alignItems: 'center',
})

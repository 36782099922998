import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import ListAltIcon from '@mui/icons-material/ListAlt'

interface IProps {
  to: string
  target?: React.HTMLAttributeAnchorTarget
}

export class OrderBtnDetail extends Component<IProps> {
  render() {
    return (
      <IconButton color='info' {...{ component: Link, to: this.props.to, target: this.props.target }} sx={{ cursor: 'pointer' }}>
        <ListAltIcon />
      </IconButton>
    )
  }
}

export class OrderButtonEdit extends Component<IProps> {
  render() {
    return (
      <IconButton color='info' {...{ component: Link, to: this.props.to, target: this.props.target }} sx={{ cursor: 'pointer' }}>
        <EditIcon />
      </IconButton>
    )
  }
}

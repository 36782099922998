import { ISubmission } from '@shared/Types'
import { PayloadAction } from '@reduxjs/toolkit'
import { CreateCRUDSlice2, ELazyStatus } from '@lib/ReduxBase'
import { TableInitialSliceRedux, TableReduxType } from '@lib/Table'
import { ISubmissionStateSlice } from './redux.types'
import { fetchSubmissionThunk } from './redux.thunks'

export const DefaultTableInfo = TableInitialSliceRedux<ISubmission>({ GridSortModel: [{ field: 'DateCreated', sort: 'desc' }] })

const SubmissionSlice = CreateCRUDSlice2<ISubmission>()({
  name: 'SubmissionSlice',
  selectId: (x) => x.Id,
  Initial: (x) => {
    const state: ISubmissionStateSlice = { ...x, tableInfo: DefaultTableInfo }
    return state
  },
  extraReducers: (builder, adapter) => {
    builder
      .addCase(fetchSubmissionThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Loaded
        state.tableInfo = action.payload.tableInfo
      })
      .addCase(fetchSubmissionThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Error
        state.tableInfo.isLoading = true
      })
      .addCase(fetchSubmissionThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Status = ELazyStatus.Loading
        state.tableInfo.isLoading = true
      })
  },
  reducers: {
    onChange: (state, action: PayloadAction<TableReduxType.OnChangeParam<ISubmission>>) => {
      state.tableInfo[action.payload.key] = action.payload.value
      state.tableInfo.details = action.payload.details
    }
  }
})
export default SubmissionSlice

import { ELazyStatus } from '@lib/ReduxBase'
import { IBillingDetail } from '@shared/Types'
import { PayloadAction } from '@reduxjs/toolkit'
import { CreateCRUDSlice2 } from '@lib/ReduxBase'
import { GridCallbackDetails } from '@mui/x-data-grid'
import { ITableTemplateState, TableInitialSliceRedux } from '@lib/Table'
import { IBillingDetailStateSlice } from './redux.types'
import { fetchBillingDetailInitialThunk, fetchBillingDetailThunk } from './redux.thunks'

const DefaultTableInfo = TableInitialSliceRedux<IBillingDetail>({ GridSortModel: [{ field: 'DateCreated', sort: 'desc' }] })

const BillingDetailSlice = CreateCRUDSlice2<IBillingDetail>()({
  name: 'BillingDetailSlice',
  selectId: (x) => x.Id,
  Initial: (x) => {
    const state: IBillingDetailStateSlice = { ...x, tableInfo: DefaultTableInfo }
    return state
  },
  extraReducers: (builder, adapter) => {
    builder
      .addCase(fetchBillingDetailThunk.fulfilled, (state, action) => {
        if (state.tableRequestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Loaded
        state.tableInfo = action.payload.tableInfo
      })
      .addCase(fetchBillingDetailThunk.rejected, (state, action) => {
        if (state.tableRequestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Error
        state.tableInfo.isLoading = true
      })
      .addCase(fetchBillingDetailThunk.pending, (state, action) => {
        state.tableRequestedId = action.meta.requestId
        state.Status = ELazyStatus.Loading
        state.tableInfo.isLoading = true
        if (action.meta.arg.clear === true) {
          state.tableInfo = DefaultTableInfo
        }
      })

    builder
      .addCase(fetchBillingDetailInitialThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Billing = action.payload.Billing
      })
      .addCase(fetchBillingDetailInitialThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
      })
      .addCase(fetchBillingDetailInitialThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Billing = undefined
      })
  },
  reducers: {
    onChange: (state, action: PayloadAction<{ key: keyof ITableTemplateState<IBillingDetail>; value: any; details: GridCallbackDetails }>) => {
      state.tableInfo[action.payload.key] = action.payload.value
      state.tableInfo.details = action.payload.details
    }
  }
})
export default BillingDetailSlice

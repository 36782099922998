import React, { Component, Fragment } from 'react'
import { Box, Divider, styled, SxProps, Theme, Typography } from '@mui/material'

interface IInfoBarConfig<TModel> {
  label?: string
  element?: React.ComponentType<IInfoBarItemProps<TModel>>
  valueFormater?: (value: any, model?: Partial<TModel>) => string | undefined
  disabledLine?: boolean
  sx?: SxProps<Theme>
}

export interface IInfoBarItemProps<TModel> {
  index: number
  keyModel: keyof TModel
  config: IInfoBarConfig<TModel>
  data?: Partial<TModel>
}

export type TInfoBarConfig<TModel> = { [key in keyof TModel]?: IInfoBarConfig<TModel> }

const CreateInfoBar = function <TModel>(configs: TInfoBarConfig<TModel>) {
  interface IItemProps extends IInfoBarItemProps<TModel> {}
  class Item extends Component<IItemProps> {
    render() {
      const { config, data, index, keyModel } = this.props
      const label = config.label ?? keyModel?.toString()
      let value = data?.[keyModel]?.toString()
      if (config.valueFormater) value = config.valueFormater(value, data)
      return (
        <>
          {index !== 0 && config.disabledLine !== true && (
            <Divider variant='middle' orientation='vertical' flexItem sx={{ my: '4px', maxWidth: '250px' }} />
          )}
          <ItemWrap sx={config.sx}>
            <Typography variant='subtitle2' sx={{ fontWeight: 600 }} noWrap>
              {label}
            </Typography>
            <CustomTypography variant='subtitle2' status={!!value ? 'normal' : 'none'} noWrap sx={{ maxWidth: '250px' }}>
              {value || '...'}
            </CustomTypography>
          </ItemWrap>
        </>
      )
    }
  }

  interface IProps {
    data?: Partial<TModel>
    sx?: SxProps<Theme>
    options?: {
      before?: JSX.Element
      after?: JSX.Element
    }
  }
  class Infobar extends Component<IProps> {
    render() {
      const keys = Object.keys(configs) as (keyof TModel)[]
      return (
        <Wrap sx={this.props.sx}>
          {!!this.props.options?.before && this.props.options.before}
          {keys.map((key, index) => {
            const config = configs[key] as IInfoBarConfig<TModel>
            const Element = config?.element ?? Item
            return (
              <Fragment key={key?.toString() + index}>
                <Element index={index} config={config} data={this.props.data} keyModel={key} />
              </Fragment>
            )
          })}
          {!!this.props.options?.after && this.props.options.after}
        </Wrap>
      )
    }
  }
  return Infobar
}
export default CreateInfoBar

const Wrap = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  gap: '18px',
  padding: '8px 12px 6px'
})
const CustomTypography = styled(Typography)<{ status: 'normal' | 'none' }>(({ status }) => ({
  fontStyle: status === 'normal' ? 'normal' : 'italic',
  color: status === 'none' ? '#818181' : undefined
}))
const ItemWrap = styled(Box)({
  minWidth: '50px',
  flex: '0 0 auto'
})

import { AppDispatch, RootState } from '@internal/redux'
import NotificationService from '@internal/services/NotificationService'
import { fetchNotificationThunk } from './redux.thunks'
import { SetSingleLocalStorageNotification } from './Services/LocalStorageNotification'
import { INotificationMapDispatchRedux, INotificationMapStateRedux } from './redux.types'
import NotificationSlice from './redux.slice'

export const mapStateToProps = (state: RootState): INotificationMapStateRedux => ({
  Status: state.NotificationSlice.Status,
  stateRedux: state.NotificationSlice
})

export const mapDispatchToProps = (dispatch: AppDispatch): INotificationMapDispatchRedux => ({
  FetchData: () => {
    return dispatch(fetchNotificationThunk())
  },
  readNotification: async (value) => {
    NotificationService.ReadNotification(value.Id)
    SetSingleLocalStorageNotification(value.Id)
    dispatch(NotificationSlice.actions.Update({ id: value.Id, changes: value }))
  }
})

import React, { Component } from 'react'
import { IFormBase } from '@lib/Forms'
import { Box, Divider, IconButton, InputBase, styled } from '@mui/material'
import { CreateFormSingleValidate } from '@lib/FormHelpers'
import { FormValidator } from '@lib/Forms'
import SearchIcon from '@mui/icons-material/Search'
import { IFormSearch } from './type'
import MediaTypeMenu, { TMenuConfig } from './MediaTypeMenu'
import { EMediaType } from '@shared/Types'

interface IInfoConfig {
  title: string
  subTitle: string
  lead: string
}

export type TInfoConfig = { [key in EMediaType]: IInfoConfig }

interface IBaseProps {
  info: TInfoConfig
  menuConfig: TMenuConfig
}

interface IFormContentProps extends IBaseProps, IFormBase<IFormSearch> {}

interface IState {
  mediaType: EMediaType
}

class FormContent extends Component<IFormContentProps, IState> {
  constructor(props: IFormContentProps) {
    super(props)
    this.state = { mediaType: EMediaType.Video }
  }

  render() {
    const info = this.props.info[this.state.mediaType]
    return (
      <CustomBgImage>
        <Wrap>
          <Title>{info.title}</Title>
          <SubTitle sx={{ width: { xs: '100%', sm: '65%', md: '45%' } }}>{info.subTitle}</SubTitle>
          <Box height='10px' />
          <WrapFormContent>
            <IconButton sx={{ p: '10px' }} aria-label='menu' type='submit'>
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder='Search'
              inputProps={{ 'aria-label': 'Search' }}
              name='Value'
              defaultValue={this.props.data?.Value}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
            <MediaTypeMenu {...this.props} configs={this.props.menuConfig} onChange={(value) => this.setState({ mediaType: value })} />
          </WrapFormContent>
        </Wrap>
        <Box className='box-dark' />
      </CustomBgImage>
    )
  }
}
const formValidate = new FormValidator<Partial<IFormSearch>>({})
const FormComponent = CreateFormSingleValidate(formValidate, FormContent).Form

interface IFormSearchProps extends IBaseProps {
  onSubmit: (value: Partial<IFormSearch>) => Promise<void>
}

export default class FormSearch extends Component<IFormSearchProps> {
  render() {
    return <FormComponent onSubmit={this.handleSubmit} InnerProps={{ menuConfig: this.props.menuConfig, info: this.props.info }} />
  }

  handleSubmit = async (value: Partial<IFormSearch>) => {
    this.props.onSubmit && this.props.onSubmit(value)
  }
}

const WrapFormContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  borderRadius: '28px',
  padding: '4px 16px',
  backgroundColor: '#fff',
  boxShadow: '0 2px 14px rgba(0,0,0,0.23)',
  width: 'calc(100% - 30px)',
  [theme.breakpoints.up('sm')]: { maxWidth: theme.breakpoints.values.sm },
  [theme.breakpoints.up('md')]: { maxWidth: theme.breakpoints.values.md }
}))

const Wrap = styled('div')({
  textAlign: 'center',
  height: `calc(50vh - var(--height-header))`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '5px',
  position: 'relative',
  zIndex: '1'
})

const Title = styled('h3')({
  fontSize: '36px',
  fontWeight: 700,
  color: '#fff'
})

const SubTitle = styled('p')({
  fontSize: '16px',
  fontWeight: 500,
  margin: '15px auto 0',
  color: '#fff',
  height: '38px'
})

const CustomBgImage = styled('div')({
  backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg-home.png)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  '& .box-dark': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.45)'
  }
})

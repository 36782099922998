import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { IconSuccess } from '@lib/Component/Animation'
import { Button, Container, Stack, Typography } from '@mui/material'
import { RouteKey } from '@internal/route'
import { CreateRoutePath } from '@lib/RouteBase'
import { WindowScrollToTopById } from '@lib/Helpers'

const CurrentId = 'submit-media-step-complete'

export default class CheckOutComplete extends Component {
  componentDidMount(): void {
    WindowScrollToTopById(CurrentId)
  }

  render() {
    return (
      <Container id={CurrentId}>
        <Stack sx={{ alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }}>
          <IconSuccess />
          <Typography variant='h4' sx={{ textTransform: 'upercase' }}>
            Submit success!
          </Typography>
          <Typography variant='subtitle1'>Thank you for sharing your media with us, have a good day</Typography>
          {/* teen + <liink>Submission list</> */}
          <Button
            color='primary'
            variant='outlined'
            sx={{ minWidth: '220px', mt: '24px' }}
            component={Link}
            to={CreateRoutePath([RouteKey.Profile, RouteKey.Submission])}
          >
            Goto List
          </Button>
        </Stack>
      </Container>
    )
  }
}

import type { FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface UserExtendFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'UserExtend', T, TVariables> {

    on<XName extends ImplementationType<'UserExtend'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): UserExtendFetcher<
        XName extends 'UserExtend' ?
        T & X :
        WithTypeName<T, ImplementationType<'UserExtend'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'UserExtend'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): UserExtendFetcher<T, TVariables>;


    readonly __typename: UserExtendFetcher<T & {__typename: ImplementationType<'UserExtend'>}, TVariables>;


    readonly displayName: UserExtendFetcher<T & {readonly "displayName": string}, TVariables>;

    "displayName+"<
        XAlias extends string = "displayName", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"displayName", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): UserExtendFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~displayName": UserExtendFetcher<Omit<T, 'displayName'>, TVariables>;


    readonly id: UserExtendFetcher<T & {readonly "id": string}, TVariables>;

    "id+"<
        XAlias extends string = "id", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"id", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): UserExtendFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~id": UserExtendFetcher<Omit<T, 'id'>, TVariables>;
}

export const userExtend$: UserExtendFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "UserExtend", 
            "OBJECT", 
            [], 
            [
                "displayName", 
                {
                    category: "ID", 
                    name: "id"
                }
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const userExtend$$ = 
    userExtend$
        .displayName
        .id
;

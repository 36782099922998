import './polyfill'
import './index.css'
import { Provider } from 'react-redux'
import { AppRouter } from '@lib/RouteBase'
import { ThemeProvider } from '@mui/material'
import { BrowserRouter } from 'react-router-dom'
import { AuthLoading } from '@lib/ApiAuthorization'
import { AlertGlobal, GlobalModal } from '@lib/ApiContext'
import ReactDOM from 'react-dom/client'
import { theme } from './themes'
import { RouteConfigs } from './Routers'
import store from './Redux'
import Error404 from './Shareds/Error404'
import reportWebVitals from './reportWebVitals'
import LayoutGlobal from './Shareds/LayoutGlobal'
import { LayoutMakeupInstance } from '@shared/Instances'

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

let baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
baseUrl = baseUrl?.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const initialApp = async () => {
  const Content = (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalModal>
          <LayoutMakeupInstance.Provider>
            <BrowserRouter basename={baseUrl ?? ''}>
              <AuthLoading>
                <AppRouter layout={LayoutGlobal} routers={RouteConfigs} errorElement={<Error404 />} />
              </AuthLoading>
            </BrowserRouter>
          </LayoutMakeupInstance.Provider>
        </GlobalModal>
        <AlertGlobal />
      </ThemeProvider>
    </Provider>
  )
  // await authService.ensureUserManagerInitialized()
  // if ((await authService.isAuthenticated()) && (await checkTokenExpiry())) {
  //   root.render(<Welcome />)
  //   await RefreshTokenOnce().catch(console.log)
  // }
  root.render(Content)
}

initialApp()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import React, { Component, PropsWithChildren } from 'react'
import { SxProps, styled, Theme, Stack } from '@mui/material'

interface IProps {
  accept: string
  onChange: (files: FileList) => void
  sx?: SxProps<Theme>
  disabled?: boolean
}

interface IState {
  isMouseOver: boolean
  isDragOver: boolean
}

type TProps = PropsWithChildren<IProps>

export default class InputFiles extends Component<TProps, IState> {
  constructor(props: TProps) {
    super(props)
    this.state = { isMouseOver: false, isDragOver: false }
  }

  stopDefaults = (e: React.DragEvent) => {
    e.stopPropagation()
    e.preventDefault()
  }

  onChangeCallBack = (files: FileList) => {
    if (files.length <= 0) return
    this.props.onChange(files)
    if (this.refInput?.value) this.refInput.value = ''
  }

  getDragEvents = () => ({
    onMouseEnter: () => {
      this.setState({ ...this.state, isMouseOver: true })
    },
    onMouseLeave: () => {
      this.setState({ ...this.state, isMouseOver: false })
    },
    onDragEnter: (e: React.DragEvent) => {
      this.stopDefaults(e)
      this.setState({ ...this.state, isDragOver: true })
    },
    onDragLeave: (e: React.DragEvent) => {
      this.stopDefaults(e)
      this.setState({ ...this.state, isDragOver: false })
    },
    onDragOver: this.stopDefaults,
    onDrop: (e: React.DragEvent<HTMLElement>) => {
      this.stopDefaults(e)
      this.setState({ ...this.state, isDragOver: false }, () => {
        this.onChangeCallBack(e.dataTransfer.files)
      })
    }
  })

  handlingChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) this.onChangeCallBack(e.target.files)
  }

  getSxRoot = (status: boolean): SxProps<Theme> => {
    if (!status) return this.props.sx ?? {}
    return { ...this.props.sx, color: '#4285f4', borderColor: '#4285f4', backgroundColor: '#e4f2ff' }
  }

  getLinkByFile = (file: File) => {
    return URL.createObjectURL(file)
  }

  refInput: HTMLInputElement | null = null
  render() {
    const { isDragOver } = this.state
    const { accept } = this.props
    return (
      <Content sx={this.getSxRoot(isDragOver)}>
        {this.props.children}
        <CustomLabel htmlFor='file-upload' {...(this.props.disabled ? {} : this.getDragEvents())}>
          <input
            hidden
            type='file'
            id='file-upload'
            multiple
            accept={accept}
            ref={(ref) => (this.refInput = ref)}
            onChange={this.handlingChangeInput}
            disabled={this.props.disabled}
          />
        </CustomLabel>
      </Content>
    )
  }
}

const Content = styled(Stack)({
  position: 'relative',
  display: 'flex',
  backgroundColor: '#fff',
  gap: '6px',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '240px',
  border: '1px dashed #7b7b7b',
  borderRadius: '4px',
  color: '#606060',
  transition: 'all 0.3s',
  padding: '24px'
})

const CustomLabel = styled('label')({
  borderRadius: '8px',
  cursor: 'pointer',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
})

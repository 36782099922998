import { ISubmission } from '@shared/Types'
import dayjs from 'dayjs'

export const MapSubmitData = (value: Partial<ISubmission>): Partial<ISubmission> => {
  delete value.IsReadAndAgreedTermOdService
  delete value.IsReadAndAgreedTermOdSubmitssion

  value.Birthday = dayjs(value.Birthday).toDate().toISOString()
  value.FlatformRepository = 'fb'
  return value
}

import { IMedia } from '@shared/Types'
import { PayloadAction } from '@reduxjs/toolkit'
import { CreateCRUDSlice2, ELazyStatus } from '@lib/ReduxBase'
import { TableInitialSliceRedux, TableReduxType } from '@lib/Table'
import { IMediaStateSlice } from './redux.types'
import { fetchMediaThunk } from './redux.thunks'

export const DefaultTableInfo = TableInitialSliceRedux<IMedia>({ GridSortModel: [{ field: 'DateCreated', sort: 'desc' }] })

const MediaSlice = CreateCRUDSlice2<IMedia>()({
  name: 'MediaSlice',
  selectId: (x) => x.Id,
  Initial: (x) => {
    const state: IMediaStateSlice = { ...x, tableInfo: DefaultTableInfo }
    return state
  },
  extraReducers: (builder, adapter) => {
    builder
      .addCase(fetchMediaThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Loaded
        state.tableInfo = action.payload.tableInfo
        state.categories = action.payload.categories
        state.delivery = action.payload.delivery
      })
      .addCase(fetchMediaThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Error
        state.tableInfo.isLoading = false
      })
      .addCase(fetchMediaThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Status = ELazyStatus.Loading
        state.categories = []
        state.tableInfo.isLoading = true
      })
  },
  reducers: {
    onChange: (state, action: PayloadAction<TableReduxType.OnChangeParam<IMedia>>) => {
      state.tableInfo[action.payload.key] = action.payload.value
      state.tableInfo.details = action.payload.details
    },
    clean: (state) => {
      state.delivery = undefined
      state.tableInfo = DefaultTableInfo
    }
  }
})
export default MediaSlice

import { IMedia, IPrice } from '@shared/Types'
import { AppDispatch, RootState } from '@internal/redux'
import { QuicklyAddToCart } from '../Cart'
import { TPutExploreFiltersFunc } from './redux.types'
import { fetchExploreFirstThunk, fetchExploreThunk } from './redux.thunks'
import { IExploreDispatchRedux, IExploreStateRedux, TPutExploreFilterFunc } from './redux.types'
import ExploreSlice from './redux.slice'

const putFilter: (dispatch: AppDispatch) => TPutExploreFilterFunc = (dispatch) => (key, value) => {
  dispatch((dis) => {
    dis(ExploreSlice.actions.updateFilter({ key, value }))
    dis(fetchExploreThunk())
  })
}

const putFilters: (dispatch: AppDispatch) => TPutExploreFiltersFunc = (dispatch) => (value) => {
  dispatch((dis) => {
    dis(ExploreSlice.actions.updateFilters(value))
    dis(fetchExploreThunk())
  })
}

export const putFilterExplore: (dispatch: AppDispatch) => TPutExploreFilterFunc = (dispatch) => (key, value) => {
  return putFilter(dispatch)(key, value)
}

// ========= ========= ========= View Base ========= ========= =========
export const mapStateToProps = (state: RootState): IExploreStateRedux => ({
  Status: state.ExploreSlice.Status,
  Slice: state.ExploreSlice
})
export const mapDispatchToProps = (dispatch: AppDispatch): IExploreDispatchRedux => ({
  FetchData: () => {
    dispatch(ExploreSlice.actions.fetchFromQueryParams())
    dispatch(fetchExploreFirstThunk())
    return dispatch(fetchExploreThunk())
  },
  putFilter: putFilter(dispatch),
  putFilters: putFilters(dispatch),
  fetchFromQueryParams: () => dispatch(ExploreSlice.actions.fetchFromQueryParams()),
  onAddToCart: (media: IMedia, price: IPrice) => {
    QuicklyAddToCart(dispatch, media, price)
  }
})

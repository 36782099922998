import { FetchDelay } from '@lib/Helpers'
import { ICategory } from '@shared/Types'
import { ServiceBase, CreateHttpService } from '@lib/Http'
import { IFilterDTO } from '../types'
import FilterMapping from './FilterMapping'

class _CategoryService extends ServiceBase {
  constructor() {
    super(CreateHttpService(`${process.env.REACT_APP_API_URI}/api/v1/guest/Category`))
  }

  private urlAll = '/All'
  all = (filter: IFilterDTO, signal?: AbortSignal) => {
    return FetchDelay(async () => {
      try {
        const filterReq = FilterMapping.parseMedia(filter)
        const res = await this.PushNotify(this.Post<any>, this.urlAll, filterReq, { signal })
        return res['Data'] as ICategory[]
      } catch (error) {
        return []
      }
    }, 500)
  }
}

const CategoryService = new _CategoryService()
export default CategoryService

import { INotification } from '@shared/Types'
import { CreateCRUDSlice2 } from '@lib/ReduxBase'
import { ELazyStatus } from '@lib/ReduxBase/types'
import { fetchNotificationThunk } from './redux.thunks'
import { INotificationStateSlice } from './redux.types'

const NotificationSlice = CreateCRUDSlice2<INotification>()({
  name: 'NotificationSlice',
  selectId: (x) => x.Id,
  Initial: (x) => x as INotificationStateSlice,
  extraReducers: (builder, adapter) => {
    builder
      .addCase(fetchNotificationThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Loaded
        if (!state.Data) return
        adapter.addMany(state.Data, action.payload.notifications)
      })
      .addCase(fetchNotificationThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Error
      })
      .addCase(fetchNotificationThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Status = ELazyStatus.Loading
      })
  }
})
export default NotificationSlice

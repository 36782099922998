import React, { Component } from 'react'
import { EMediaType } from '@shared/Types'
import { IFormBase } from '@lib/Forms'
import { Box, Divider, IconButton, InputBase, styled } from '@mui/material'
import { CreateFormSingleValidate } from '@lib/FormHelpers'
import { FormValidator } from '@lib/Forms'
import SearchIcon from '@mui/icons-material/Search'
import { IFormSearch } from './type'
import MediaTypeMenu, { TMenuConfig } from './MediaTypeMenu'

interface IInfoConfig {
  title: string
  subTitle: string
  lead: string
}

export type TInfoConfig = { [key in EMediaType]: IInfoConfig }

interface IBaseProps {
  info: TInfoConfig
  menuConfig: TMenuConfig
}

interface IFormContentProps extends IBaseProps, IFormBase<IFormSearch> {}

interface IState {
  mediaType: EMediaType
}

class FormContent extends Component<IFormContentProps, IState> {
  constructor(props: IFormContentProps) {
    super(props)
    this.state = { mediaType: EMediaType.Video }
  }

  render() {
    const { menuConfig, info, ...otherProps } = this.props
    const infoData = info[this.state.mediaType]
    return (
      <Wrap>
        <Title>{infoData.title}</Title>
        <SubTitle sx={{ width: { xs: '100%', sm: '65%', md: '45%' } }}>{infoData.subTitle}</SubTitle>
        <Box height='10px' />
        <WrapFormContent>
          <IconButton sx={{ p: '10px' }} aria-label='menu' type='submit'>
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder='Search'
            inputProps={{ 'aria-label': 'Search' }}
            name='Value'
            defaultValue={this.props.data?.Value}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
          <MediaTypeMenu {...otherProps} configs={menuConfig} onChange={(value) => this.setState({ mediaType: value })} />
        </WrapFormContent>
      </Wrap>
    )
  }
}
const formValidate = new FormValidator<Partial<IFormSearch>>({})
const FormComponent = CreateFormSingleValidate(formValidate, FormContent).Form

interface IFormSearchProps extends IBaseProps {
  data?: IFormSearch
  onSubmit: (value: Partial<IFormSearch>) => Promise<void>
}

export default class FormSearch extends Component<IFormSearchProps> {
  render() {
    return (
      <FormComponent
        key={JSON.stringify(this.props.data)}
        data={this.props.data}
        onSubmit={this.props.onSubmit}
        InnerProps={{ menuConfig: this.props.menuConfig, info: this.props.info }}
      />
    )
  }
}

const WrapFormContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  borderRadius: '28px',
  padding: '4px 16px',
  backgroundColor: '#fff',
  boxShadow: '0 2px 14px rgba(0,0,0,0.23)',
  width: 'calc(100% - 30px)',
  [theme.breakpoints.up('sm')]: { maxWidth: theme.breakpoints.values.sm },
  [theme.breakpoints.up('md')]: { maxWidth: theme.breakpoints.values.md }
}))

const Wrap = styled('div')({
  textAlign: 'center',
  height: `calc(50vh - var(--height-header))`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '5px',
  position: 'relative',
  zIndex: '1'
})

const Title = styled('h3')({
  fontSize: '36px',
  fontWeight: 700,
  color: '#fff'
})

const SubTitle = styled('p')({
  fontSize: '16px',
  fontWeight: 500,
  margin: '15px auto 0',
  color: '#fff',
  height: '38px'
})

import React, { FC } from 'react'
import { SvgLoading } from '@shared/Icons'
import { Box, Fade, Stack, styled, Typography } from '@mui/material'
import { IPdfViewerOptions } from './types'
import { PdfViewerOptionsToStringtify } from './helper'

interface IProps {
  src: string
  options?: IPdfViewerOptions
}

const PdfViewer: FC<IProps> = (props) => {
  const iframeRef = React.useRef<HTMLIFrameElement>(null)
  const [isLoading, setIsLoading] = React.useState(true)

  const getSrc = (src: string, options?: IPdfViewerOptions) => {
    return `${src}${options ? PdfViewerOptionsToStringtify(options) : ''}`
  }

  React.useEffect(() => {
    const iframe = iframeRef.current
    const onComplete = () => {
      setIsLoading(false)
    }
    if (iframe) iframe.addEventListener('load', onComplete)
    return () => {
      // Clean up event
      if (iframe) iframe.removeEventListener('load', onComplete)
    }
  }, [])

  return (
    <Wrap>
      <Box sx={{ flex: 1, position: 'relative', overflow: 'hidden' }}>
        <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 'calc(100% + 18px)' }}>
          <IFrame ref={iframeRef} src={getSrc(props.src, props.options)} title='Privacy Policy' />
        </Box>
      </Box>
      <Fade in={isLoading} unmountOnExit>
        <Backdrop>
          <SvgLoading sx={{ fontSize: '6.5rem' }} fill='#fb8c1e' />
          <Typography variant='subtitle2'>Loading . . .</Typography>
        </Backdrop>
      </Fade>
    </Wrap>
  )
}
export default PdfViewer

const IFrame = styled('iframe')({
  border: 0,
  width: '100%',
  height: '100%',
  overflowX: 'hidden'
})

const Wrap = styled(Stack)({
  flex: 1,
  position: 'relative',
  justifyContent: 'stretch',
  marginTop: '6px'
})

const Backdrop = styled(Box)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  background: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
})

import type { FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface BillingDetailFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'BillingDetail', T, TVariables> {

    on<XName extends ImplementationType<'BillingDetail'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): BillingDetailFetcher<
        XName extends 'BillingDetail' ?
        T & X :
        WithTypeName<T, ImplementationType<'BillingDetail'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'BillingDetail'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): BillingDetailFetcher<T, TVariables>;


    readonly __typename: BillingDetailFetcher<T & {__typename: ImplementationType<'BillingDetail'>}, TVariables>;


    readonly id: BillingDetailFetcher<T & {readonly "id": string}, TVariables>;

    "id+"<
        XAlias extends string = "id", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"id", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): BillingDetailFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~id": BillingDetailFetcher<Omit<T, 'id'>, TVariables>;


    readonly billingId: BillingDetailFetcher<T & {readonly "billingId": string}, TVariables>;

    "billingId+"<
        XAlias extends string = "billingId", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"billingId", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): BillingDetailFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~billingId": BillingDetailFetcher<Omit<T, 'billingId'>, TVariables>;


    readonly resourceId: BillingDetailFetcher<T & {readonly "resourceId": string}, TVariables>;

    "resourceId+"<
        XAlias extends string = "resourceId", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"resourceId", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): BillingDetailFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~resourceId": BillingDetailFetcher<Omit<T, 'resourceId'>, TVariables>;


    readonly payout: BillingDetailFetcher<T & {readonly "payout": number}, TVariables>;

    "payout+"<
        XAlias extends string = "payout", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"payout", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): BillingDetailFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: number} : 
                {readonly [key in XAlias]: number}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~payout": BillingDetailFetcher<Omit<T, 'payout'>, TVariables>;


    readonly resourceInfo: BillingDetailFetcher<T & {readonly "resourceInfo"?: string}, TVariables>;

    "resourceInfo+"<
        XAlias extends string = "resourceInfo", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"resourceInfo", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): BillingDetailFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~resourceInfo": BillingDetailFetcher<Omit<T, 'resourceInfo'>, TVariables>;


    billing<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Billing', X, XVariables>
    ): BillingDetailFetcher<
        T & {readonly "billing"?: X}, 
        TVariables & XVariables
    >;

    billing<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "billing", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Billing', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"billing", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): BillingDetailFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    readonly dateCreated: BillingDetailFetcher<T & {readonly "dateCreated": string}, TVariables>;

    "dateCreated+"<
        XAlias extends string = "dateCreated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateCreated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): BillingDetailFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateCreated": BillingDetailFetcher<Omit<T, 'dateCreated'>, TVariables>;


    readonly dateUpdated: BillingDetailFetcher<T & {readonly "dateUpdated": string}, TVariables>;

    "dateUpdated+"<
        XAlias extends string = "dateUpdated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateUpdated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): BillingDetailFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateUpdated": BillingDetailFetcher<Omit<T, 'dateUpdated'>, TVariables>;
}

export const billingDetail$: BillingDetailFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "BillingDetail", 
            "OBJECT", 
            [], 
            [
                {
                    category: "ID", 
                    name: "id"
                }, 
                "billingId", 
                "resourceId", 
                "payout", 
                {
                    category: "SCALAR", 
                    name: "resourceInfo", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "billing", 
                    targetTypeName: "Billing", 
                    undefinable: true
                }, 
                "dateCreated", 
                "dateUpdated"
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const billingDetail$$ = 
    billingDetail$
        .id
        .billingId
        .resourceId
        .payout
        .resourceInfo
        .dateCreated
        .dateUpdated
;

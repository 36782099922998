import React, { Component } from 'react'
import { EMediaType } from '@shared/Types'
import withNavigate, { INavigateProps } from '@lib/Hocs/withNavigation'
import TheatersIcon from '@mui/icons-material/Theaters'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import { IFormSearch } from './type'
import { IMediaFilterDTO } from '../../types'
import { TMenuConfig } from './MediaTypeMenu'
import FormSearch, { TInfoConfig } from './FormSearch'
import { Box, styled } from '@mui/material'

export * from './type'

const MenuConfig: TMenuConfig = {
  [EMediaType.Video]: { display: 'Videos', icon: <TheatersIcon /> },
  [EMediaType.Audio]: { display: 'Audios', icon: <MusicNoteIcon /> }
}

const InfoConfig: TInfoConfig = {
  [EMediaType.Video]: {
    title: 'VIDEO COLLECTION',
    subTitle: 'Authentic media, new updates constantly, high quality videos and high quality audio',
    lead: 'Try video url or keyword. e.g: the dogs'
  },
  [EMediaType.Audio]: {
    title: 'AUDIO COLLECTION',
    subTitle: '10,000+ unique media product items, copyright support in the most popular social networks: youtube, facebook, spotify...',
    lead: 'Try audio url or keyword. e.g: dreamy'
  }
}

interface IProps extends INavigateProps {
  data?: IFormSearch
  onSearch?: (value: IMediaFilterDTO) => Promise<void>
}

class SectionSearch extends Component<IProps> {
  render() {
    return (
      <CustomBgImage>
        <FormSearch
          data={this.props.data}
          menuConfig={MenuConfig}
          info={InfoConfig}
          onSubmit={async (value) => {
            await (this.props.onSearch && this.props.onSearch({ searchKey: value.Value, mediaType: value.MediaType }))
          }}
        />
        <Box className='box-dark' />
      </CustomBgImage>
    )
  }
}
export default withNavigate(SectionSearch)

const CustomBgImage = styled('div')({
  backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg-home.png)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  '& .box-dark': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.45)'
  }
})

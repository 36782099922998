import {
  EDatasourceKey,
  EGalleryArea,
  EGalleryListType,
  EMediaDeliveryStatus,
  EPrivacyPolicyType,
  ETicketType,
  IBillingDetailResourceInfo
} from '../Types'
import { EBillingStatus, EBillingType, ECategoryType, EClaimedMediaStatus, EGalleryType, EMediaDelivery, ENotificationAreaType } from '../Types'
import { EMediaStatus, EMediaType, ENotificationSendType, EOrderPayment, EOrderStatus, EPriceType, IPriceJsonContent, IBillingDetail } from '../Types'

export const getCategoryTypes = () => {
  return Array.from(Object.values(ECategoryType)).map((x) => ({
    name: x.toString(),
    value: x
  }))
}

export const getBillingTypes = () => {
  return Array.from(Object.values(EBillingType)).map((x) => ({
    name: x.toString(),
    value: x
  }))
}

export const getBillingStatuses = () => {
  return Array.from(Object.values(EBillingStatus)).map((x) => ({
    name: x.toString(),
    value: x
  }))
}

export const getClaimedStatuses = () => {
  return Array.from(Object.values(EClaimedMediaStatus)).map((x) => ({
    name: x.toString(),
    value: x
  }))
}

export const getGalleryTypes = () => {
  return Array.from(Object.values(EGalleryType)).map((x) => ({
    name: x.toString(),
    value: x
  }))
}

export const getMediaTypes = () => {
  return Array.from(Object.values(EMediaType)).map((x) => ({
    name: x.toString(),
    value: x
  }))
}

export const getMediaStatuses = () => {
  return Array.from(Object.values(EMediaStatus)).map((x) => ({
    name: x.toString(),
    value: x
  }))
}

export const getNotificationSendTypes = () => {
  return Array.from(Object.values(ENotificationSendType)).map((x) => ({
    name: x.toString(),
    value: x
  }))
}

export const getNotificationAreaTypes = () => {
  return Array.from(Object.values(ENotificationAreaType)).map((x) => ({
    name: x.toString(),
    value: x
  }))
}

export const getOrderStatuses = () => {
  return Array.from(Object.values(EOrderStatus))
    .map((x) => ({
      name: x.toString(),
      value: x
    }))
    .filter((x) => x.value !== EOrderStatus.Draft)
}

export const getEOrderPayments = () => {
  return Array.from(Object.values(EOrderPayment)).map((x) => ({
    name: x.toString(),
    value: x
  }))
}

export const getPriceTypes = () => {
  return Array.from(Object.values(EPriceType)).map((x) => ({
    name: x.toString(),
    value: x
  }))
}

export const getMediaDeliveryTypes = () => {
  return Array.from(Object.values(EMediaDelivery)).map((x) => ({
    name: x.toString(),
    value: x
  }))
}

export const getTicketTypes = () => {
  return Array.from(Object.values(ETicketType)).map((x) => ({
    name: x.toString(),
    value: x
  }))
}

export const getPrivacyPolicyTypes = () => {
  return Array.from(Object.values(EPrivacyPolicyType)).map((x) => ({
    name: x.toString(),
    value: x
  }))
}

export const getDeliveryStatus = () => Array.from(Object.values(EMediaDeliveryStatus)).map((x) => ({ name: x.toString(), value: x }))

export const getDataSourceKey = () => Array.from(Object.values(EDatasourceKey)).map((x) => ({ name: x.toString(), value: x }))

export const getGalleryArea = () => Array.from(Object.values(EGalleryArea)).map((x) => ({ name: x.toString(), value: x }))

export const getGalleryListType = () => Array.from(Object.values(EGalleryListType)).map((x) => ({ name: x.toString(), value: x }))

// ========= ========= ========= Other ========= ========= =========

export const ParsePriceJsonContent = (value: string = '{}'): IPriceJsonContent => {
  try {
    return JSON.parse(value)
  } catch (error) {
    return {}
  }
}

export const ParseBillingDetailResourceInfo = (value: string = '{}'): IBillingDetailResourceInfo => {
  try {
    return JSON.parse(value)
  } catch (error) {
    return {}
  }
}

export const AddBillingDetailResourceInfo = (billingDetail: IBillingDetail, resourceInfo: IBillingDetailResourceInfo): IBillingDetail => {
  let bd = { ...billingDetail }
  const temp = Object.assign({}, ParseBillingDetailResourceInfo(bd.ResourceInfo), resourceInfo)
  bd.ResourceInfo = JSON.stringify(temp)
  return bd
}

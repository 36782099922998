import { createContext } from 'react'
import { ICartItemContext } from './type'

export const CartItemContext = createContext<ICartItemContext>({
  onBlur: () => {},
  onDelete: () => {},
  onAdd: () => {},
  onClearLink: () => {},
  MessageError: {},
})

export const mapCartItemContext = (context: (state: ICartItemContext) => React.ReactNode | JSX.Element) => {
  return <CartItemContext.Consumer>{context}</CartItemContext.Consumer>
}

import type { FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';
import type {PaymentType} from '../enums';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface PaymentInfoFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'PaymentInfo', T, TVariables> {

    on<XName extends ImplementationType<'PaymentInfo'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): PaymentInfoFetcher<
        XName extends 'PaymentInfo' ?
        T & X :
        WithTypeName<T, ImplementationType<'PaymentInfo'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'PaymentInfo'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): PaymentInfoFetcher<T, TVariables>;


    readonly __typename: PaymentInfoFetcher<T & {__typename: ImplementationType<'PaymentInfo'>}, TVariables>;


    readonly id: PaymentInfoFetcher<T & {readonly "id": string}, TVariables>;

    "id+"<
        XAlias extends string = "id", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"id", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): PaymentInfoFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~id": PaymentInfoFetcher<Omit<T, 'id'>, TVariables>;


    readonly type: PaymentInfoFetcher<T & {readonly "type": PaymentType}, TVariables>;

    "type+"<
        XAlias extends string = "type", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"type", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): PaymentInfoFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: PaymentType} : 
                {readonly [key in XAlias]: PaymentType}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~type": PaymentInfoFetcher<Omit<T, 'type'>, TVariables>;


    readonly userId: PaymentInfoFetcher<T & {readonly "userId": string}, TVariables>;

    "userId+"<
        XAlias extends string = "userId", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"userId", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): PaymentInfoFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~userId": PaymentInfoFetcher<Omit<T, 'userId'>, TVariables>;


    readonly jsonContent: PaymentInfoFetcher<T & {readonly "jsonContent"?: string}, TVariables>;

    "jsonContent+"<
        XAlias extends string = "jsonContent", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"jsonContent", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): PaymentInfoFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~jsonContent": PaymentInfoFetcher<Omit<T, 'jsonContent'>, TVariables>;


    readonly dateCreated: PaymentInfoFetcher<T & {readonly "dateCreated": string}, TVariables>;

    "dateCreated+"<
        XAlias extends string = "dateCreated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateCreated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): PaymentInfoFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateCreated": PaymentInfoFetcher<Omit<T, 'dateCreated'>, TVariables>;


    readonly dateUpdated: PaymentInfoFetcher<T & {readonly "dateUpdated": string}, TVariables>;

    "dateUpdated+"<
        XAlias extends string = "dateUpdated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateUpdated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): PaymentInfoFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateUpdated": PaymentInfoFetcher<Omit<T, 'dateUpdated'>, TVariables>;
}

export const paymentInfo$: PaymentInfoFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "PaymentInfo", 
            "OBJECT", 
            [], 
            [
                {
                    category: "ID", 
                    name: "id"
                }, 
                "type", 
                "userId", 
                {
                    category: "SCALAR", 
                    name: "jsonContent", 
                    undefinable: true
                }, 
                "dateCreated", 
                "dateUpdated"
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const paymentInfo$$ = 
    paymentInfo$
        .id
        .type
        .userId
        .jsonContent
        .dateCreated
        .dateUpdated
;

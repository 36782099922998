import React, { Component } from 'react'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'

interface IProps {
  isPaid?: boolean
}
export default class UIPaidStatus extends Component<IProps> {
  render() {
    if (this.props.isPaid === true) return <DoneIcon color='success' />
    return <CloseIcon color='error' />
  }
}

import { IBilling } from '@shared/Types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { MergeObjects, TableQueryParam } from '@lib/Helpers'
import { MapTableData, MapTableInfo, TableReduxType } from '@lib/Table'
import BillingService, { BillingMapping } from '@internal/services/BillingService'
import { IBillingStateSlice } from './redux.types'
import { DefaultTableInfo } from './redux.slice'

interface IParamThunk extends TableReduxType.ThunkParam<IBilling> {}
interface IResponse extends TableReduxType.ThunkReponse<IBilling> {}
export const fetchBillingThunk = createAsyncThunk<IResponse, IParamThunk | undefined>('fetchBillingThunk', async (param, thunkAPI) => {
  const state = thunkAPI.getState() as { BillingSlice: IBillingStateSlice }
  const tableInfoState = MergeObjects(state.BillingSlice.tableInfo, param?.tableInfo)
  const tableInfoMap = MapTableInfo(tableInfoState, param?.tableChange)

  const filter = BillingMapping.tableInfoToFilter(tableInfoMap)
  const res = await BillingService.AllGraphQL(filter, thunkAPI.signal)

  const tableInfo = MapTableData(tableInfoMap, { Data: BillingMapping.billings(res?.items), Total: res?.totalItems })
  TableQueryParam.setParam(tableInfo, DefaultTableInfo)
  return { tableInfo }
})

import { QueryParam } from '@lib/Helpers'
import { EMediaStatus, EMediaType } from '@shared/Types'
import { ICategory, IMedia } from '@shared/Types'
import { ELogic, EOperator, EOrder, IModelFilter, PropFilter } from '@lib/Table'
import { IMediaFilterDTO } from './types'
import { ConstantDefault } from './helpers'

type TMediaFilter = IModelFilter<IMedia>
type TCategoryFilter = IModelFilter<ICategory>

interface IFilterParmasDTO extends Omit<IMediaFilterDTO, 'page'> {
  page?: string
  sortField?: string
  sortType?: EOrder
}

class MediaMappingServiceBase {
  initilal = (): IMediaFilterDTO => {
    return { ...this.byChangeMediaType(), ...this.getQueryParams() }
  }

  byChangeMediaType = (mediaType?: EMediaType): IMediaFilterDTO => {
    const obj: IMediaFilterDTO = { page: ConstantDefault.page, amount: ConstantDefault.pageCount }
    if (mediaType) obj.mediaType = mediaType
    return obj
  }

  private getQueryParams = (): IMediaFilterDTO => {
    const obj: IFilterParmasDTO = QueryParam.GetAll()
    const objRes = Object.assign({}, obj, { page: obj.page ? parseInt(obj.page) : ConstantDefault.page })
    if (objRes.sortField && objRes.sortType) {
      objRes.sort = { field: objRes.sortField as keyof IMedia, type: objRes.sortType as EOrder }
    }
    if (!!objRes.sortField) delete objRes.sortField
    if (!!objRes.sortType) delete objRes.sortType
    objRes.mediaType = obj.mediaType === EMediaType.Audio ? EMediaType.Audio : EMediaType.Video
    return objRes
  }

  setQueryParams = (value: IMediaFilterDTO) => {
    const obj: IFilterParmasDTO = { ...value, page: value.page?.toString() }
    delete obj.amount
    if (!value.page || value.page < 2) delete obj.page
    if (!value.searchKey) delete obj.searchKey
    if (obj.sort) {
      obj.sortField = obj.sort?.field
      obj.sortType = obj.sort?.type
    }
    delete obj.sort
    QueryParam.Put(obj)
  }

  parseMedia = (value: IMediaFilterDTO): TMediaFilter => {
    const page = value.page ? value.page - 1 : 0
    const filter: TMediaFilter = { Page: page, Amount: value.amount ?? 0 }

    let propFilters: PropFilter<IMedia>[] = [
      { FieldName: 'Type', Value: value.mediaType ?? EMediaType.Video, Operator: EOperator.Equal, Logic: ELogic.And },
      { FieldName: 'Status', Value: EMediaStatus.Approved, Operator: EOperator.Equal, Logic: ELogic.And }
    ]

    if (value.searchKey) {
      propFilters.push({
        Logic: ELogic.And,
        Scopes: [
          { FieldName: 'Name', Value: value.searchKey, Operator: EOperator.Contains },
          { FieldName: 'Description', Value: value.searchKey, Operator: EOperator.Contains, Logic: ELogic.Or }
        ]
      })
    }

    if (value.categories) {
      propFilters.push({ FieldName: 'CategoryNames', Operator: EOperator.Contains, Value: value.categories, Logic: ELogic.And })
    }

    if (value.sort && value.sort.type) filter.PropOrders = [{ FieldName: value.sort.field, Type: value.sort.type }]

    filter.PropFilters = propFilters
    return filter
  }

  parseCategory = (value: IMediaFilterDTO): TCategoryFilter => {
    const filter: TCategoryFilter = {}
    let propFilters: PropFilter<ICategory>[] = [{ FieldName: 'Type', Value: value.mediaType ?? EMediaType.Video, Operator: EOperator.Equal }]
    filter.PropFilters = propFilters
    return filter
  }
}

const MediaMappingService = new MediaMappingServiceBase()
export default MediaMappingService

import React, { Component } from 'react'
import { Box, Typography, styled } from '@mui/material'
import { INotification } from '@shared/Types'
import { formatTimeAgo } from '../Helpers'

interface IProps {
  data: INotification
  onClick: () => void
}

export default class Item extends Component<IProps> {
  render() {
    const { data } = this.props
    return (
      <Wrapper onClick={this.props.onClick}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '6px' }}>
          {!data.IsRead && <Cir />}
          <Title variant='subtitle1'>{data.Title}</Title>
        </Box>
        <Description variant='subtitle2'>{data.SubTitle}</Description>
        <Typography variant='caption' sx={{ mt: '12px' }}>
          {!data.DateCreated ? 'unknown' : formatTimeAgo(data.DateCreated ?? '')}
        </Typography>
      </Wrapper>
    )
  }
}

const Wrapper = styled(Box)({
  marginTop: '6px',
  padding: '8px 18px',
  backgroundColor: 'rgba(0,0,0,0)',
  transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1)',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.05)'
  }
})

const Title = styled(Typography)({
  color: '#222f44',
  fontWeight: 600,
  letterSpacing: '1.1px'
})

const Description = styled(Typography)({
  letterSpacing: '1.1px',
  color: '#8594a4',
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  maxHeight: '3rem'
})

const Cir = styled(Box)({
  marginTop: '0.6rem',
  marginLeft: '-8px',
  width: '4px',
  height: '4px',
  borderRadius: '50%',
  backgroundColor: '#76ff03'
})

import { IPrice } from '@shared/Types'
import { EPriceType } from '@shared/Types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IMediaDTO, ICategoryDTO, IQueryParams } from './types'
import MediaService from './services/MediaService'
import CategoryService from './services/CategoryService'
import PricePackageService from './services/PricePackageService'

interface IMediaDetailResult {
  media?: IMediaDTO
  recommends: IMediaDTO[]
  categories: ICategoryDTO[]
  pricePackages: IPrice[]
}

export const fetchMediaDetailThunk = createAsyncThunk<IMediaDetailResult, IQueryParams>('fetchMediaDetailThunk', async (params, thunkAPI) => {
  // const state = thunkAPI.getState() as { MediaDetailSlice: IMediaDetailStateSlice }
  const [media, recommends, categories, prices] = await Promise.all([
    MediaService.detail(params.id, thunkAPI.signal).catch(() => undefined),
    MediaService.all({ mediaType: params.type }, { Amount: 13 }, thunkAPI.signal),
    CategoryService.all({ mediaType: params.type }, thunkAPI.signal).catch(() => []),
    PricePackageService.all({ pricePackagetype: EPriceType.Media }, thunkAPI.signal).catch(() => [])
  ])
  return { media, recommends: recommends.Data, categories, pricePackages: prices }
})

import { FetchDelay } from '@lib/Helpers'
import { ICategory } from '@shared/Types'
import { ServiceBase, CreateHttpService } from '@lib/Http'
import { IMediaFilterDTO } from './types'
import MediaMappingService from './service.mapping'

class CategoryServiceBase extends ServiceBase {
  constructor() {
    super(CreateHttpService(`${process.env.REACT_APP_API_URI}/api/v1/guest/Category`))
  }

  private urlAll = '/All'
  filter = (filter: IMediaFilterDTO, signal?: AbortSignal): Promise<ICategory[]> => {
    return FetchDelay(async () => {
      try {
        const filterReq = MediaMappingService.parseCategory(filter)
        const res = await this.PushNotify(this.Post<any>, this.urlAll, filterReq, { signal })
        return res['Data'] as ICategory[]
      } catch (error) {
        return []
      }
    }, 500)
  }
}

const CategoryService = new CategoryServiceBase()
export default CategoryService

import { ApiAlertContext } from '@lib/ApiContext'
import { RootState, AppDispatch } from '@internal/redux'
import MediaDeliveryService, { MediaDeliveryMapping } from '@internal/services/MediaDeliveryService'
import { fetchDeliveryThunk } from './redux.thunks'
import { IDeliveryDispatchRedux, IDeliveryStateRedux } from './redux.types'
import DeliverySlice, { DefaultTableInfo } from './redux.slice'
import { TableQueryParam } from '@lib/Helpers'
import { IMediaDelivery } from '@shared/Types'

export const mapStateToProps = (state: RootState): IDeliveryStateRedux => ({
  Status: state.DeliverySlice.Status,
  stateRedux: state.DeliverySlice,
  tableInfo: state.DeliverySlice.tableInfo
})

type TRequest = { abort: () => void }
const FetchAccessory: { request?: TRequest } = {}
export const mapDispatchToProps = (dispatch: AppDispatch): IDeliveryDispatchRedux => ({
  FetchData: () => {
    const tableInfo = TableQueryParam.getParam<IMediaDelivery>(DefaultTableInfo)
    return dispatch(fetchDeliveryThunk({ tableInfo }))
  },
  onTableChange: (key, value, details) => {
    if (key === 'FilterModel') {
      dispatch(DeliverySlice.actions.onChange({ key, value, details }))
    }
    FetchAccessory.request?.abort()
    FetchAccessory.request = dispatch(fetchDeliveryThunk({ tableChange: { key, value: value, details } }))
  },
  CRUD: {
    Create: async (model) => {
      const data = await MediaDeliveryService.Create(model)
      ApiAlertContext.ApiAlert?.PushSuccess('Created successfully!')
      dispatch(fetchDeliveryThunk())
      return data
    },
    Update: async (Id, model) => {
      await MediaDeliveryService.Update(Id, MediaDeliveryMapping.update(model))
      ApiAlertContext.ApiAlert?.PushSuccess('Updated successfully!')
      dispatch(fetchDeliveryThunk())
    },
    Delete: async (Id) => {
      const data = await MediaDeliveryService.Remove(Id)
      if (!!data) {
        ApiAlertContext.ApiAlert?.PushSuccess('Deleted successfully!')
        dispatch(fetchDeliveryThunk())
      }
    }
  }
})

import { EGalleryArea, IGallery } from '@shared/Types'
import { CRUDServiceBase } from '@lib/Http'
import Graphql from '@coreprj/graphql'

class GalleryGuestServiceBase extends CRUDServiceBase<IGallery, number> {
  constructor() {
    super(`/api/v1/guest/Gallery`)
  }

  AllGraphQL = async (signal?: AbortSignal) => {
    const res = await Graphql.GeustMediaStore.Query(Graphql.QMediaStore.GalleriesAreaQuery(EGalleryArea.Home), { signal })
    return res
  }
}
const GalleryGuestService = new GalleryGuestServiceBase()
export default GalleryGuestService

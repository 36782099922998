import { EMediaDeliveryStatus } from '@shared/Types'
import CreateStatusCell, { TStatusCellConfig } from '@shared/UIStyleds/CreateStatusCell'

export const DeliveryStatusCellConfig: TStatusCellConfig<EMediaDeliveryStatus> = {
  Pending: { background: 'linear-gradient(to right,#0090ff,#88c9ff)' },
  Processing: { background: 'linear-gradient(to right,#0d6efd,#82baff)' },
  Rejected: { background: 'linear-gradient(to right,#e72134,#eba1a1)' },
  Done: { background: 'linear-gradient(to right,#357a38,#1edd9b)' }
}

const DeliveryStatusCell = CreateStatusCell<EMediaDeliveryStatus>(DeliveryStatusCellConfig, { sxInner: { minWidth: '90px' } })
export default DeliveryStatusCell

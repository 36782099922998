import React, { Component } from 'react'
import { Box, CircularProgress, IconButton, Dialog } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { IItemInfo, EOpenType } from './type'
import { FileIconColor } from './FileIconColor'
import AudioView from './ContentTypeViews/AudioView'
import VideoView from './ContentTypeViews/VideoView'
import TextView from './ContentTypeViews/TextView'
import OtherView from './ContentTypeViews/OtherView'
import ImageView from './ContentTypeViews/ImageView'
import UploadService from './UploadService'

import './index.css'

export * from './type'

interface ContentPreviewState {
  open: boolean
  loading: boolean
  data?: IItemInfo
  OpenType: EOpenType
  content?: string
}

interface ContentPreviewProps {}

export default class ContentPreview extends Component<ContentPreviewProps, ContentPreviewState> {
  constructor(props: ContentPreviewProps) {
    super(props)
    this.state = { open: false, loading: false, OpenType: EOpenType.other }
  }
  render() {
    return (
      <Dialog
        fullScreen
        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.7)' } } }}
        PaperProps={{ style: { background: 'transparent' }, onMouseDown: this.handleClose }}
        open={this.state.open}
      >
        <Box className='content-view-wrap'>
          <Box className='content-view-header' onMouseDown={this.onPrevent}>
            <Box className='left'>
              <IconButton onClick={this.handleClose} style={{ color: 'white' }}>
                <ArrowBackIcon />
              </IconButton>
            </Box>
            <Box className='center'>
              <Box width={20} style={{ margin: 4 }}>
                <FileIconColor extension={this.state.data?.Extension.toLocaleLowerCase() ?? ''} />
              </Box>
              {this.state.data?.Name}
            </Box>
            <Box className='right'>
              <IconButton onClick={this.onDownload} style={{ color: 'white' }}>
                <DownloadIcon />
              </IconButton>
            </Box>
          </Box>
          <Box className='content-container'>
            <Box className='content'>{this.renderContent()}</Box>
          </Box>
        </Box>
      </Dialog>
    )
  }

  handleClickOpen = (data: IItemInfo) => {
    this.setState({ open: true, loading: true, data }, this.loadData)
  }

  handleClose = () => {
    this.setState({ open: false, data: undefined, content: undefined })
  }

  onDownload = () => {
    if (!this.state.data) return
    UploadService.Download(this.state.data)
  }

  onPrevent: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
  }

  getTextContent = async (url: string) => {
    const res = await fetch(url)
    if (res.status === 200) {
      return await res.text()
    } else {
      throw new Error('load fail!')
    }
  }

  loadData = async () => {
    const { data } = this.state
    if (!data) return
    const dataInfo = await UploadService.getPreviewInfo(data.DriveFileId)
    if (!dataInfo) return

    if (dataInfo.Type === EOpenType.text) {
      try {
        dataInfo.content = await this.getTextContent(dataInfo.content)
      } catch {
        dataInfo.content = 'Load fail!'
        dataInfo.Type = EOpenType.other
      }
    }

    this.setState({ loading: false, OpenType: dataInfo.Type, content: dataInfo.content })
  }

  switchTypeView = () => {
    switch (this.state.OpenType) {
      case EOpenType.audio:
        return <AudioView AudioUrl={this.state.content || ''} />
      case EOpenType.video:
        return <VideoView videoUrl={this.state.content || ''} />
      case EOpenType.text:
        return <TextView contentText={this.state.content || ''} />
      case EOpenType.image:
        return <ImageView content={this.state.content || ''} />
      default:
        return <OtherView data={this.state.data} content={this.state.content} />
    }
  }

  renderContent = () => {
    if (!this.state.loading) return this.switchTypeView()
    return (
      <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    )
  }
}

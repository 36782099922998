import React, { Component, ReactNode } from 'react'
import { Box, Container, Stack, styled } from '@mui/material'
import YouTubeIcon from '@mui/icons-material/YouTube'
import FacebookIcon from '@mui/icons-material/FacebookOutlined'
import { mapLink } from '../helper'

interface IPartnerConfigs {
  name: string
  link: string
  icon: ReactNode
}

const partners: IPartnerConfigs[] = [
  {
    name: 'Youtube',
    icon: <YouTubeIcon fontSize='large' sx={{ color: '#606060' }} />,
    link: 'https://www.youtube.com/'
  },
  {
    name: 'Facebook',
    icon: <FacebookIcon fontSize='large' sx={{ color: '#606060' }} />,
    link: 'https://www.facebook.com/'
  }
]

interface IProps {
  disabledBlank?: boolean
}

export class OurPartner extends Component<IProps> {
  render() {
    return (
      <Container>
        <Stack gap='20px' py='15px' alignItems='center'>
          <SubTitle>Our Partners</SubTitle>
          <Stack direction='row' gap='20px'>
            {partners.map((e) => (
              <Item key={e.name} {...mapLink(e.link)}>
                {e.icon}
                <Title>{e.name}</Title>
              </Item>
            ))}
          </Stack>
        </Stack>
      </Container>
    )
  }
}
export default OurPartner

const Item = styled(Box)({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  textDecoration: 'unset !important'
})

const SubTitle = styled('h3')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 500
})

const Title = styled('h2')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '22px',
  fontWeight: 700,
  color: '#606060'
})

import React, { Component } from 'react'
import { Box } from '@mui/material'

import NoDataImage from './nodata.png'

export default class NoRowsOverlay extends Component {
  render() {
    return (
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', width: '100%', height: '100%', alignItems: 'center' }}>
        <Box component='img' sx={{ maxWidth: '180px', opacity: '35%' }} src={NoDataImage} alt='nodata' />
      </Box>
    )
  }
}

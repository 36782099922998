import React, { Component } from 'react'
import { InputOutline } from '@lib-lab/Form'
import { Button, InputAdornment, Typography } from '@mui/material'
import { CreateFormBase, IFormBase, FormValidator, SingleRuleValidate, getErrorMessage } from '@lib/Forms'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'

export interface IFormSendData {
  Message: string
}

class FormContent extends Component<IFormBase<IFormSendData>> {
  render() {
    return (
      <InputOutline
        MaxLength={2000}
        Title='Type of message'
        minRows={1}
        maxRows={10}
        // onBlur={() => this.props.onBlur && this.props.onBlur('Message')}
        {...getErrorMessage(this.props.messageErrors, 'Message')}
        defaultValue={this.props.data?.Message?.toString()}
        inputProps={{
          multiline: true,
          name: 'Message',
          InputProps: {
            endAdornment: (
              <InputAdornment position='end'>
                <Button type='submit' variant='text' sx={{ color: '#606060', textTransform: 'none' }} endIcon={<SendOutlinedIcon />}>
                  <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>Submit</Typography>
                </Button>
              </InputAdornment>
            ),
            sx: { backgroundColor: '#fff', alignItems: 'flex-end', '& .MuiInputAdornment-root': { marginBottom: '10px' } }
          }
        }}
      />
    )
  }
}

const FormBaseInstance = CreateFormBase<IFormSendData>({
  validate: new FormValidator<Partial<IFormSendData>>({
    Message: { Rules: [{ rule: SingleRuleValidate.Required }] }
  })
})
interface IProps {
  onSubmit: (value: Partial<IFormSendData>) => Promise<void>
}
export default class FormSend extends Component<IProps> {
  render() {
    return (
      <FormBaseInstance.Form sx={{ width: '100%' }} onSubmit={this.props.onSubmit}>
        {FormBaseInstance.ContextMapping((context) => (
          <FormContent onBlur={context.onBlur} messageErrors={context.messageErrors} />
        ))}
      </FormBaseInstance.Form>
    )
  }
}

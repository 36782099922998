import React, { FC, Fragment } from 'react'
import { RouteKey } from '@internal/route'
import { LogoBase64 } from '@shared/ImageBase64'
import { CreateRoutePath } from '@lib/RouteBase'
import { Link, LinkProps } from 'react-router-dom'
import { alpha, Box, Divider, Drawer, IconButton, styled } from '@mui/material'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemProps, ListItemText } from '@mui/material'
import * as IconsMaterial from '@mui/icons-material'
import { useCurrentMenuChecking } from './hooks'
import AppConfigs from '../AppConfigs'

const CONFIGS = AppConfigs.profile.menuHeader

const HeaderMenu: FC = () => {
  const [open, setOpen] = React.useState(false)
  const toggleDrawer = (newOpen: boolean) => () => setOpen(newOpen)

  const currentMenuChecking = useCurrentMenuChecking()

  const renderItems = () => {
    return CONFIGS.map((item, index) => (
      <Fragment key={item.title + index}>
        <ListItemCustom to={CreateRoutePath(item.key)} {...currentMenuChecking(item.key)}>
          <ListItemButton>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItemButton>
        </ListItemCustom>
        {item.bottomLine && <Divider />}
      </Fragment>
    ))
  }

  return (
    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
      <IconButton size='large' color='inherit' sx={{ mr: '12px' }} onClick={toggleDrawer(true)}>
        <IconsMaterial.Menu />
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <Box sx={{ width: '270px', padding: '0 6px' }} role='presentation' onClick={toggleDrawer(false)}>
          <LogoWrapper to={RouteKey.Home}>
            <Box component='img' src={LogoBase64} />
          </LogoWrapper>
          <List>{renderItems()}</List>
        </Box>
      </Drawer>
    </Box>
  )
}
export default HeaderMenu

const LogoWrapper = styled(Link)({
  textDecoration: 'none!important',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  grap: '6px',
  width: '100%',
  padding: '24px 0',
  '& > img': {
    height: '28px'
  }
})

const ListItemCustom = styled((p: ListItemProps & Pick<LinkProps, 'to'>) => <ListItem component={Link} disablePadding {...p} />)({
  textDecoration: 'unset!important',
  color: '#202020',
  '&.active': {
    backgroundColor: `${alpha('#1A9BE8', 0.15)} !important`,
    color: '#1A9BE8'
  },
  '&.active svg': {
    color: '#1A9BE8!important'
  }
})

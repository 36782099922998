import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { RouteKey } from '@internal/route'
import { RootState } from '@internal/redux'
import { ELazyStatus } from '@lib/ReduxBase'
import { LogoBase64 } from '@shared/ImageBase64'
import { AvatarButton, LoginMenu } from '@lib/ApiAuthorization'
import { LoadingAppState } from '@internal/redux/Slices/AppStatusSlice'
import { AppBar, Box, Collapse, Container, Divider, Fade, LinearProgress, Toolbar, styled } from '@mui/material'
import { useCurrentIsProfile } from './hooks'
import { CartBadgeMapRedux } from '../../Views/Cart'
import { NotificationMapRedux } from '../../Views/Notification'
import HeaderNav from './header.nav'
import HeaderMenu from './header.menu'

export const HeaderHeight = '96px'

interface IAppHeaderProps extends LoadingAppState {
  fixed?: boolean
}

export const AppHeader: FC<IAppHeaderProps> = (props) => {
  const currentIsProfile = useCurrentIsProfile()
  return (
    <>
      {props.fixed && <Box sx={{ height: HeaderHeight }} />}
      <CustomAppBar sx={{ position: props.fixed ? 'fixed' : 'static' }}>
        <Box sx={{ padding: { xs: '0', md: '0 35px' }, display: 'flex', alignItems: 'center', height: HeaderHeight }}>
          <Container>
            <Toolbar disableGutters>
              <Collapse in={currentIsProfile} orientation='horizontal' unmountOnExit>
                <HeaderMenu />
              </Collapse>
              <LogoWrapper to={RouteKey.Home}>
                <Box component='img' src={LogoBase64} />
              </LogoWrapper>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: '15px', md: '24px' } }}>
                <HeaderNav />
                <Divider orientation='vertical' sx={{ height: '28px', display: { xs: 'none', md: 'block' } }} />
                <NotificationMapRedux />
                <CartBadgeMapRedux />
                <Divider orientation='vertical' sx={{ height: '28px' }} />
                <LoginMenu
                  AvatarButton={(p) => {
                    const { profilePath, ...other } = p
                    return <AvatarButton {...other} profilePath={RouteKey.Profile} />
                  }}
                />
              </Box>
            </Toolbar>
          </Container>
        </Box>
      </CustomAppBar>
      <Fade in={props.Status === ELazyStatus.Loading}>
        <WrapLoading>
          <LinearProgress />
        </WrapLoading>
      </Fade>
    </>
  )
}

const mapStateToProps = (state: RootState): LoadingAppState => ({
  Status: state.AppStatusSlice.Status
})

export default connect(mapStateToProps)(AppHeader)

const CustomAppBar = styled(AppBar)({
  backgroundColor: '#FFFFFF',
  boxShadow: '0 2px 14px rgba(0,0,0,0.23)',
  color: '#0f0f0f'
})

const LogoWrapper = styled(Link)(({ theme }) => ({
  textDecoration: 'none!important',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  grap: '6px',
  '& > img': {
    height: '40px'
  },
  [theme.breakpoints.down('md')]: {
    '& > img': {
      height: '28px'
    }
  }
}))

const WrapLoading = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  zIndex: theme.app.zIndex.infinity
}))

import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { CreateHocLazy, ELazyStatus } from '@lib/ReduxBase'
import { IPricingReduxProps } from './type'
import { mapDispatchToProps, mapStateToProps } from './redux.map'
import Layout from './ui/Layout'
import PriceContent from './price.content'
import { useLocation } from 'react-router-dom'
import { RouteKey } from '@internal/route'
import { WindowScrollToTop } from '@lib/Helpers'

interface IProps extends IPricingReduxProps {}

const ViewBase: FC<IProps> = (props) => {
  const location = useLocation()
  useEffect(() => {
    if (location.pathname.includes(RouteKey.Pricing)) {
      WindowScrollToTop()
    }
    return () => {}
  }, [location.pathname])
  return (
    <Layout
      isLoading={props.Status === ELazyStatus.Loading}
      components={{
        content: <PriceContent data={props.PricingSlice.pricePackages} />
      }}
    />
  )
}

export * from './redux.thunks'
export { default as PricingSlice } from './redux.slice'

const HocLazyInstance = CreateHocLazy(ViewBase)
export const PricingMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)

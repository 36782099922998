import type { FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';
import type {ESendType} from '../enums';
import type {EAreaType} from '../enums';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface NotificationInfoFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'NotificationInfo', T, TVariables> {

    on<XName extends ImplementationType<'NotificationInfo'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): NotificationInfoFetcher<
        XName extends 'NotificationInfo' ?
        T & X :
        WithTypeName<T, ImplementationType<'NotificationInfo'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'NotificationInfo'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): NotificationInfoFetcher<T, TVariables>;


    readonly __typename: NotificationInfoFetcher<T & {__typename: ImplementationType<'NotificationInfo'>}, TVariables>;


    readonly id: NotificationInfoFetcher<T & {readonly "id": string}, TVariables>;

    "id+"<
        XAlias extends string = "id", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"id", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): NotificationInfoFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~id": NotificationInfoFetcher<Omit<T, 'id'>, TVariables>;


    readonly title: NotificationInfoFetcher<T & {readonly "title"?: string}, TVariables>;

    "title+"<
        XAlias extends string = "title", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"title", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): NotificationInfoFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~title": NotificationInfoFetcher<Omit<T, 'title'>, TVariables>;


    readonly subTitle: NotificationInfoFetcher<T & {readonly "subTitle"?: string}, TVariables>;

    "subTitle+"<
        XAlias extends string = "subTitle", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"subTitle", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): NotificationInfoFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~subTitle": NotificationInfoFetcher<Omit<T, 'subTitle'>, TVariables>;


    readonly content: NotificationInfoFetcher<T & {readonly "content"?: string}, TVariables>;

    "content+"<
        XAlias extends string = "content", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"content", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): NotificationInfoFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~content": NotificationInfoFetcher<Omit<T, 'content'>, TVariables>;


    readonly dateExpired: NotificationInfoFetcher<T & {readonly "dateExpired"?: string}, TVariables>;

    "dateExpired+"<
        XAlias extends string = "dateExpired", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateExpired", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): NotificationInfoFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateExpired": NotificationInfoFetcher<Omit<T, 'dateExpired'>, TVariables>;


    readonly sendType: NotificationInfoFetcher<T & {readonly "sendType": ESendType}, TVariables>;

    "sendType+"<
        XAlias extends string = "sendType", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"sendType", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): NotificationInfoFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: ESendType} : 
                {readonly [key in XAlias]: ESendType}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~sendType": NotificationInfoFetcher<Omit<T, 'sendType'>, TVariables>;


    readonly areaType: NotificationInfoFetcher<T & {readonly "areaType": EAreaType}, TVariables>;

    "areaType+"<
        XAlias extends string = "areaType", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"areaType", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): NotificationInfoFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: EAreaType} : 
                {readonly [key in XAlias]: EAreaType}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~areaType": NotificationInfoFetcher<Omit<T, 'areaType'>, TVariables>;


    notificationUsers<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'NotificationUser', X, XVariables>
    ): NotificationInfoFetcher<
        T & {readonly "notificationUsers"?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables
    >;

    notificationUsers<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "notificationUsers", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'NotificationUser', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"notificationUsers", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): NotificationInfoFetcher<
        T & {readonly [key in XAlias]?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    notificationStatuses<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'NotificationStatus', X, XVariables>
    ): NotificationInfoFetcher<
        T & {readonly "notificationStatuses"?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables
    >;

    notificationStatuses<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "notificationStatuses", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'NotificationStatus', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"notificationStatuses", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): NotificationInfoFetcher<
        T & {readonly [key in XAlias]?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    readonly dateCreated: NotificationInfoFetcher<T & {readonly "dateCreated": string}, TVariables>;

    "dateCreated+"<
        XAlias extends string = "dateCreated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateCreated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): NotificationInfoFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateCreated": NotificationInfoFetcher<Omit<T, 'dateCreated'>, TVariables>;


    readonly dateUpdated: NotificationInfoFetcher<T & {readonly "dateUpdated": string}, TVariables>;

    "dateUpdated+"<
        XAlias extends string = "dateUpdated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateUpdated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): NotificationInfoFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateUpdated": NotificationInfoFetcher<Omit<T, 'dateUpdated'>, TVariables>;
}

export const notificationInfo$: NotificationInfoFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "NotificationInfo", 
            "OBJECT", 
            [], 
            [
                {
                    category: "ID", 
                    name: "id"
                }, 
                {
                    category: "SCALAR", 
                    name: "title", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "subTitle", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "content", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "dateExpired", 
                    undefinable: true
                }, 
                "sendType", 
                "areaType", 
                {
                    category: "LIST", 
                    name: "notificationUsers", 
                    targetTypeName: "NotificationUser", 
                    undefinable: true
                }, 
                {
                    category: "LIST", 
                    name: "notificationStatuses", 
                    targetTypeName: "NotificationStatus", 
                    undefinable: true
                }, 
                "dateCreated", 
                "dateUpdated"
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const notificationInfo$$ = 
    notificationInfo$
        .id
        .title
        .subTitle
        .content
        .dateExpired
        .sendType
        .areaType
        .dateCreated
        .dateUpdated
;

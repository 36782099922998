import React, { Component } from 'react'
import { EBillingStatus } from '@shared/Types'
import { CreateStatusCell, IInfoBarItemProps } from '@shared/UIStyleds'
import { IBillingDTO } from './types'

export const BillingStatusCell = CreateStatusCell<EBillingStatus>({
  New: { background: 'linear-gradient(to right,#00b93b,#5adc82)' },
  Paid: { background: 'linear-gradient(to right,#357a38,#1edd9b)' },
  UnPaid: { background: 'linear-gradient(to right, rgb(178, 137, 0), rgb(235, 204, 99))' }
})

interface IProps extends IInfoBarItemProps<IBillingDTO> {}
export class BillingDetailStatusCell extends Component<IProps> {
  render() {
    if (!this.props.data?.Status) return <></>
    return <BillingStatusCell data={this.props.data?.Status} sx={{ justifyContent: 'flex-end' }} />
  }
}

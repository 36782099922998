import { GetImageUrl } from '@lib/Helpers'
import { ICart, IMedia, IPrice } from '@shared/Types'
import { AppDispatch, RootState } from '@internal/redux'
import { ICarIDispatchRedux, ICarIStateRedux } from './redux.types'
import { fetchCartThunk, fetchCheckOutValidateThunk } from './redux.thunks'
import CartSlice from './redux.slice'

export const mapStateToProps = (state: RootState): ICarIStateRedux => ({
  Status: state.CartSlice.Status,
  CartSlice: state.CartSlice
})

export const mapDispatchToProps = (dispatch: AppDispatch): ICarIDispatchRedux => ({
  FetchData: () => {
    return dispatch(fetchCartThunk())
  },
  addCart: (value) => {
    dispatch(CartSlice.actions.addCart(value))
  },
  updateCart: (value) => {
    dispatch(CartSlice.actions.updateCart(value))
  },
  removeCart: (id) => {
    dispatch(CartSlice.actions.removeCart(id))
  },
  checkOutValidate: async () => {
    await dispatch(fetchCheckOutValidateThunk())
  }
})

const MapCart = (media: IMedia, price: IPrice): ICart => ({
  Id: media.Id,
  Name: media.Name ?? '',
  Description: media.Description,
  Type: media.Type,
  ImageUri: GetImageUrl(media.ResourceId ?? ''),
  Infos: [
    { Key: 'Type', Value: media.Type },
    { Key: 'Price', Value: `$ ${(price.Price ?? 0).toFixed(2)}` }
  ],
  PriceOrigin: price
})

export const QuicklyAddToCart = (dispatch: AppDispatch, media: IMedia, price: IPrice) => {
  const value = MapCart(media, price)
  dispatch(CartSlice.actions.addCart(value))
}

import { IBilling, IBillingDetail } from '@shared/Types'
import { IBillingDTO } from './types'

export const MapInfoBarData = (billingDetails: IBillingDetail[], billing?: IBilling) => {
  const data: IBillingDTO = Object.assign({}, billing)
  data.Total = billingDetails.reduce<number>((a, b) => {
    a += b.Payout
    return a
  }, 0)
  return data
}

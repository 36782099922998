import React, { Component } from 'react'
import { FormModalWrapper } from '@lib/Forms'
import { Box, Collapse, styled } from '@mui/material'
import { EMediaDeliveryType, EMediaDelivery, IMediaDelivery } from '@shared/Types'
import { DeliveryBtnBack } from '../ui.buttons'
import { EDeliveryFormMode } from './types'
import { GetFormInternalType, GetFormUploadType, GetFormUrlType } from './form.by-site'
import FormCreateSelectMode from './form.create.select-mode'

interface IState {
  selectedOption?: EMediaDeliveryType
}
interface IProps {
  onLinkRepoSubmit?: (value: Partial<IMediaDelivery>) => Promise<void>
  onUploadMediaSubmit?: (value: Partial<IMediaDelivery>) => Promise<void>
  onInternalSubmit?: (value: Partial<IMediaDelivery>) => Promise<void>
  mode?: EDeliveryFormMode
}

export default class FormCreate extends Component<IProps, IState> {
  FormUrlType
  FormUploadType
  FormInternalType
  constructor(props: IProps) {
    super(props)
    this.state = { selectedOption: undefined }
    this.FormUrlType = GetFormUrlType({ disabledStatus: props.mode === EDeliveryFormMode.Client })
    this.FormUploadType = GetFormUploadType({ disabledStatus: props.mode === EDeliveryFormMode.Client })
    this.FormInternalType = GetFormInternalType()
  }
  render() {
    const { FormUrlType, FormUploadType, FormInternalType } = this
    return (
      <FormModalWrapper
        title='Create'
        size='md'
        slots={{
          beforeTitle: this.renderBeforeTitle(),
          sxTopbarProps: !this.state.selectedOption ? { boxShadow: 'none' } : {}
        }}
      >
        <Wrap>
          <Collapse in={!this.state.selectedOption} unmountOnExit>
            <FormCreateSelectMode onClick={(value) => this.setState({ selectedOption: value })} mode={this.props.mode} />
          </Collapse>
          <Collapse in={this.state.selectedOption === EMediaDeliveryType.Url} unmountOnExit>
            <FormUrlType
              onSubmit={async (value) => {
                if (!this.props.onLinkRepoSubmit) return
                await this.props.onLinkRepoSubmit({
                  ...value,
                  Type: EMediaDelivery.Submission,
                  ContentType: EMediaDeliveryType.Url
                })
              }}
            />
          </Collapse>
          <Collapse in={this.state.selectedOption === EMediaDeliveryType.Upload} unmountOnExit>
            <FormUploadType
              onSubmit={async (value) => {
                if (!this.props.onUploadMediaSubmit) return
                await this.props.onUploadMediaSubmit({
                  ...value,
                  Type: EMediaDelivery.Submission,
                  ContentType: EMediaDeliveryType.Upload
                })
              }}
            />
          </Collapse>
          <Collapse in={this.state.selectedOption === EMediaDeliveryType.Internal} unmountOnExit>
            <FormInternalType
              onSubmit={async (value) => {
                if (!this.props.onInternalSubmit) return
                await this.props.onInternalSubmit({
                  ...value,
                  Type: EMediaDelivery.Submission,
                  ContentType: EMediaDeliveryType.Internal
                })
              }}
            />
          </Collapse>
        </Wrap>
      </FormModalWrapper>
    )
  }

  renderBeforeTitle = () => (
    <DeliveryBtnBack disabled={this.state.selectedOption === undefined} onClick={() => this.setState({ selectedOption: undefined })} />
  )
}

const Wrap = styled(Box)({
  minHeight: '12rem'
})

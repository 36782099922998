import { RootState } from '@internal/redux'
import { ISubmission } from '@shared/Types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { MergeObjects, TableQueryParam } from '@lib/Helpers'
import { MapTableData, MapTableInfo, TableReduxType } from '@lib/Table'
import SubmissionService, { SubmissionMapping } from '@internal/services/SubmissionService'
import { DefaultTableInfo } from './redux.slice'

interface IParamsThunk extends TableReduxType.ThunkParam<ISubmission> {}
interface IResponseThunk extends TableReduxType.ThunkReponse<ISubmission> {}
export const fetchSubmissionThunk = createAsyncThunk<IResponseThunk, IParamsThunk | undefined>('fetchSubmissionThunk', async (param, thunkAPI) => {
  const state = thunkAPI.getState() as RootState
  const tableInfoState = MergeObjects(state.SubmissionSlice.tableInfo, param?.tableInfo)
  const tableInfoMap = MapTableInfo(tableInfoState, param?.tableChange)

  const filter = SubmissionMapping.tableInfoToFilter(tableInfoMap)
  const res = await SubmissionService.All(filter, thunkAPI.signal)

  const tableInfo = MapTableData(tableInfoMap, { Data: res?.Data, Total: res?.Total })
  TableQueryParam.setParam(tableInfo, DefaultTableInfo)
  return { tableInfo }
})

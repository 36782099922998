import React, { Component } from 'react'
import { FormModalWrapper } from '@lib/Forms'
import { EMediaDeliveryType, IMediaDelivery } from '@shared/Types'
import { EDeliveryFormMode } from './types'
import { GetFormInternalType, GetFormUploadType, GetFormUrlType } from './form.by-site'

interface IProps {
  data?: IMediaDelivery
  onSubmit: (value: Partial<IMediaDelivery>) => Promise<void>
  mode?: EDeliveryFormMode
}
export default class FormEdit extends Component<IProps> {
  FormUrlType
  FormUploadType
  FormInternalType
  constructor(props: IProps) {
    super(props)
    this.state = { selectedOption: undefined }
    this.FormUrlType = GetFormUrlType({ disabledStatus: props.mode === EDeliveryFormMode.Client })
    this.FormUploadType = GetFormUploadType({ disabledStatus: props.mode === EDeliveryFormMode.Client })
    this.FormInternalType = GetFormInternalType()
  }
  render() {
    return (
      <FormModalWrapper title='Edit' size='md'>
        {this.renderContent(this.props.data?.ContentType)}
      </FormModalWrapper>
    )
  }
  renderContent(deliveryType?: EMediaDeliveryType) {
    const { FormUrlType, FormUploadType, FormInternalType } = this
    switch (deliveryType) {
      case EMediaDeliveryType.Url: {
        return <FormUrlType data={this.props.data} onSubmit={this.props.onSubmit} />
      }
      case EMediaDeliveryType.Upload: {
        return <FormUploadType data={this.props.data} onSubmit={this.props.onSubmit} />
      }
      case EMediaDeliveryType.Internal: {
        return <FormInternalType data={this.props.data} onSubmit={this.props.onSubmit} />
      }
      default:
        return <></>
    }
  }
}

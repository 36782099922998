import type { AcceptableVariables, UnresolvedVariables, FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface MediaPageFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'MediaPage', T, TVariables> {

    on<XName extends ImplementationType<'MediaPage'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): MediaPageFetcher<
        XName extends 'MediaPage' ?
        T & X :
        WithTypeName<T, ImplementationType<'MediaPage'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'MediaPage'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): MediaPageFetcher<T, TVariables>;


    readonly __typename: MediaPageFetcher<T & {__typename: ImplementationType<'MediaPage'>}, TVariables>;


    item<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Media', X, XVariables>
    ): MediaPageFetcher<
        T & {readonly "item"?: X}, 
        TVariables & XVariables & MediaPageArgs["item"]
    >;

    item<
        XArgs extends AcceptableVariables<MediaPageArgs['item']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'Media', X, XVariables>
    ): MediaPageFetcher<
        T & {readonly "item"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaPageArgs['item']>
    >;

    item<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "item", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Media', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"item", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaPageFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaPageArgs["item"] & XDirectiveVariables
    >;

    item<
        XArgs extends AcceptableVariables<MediaPageArgs['item']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "item", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'Media', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"item", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaPageFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaPageArgs['item']> & XDirectiveVariables
    >;


    items<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Media', X, XVariables>
    ): MediaPageFetcher<
        T & {readonly "items": ReadonlyArray<X>}, 
        TVariables & XVariables
    >;

    items<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "items", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Media', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"items", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): MediaPageFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: ReadonlyArray<X>} : 
                {readonly [key in XAlias]: ReadonlyArray<X>}
        ), 
        TVariables & XVariables & XDirectiveVariables
    >;


    readonly hasPreviousPage: MediaPageFetcher<T & {readonly "hasPreviousPage": boolean}, TVariables>;

    "hasPreviousPage+"<
        XAlias extends string = "hasPreviousPage", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"hasPreviousPage", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): MediaPageFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: boolean} : 
                {readonly [key in XAlias]: boolean}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~hasPreviousPage": MediaPageFetcher<Omit<T, 'hasPreviousPage'>, TVariables>;


    readonly hasNextPage: MediaPageFetcher<T & {readonly "hasNextPage": boolean}, TVariables>;

    "hasNextPage+"<
        XAlias extends string = "hasNextPage", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"hasNextPage", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): MediaPageFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: boolean} : 
                {readonly [key in XAlias]: boolean}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~hasNextPage": MediaPageFetcher<Omit<T, 'hasNextPage'>, TVariables>;


    readonly totalItems: MediaPageFetcher<T & {readonly "totalItems": number}, TVariables>;

    "totalItems+"<
        XAlias extends string = "totalItems", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"totalItems", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): MediaPageFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: number} : 
                {readonly [key in XAlias]: number}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~totalItems": MediaPageFetcher<Omit<T, 'totalItems'>, TVariables>;
}

export const mediaPage$: MediaPageFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "MediaPage", 
            "EMBEDDED", 
            [], 
            [
                {
                    category: "REFERENCE", 
                    name: "item", 
                    argGraphQLTypeMap: {id: 'ID!'}, 
                    targetTypeName: "Media", 
                    undefinable: true
                }, 
                {
                    category: "LIST", 
                    name: "items", 
                    targetTypeName: "Media"
                }, 
                "hasPreviousPage", 
                "hasNextPage", 
                "totalItems"
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const mediaPage$$ = 
    mediaPage$
        .hasPreviousPage
        .hasNextPage
        .totalItems
;

export interface MediaPageArgs {

    readonly item: {
        readonly id: string
    }
}

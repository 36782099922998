import type { AcceptableVariables, UnresolvedVariables, FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';
import type {GalleryType} from '../enums';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface GalleryFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'Gallery', T, TVariables> {

    on<XName extends ImplementationType<'Gallery'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): GalleryFetcher<
        XName extends 'Gallery' ?
        T & X :
        WithTypeName<T, ImplementationType<'Gallery'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'Gallery'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): GalleryFetcher<T, TVariables>;


    readonly __typename: GalleryFetcher<T & {__typename: ImplementationType<'Gallery'>}, TVariables>;


    readonly id: GalleryFetcher<T & {readonly "id": string}, TVariables>;

    "id+"<
        XAlias extends string = "id", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"id", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): GalleryFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~id": GalleryFetcher<Omit<T, 'id'>, TVariables>;


    readonly userCreatedId: GalleryFetcher<T & {readonly "userCreatedId"?: string}, TVariables>;

    "userCreatedId+"<
        XAlias extends string = "userCreatedId", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"userCreatedId", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): GalleryFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~userCreatedId": GalleryFetcher<Omit<T, 'userCreatedId'>, TVariables>;


    readonly name: GalleryFetcher<T & {readonly "name"?: string}, TVariables>;

    "name+"<
        XAlias extends string = "name", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"name", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): GalleryFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~name": GalleryFetcher<Omit<T, 'name'>, TVariables>;


    readonly description: GalleryFetcher<T & {readonly "description"?: string}, TVariables>;

    "description+"<
        XAlias extends string = "description", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"description", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): GalleryFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~description": GalleryFetcher<Omit<T, 'description'>, TVariables>;


    readonly type: GalleryFetcher<T & {readonly "type": GalleryType}, TVariables>;

    "type+"<
        XAlias extends string = "type", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"type", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): GalleryFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: GalleryType} : 
                {readonly [key in XAlias]: GalleryType}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~type": GalleryFetcher<Omit<T, 'type'>, TVariables>;


    readonly imageUri: GalleryFetcher<T & {readonly "imageUri"?: string}, TVariables>;

    "imageUri+"<
        XAlias extends string = "imageUri", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"imageUri", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): GalleryFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~imageUri": GalleryFetcher<Omit<T, 'imageUri'>, TVariables>;


    readonly key: GalleryFetcher<T & {readonly "key"?: string}, TVariables>;

    "key+"<
        XAlias extends string = "key", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"key", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): GalleryFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~key": GalleryFetcher<Omit<T, 'key'>, TVariables>;


    galleryDetail<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'GalleryDetail', X, XVariables>
    ): GalleryFetcher<
        T & {readonly "galleryDetail"?: X}, 
        TVariables & XVariables & GalleryArgs["galleryDetail"]
    >;

    galleryDetail<
        XArgs extends AcceptableVariables<GalleryArgs['galleryDetail']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'GalleryDetail', X, XVariables>
    ): GalleryFetcher<
        T & {readonly "galleryDetail"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, GalleryArgs['galleryDetail']>
    >;

    galleryDetail<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "galleryDetail", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'GalleryDetail', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"galleryDetail", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): GalleryFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & GalleryArgs["galleryDetail"] & XDirectiveVariables
    >;

    galleryDetail<
        XArgs extends AcceptableVariables<GalleryArgs['galleryDetail']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "galleryDetail", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'GalleryDetail', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"galleryDetail", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): GalleryFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, GalleryArgs['galleryDetail']> & XDirectiveVariables
    >;


    galleryDetails<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'GalleryDetail', X, XVariables>
    ): GalleryFetcher<
        T & {readonly "galleryDetails"?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables
    >;

    galleryDetails<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "galleryDetails", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'GalleryDetail', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"galleryDetails", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): GalleryFetcher<
        T & {readonly [key in XAlias]?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    readonly dateCreated: GalleryFetcher<T & {readonly "dateCreated": string}, TVariables>;

    "dateCreated+"<
        XAlias extends string = "dateCreated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateCreated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): GalleryFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateCreated": GalleryFetcher<Omit<T, 'dateCreated'>, TVariables>;


    readonly dateUpdated: GalleryFetcher<T & {readonly "dateUpdated": string}, TVariables>;

    "dateUpdated+"<
        XAlias extends string = "dateUpdated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateUpdated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): GalleryFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateUpdated": GalleryFetcher<Omit<T, 'dateUpdated'>, TVariables>;
}

export const gallery$: GalleryFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "Gallery", 
            "OBJECT", 
            [], 
            [
                {
                    category: "ID", 
                    name: "id"
                }, 
                {
                    category: "SCALAR", 
                    name: "userCreatedId", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "name", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "description", 
                    undefinable: true
                }, 
                "type", 
                {
                    category: "SCALAR", 
                    name: "imageUri", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "key", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "galleryDetail", 
                    argGraphQLTypeMap: {id: 'ID!'}, 
                    targetTypeName: "GalleryDetail", 
                    undefinable: true
                }, 
                {
                    category: "LIST", 
                    name: "galleryDetails", 
                    targetTypeName: "GalleryDetail", 
                    undefinable: true
                }, 
                "dateCreated", 
                "dateUpdated"
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const gallery$$ = 
    gallery$
        .id
        .userCreatedId
        .name
        .description
        .type
        .imageUri
        .key
        .dateCreated
        .dateUpdated
;

export interface GalleryArgs {

    readonly galleryDetail: {
        readonly id: string
    }
}

import { ModelType } from 'graphql-ts-client-api'
import { createRequestBuilder } from '../../Requests/RequestParam'
import { query$, mediaStoreGraphDbContext$ } from '../../__generated/media-store/fetchers'

import { media$$, category$$ } from '../../__generated/media-store/fetchers'
import { galleryDetailPage$ } from '../../__generated/media-store/fetchers'
import { gallery$$, galleryPage$, galleryDetail$$ } from '../../__generated/media-store/fetchers'

const GalleryDetailSelector = galleryDetail$$.category(category$$).media(media$$)
const GallerySelector = gallery$$

export type TGallery = ModelType<typeof GallerySelector>
export type TGalleryDetail = ModelType<typeof GalleryDetailSelector>
export type TGalleryCategory = ModelType<typeof category$$>
export type TGalleryMedia = ModelType<typeof media$$>

// ========= ========= ========= Galleries ========= ========= =========
export type TGalleriesFilterParams = Parameters<typeof mediaStoreGraphDbContext$.galleries>[0]
export const GalleriesQuery = (params: TGalleriesFilterParams) => {
  const galleryPage = galleryPage$.items(GallerySelector).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(mediaStoreGraphDbContext$.galleries(params, galleryPage))
}

const GalleryAreaSelector = gallery$$.galleryDetails(GalleryDetailSelector)
export const GalleriesAreaQuery = <TParam extends string = string>(area: TParam) => {
  const query = createRequestBuilder<TGallery>({ ignoreEmpty: true }).filterContains('key', `-${area}-`)
  const galleryPage = galleryPage$.items(GalleryAreaSelector).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(mediaStoreGraphDbContext$.galleries(query.build(), galleryPage))
}

// ========= ========= ========= Gallery ========= ========= =========
export type TGalleryFilterParams = Parameters<typeof mediaStoreGraphDbContext$.gallery>[0]
export const GalleryQuery = (params: TGalleryFilterParams) => {
  return query$.mediaStore(mediaStoreGraphDbContext$.gallery(params, GallerySelector))
}

// ========= ========= ========= Gallery Detail ========= ========= =========
export type TGalleryDetailFilterParams = Parameters<typeof mediaStoreGraphDbContext$.galleryDetails>[0]
export const GalleryDetail = (galleryId: string, params: TGalleryDetailFilterParams) => {
  const galleryDetailPage = galleryDetailPage$.items(GalleryDetailSelector).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(
    mediaStoreGraphDbContext$.gallery({ id: galleryId }, GallerySelector).galleryDetails(params, galleryDetailPage)
  )
}

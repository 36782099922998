import { AxiosError } from 'axios'
import { ApiAlertContext } from '@lib/ApiContext'
import { ApplicationPaths, authService } from 'partner-oidc-auth'

if (process.env.NODE_ENV === 'development') {
  ;(window as any).authService = authService
}
export const checkTokenExpiry = async () => {
  try {
    const user = await authService.getUser()
    if (!user) {
      console.log('No user is currently logged in.')
      return true
    }

    const now = Math.floor(Date.now() / 1000) // Current time in seconds since epoch
    const expiresIn = user.exp - now

    if (expiresIn <= 0) {
      console.log('The token has expired.')
      return true
    } else {
      console.log(`The token will expire in ${expiresIn} seconds.`)
      return false
    }
  } catch (error) {
    console.error('Error checking token expiry:', error)
    return true
  }
}
export const RefreshTokenOnce = async () => {
  const timeStamp = new Date().getTime() - parseInt(localStorage.getItem('ltt') ?? '0')
  localStorage.setItem('ltt', new Date().getTime().toString())
  if (timeStamp >= 4000) {
    try {
      await authService.ensureUserManagerInitialized()
      await authService.signIn({})
    } catch (e) {
      console.log(e)
    }
  }
}
export const ProcessRepose = async (err: AxiosError) => {
  if (err.response?.status === 403) {
    const dataError: { Code: number; Message: string } = err.response.data as any
    switch (dataError.Code) {
      case 3: {
        await authService.signOut({ returnUrl: authService.getReturnUrl() })
        break
      }
      case 1: {
        await authService.userManager?.revokeTokens(['access_token'])
        window.location.replace(ApplicationPaths.Origin(''))
        break
      }
      default: {
        window.location.replace(ApplicationPaths.Origin(ApplicationPaths.IdentityAccessDenied))
        break
      }
    }
  } else if (err.response?.status === 401) {
  } else if (err.code === 'ERR_CANCELED') {
  } else if ((err.response?.status ?? 500) >= 500) {
    if (err.code !== 'ERR_CANCELED') {
      ApiAlertContext.ApiAlert?.PushError('An error has occurred!')
    }
  } else if (err.response?.status === 404) {
    ApiAlertContext.ApiAlert?.PushError('Resource not found!')
  }
  return Promise.reject(err)
}

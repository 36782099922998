import CreateModalRoute from './CreateModalRoute'
import CreateModalRouteWrapper from './CreateWrapper'

const LayoutModalRoute = {
  Provider: CreateModalRoute(),
  Wrapper: CreateModalRouteWrapper()
}
export default LayoutModalRoute

export * from './CreateWrapper'

import React from 'react'
import { ITicket } from '@shared/Types'
import { Typography } from '@mui/material'
import { CreateFormComfirm } from '@lib/Forms'

export { default as FormSend } from './FormSend'
export * from './FormSend'

export const FormConfirmClose = CreateFormComfirm<ITicket>({
  title: 'Are you sure close the ticket?',
  content: (x) => <Typography variant='subtitle2'>{x?.Subject}</Typography>
})

export const FormConfirmReopen = CreateFormComfirm<ITicket>({
  title: 'Are you sure reopen the ticket?',
  content: (x) => <Typography variant='subtitle2'>{x?.Subject}</Typography>
})

import React, { Component } from 'react'
import { EMediaType } from '@shared/Types'
import { Box, IconButton } from '@mui/material'
import { ITableTemplateState, TableTemplateContext } from '@lib/Table'
import { GlobalModalContext, IGlobalModalContext } from '@lib/ApiContext'
import * as IconsMaterial from '@mui/icons-material'

interface IActionPannel {
  Edit?: JSX.Element
  Details?: JSX.Element
  Delete?: JSX.Element
  onPreview?: () => void
  mediaType?: EMediaType
}

const EmptyDiv = () => <></>

export default class MediaActionPannel extends Component<IActionPannel> {
  onEdit = () => {
    this.ModalContext?.ShowModal({
      backdropActivated: true,
      ContentModal: () => this.props.Edit || <EmptyDiv />,
      sx: { display: 'flex', justifyContent: 'center', alignItems: 'center' }
    })
  }
  onDelete = () => {
    this.ModalContext?.ShowModal({
      backdropActivated: true,
      ContentModal: () => this.props.Delete || <EmptyDiv />,
      sx: { display: 'flex', justifyContent: 'center', alignItems: 'center' }
    })
  }
  onDetails = () => {
    this.ModalContext?.ShowModal({
      backdropActivated: true,
      ContentModal: () => this.props.Details || <EmptyDiv />,
      sx: { display: 'flex', justifyContent: 'center', alignItems: 'center' }
    })
  }
  onPreview = () => {
    this.props.onPreview && this.props.onPreview()
  }

  getButtons = function* (that: MediaActionPannel, state: ITableTemplateState) {
    if (that.props.onPreview)
      yield (
        <IconButton onClick={that.onPreview} key={'Preview'} color='inherit'>
          {/* {that.props.mediaType === EMediaType.Video ? <IconsMaterial.SmartDisplay /> : <IconsMaterial.PlayCircleOutline />} */}
          <IconsMaterial.PlayCircleOutline />
        </IconButton>
      )
    if (that.props.Details)
      yield (
        <IconButton onClick={that.onDetails} key={'Details'} color='inherit'>
          <IconsMaterial.AppRegistration />
        </IconButton>
      )
    if (that.props.Edit)
      yield (
        <IconButton onClick={that.onEdit} key={'Edit'} color='info'>
          <IconsMaterial.Edit />
        </IconButton>
      )
    if (that.props.Delete)
      yield (
        <IconButton onClick={that.onDelete} key={'Delete'} color='error'>
          <IconsMaterial.Delete />
        </IconButton>
      )
  }
  ModalContext?: IGlobalModalContext
  render() {
    return (
      <GlobalModalContext.Consumer>
        {(context) => {
          this.ModalContext = context
          return (
            <TableTemplateContext.Consumer>
              {({ state }) => <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>{Array.from(this.getButtons(this, state))}</Box>}
            </TableTemplateContext.Consumer>
          )
        }}
      </GlobalModalContext.Consumer>
    )
  }
}

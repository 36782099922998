import { Box, Container, Typography } from '@mui/material'
import React, { Component } from 'react'

export default class AboutUsPage extends Component {
  render() {
    return (
      <Container
        maxWidth={false}
        sx={{
          height: 'calc(100vh - var(--height-header))',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Typography variant='h1'>About us</Typography>
        <Box sx={{ flex: '0 0 auto', width: '250px', height: '2px', bgcolor: '#818181', mb: '24px' }} />
        <Typography>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, repellendus.</Typography>
      </Container>
    )
  }
}

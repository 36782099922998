import { AppDispatch, RootState } from '@internal/redux'
import { GetBillingId } from '@shared/Services/QueryParamService'
import { CreateBillingDetailService } from '@internal/services/BillingDetailService'
import BillingDetailSlice from './redux.slice'
import { fetchBillingDetailInitialThunk, fetchBillingDetailThunk } from './redux.thunks'
import { IBillingDetailMapDispatchRedux, IBillingDetailMapStateRedux } from './redux.types'

export const mapStateToProps = (state: RootState): IBillingDetailMapStateRedux => ({
  Status: state.BillingDetailSlice.Status,
  stateRedux: state.BillingDetailSlice,
  tableInfo: state.BillingDetailSlice.tableInfo
})

type TRequest = { abort: () => void }
const DefaultRequest: TRequest = { abort: () => {} }
const FetchAccessory: { request?: TRequest } = {}
export const mapDispatchToProps = (dispatch: AppDispatch): IBillingDetailMapDispatchRedux => ({
  FetchData: () => {
    const billingId = GetBillingId()
    if (!billingId) return DefaultRequest
    return dispatch(fetchBillingDetailInitialThunk({ billingId, clear: true }))
  },
  onTableChange: (key, value, details) => {
    if (key === 'FilterModel') {
      dispatch(BillingDetailSlice.actions.onChange({ key, value, details }))
    }
    FetchAccessory.request?.abort()
    const billingId = GetBillingId()
    if (!billingId) return DefaultRequest
    FetchAccessory.request = dispatch(fetchBillingDetailThunk({ tableChange: { key, value: value, details }, billingId }))
  },
  CRUD: {
    Create: async (model) => {
      const billingId = GetBillingId()
      if (!billingId) return
      await CreateBillingDetailService(billingId).Create(model)
      dispatch(fetchBillingDetailThunk({ billingId }))
    },
    Update: async (Id, model) => {
      const billingId = GetBillingId()
      if (!billingId) return
      await CreateBillingDetailService(billingId).Update(Id, model)
      dispatch(fetchBillingDetailThunk({ billingId }))
    },
    Delete: async (Id) => {
      const billingId = GetBillingId()
      if (!billingId) return
      const data = await CreateBillingDetailService(billingId).Remove(Id)
      if (data) {
        dispatch(fetchBillingDetailThunk({ billingId }))
      }
    }
  }
})

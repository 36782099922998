import { IconButton, InputBase, styled, Box } from '@mui/material'
import { ChangeEventHandler, Component, FC, FormEventHandler, useEffect, useRef, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { IExploreReduxProps } from './redux.types'

interface IProps {
  defaultValue?: string
  onChange?: (value: string) => void
}

const InputControl: FC<IProps> = (props) => {
  const [inputValue, setInputValue] = useState(props.defaultValue ?? '')
  const [isInputFocused, setIsInputFocused] = useState(false)

  const onChange = useRef((value: string | null) => {
    props.onChange && props.onChange(value ?? '')
  })

  const handleInputChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const value = event.target.value
    setInputValue(value)
  }

  const handleInputFocus = (status: boolean) => {
    setIsInputFocused(status)
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    // handleInputFocus(false)
    if (props.defaultValue !== inputValue) {
      onChange.current(inputValue)
    }
  }

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setInputValue(props.defaultValue ?? '')
    }, 100)
    return () => {
      clearTimeout(debounceTimeout)
    }
  }, [props.defaultValue])

  return (
    <Box component='form' onSubmit={handleSubmit} position='relative'>
      <Box sx={{ height: '52px', width: '150px' }} />
      <Main className={isInputFocused ? 'focus' : ''}>
        <Box className='st-content'>
          <IconButton sx={{ p: '10px' }} aria-label='menu'>
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ transition: '0.3s', width: isInputFocused ? '250px' : '100px' }}
            placeholder='Keywords'
            inputProps={{ 'aria-label': 'Keywords' }}
            value={inputValue}
            // defaultValue={inputValue}
            onChange={handleInputChange}
            onFocus={() => handleInputFocus(true)}
            onBlur={() => {
              handleInputFocus(false)
              if (props.defaultValue !== inputValue) {
                onChange.current(inputValue)
              }
            }}
          />
        </Box>
        <Box className='st-shadow' />
      </Main>
    </Box>
  )
}

export default class FormInputSearch extends Component<IExploreReduxProps> {
  render() {
    const value = this.props.Slice.filter.searchKey
    return <InputControl defaultValue={value} onChange={this.handleChange} />
  }

  handleChange = (value: string) => {
    this.props.putFilter('searchKey', value)
  }
}

const Main = styled(Box)({
  display: 'flex',
  position: 'absolute',
  top: 0,
  left: 0,
  '& .st-shadow': {
    background: 'linear-gradient(to right, #fff, transparent)',
    marginLeft: '-20px',
    width: '0',
    height: '52px',
    transition: '0.2s'
  },
  '& .st-content': {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: '28px',
    padding: '4px 16px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 14px rgba(0,0,0,0)',
    transition: '0.2s',
    position: 'relative',
    zIndex: 5
  },
  '&.focus .st-shadow': {
    width: '150px'
  },
  '&.focus .st-content': {
    boxShadow: '0 2px 14px rgba(0,0,0,0.23)'
  }
})

import { Box } from '@mui/material'
import React, { Component, PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'
export interface IAuthLoadingContext {
  Open: (message?: string) => void
  Close: () => void
  ChangeStatus: (open: boolean, message?: string) => void
}

interface IAuthLoadingProps { }
interface IAuthLoadingState {
  open: boolean
  message?: string
}

export const AuthLoadingContext = React.createContext<IAuthLoadingContext>({} as any)

export default class AuthLoading extends Component<PropsWithChildren<IAuthLoadingProps>, IAuthLoadingState> implements IAuthLoadingContext {
  constructor(props: IAuthLoadingProps) {
    super(props)
    this.state = {
      open: false
    }
  }
  ChangeStatus = (open: boolean, message?: string) => {
    if (open) {
      this.Open(message)
    } else {
      this.Close()
    }
  }
  componentDidMount(): void {
  }

  Open = (message?: string) => {
    this.setState({
      open: true,
      message
    })
  }

  Close = () => {
    this.setState({ open: false, message: undefined })
  }

  renderContent = () => {
    return (
      <Box
        sx={{
          display: this.state.open ? 'flex' : 'none',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: '#00000085',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <h6 style={{ color: 'white' }}>{this.state.message}</h6>
        <div>
          {/* <LazySpinner /> */}
        </div>
      </Box>
    )
  }
  render() {
    return <AuthLoadingContext.Provider value={this}>
      {this.props.children}
      {createPortal(this.renderContent(), document.body)}
    </AuthLoadingContext.Provider>
  }
}

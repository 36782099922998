import React, { Component } from 'react'
import { ICart } from '@shared/Types'
import { Badge, IconButton, Menu, SxProps, Theme, Tooltip, styled } from '@mui/material'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import { ICartBadgeReduxProps } from '../redux.types'
import CartBadgeContent from './Content'

interface IProps extends ICartBadgeReduxProps {}

interface IState {
  anchorEl: null | HTMLElement
}

class CartBadge extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      anchorEl: null
    }
  }

  render() {
    const data = this.getCarts()
    const { anchorEl } = this.state
    const isOpen = Boolean(anchorEl)
    return (
      <>
        <Tooltip title='Cart'>
          <CustomButton
            {...{ component: 'div' }}
            id='btn-explore-menu-appbar'
            aria-controls={isOpen ? 'explore-menu-appbar' : undefined}
            aria-haspopup='true'
            aria-expanded={isOpen ? 'true' : undefined}
            onClick={this.onOpen}
            color='inherit'
          >
            <Badge component='div' badgeContent={data.length} color='error' anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
              <ShoppingCartOutlinedIcon sx={{ color: '#606060' }} />
            </Badge>
          </CustomButton>
        </Tooltip>
        <Menu
          id='cart-appbar'
          keepMounted
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          slotProps={{ paper: { elevation: 0, sx: sxPaperProps } }}
          sx={{ mt: '45px' }}
          open={isOpen}
          onClose={this.onClose}
        >
          <CartBadgeContent data={data} onClose={this.onClose} onRemove={this.handleClickRemove} />
        </Menu>
      </>
    )
  }

  handleClickRemove = (value: ICart) => this.props.removeCart(value.Id)
  getCarts = () => Object.values(this.props.CartSlice.carts.entities) as ICart[]

  onOpen = (event: React.MouseEvent<HTMLElement>) => this.setState({ ...this.state, anchorEl: event.currentTarget })
  onClose = () => this.setState({ ...this.state, anchorEl: null })
}
export default CartBadge

const sxPaperProps: SxProps<Theme> = {
  overflow: 'visible',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  marginTop: 1,
  minWidth: '360px',
  maxWidth: '550px',
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 14,
    width: 10,
    height: 10,
    backgroundColor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0
  }
}

const CustomButton = styled(IconButton)({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  textTransform: 'capitalize',
  fontWeight: '500',
  color: '#606060'
})

import React, { Component } from 'react'
import { RouteKey } from '@internal/route'
import { CreateRoutePath } from '@lib/RouteBase'
import { CreateInputText } from '@lib/Forms/Inputs'
import { Box, styled, Typography } from '@mui/material'
import { ISubmission, ESubmissionStep } from '@shared/Types'
import { CreateFormGridLayout, FormValidator, SingleRuleValidate } from '@lib/Forms'
import withNavigate, { INavigateProps } from '@lib/Hocs/withNavigation'
import ActionsBar from './UI/ActionsBar'
import LocalStorageSubmisstion from './Helpers/LocalStorageSubmisstion'

const InputTextComponent = CreateInputText<ISubmission>({
  options: { maxLength: 250, textFieldProps: { multiline: true, minRows: 4, maxRows: 5 } }
})

const FormGridLayoutComponent = CreateFormGridLayout<ISubmission>({
  configs: [
    {
      key: 'RepositoryUrl',
      label: 'Please copy and paste the link of your repository here'
    },
    { key: 'Title', label: 'Title Suggestion' },
    {
      key: 'KindlyDescribe',
      label: 'What happen in the your repository ?',
      inputElement: InputTextComponent
    }
  ],
  validate: new FormValidator({
    RepositoryUrl: {
      Rules: [{ rule: SingleRuleValidate.Required }, { rule: SingleRuleValidate.Url }]
    },
    Title: { Rules: [{ rule: SingleRuleValidate.Required }] },
    KindlyDescribe: { Rules: [{ rule: SingleRuleValidate.Required }] }
  })
})

interface IProps extends INavigateProps {}

class StepAboutMedia extends Component<IProps> {
  render() {
    const data = LocalStorageSubmisstion.getData()
    return (
      <Box sx={{ marginTop: '48px' }}>
        <Title variant='h6'>Let's learn more about your video</Title>
        <FormGridLayoutComponent
          data={data?.item}
          onSubmit={async (value) => {
            LocalStorageSubmisstion.setData(Object.assign({}, data?.item, value), ESubmissionStep.AboutMedia)
            this.props.navigate(CreateRoutePath([RouteKey.Submit, RouteKey.SMTAboutYouself]))
          }}
          slots={{
            sx: { minHeight: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'stretch' },
            action: () => <ActionsBar />
          }}
        />
      </Box>
    )
  }
}
export default withNavigate(StepAboutMedia)

const Title = styled(Typography)({
  textTransform: 'uppercase',
  color: '#606060',
  textAlign: 'center',
  fontWeight: 600
})

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { EMediaDeliveryType } from '@shared/Types'
import { Box, Fade, IconButton, IconButtonProps, styled } from '@mui/material'
import LinkIcon from '@mui/icons-material/Link'
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined'
import ListAltIcon from '@mui/icons-material/ListAlt'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'

interface IProps {
  to: string
  target?: React.HTMLAttributeAnchorTarget
  type: EMediaDeliveryType
}

export class DeliveryBtnDetail extends Component<IProps> {
  render() {
    return (
      <IconButton color='inherit' sx={{ cursor: 'pointer' }} {...{ component: Link, to: this.props.to, target: this.props.target }}>
        {this.renderIcon(this.props.type)}
      </IconButton>
    )
  }

  renderIcon = (t: EMediaDeliveryType) => {
    switch (t) {
      case EMediaDeliveryType.Url:
        return <LinkIcon />
      case EMediaDeliveryType.Upload:
        return <ListAltIcon />
      case EMediaDeliveryType.Internal:
      default:
        return <SourceOutlinedIcon />
    }
  }
}

export class DeliveryBtnBack extends Component<Omit<IconButtonProps, 'disabled'> & { disabled?: boolean }> {
  render() {
    const { children, disabled, ...p } = this.props
    return (
      <Box>
        <Fade in={!disabled}>
          <CustomIconButton color='inherit' {...p}>
            <ArrowBackIosNewOutlinedIcon fontSize='small' />
          </CustomIconButton>
        </Fade>
      </Box>
    )
  }
}

const CustomIconButton = styled(IconButton)({
  cursor: 'pointer',
  flex: '0 0 auto',
  '& svg': { transition: 'all 0.2s' },
  '&:hover svg': { color: '#1976D2' }
})

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { RouteKey } from '@internal/route'
import { Box, Container, Divider, Stack, Typography, alpha, styled } from '@mui/material'
import withRouter, { IRouterProps } from '@lib/Hocs/withRouter'
import * as MaterialIcons from '@mui/icons-material'
import AppConfigs from '../AppConfigs'
import HookChangeParameter from './hook.change-parameter'

const CONFIGS = AppConfigs.profile.menu

interface IProps extends IRouterProps {}

interface IState {
  pathname: string
}

class ProfileMenu extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { pathname: '/' }
  }

  render() {
    return (
      <Container maxWidth={false} sx={{ position: 'sticky', top: '96px' }}>
        <HookChangeParameter onChange={this.handleLocationChange} />
        <Box sx={{ height: '72px' }} />
        <Stack sx={{ gap: '6px' }}>
          {CONFIGS.map((item, index) => {
            const pathname = item.key === '/' ? `/${RouteKey.Profile}` : `/${RouteKey.Profile}/${item.key}`
            const activated = this.isActived(pathname)
            return (
              <Box key={index}>
                <Item
                  {...(activated ? { className: 'active' } : {})}
                  {...{ component: Link, to: pathname }}
                  onClick={() => this.setState({ pathname })}
                >
                  {item.icon}
                  <Typography variant='subtitle1'>{item.title}</Typography>
                </Item>
              </Box>
            )
          })}
          <Divider sx={{ mt: '56px' }} />
          {this.renderAbout()}
        </Stack>
      </Container>
    )
  }

  renderAbout = () => {
    const pathname = `/${RouteKey.Profile}/${RouteKey.AboutUs}`
    return (
      <Item
        {...(this.isActived(pathname) ? { className: 'active' } : {})}
        {...{ component: Link, to: pathname }}
        // onClick={() => this.setState({ pathname })}
      >
        <MaterialIcons.ErrorOutline />
        <Typography variant='subtitle1'>About us</Typography>
      </Item>
    )
  }

  handleLocationChange = (pathname: string) => {
    this.setState({ pathname })
  }

  componentDidMount(): void {
    this.setState({ pathname: this.props.location.pathname })
  }

  isActived = (value: string) => {
    const { pathname } = this.state
    // Use the URL to parse the path and ignore query parameters
    const currentPath = new URL(pathname, window.location.origin).pathname
    const targetPath = new URL(value, window.location.origin).pathname
    // Special case for main profile
    if (targetPath === `/${RouteKey.Profile}`) {
      return currentPath === `/${RouteKey.Profile}` || currentPath === '/'
    }
    // Check if currentPath starts with targetPath
    return currentPath.startsWith(targetPath)
  }
}

export default withRouter(ProfileMenu)

const Item = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  padding: '12px 24px',
  borderRadius: '3px 22px 22px 3px',
  backgroundColor: '#fff',
  transition: 'linear 0.15s',
  cursor: 'pointer',
  textDecoration: 'none',
  color: '#818181',
  '&.active': {
    backgroundColor: `${alpha('#1A9BE8', 0.15)} !important`,
    color: '#1A9BE8'
  },
  '&:hover': {
    backgroundColor: alpha('#1A9BE8', 0.1)
  }
})

import React from 'react'
import { RouteKey } from '@internal/route'
import { useLocation } from 'react-router-dom'
import { WindowScrollToTopById } from '@lib/Helpers'
import { IRouterProps, withRouter } from '@lib/Hocs'
import StepperBar, { IStepperBarConfig, IStepperBarProps } from './UI/StepperBar'
import LocalStorageSubmisstion from './Helpers/LocalStorageSubmisstion'
import { CreateRoutePath } from '@lib/RouteBase'

const MarkId = 'stepper-bar-submit-media'

const useScrollToTop = () => {
  const location = useLocation()
  React.useEffect(() => {
    WindowScrollToTopById(MarkId, { scrollMore: -96 })
  }, [location])
}

interface IProps extends IRouterProps {
  configs: IStepperBarConfig[]
}

const StepperLayout: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const submitPath = CreateRoutePath(RouteKey.Submit)
  useScrollToTop()

  const getStepBarProps = (): IStepperBarProps => {
    const data = LocalStorageSubmisstion.getData()
    if (!data) return { configs: props.configs, activeStep: 0 }
    const steps = new Set(data.stepDones)
    const list = props.configs.map((item, index) => {
      if (steps.has(item.key)) item.activated = true
      return item
    })
    const index = list.findIndex((x) => {
      if (props.location.pathname !== submitPath) {
        return props.location.pathname === x.to ?? ''
      }
      return !x.activated
    })
    return { configs: list, activeStep: Math.max(index, 0) }
  }

  React.useEffect(() => {
    if (props.location.pathname === submitPath) {
      const item = getStepBarProps()
      props.navigate(item.configs[item.activeStep].to ?? '')
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname])

  return (
    <>
      <div id={MarkId} />
      <StepperBar {...getStepBarProps()} />
      {props.children}
    </>
  )
}

export default withRouter(StepperLayout)

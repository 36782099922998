const localKey = 'notification_data'

export const SetLocalStorageNotification = (notificationIds: string[]) => {
  localStorage.removeItem(localKey)
  localStorage.setItem(localKey, JSON.stringify(notificationIds))
}

export const GetLocalStorageNotification = (): string[] => {
  try {
    const data = localStorage.getItem(localKey)
    return JSON.parse(data ?? '[]')
  } catch (error) {
    return []
  }
}

export const SetSingleLocalStorageNotification = (notificationId: string) => {
  const setIds = new Set(GetLocalStorageNotification())
  setIds.add(notificationId)
  SetLocalStorageNotification(Array.from(setIds))
}

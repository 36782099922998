import React, { FC } from 'react'
import { defaultStyles, FileIcon } from 'react-file-icon'
import { styleDefObj } from './style-customize'

interface FileIconColorProps {
  extension: string
}
export const FileIconColor: FC<FileIconColorProps> = (props) => {
  const { extension } = props
  const customDefaultLabelColor = styleDefObj[extension] ? styleDefObj[extension]['labelColor'] ?? '#777' : '#777'

  // Library defined default labelColor
  const _defaultStyles: any = defaultStyles
  const libDefaultGlyphColor = _defaultStyles[extension] && _defaultStyles[extension]['labelColor']
  return (
    <FileIcon
      extension={extension}
      glyphColor={libDefaultGlyphColor ?? customDefaultLabelColor}
      labelColor={customDefaultLabelColor}
      {..._defaultStyles[extension]}
      {...styleDefObj[extension]}
    />
  )
}

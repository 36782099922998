import React, { Component } from 'react'
import { IconButton, Button, Box, Divider, styled, SxProps, Theme, Fade, ClickAwayListener } from '@mui/material'
import * as IconsMaterial from '@mui/icons-material'

interface ISlots {
  size?: 'micro' | 'small'
  color?: 'primary' | 'inherit'
  buttonElement?: React.ComponentType<{ onClick: () => void }>
}

interface IProps {
  onSubmit?: () => void
  disabled?: boolean
  slots?: ISlots
}

interface IState {
  isCheck: boolean
}

export class ConfirmButton extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { isCheck: false }
  }
  render() {
    return (
      <ClickAwayListener onClickAway={this.handleClickCancel}>
        <Wrap sx={this.getSxWrap()}>
          {this.renderContent()}
          <Fade in={this.state.isCheck} timeout={200}>
            <WrapItem>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '9px' }}>
                <IconButton color='success' size='small' onClick={this.handleClickDone}>
                  <IconsMaterial.Done sx={{ color: '#2e7d32 !important' }} />
                </IconButton>
                <Divider orientation='vertical' flexItem />
                <IconButton color='error' size='small' onClick={this.handleClickCancel}>
                  <IconsMaterial.Close sx={{ color: '#d32f2f !important' }} />
                </IconButton>
              </Box>
            </WrapItem>
          </Fade>
        </Wrap>
      </ClickAwayListener>
    )
  }

  renderContent = () => {
    let inner = (
      <CustomButton fullWidth color='inherit' size='small' endIcon={<IconsMaterial.ClearAll />} onClick={this.handlingClick}>
        Clear all
      </CustomButton>
    )
    if (this.props.slots?.buttonElement) {
      const BtnComponent = this.props.slots.buttonElement
      inner = <BtnComponent onClick={this.handlingClick} />
    }
    return (
      <Fade in={!this.state.isCheck} timeout={200}>
        <WrapItem>{inner}</WrapItem>
      </Fade>
    )
  }

  handleClickDone = () => {
    this.props.onSubmit && this.props.onSubmit()
    this.setState({ isCheck: false })
  }

  handleClickCancel = () => {
    this.setState({ isCheck: false })
  }

  handlingClick = () => {
    this.setState({ isCheck: true })
  }

  getSxWrap = (): SxProps<Theme> => {
    const { slots } = this.props
    let sxSvg: SxProps<Theme> = {}
    if (slots?.size === 'micro') {
      sxSvg = { ...sxSvg, fontSize: '1.1rem' }
    }
    if (slots?.color) {
      sxSvg = { ...sxSvg, color: slots.color }
    }
    return { '& svg': sxSvg } as SxProps<Theme>
  }
}

const Wrap = styled(Box)({
  height: '32px',
  width: '100px',
  position: 'relative'
})

const WrapItem = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end'
})

const CustomButton = styled(Button)({
  textTransform: 'none',
  color: '#454545',
  fontWeight: 600
})

import { IMedia } from '@shared/Types'
import { TableQueryParam } from '@lib/Helpers'
import { QueryParamService } from '@shared/Services'
import { RootState, AppDispatch } from '@internal/redux'
import MediaService, { MediaMapping } from '@internal/services/MediaService'
import { fetchMediaThunk } from './redux.thunks'
import { IMediaMapDispatchRedux, IMediaMapStateRedux } from './redux.types'
import MediaSlice, { DefaultTableInfo } from './redux.slice'

export const mapStateToProps = (state: RootState): IMediaMapStateRedux => ({
  Status: state.MediaSlice.Status,
  stateRedux: state.MediaSlice,
  tableInfo: state.MediaSlice.tableInfo
})

type TRequest = { abort: () => void }
const FetchAccessory: { request?: TRequest } = {}
export const mapDispatchToProps = (dispatch: AppDispatch): IMediaMapDispatchRedux => ({
  FetchData: () => {
    const deliveryId = QueryParamService.GetDeliveryId()
    const tableInfo = !deliveryId ? TableQueryParam.getParam<IMedia>(DefaultTableInfo) : undefined
    return dispatch(fetchMediaThunk({ deliveryId, tableInfo }))
  },
  onTableChange: (key, value, details) => {
    if (key === 'FilterModel') {
      dispatch(MediaSlice.actions.onChange({ key, value, details }))
    }
    const deliveryId = QueryParamService.GetDeliveryId()
    FetchAccessory.request?.abort()
    FetchAccessory.request = dispatch(fetchMediaThunk({ tableChange: { key, value: value, details }, deliveryId }))
  },
  CRUD: {
    Create: async (model) => {
      const dataMapping = MediaMapping.mediaCreate(model)
      const deliveryId = QueryParamService.GetDeliveryId()
      if (!!deliveryId) {
        await MediaService.DeleveryDetailCreate({ ...dataMapping, DeliveryId: deliveryId?.toString() })
      } else {
        await MediaService.Create(dataMapping)
      }
      dispatch(fetchMediaThunk({ deliveryId }))
    }
  },
  clean: () => {
    dispatch(MediaSlice.actions.clean())
  }
})

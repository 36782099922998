import { FetchDelay } from '@lib/Helpers'
import { IMedia } from '@shared/Types'
import { EMediaType } from '@shared/Types'
import { ServiceBase, CreateHttpService } from '@lib/Http'
import { IFilterDTO, IMediaDTO } from '../types'
import UploadService from './UploadService'
import FilterMapping, { TMediaFilter } from './FilterMapping'

interface IFetchFilterResult {
  Amount: number
  Page: number
  Total: number
  Data: IMedia[]
}

class MediaServiceBase extends ServiceBase {
  constructor() {
    super(CreateHttpService(`${process.env.REACT_APP_API_URI}/api/v1/guest/Media`))
  }

  private urlAll = '/All'
  all = async (filter: IFilterDTO, defaultFilter?: TMediaFilter, signal?: AbortSignal): Promise<IFetchFilterResult> => {
    return FetchDelay<IFetchFilterResult>(async () => {
      try {
        const filterReq = FilterMapping.parseMedia(filter, defaultFilter)
        const res = await this.PushNotify(this.Post<IFetchFilterResult>, this.urlAll, filterReq, { signal })
        return res
      } catch (error) {
        const defaultValue: IFetchFilterResult = { Amount: 0, Data: [], Page: 0, Total: 0 }
        return defaultValue
      }
    }, 1000)
  }

  private urlDetail = '/Get'
  detail = (id?: string, signal?: AbortSignal): Promise<IMediaDTO | undefined> => {
    return FetchDelay(async () => {
      if (!id) return
      try {
        const res = await this.PushNotify(this.Get<IMediaDTO>, `${this.urlDetail}?Id=${id}`, { signal })
        if (res.Type === EMediaType.Video && res.ResourceId) {
          const videoStreams = await UploadService.GetVideoStream(res.ResourceId)
          res.ResourceData = videoStreams
        }
        return res !== null ? res : undefined
      } catch (error) {
        return
      }
    }, 1000)
  }
}
const MediaService = new MediaServiceBase()
export default MediaService

import React, { Component } from 'react'
import { Button, ButtonProps, styled, Typography } from '@mui/material'
import LinkIcon from '@mui/icons-material/Link'
import HelpTooltip from '@lib/Component/HelpTooltip'
import FileUploadIcon from '@mui/icons-material/FileUpload'

export class MediaBtnUpload extends Component<Partial<ButtonProps>> {
  render() {
    return (
      <ButtonCustom variant='contained' size='small' startIcon={<FileUploadIcon />} {...this.props}>
        Upload
      </ButtonCustom>
    )
  }
}

export class MediaBtnUploadNote extends Component {
  render() {
    return (
      <HelpTooltip title='Notes'>
        <Typography variant='subtitle2'>
          You can upload media while it is in a <span style={{ color: '#1976d2', fontWeight: 600 }}>Pending</span> state
        </Typography>
      </HelpTooltip>
    )
  }
}

export class MediaBtnLinks extends Component<Partial<ButtonProps>> {
  render() {
    return (
      <ButtonCustom variant='outlined' size='small' color='inherit' startIcon={<LinkIcon />} {...this.props} sx={{ opacity: 0.8, ...this.props.sx }}>
        Repository URLs
      </ButtonCustom>
    )
  }
}

const ButtonCustom = styled(Button)({
  textTransform: 'unset',
  lineHeight: 1,
  padding: '7px 12px',
  fontWeight: 600
})

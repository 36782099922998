import React, { Component } from 'react'
import { motion } from 'framer-motion'
import { FormatFileSize } from '@lib/Helpers'
import { Text14, Text16, TextBase } from '@internal/shareds/Text'
import { Box, Button, Fade, SxProps, Theme, Typography, styled } from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { MaximumFileSizeLimit, validateFilesSize } from '../helpers'
import ButtonConfirmDelete from './ButtonConfirmDelete'

interface IProps {
  multiple?: boolean
}
interface IState {
  files: File[]
}
export default class OptionBar extends Component<IProps, IState> {
  refInput: HTMLInputElement | null = null
  constructor(props: IProps) {
    super(props)
    this.state = { files: [] }
  }

  render() {
    const total = this.getTotalFileSize()
    const message = this.getMessageError()
    const stl = getCustomStyleds(total, this.props.multiple)
    return (
      <Box sx={{ mt: '9px' }}>
        {this.renderSelectedFiles()}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
          <Button component='label' startIcon={<AttachFileIcon />}>
            <TextBase sx={{ textTransform: 'none' }}>Attach files</TextBase>
            <input type='file' accept='image/*' hidden multiple onChange={this.handleChangeInputFile} ref={(ref) => (this.refInput = ref)} />
          </Button>
          <Fade in={!!message}>
            <Typography variant='caption' sx={stl.helpText}>
              {message}
            </Typography>
          </Fade>
          <Box sx={{ flex: 1 }} />
          {this.state.files.length > 0 && (
            <Typography variant='subtitle1' sx={stl.total}>
              Total: {FormatFileSize(total / 1024)}
            </Typography>
          )}
        </Box>
      </Box>
    )
  }
  renderSelectedFiles = () => {
    const list = this.state.files
    if (list.length < 1) return <></>
    return (
      <Wrap>
        {list.map((item, index) => {
          const stl = getCustomStyleds(item.size, this.props.multiple)
          return (
            <Box component='li' key={index}>
              <MotionItem
                sx={{ borderRadius: '6px' }}
                initial={{ opacity: 0.3, backgroundColor: '#dedede' }}
                animate={{ opacity: 1, backgroundColor: '#fafafa' }}
                transition={{ ease: 'linear', duration: 1 }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Text16 className='noselect'>{index + 1}.</Text16>
                  <Text16 sx={{ flex: 1, fontWeight: 600, ...stl.overLimit }} noWrap>
                    {item.name}
                  </Text16>
                  <Text14 className='noselect' sx={{ color: stl.textColor }}>
                    {FormatFileSize(item.size / 1024)}
                  </Text14>
                  <ButtonConfirmDelete InnerProps={{ color: stl.iconColor }} onSubmit={() => this.handleDeleteFile(item)} />
                </Box>
              </MotionItem>
            </Box>
          )
        })}
      </Wrap>
    )
  }

  handleChangeInputFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: File[] = Array.from(event.target.files ?? [])
    const list = this.state.files
    const sFileNames = new Set(list.map((x) => x.name))
    value.forEach((x) => {
      if (!sFileNames.has(x.name)) list.push(x)
    })
    this.setState({ files: list }, () => {
      if (!!this.refInput) this.refInput.value = ''
    })
  }
  handleDeleteFile = (value: File) => {
    const list = this.state.files.filter((x) => x.name !== value.name)
    this.setState({ files: list })
  }

  getData = (): File[] => this.state.files
  getMessageError = (): string | undefined => {
    const valid = validateFilesSize(this.state.files, { multiple: this.props.multiple })
    if (!!valid) return
    if (this.props.multiple === true) {
      return `Total file size must be less than ${FormatFileSize(MaximumFileSizeLimit / 1024)}`
    } else {
      return `The size of each file must be less than ${FormatFileSize(MaximumFileSizeLimit / 1024)}`
    }
  }
  getTotalFileSize = () => {
    const sum = this.state.files.reduce((a, b) => {
      a += b.size
      return a
    }, 0)
    return sum
  }
}

interface ICustomStyleds {
  total?: SxProps<Theme>
  helpText?: SxProps<Theme>
  textColor: string
  iconColor: 'error' | 'inherit'
  overLimit?: SxProps<Theme>
}
const getCustomStyleds = (size: number, multiple?: boolean): ICustomStyleds => {
  const isOverLimit = size > MaximumFileSizeLimit
  const color = isOverLimit ? '#d32f2f' : '#606060'
  const stCommon: Partial<ICustomStyleds> = { helpText: { color: '#d32f2f', marginTop: '3px' } }
  let st: ICustomStyleds = {
    total: { color: '#606060', fontWeight: '600' },
    textColor: color,
    iconColor: isOverLimit ? 'error' : 'inherit',
    overLimit: isOverLimit ? { textDecoration: 'line-through' } : {}
  }
  if (multiple === true) {
    st = {
      total: { color: color, fontWeight: '600' },
      textColor: '#606060',
      iconColor: 'inherit',
      overLimit: {}
    }
  }
  return Object.assign({}, stCommon, st)
}
const MotionItem = styled(motion.div)({
  '& > div': {
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    marginTop: '4px',
    padding: '3px 9px'
  }
})
const Wrap = styled('ul')({
  maxHeight: `${42 * 5 + 15}px`,
  overflow: 'auto',
  padding: '0 3px 9px'
})

import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { IFormInputBase } from '@lib/Forms'
import { IMediaDelivery } from '@shared/Types'
import { CreateSelectWithApi } from '@lib/Forms/Inputs'
import SelectService from '@internal/services/SelectService'

// import { EMediaDeliveryType, IMediaDelivery } from '@shared/Types'
// import { CreateSelectSimple, CreateSelectWithApi } from '@lib/Forms/Inputs'
// import { getMediaDeliveryTypes } from '@shared/Services'

// const SelectSimple = CreateSelectSimple<IMediaDelivery>()
const SelectWithApiComponent = CreateSelectWithApi<IMediaDelivery>()

interface IProps extends IFormInputBase<IMediaDelivery> {}
export default class SelectResourceId extends Component<IProps> {
  render() {
    return (
      <Grid container spacing={2}>
        {/* <Grid item xs={12} md={3}>
          <SelectSimple
            {...{ ...this.props, name: 'Type', label: 'Type' }}
            defaultValue={EMediaDeliveryType.Submission}
            options={getMediaDeliveryTypes().filter((x) => x.value !== EMediaDeliveryType.Other)}
          />
        </Grid> */}
        <Grid item xs={12}>
          <SelectWithApiComponent
            {...{ ...this.props, name: 'ResourceId', label: 'Contract' }}
            // disabled={!!this.props.data}
            fetchData={SelectService.fetchSubmissions}
          />
        </Grid>
      </Grid>
    )
  }
}

import VisaSVGIcon from '../Assets/svgs/visa.svg'

import { Component } from 'react'
import { Box, Container, Stack, Typography, styled } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

class ReportScreen extends Component {
  render() {
    return (
      <Container>
        <Box height="25px" />
        <NotifyBox>
          <Text>
            You have completed your purchase from us, thank you very much We've sent you an email so you can visit and find what's
            yours
          </Text>
        </NotifyBox>
        <Box height="25px" />
        <Paper>
          <Line />
          <Grid container>
            <Grid xs={12} sm={6}>
              <Stack sx={{ gap: '15px', mr: { xs: 0, sm: '10px' } }}>
                <Stack direction="row" justifyContent="space-between">
                  <Title>Item: 3</Title>
                  <Title>449 $</Title>
                </Stack>
                <Line />
                <Stack direction="row" justifyContent="space-between">
                  <Title>VAT</Title>
                  <Title>10 %</Title>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Title>Subtotal</Title>
                  <Title>455 %</Title>
                </Stack>
              </Stack>
            </Grid>
            <Grid xs={12} sm={6}>
              <Stack sx={{ gap: '15px', ml: { xs: 0, sm: '10px' } }}>
                <Stack gap="10px" alignItems="flex-start">
                  <Title>Payment method</Title>
                  <CustomImage src={VisaSVGIcon} alt="VisaSVGIcon" />
                </Stack>
                <Line />
                <Stack gap="10px" alignItems="flex-start">
                  <Title>Card Holder</Title>
                  <TextContent>Jim Hana</TextContent>
                </Stack>
                <Stack gap="10px" alignItems="flex-start">
                  <Title>Credit Card Number</Title>
                  <TextContent>1234 5678 91011121</TextContent>
                </Stack>
                <Stack gap="10px" alignItems="flex-start">
                  <Title>Exp Date</Title>
                  <TextContent>23/12/2023</TextContent>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Box height="10px" />
          <Title sx={{ textAlign: 'center' }}>Product in your cart</Title>
          <Line />
          <Typography variant="h5">No Data</Typography>
        </Paper>
      </Container>
    )
  }
}
export default ReportScreen

const Line = styled('div')({
  borderBottom: '1px dashed rgba(0, 0, 0, 0.15)',
})

const NotifyBox = styled('div')({
  backgroundColor: 'rgba(0,206,10,0.1)',
  padding: '35px 0',
  borderRadius: '8px',
  textAlign: 'center',
  border: '1px dashed #00CE0A',
})

const Text = styled('p')({
  fontWeight: 700,
  fontSize: '16px',
})

const Paper = styled(Stack)({
  backgroundColor: '#F4F1EB',
  borderRadius: '8px',
  padding: '50px 20px',
  gap: '20px',
})

const Title = styled('h3')({
  fontSize: '20px',
  fontWeight: 700,
})

const TextContent = styled('p')({
  fontSize: '16px',
  fontWeight: 500,
  color: '#878787',
})

const CustomImage = styled('img')({
  height: '24px',
})

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteKey } from '@internal/route'
import { TableFormater } from '@lib/Helpers'
import { CreateHocLazy } from '@lib/ReduxBase'
import { CreateTableTemplate, MapOprators } from '@lib/Table'
import { IBillingDetailDTO } from '@internal/services/BillingDetailService'
import { BillingInfoBar, MapInfoBarData, TotalMoneyCell } from '@shared/Pages/Billing'
import LayoutModalRoute from '@lib/Layout/ModalRoute'
import { IBillingReduxProps } from './redux.types'
import { mapDispatchToProps, mapStateToProps } from './redux.map'

const TableInstance = CreateTableTemplate<IBillingDetailDTO>('Redux', {
  getRowId: (x) => x.Id,
  config: {
    Name: { type: 'string', flex: 2, minWidth: 200 },
    ResourceId: { type: 'string', flex: 2, minWidth: 200 },
    Payout: {
      type: 'number',
      flex: 1,
      minWidth: 175,
      headerName: 'Payout (USD)',
      headerAlign: 'left',
      renderCell: (params) => <TotalMoneyCell Usd={params.value} UsdRate={0} />
    },
    DateCreated: {
      type: 'string',
      headerName: 'Date created',
      minWidth: 190,
      filterable: false,
      renderCell: (params) => TableFormater.tooltipDate(params.value)
    }
  },
  filterOperators: MapOprators //server mode
})

interface IProps extends IBillingReduxProps {}
export default class BillingDetailPage extends Component<IProps> {
  render() {
    const infoBarData = MapInfoBarData(this.props.tableInfo.PageInfo.data, this.props.stateRedux.Billing)
    return (
      <>
        <BillingInfoBar in client data={infoBarData} />
        <TableInstance ReduxOption={this.props.tableInfo} onChange={this.props.onTableChange} />
      </>
    )
  }
  getExistedIds = () => {
    return this.props.tableInfo.PageInfo.data.map((x) => x.ResourceId)
  }
}

export * from './redux.thunks'
export { default as BillingDetailSlice } from './redux.slice'

const HocLazyInstance = CreateHocLazy(BillingDetailPage)
export const BillingDetailContainer = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)
export const BillingDetailModal: React.FC = () => (
  <LayoutModalRoute.Wrapper size='xl' title='Billing detail' back={[RouteKey.Profile, RouteKey.Billing]}>
    <BillingDetailContainer />
  </LayoutModalRoute.Wrapper>
)

import { ICart } from '@shared/Types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { CartLocalStorage } from '@internal/shareds/LocalStorages'
import CheckOutService from '@internal/services/CheckoutService'

interface ICartResult {
  carts: ICart[]
}

export const fetchCartThunk = createAsyncThunk<ICartResult>('fetchCartThunk', async (_, thunkAPI) => {
  // const state = thunkAPI.getState() as { CartSlice: ICartStateSlice }
  const res = await CartLocalStorage.getDataAsync()
  return { carts: res }
})

interface IValidateResult {
  status: boolean
}
export const fetchCheckOutValidateThunk = createAsyncThunk<IValidateResult>('fetchCheckOutValidateThunk', async (_, thunkAPI) => {
  // const state = thunkAPI.getState() as { CartSlice: ICartStateSlice }
  const dataLocal = CartLocalStorage.getData()
  const res = await CheckOutService.validate(dataLocal)
  return { status: res }
})

export const removeAllCartThunk = createAsyncThunk('fetchRemoveAllCartThunk', async (_, thunkAPI) => {})

import React, { Component } from 'react'
import { ELazyStatus } from '@lib/ReduxBase'
import { CreateRoutePath } from '@lib/RouteBase'
import { ApiAlertContext } from '@lib/ApiContext'
import { Container, Divider } from '@mui/material'
import { ETicketConversation, ETicketType } from '@shared/Types'
import withNavigate, { INavigateProps } from '@lib/Hocs/withNavigation'
import UIStyleds from '@shared/UIStyleds'
import TopBar from '@shared/Pages/TopBar'
import TicketService from '@internal/services/TicketService'
import CreateTicketConversationService from '@internal/services/TicketConversationService'
import { FormCreate } from './Forms'
import { IFormCreateData } from './type'
import { RouteKey } from '../../Routers'
import { filesToArrayBase64, validateFilesSize } from './helpers'
import OptionBar from './Forms/OptionBar'
import LoadingView from './UI/LoadingView'

interface IProps extends INavigateProps {}
interface IState {
  loadingStatus: ELazyStatus
}

class TicketCreate extends Component<IProps, IState> {
  refOptionBar: OptionBar | null = null
  refLoadingView: LoadingView | null = null
  render() {
    return (
      <UIStyleds.WrapFullDisplay sx={{ position: 'relative' }}>
        <TopBar btnBackTitle='back to tickets' title='New ticket' to={CreateRoutePath([RouteKey.Profile, RouteKey.Ticket])} />
        <Divider />
        <UIStyleds.WrapScroll>
          <Container maxWidth={false} sx={{ mt: '24px' }}>
            <FormCreate Option={<OptionBar multiple ref={(ref) => (this.refOptionBar = ref)} />} onSubmit={this.handleCreate} />
          </Container>
        </UIStyleds.WrapScroll>
        <LoadingView ref={(ref) => (this.refLoadingView = ref)} />
      </UIStyleds.WrapFullDisplay>
    )
  }

  handleCreate = async (value: Partial<IFormCreateData>) => {
    const files = this.refOptionBar?.getData() ?? []
    try {
      this.refLoadingView?.load()
      if (!validateFilesSize(files, { multiple: true })) {
        throw new Error('Attachment file is not valid!')
      }
      const attachs: string[] = await filesToArrayBase64(files)
      const res = await TicketService.Create({ Subject: value.Subject, Type: value.Type ?? ETicketType.General })
      const conversationAPI = CreateTicketConversationService(res.Id)
      await conversationAPI.Create({ Message: value.Message, Type: ETicketConversation.Image, AttachFile: JSON.stringify(attachs) })
      this.props.navigate(CreateRoutePath([RouteKey.Profile, RouteKey.Ticket]))
    } catch (error: any) {
      // console.log(error)
      ApiAlertContext.ApiAlert?.PushWarning(error.message ?? 'Please check again')
    } finally {
      this.refLoadingView?.stop()
    }
  }
}
export default withNavigate(TicketCreate)

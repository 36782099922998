import React, { Component } from 'react'
import { ApplicationPaths, authService } from 'partner-oidc-auth'
import { Route, Routes } from 'react-router-dom'
import { AuthorizeRoute } from '@lib/ApiAuthorization'
import LayoutModalRoute from '@lib/Layout/ModalRoute'
import { IRoute } from './types'

interface IAppRouterProps {
  routers: IRoute[]
  layout: React.ComponentType<React.PropsWithChildren>
  errorElement?: JSX.Element
}
interface IAppRouterState {
  roles: string[]
}

class AppRouter extends Component<IAppRouterProps, IAppRouterState> {
  isComponentMounted: boolean = true
  WrapRouter: JSX.Element[] = []
  subId: number = -1
  constructor(props: IAppRouterProps) {
    super(props)
    this.state = {
      roles: []
    }
    this.initial([])
    this.subId = authService.subscribe(this.updateAuthentication)
  }
  initial = (roles: string[]) => {
    this.WrapRouter = this.props.routers.filter((x) => x.Hiden !== false).map((route) => this.renderRouter(roles, route))
  }
  componentDidUpdate(prevProps: Readonly<IAppRouterProps>, prevState: Readonly<object>, snapshot?: any): void {
    if (prevProps.routers !== this.props.routers) this.initial(this.state.roles ?? [])
  }
  componentWillUnmount(): void {
    this.isComponentMounted = false
    authService.unsubscribe(this.subId)
  }
  componentDidMount = () => {
    /**
     * Request 401, try refresh token
     */
    if (!window.location.href.includes(ApplicationPaths.ApiAuthorizationPrefix)) {
      this.updateRole()
    }
  }
  render() {
    return (
      <Routes>
        <Route path='/' element={this.renderElement()} errorElement={this.props.errorElement}>
          {this.WrapRouter}
        </Route>
      </Routes>
    )
  }
  renderElement = () => {
    const LayoutElement = this.props.layout
    return (
      <>
        <LayoutElement />
        <LayoutModalRoute.Provider />
      </>
    )
  }
  renderRouter = (roles: string[], router: IRoute) => {
    const path = router.Path
    if ('Children' in router) {
      return router.Children ? (
        <Route key={router.Path} path={router.Path} element={router.element}>
          {router.Children.map((item) => this.renderRouter(roles, Object.assign({}, item, { Path: removeFirstSlash(item.Path) })))}
        </Route>
      ) : (
        <Route />
      )
    }
    const IsAllow = roles && roles.length ? roles.some((role) => this.isAllowAccess(role, router.roles)) : true
    const Element = !IsAllow ? <></> : router.roles ? <AuthorizeRoute element={router.element} path={path} roles={router.roles} /> : router.element
    const ComponentRoute = WrapPageRoute(Element, {
      prefix: router.Prefix ?? 'BERLINTOMEK',
      title: router.Title ?? router.Path?.toLocaleLowerCase() ?? ''
    })
    return <Route key={router.Path} path={path} element={<ComponentRoute />} />
  }
  getRootUrl = () => {
    return authService.getReturnUrl()
  }
  updateRole = async () => {
    const roles2 = (await authService.getRoles()) ?? []
    const roles1 = this.state.roles
    const temp1 = new Set(this.state.roles)
    const temp2 = new Set(roles2)
    if (roles1.filter((x) => !temp2.has(x)).length !== roles2.filter((x) => !temp1.has(x)).length) {
      this.initial(roles2)
    }
    this.setState({ roles: roles2 })
  }
  updateAuthentication = async () => {
    if (this.isComponentMounted) await this.updateRole()
  }
  isAllowAccess = (role: string, roles?: string[][]) => {
    if (!roles || !roles.length || !roles[0].length) return true
    return roles.every((x) => x.some((y) => y.toLowerCase() === role.toLowerCase()))
  }
}
export default AppRouter

// ========= ========= ========= Other ========= ========= =========
// const HideMenuRole = (role: RoleKeyExternalSite) => {
//   return !Object.values(RoleKeyExternalSite).some((x) => x === role)
// }
const removeFirstSlash = (router: string) => {
  if (router.startsWith('/')) {
    return router.substring(1)
  }
  return router
}

interface PageRouteProps {
  title?: string
  prefix?: string
}
const PageRoute: React.FC<React.PropsWithChildren<PageRouteProps>> = (props) => {
  // TODO <root>/app/:detail/:id -> <root>/app/<media name>
  React.useEffect(() => {
    const title = (props.title ?? '') === '/' ? '' : props.title ?? ''
    const tmps = [props.prefix || '', title]
    document.title = tmps
      .filter((x) => x)
      .join('-')
      .replace(/\W+/, '-')
  }, [props.prefix, props.title])
  return <>{props.children}</>
}

interface OptionPage {
  title?: string
  prefix?: string
}
const WrapPageRoute = (WrapComponent: JSX.Element, option?: OptionPage) => {
  const InnerPageRoute = (props: any) => {
    const { title, ...other } = props
    option = option ? option : {}
    return (
      <PageRoute title={option.title ?? title} prefix={option.prefix}>
        {React.cloneElement(WrapComponent, other)}
      </PageRoute>
    )
  }
  return InnerPageRoute
}

import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, CircularProgress, IconButton, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CachedIcon from '@mui/icons-material/Cached'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'

interface IProps {
  loading?: boolean
  onClick?: () => void
}

export const TUIBRefresh: FC<IProps> = (p) => (
  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', pr: '18px', my: '-6px' }}>
    <Tooltip title='Refresh'>
      <IconButton onClick={p.onClick}>{p.loading === true ? <CircularProgress size='24px' /> : <CachedIcon />}</IconButton>
    </Tooltip>
  </Box>
)

interface ILinkProps {
  to: string
  target?: React.HTMLAttributeAnchorTarget
}

export const TUIBConversation: FC<ILinkProps> = (props) => {
  return (
    <IconButton color='info' {...{ component: Link, to: props.to, target: props.target }} sx={{ cursor: 'pointer' }}>
      <QuestionAnswerIcon />
    </IconButton>
  )
}

export const TUIBCreate: FC<{ to: string }> = (props) => {
  return (
    <Button size='small' variant='contained' startIcon={<AddIcon />} component={Link} to={props.to}>
      Create
    </Button>
  )
}

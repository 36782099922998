import React, { Component } from 'react'
import { LinkRows } from '@lib/Forms/Inputs'
import { IFormUIItemProps } from '@lib/Forms'
import { Box, Divider, Typography } from '@mui/material'
import { ISubmission, ISubmissionJson } from '@shared/Types'
import { ParseStringArray, TryParseJson } from '@lib/Helpers'
import { AnswerPersonWhoFilmed } from './Questions'

export interface IQuestionItemPreview {
  subAnswer?: keyof ISubmission
  prefixTitle?: boolean
  pasteLinks?: boolean
}

interface IProps extends IFormUIItemProps<ISubmission, IQuestionItemPreview> {}

export default class QuestionPreviewItem extends Component<IProps> {
  render() {
    const label = this.props.label ?? this.props.name?.toString()

    return (
      <>
        <Box sx={{ padding: '16px 0' }}>
          <Typography variant='subtitle1' sx={{ mt: '2px', color: '#0f0f0f', fontWeight: 500 }}>
            {label}
          </Typography>
          <Box sx={{ mt: '24px' }}>
            {this.renderText()}
            {this.renderLinks()}
          </Box>
        </Box>
        <Divider />
      </>
    )
  }

  renderText = () => {
    const obj = this.getData()
    let display = obj?.answer?.[0]?.value
    const pwf: keyof typeof AnswerPersonWhoFilmed = 'Other'
    if (obj?.answer?.[0]?.key === pwf) display = obj?.secondaryAnswer
    if (this.props.options?.prefixTitle === true && obj.secondaryAnswer) {
      display = `${display}, ${obj.secondaryAnswer}`
    }
    return (
      <Typography variant='subtitle2' sx={{ color: '#606060', fontWeight: 500 }}>
        {display}
      </Typography>
    )
  }

  renderLinks = () => {
    if (!this.props.options?.pasteLinks) return <></>
    const obj = this.getData()
    const links = ParseStringArray(obj.secondaryAnswer)
    return (
      <LinkRows
        data={links}
        // components={{ actionRow: (value) => <CopyBox content={value} /> }}
      />
    )
  }

  getData = () => {
    const { name, options: other } = this.props
    if (!name) return {}
    return TryParseJson<ISubmissionJson>(this.props.data?.[other?.subAnswer ?? name]) ?? {}
  }
}

import { ITicket } from '@shared/Types'
import { authService } from 'partner-oidc-auth'
import { createAsyncThunk } from '@reduxjs/toolkit'
import TicketService, { TicketMapping } from '@internal/services/TicketService'

interface IParamsThunk {
  ticketId: string
  cleaned?: boolean
  isRefresh?: boolean
}

interface IResponseThunk {
  data?: ITicket
}

export const fetchTicketDetailThunk = createAsyncThunk<IResponseThunk, IParamsThunk>('fetchTicketDetailThunk', async (param, thunkAPI) => {
  // const state = thunkAPI.getState() as { TicketDetailSlice: ITicketDetailStateSlice }
  const user = await authService.getUser()
  const result = await TicketService.GraphQLSingle({ id: param.ticketId }, thunkAPI.signal)
  const dataResponse: IResponseThunk = {
    data: TicketMapping.ticket(user?.sub ?? '', result)
  }
  return dataResponse
})

import React, { Component } from 'react'
import { Box } from '@mui/material'
import { ICart } from '@shared/Types'
import { connect } from 'react-redux'
import { RouteKey } from '@internal/route'
import { Dictionary } from '@reduxjs/toolkit'
import { CreateHocLazy } from '@lib/ReduxBase'
import { ICartReduxProps } from './redux.types'
import { WindowScrollToTop } from '@lib/Helpers'
import { CreateRoutePath } from '@lib/RouteBase'
import { ApiAlertContext } from '@lib/ApiContext'
import { ECheckOutValidateStatus } from '@shared/Types'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { mapDispatchToProps, mapStateToProps } from './redux.map'
import UILayout from './ui.layout'
import NoData from './ui/NoData'
import CartBadge from './CartBadge'
import CartItem from './ui/CartItem'
import ChooseLicense from './ChooseLicense'
import SummaryReport from './ui/SummaryReport'
import AddToCartButton from './AddToCartButton'

interface IProps extends ICartReduxProps {}

class ViewBase extends Component<IProps & { navigate: NavigateFunction }> {
  render() {
    const data = this.getCartData()
    return (
      <UILayout
        content={this.renderContent(data)}
        summaryReport={
          <SummaryReport
            disabled={data.length < 1}
            data={data}
            onCheckOut={this.handleSubmitCheckOut}
            checkOutStatus={this.props.CartSlice.checkOutStatus}
          />
        }
      />
    )
  }

  componentDidMount(): void {
    WindowScrollToTop()
  }

  componentDidUpdate(): void {
    if (this.props.CartSlice.checkOutStatus === ECheckOutValidateStatus.Valid) {
      this.props.navigate(CreateRoutePath(RouteKey.CheckOut))
    }
  }

  itemMap: Dictionary<CartItem | null> = {}
  renderContent = (carts: ICart[]) => {
    if (carts.length < 1) return <NoData />
    return (
      <Box sx={{ py: '28px' }}>
        {carts.map((item, index) => (
          <CartItem
            ref={(ref) => (this.itemMap[item.Id] = ref)}
            key={index}
            data={item}
            onChange={this.handleChangeItem}
            onDelete={this.handleDeleteItem}
          />
        ))}
      </Box>
    )
  }

  validateAll = () => {
    let temp = true
    Object.values(this.itemMap)
      .filter((e) => !!e)
      .forEach((item) => {
        const status = item?.validate() ?? false
        if (temp) temp = status
      })
    return temp
  }

  getCartData = () => {
    return Object.values(this.props.CartSlice.carts.entities) as ICart[]
  }

  handleSubmitCheckOut = async () => {
    const isValid = this.validateAll()
    if (!isValid) {
      ApiAlertContext.ApiAlert?.PushWarning('Please check your cart again!')
      return
    }
    this.props.checkOutValidate()
  }

  handleChangeItem = (value: ICart) => {
    this.props.updateCart(value)
  }

  handleDeleteItem = (value: ICart) => {
    delete this.itemMap[value.Id]
    this.props.removeCart(value.Id)
  }
}

export * from './redux.thunks'
export { default as CartSlice } from './redux.slice'
export { QuicklyAddToCart } from './redux.map'

const HocLazyInstance = CreateHocLazy((p: IProps) => <ViewBase {...p} navigate={useNavigate()} />)
export const CartMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)

export const ChooseLicenseMapRedux = connect(mapStateToProps, mapDispatchToProps)(ChooseLicense)

export const CartBadgeMapRedux = connect(mapStateToProps, mapDispatchToProps)(CartBadge)

export const AddToCartButtonMapRedux = connect(mapStateToProps, mapDispatchToProps)(AddToCartButton)

import React, { Component } from 'react'
import { Box, IconButton, TextField, Tooltip } from '@mui/material'
import AddLinkIcon from '@mui/icons-material/AddLink'
import { ICartItemPropsBase } from '../../type'
import LinkRows from './LinkRows'
import Helpers from '../../helpers'

interface IProps {
  defaultValue?: string[]
  cartContext: ICartItemPropsBase
}

export default class PasteLinksInput extends Component<IProps> {
  placeholderText = `Enter all platform-capable media (audio, video) URLs`
  refInput: HTMLInputElement | HTMLTextAreaElement | null = null
  refInputPasteLinks: HTMLInputElement | HTMLTextAreaElement | null = null

  render() {
    const { cartContext } = this.props
    return (
      <Box sx={{ marginTop: '18px' }}>
        <textarea hidden name='PasteLinks' defaultValue={this.props.defaultValue} ref={(ref) => (this.refInputPasteLinks = ref)} />
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '6px' }}>
          <TextField
            fullWidth
            multiline
            // rows={1}
            maxRows={7}
            size='small'
            name='PasteSingleLink'
            inputRef={(ref) => (this.refInput = ref)}
            placeholder={this.placeholderText}
            onKeyDown={this.handleKeyDown}
            onPaste={this.handlePaste}
            {...Helpers.mapTextFieldProps('PasteSingleLink', cartContext.onBlur, cartContext.MessageError)}
          />
          <Tooltip title='Add links'>
            <IconButton color='primary' sx={{ mt: '2px' }} onClick={this.handleClickAdd}>
              <AddLinkIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <LinkRows data={this.getData()} onDelete={cartContext.onDelete} onClear={cartContext.onClearLink} />
      </Box>
    )
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const dValue = this.getDefaultValue()
    if (this.refInputPasteLinks && this.refInputPasteLinks.value !== dValue) {
      this.refInputPasteLinks.value = dValue
    } else if (this.refInput && (this.props.defaultValue?.length ?? true)) {
      this.refInput.value = ''
    }
  }

  handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = event.clipboardData.getData('text')
    if (pasteData) {
      event.preventDefault()
      if (!this.refInput || !this.refInputPasteLinks) return
      this.refInput.value = pasteData
      this.props.cartContext.onAdd && this.props.cartContext.onAdd()
      this.refInput.value = ''
    }
  }

  handleClickAdd = () => {
    if (!this.refInput || !this.refInputPasteLinks) return
    this.props.cartContext.onAdd && this.props.cartContext.onAdd()
    if (Helpers.isValidAll(this.refInputPasteLinks.value, this.refInput.value)) {
      this.refInput.value = ''
    }
  }

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && this.refInput && this.refInputPasteLinks) {
      event.preventDefault()
      this.props.cartContext.onAdd && this.props.cartContext.onAdd()
      if (Helpers.isValidAll(this.refInputPasteLinks.value, this.refInput.value)) {
        this.refInput.value = ''
      }
    }
  }

  getDefaultValue = () => Helpers.linksToString(this.props.defaultValue)

  getData = (): string[] => this.props.defaultValue ?? []
}

import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom'

export interface IRouterProps {
  navigate: NavigateFunction
  location: {
    pathname: string
    search: string
    state: any
    hash: string
    key?: string | undefined
  }
}

export const withRouter = <P extends IRouterProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof IRouterProps>) => {
    const navigate = useNavigate()
    const location = useLocation()
    return <Component {...(props as P)} location={location} navigate={navigate} />
  }
}
export default withRouter

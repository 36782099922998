import React, { Component } from 'react'
import DownloadIcon from '@mui/icons-material/Download'
import { Button } from '@mui/material'
import { IItemInfo } from '../../type'
import UploadService from '../../UploadService'

import './index.css'

interface OtherViewProps {
  content?: string
  data?: IItemInfo
}
export default class OtherView extends Component<OtherViewProps> {
  onDownload = () => {
    if (this.props.data) {
      UploadService.Download(this.props.data)
    }
  }
  onPrevent: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
  }
  render() {
    return (
      <div className='other-view-container'>
        {/* <div className='other-view-card card' onMouseDown={this.onPrevent}> */}
        <div className='other-view-card card'>
          <div className='title'>{this.props.content || 'No preview available'}</div>
          <Button onClick={this.onDownload} style={{ width: 150 }} variant='contained' endIcon={<DownloadIcon />}>
            Download
          </Button>
        </div>
      </div>
    )
  }
}

import { IOrder } from '@shared/Types'
import { CreateCRUDSlice2, ELazyStatus } from '@lib/ReduxBase'
import { ISubmissionDetailStateSlice } from './types'
import { fetchSubmissionDetailThunk } from './Thunks'

const SubmissionDetailSlice = CreateCRUDSlice2<IOrder>()({
  name: 'SubmissionDetailSlice',
  selectId: (x) => x.Id,
  Initial: (x) => {
    const init: ISubmissionDetailStateSlice = { ...x }
    return init
  },
  extraReducers: (builder, adapter) => {
    builder
      .addCase(fetchSubmissionDetailThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Loaded

        state.Submission = action.payload.data ?? undefined
      })
      .addCase(fetchSubmissionDetailThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Error
      })
      .addCase(fetchSubmissionDetailThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Status = ELazyStatus.Loading

        state.Submission = undefined
      })
  },
  reducers: {}
})
export default SubmissionDetailSlice

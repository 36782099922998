import React, { Component } from 'react'
import { Button } from '@mui/material'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import { ICart, ICartInfo } from '@shared/Types'
import { IAddToCartButtonReduxProps } from './redux.types'

interface IProps extends IAddToCartButtonReduxProps {}

export default class AddToCartButton extends Component<IProps> {
  render() {
    return (
      <Button color='primary' variant='contained' sx={{ textTransform: 'unset' }} endIcon={<AddShoppingCartIcon />} onClick={this.handleClick}>
        Add to cart
      </Button>
    )
  }

  handleClick = () => {
    const cartInfos: ICartInfo[] = [{ Key: 'Price', Value: this.props.pricePackage.Price.toString() }]
    const cart: ICart = {
      Id: this.props.pricePackage.Id,
      Name: this.props.pricePackage.Name ?? '',
      Type: 'Pack',
      PriceOrigin: this.props.pricePackage,
      Infos: cartInfos
    }
    this.props.addCart(cart)
  }
}

import type { FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface TicketVisitedFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'TicketVisited', T, TVariables> {

    on<XName extends ImplementationType<'TicketVisited'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): TicketVisitedFetcher<
        XName extends 'TicketVisited' ?
        T & X :
        WithTypeName<T, ImplementationType<'TicketVisited'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'TicketVisited'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): TicketVisitedFetcher<T, TVariables>;


    readonly __typename: TicketVisitedFetcher<T & {__typename: ImplementationType<'TicketVisited'>}, TVariables>;


    readonly dateVisited: TicketVisitedFetcher<T & {readonly "dateVisited": string}, TVariables>;

    "dateVisited+"<
        XAlias extends string = "dateVisited", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateVisited", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): TicketVisitedFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateVisited": TicketVisitedFetcher<Omit<T, 'dateVisited'>, TVariables>;


    readonly userId: TicketVisitedFetcher<T & {readonly "userId"?: string}, TVariables>;

    "userId+"<
        XAlias extends string = "userId", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"userId", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): TicketVisitedFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~userId": TicketVisitedFetcher<Omit<T, 'userId'>, TVariables>;


    readonly ticketId: TicketVisitedFetcher<T & {readonly "ticketId": string}, TVariables>;

    "ticketId+"<
        XAlias extends string = "ticketId", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"ticketId", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): TicketVisitedFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~ticketId": TicketVisitedFetcher<Omit<T, 'ticketId'>, TVariables>;


    ticket<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Ticket', X, XVariables>
    ): TicketVisitedFetcher<
        T & {readonly "ticket"?: X}, 
        TVariables & XVariables
    >;

    ticket<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "ticket", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Ticket', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"ticket", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): TicketVisitedFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;
}

export const ticketVisited$: TicketVisitedFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "TicketVisited", 
            "EMBEDDED", 
            [], 
            [
                "dateVisited", 
                {
                    category: "SCALAR", 
                    name: "userId", 
                    undefinable: true
                }, 
                "ticketId", 
                {
                    category: "REFERENCE", 
                    name: "ticket", 
                    targetTypeName: "Ticket", 
                    undefinable: true
                }
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const ticketVisited$$ = 
    ticketVisited$
        .dateVisited
        .userId
        .ticketId
;

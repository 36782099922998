/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppDispatch, RootState } from '@internal/redux'
import { fetchMediaDetailThunk } from './redux.thunks'
import { IMediaDetailDispatchRedux, IMediaDetailStateRedux } from './types'
import { QuicklyAddToCart } from '../Cart'

export const mapStateToProps = (state: RootState): IMediaDetailStateRedux => ({
  Status: state.MediaDetailSlice.Status,
  MediaDetailSlice: state.MediaDetailSlice
})

export const mapDispatchToProps = (dispatch: AppDispatch): IMediaDetailDispatchRedux => ({
  FetchData: (value) => {
    // return dispatch(fetchMediaDetailThunk(value))
    return { abort: () => {} }
  },
  fetchData: (value) => {
    dispatch(fetchMediaDetailThunk(value))
  },
  addToCart: (media, price) => {
    QuicklyAddToCart(dispatch, media, price)
  }
})

import React, { Component } from 'react'
import { EMediaType } from '@shared/Types'
import { IFormBase } from '@lib/Forms'
import { Box, Button, Menu, MenuItem, PopoverVirtualElement, SxProps, Theme, Typography, styled } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { IFormSearch } from './type'

interface IConfig {
  display: string
  icon: React.ReactNode
}

export type TMenuConfig = { [key in EMediaType]: IConfig }

interface IProps extends IFormBase<IFormSearch> {
  configs: TMenuConfig
  onChange?: (value: EMediaType) => void
}

interface IState {
  anchorEl: Element | (() => Element) | PopoverVirtualElement | (() => PopoverVirtualElement) | null
  mediaType: EMediaType
}

export class MediaTypeMenu extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { anchorEl: null, mediaType: this.props.data?.MediaType ?? EMediaType.Video }
  }

  refInput: HTMLInputElement | null = null
  render() {
    const isOpen = Boolean(this.state.anchorEl)
    return (
      <>
        <input hidden name='MediaType' ref={(ref) => (this.refInput = ref)} defaultValue={this.state.mediaType} />
        <CustomButton
          id='btn-explore-menu-appbar'
          aria-controls={isOpen ? 'explore-menu-appbar' : undefined}
          aria-haspopup='true'
          aria-expanded={isOpen ? 'true' : undefined}
          disableElevation
          onClick={this.onOpen}
          endIcon={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          color='inherit'
        >
          {this.getTextButton()}
        </CustomButton>
        <Menu
          keepMounted
          sx={{ mt: '45px' }}
          id='explore-menu-appbar'
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          slotProps={{ paper: { elevation: 0, sx: sxPaperProps } }}
          open={isOpen}
          onClose={this.onClose}
        >
          {this.renderContentMenu()}
        </Menu>
      </>
    )
  }

  renderContentMenu = () => {
    return (
      <Box>
        {this.getKeys().map((key) => {
          const item = this.props.configs[key]
          return (
            <CustomMenu key={key} onClick={() => this.handleClickMenuItem(key)} className={key === this.state.mediaType ? 'active' : ''}>
              {item.icon}
              <Typography textAlign='center'>{item.display}</Typography>
            </CustomMenu>
          )
        })}
      </Box>
    )
  }

  getKeys = (): EMediaType[] => {
    try {
      return Object.keys(this.props.configs) as EMediaType[]
    } catch (error) {
      return []
    }
  }

  getTextButton = () => {
    return this.props.configs[this.state.mediaType].display
  }

  onOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  onClose = () => {
    this.setState({ anchorEl: null })
  }

  handleClickMenuItem = (key: EMediaType) => {
    this.onClose()
    this.setState({ mediaType: key })
    this.props.onChange && this.props.onChange(key)
    if (!this.refInput) return
    this.refInput.value = key
  }
}
export default MediaTypeMenu

const sxPaperProps: SxProps<Theme> = {
  overflow: 'visible',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  mt: 1.5,
  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1
  },
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 14,
    width: 10,
    height: 10,
    bgcolor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0
  }
}

const CustomButton = styled(Button)({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  textTransform: 'capitalize',
  fontWeight: '500',
  color: '#606060'
})

const CustomMenu = styled(MenuItem)({
  minWidth: '150px',
  transition: 'all linear 0.2s',
  display: 'flex',
  gap: '15px',
  '&: hover': {
    backgroundColor: '#dedede'
  },
  '&.active': {
    color: '#1976D2'
  }
})

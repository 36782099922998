import { ICart, ICartJsonContent } from '@shared/Types'
import { getErrorMessage } from '@lib/Forms'
import { IInfoItemCartConfigDTO } from '../type'
import { ILinkValidDTO, TMapTextField, IMapResult } from '../type'

class HelpersBase {
  maxLink = 10

  getImageUrl = (resourceId: string) => {
    return '/api/v1/guest/Upload/VideoThumb/' + resourceId
  }

  validPasteLinks = (value: string = '') => {
    const links = value
      .split('\n')
      .filter((e) => !!e)
      .slice(0, this.maxLink)
      .map((e) => e.trim())
    const linkSet = new Set<string>([])
    return links.reduce<ILinkValidDTO>(
      (a, b) => {
        if (this.isValidLink(b) && !linkSet.has(b)) {
          a.links.push(b)
          linkSet.add(b)
        } else a.invalid++
        return a
      },
      { links: [], invalid: 0 }
    )
  }

  getTotal = (value: ICart[]) => {
    const total = value.reduce<number>((a, b) => {
      const price = b.PriceOrigin?.Price ?? 0
      const amount = b.JsonContent?.Links?.length ?? 0
      a += price * amount
      return a
    }, 0)
    return total.toFixed(2)
  }

  getTotalLinks = (value: ICart[]) => {
    const total = value.reduce<number>((a, b) => {
      a += b.JsonContent?.Links?.length ?? 0
      return a
    }, 0)
    return total
  }

  linksToString = (value?: string[]) => value?.join('\n') ?? ''

  linksToArray = (value: string = '') => {
    const updatedText = value.split('http').join(' http')
    return (
      updatedText
        .match(/[hH][tT][tT][pP][sS]?:\/\/[^\s]+/g)
        ?.filter((x) => x)
        .map((x) => x.trim()) ?? []
    )
  }

  joinLink = (links: string[] = [], newLinks: string[] = []): string[] => {
    const arr = [...newLinks, ...links].filter((e) => !!e)
    const linkSet = new Set(arr)
    return Array.from(linkSet)
  }

  removeLink = (links: string[], value: string) => {
    const linkSet = new Set(links)
    const formatString = value.trim()
    linkSet.delete(formatString)
    return Array.from(linkSet)
  }

  updateLinkJsonContentCart = (cart: ICart, value?: string[]): ICart => {
    const jsonContent: ICartJsonContent = value ? { Links: value } : {}
    return { ...cart, JsonContent: jsonContent }
  }

  parseJsonContentCart = (jsonString?: string): ICartJsonContent => {
    try {
      return JSON.parse(jsonString ?? '')
    } catch (error) {
      return {}
    }
  }

  getCartItemInfos = (item: ICart): IInfoItemCartConfigDTO[] => {
    const linksLength = item.JsonContent?.Links?.length ?? 0
    const list: IInfoItemCartConfigDTO[] = (item.Infos ? [...item.Infos] : []).map((x) => ({ key: x.Key, value: x.Value }))
    list.push({ key: 'Quanlity', value: `${linksLength} / ${this.maxLink}` })
    list.push({ key: 'Sub total', value: `$ ${(linksLength * (item.PriceOrigin?.Price ?? 0)).toFixed(2)}` })
    return list
  }

  mapTextFieldProps: TMapTextField = (name, onBlur, MessageError) => {
    const data = getErrorMessage(MessageError, name)
    const props: IMapResult = { onBlur: () => onBlur && onBlur(name) }
    if (data.error) {
      props.error = Boolean(data.error)
      props.helperText = data.message ?? ''
    }
    return props
  }

  formatString(template: string, ...values: any[]): string {
    return template.replace(/{(\d+)}/g, (match, index) => {
      return typeof values[index] !== 'undefined' ? values[index] : match
    })
  }

  isValidLink = (value: string = '') => {
    const formatString = value.trim()
    return this.isValidRegexURL(formatString) && this.isValidURL(formatString)
  }

  isDuplicatedLink = (linkString: string = '', value: string = '') => {
    const linkSet = new Set(this.linksToArray(linkString))
    const formatString = value.trim()
    return linkSet.has(formatString)
  }

  isValidAll = (linkString: string = '', value: string = '') => {
    const formatString = value.trim()
    const check1 = !this.isDuplicatedLink(linkString, formatString) && this.isValidLink(formatString)
    const check2 = this.linksToArray(linkString).length <= this.maxLink
    return check1 && check2
  }

  isValidRegexURL = (url: string): boolean => {
    const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
    return regex.test(url)
  }

  isValidURL = (url: string): boolean => {
    try {
      new URL(url)
      return true
    } catch (_) {
      return false
    }
  }
}

const Helpers = new HelpersBase()
export default Helpers

import { RouteKey } from '@internal/route'
import { QueryParam } from '../Helpers'

interface IEmpty {}

export interface IRoutePathOption<TModel extends IEmpty = IEmpty> {
  modal?: RouteKey | RouteKey[]
  query?: TModel
}

export interface IRoutePath<TModel extends IEmpty = IEmpty> extends IRoutePathOption<TModel> {
  route: RouteKey | RouteKey[]
}

export const CreateRoutePath = <TModel extends IEmpty = IEmpty>(route: RouteKey | RouteKey[], options?: IRoutePathOption<TModel>) => {
  const query = Object.assign({}, options?.query, options?.modal ? { md: options?.modal } : {})
  const queryString = QueryParam.stringify(query)
  const routes = new Set(Array.isArray(route) ? route : route ? [route] : [])
  const list = Array.from(routes)
  let path = list.join('/')
  if (list[0] === '/') path = ''
  return `/${path}${queryString === '?' ? '' : queryString}`
}

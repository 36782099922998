import React from 'react'
import { SxProps, Theme } from '@mui/material'
import { IFormBase } from '@lib/Forms'
import { CreateFormBase } from '@lib/Forms'
import { FormValidator } from '@lib/Forms'

const CreateFormSingleValidate = function <TModel, TPropCommon extends IFormBase<TModel>>(
  FormValidate: FormValidator<Partial<TModel>>,
  FormInfo: React.ComponentType<TPropCommon>
) {
  interface IProps {
    onSubmit: (data: Partial<TModel>) => Promise<void>
    data?: TModel
    sx?: SxProps<Theme>
    disabled?: boolean
    InnerProps?: Omit<TPropCommon, keyof IFormBase<TModel>> & { innerRef?: (ref: any) => void }
  }

  const Template = CreateFormBase<TModel>()

  const Form: React.FC<IProps> = (props) => {
    const [isLoading, setIsLoading] = React.useState(false)
    const onSubmit = React.useCallback(
      async (p: Partial<TModel>) => {
        try {
          setIsLoading(true)
          await props.onSubmit(p)
        } catch {
        } finally {
          setIsLoading(false)
        }
      },
      [props]
    )
    return (
      <Template.Form
        {...{ sx: { '& .MuiFormHelperText-root.Mui-error': { color: '#d32f2f!important' }, ...props.sx } }}
        validate={FormValidate}
        onSubmit={onSubmit}
      >
        <Template.Context.Consumer>
          {({ onBlur, messageErrors: MessageError }) => {
            return (
              <FormInfo
                ref={props.InnerProps?.innerRef}
                {...(props.InnerProps ?? ({} as any))}
                onBlur={onBlur}
                MessageError={MessageError}
                data={props.data}
                disabled={isLoading || props.disabled}
              />
            )
          }}
        </Template.Context.Consumer>
      </Template.Form>
    )
  }
  return {
    ...Template,
    Form
  }
}
export default CreateFormSingleValidate

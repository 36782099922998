import type { FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';
import type {ETicketConversation} from '../enums';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface TicketConversationFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'TicketConversation', T, TVariables> {

    on<XName extends ImplementationType<'TicketConversation'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): TicketConversationFetcher<
        XName extends 'TicketConversation' ?
        T & X :
        WithTypeName<T, ImplementationType<'TicketConversation'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'TicketConversation'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): TicketConversationFetcher<T, TVariables>;


    readonly __typename: TicketConversationFetcher<T & {__typename: ImplementationType<'TicketConversation'>}, TVariables>;


    readonly id: TicketConversationFetcher<T & {readonly "id": string}, TVariables>;

    "id+"<
        XAlias extends string = "id", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"id", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): TicketConversationFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~id": TicketConversationFetcher<Omit<T, 'id'>, TVariables>;


    readonly ticketId: TicketConversationFetcher<T & {readonly "ticketId": string}, TVariables>;

    "ticketId+"<
        XAlias extends string = "ticketId", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"ticketId", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): TicketConversationFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~ticketId": TicketConversationFetcher<Omit<T, 'ticketId'>, TVariables>;


    readonly attachFile: TicketConversationFetcher<T & {readonly "attachFile"?: string}, TVariables>;

    "attachFile+"<
        XAlias extends string = "attachFile", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"attachFile", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): TicketConversationFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~attachFile": TicketConversationFetcher<Omit<T, 'attachFile'>, TVariables>;


    readonly message: TicketConversationFetcher<T & {readonly "message"?: string}, TVariables>;

    "message+"<
        XAlias extends string = "message", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"message", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): TicketConversationFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~message": TicketConversationFetcher<Omit<T, 'message'>, TVariables>;


    readonly type: TicketConversationFetcher<T & {readonly "type": ETicketConversation}, TVariables>;

    "type+"<
        XAlias extends string = "type", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"type", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): TicketConversationFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: ETicketConversation} : 
                {readonly [key in XAlias]: ETicketConversation}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~type": TicketConversationFetcher<Omit<T, 'type'>, TVariables>;


    readonly senderId: TicketConversationFetcher<T & {readonly "senderId"?: string}, TVariables>;

    "senderId+"<
        XAlias extends string = "senderId", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"senderId", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): TicketConversationFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~senderId": TicketConversationFetcher<Omit<T, 'senderId'>, TVariables>;


    ticket<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Ticket', X, XVariables>
    ): TicketConversationFetcher<
        T & {readonly "ticket"?: X}, 
        TVariables & XVariables
    >;

    ticket<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "ticket", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Ticket', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"ticket", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): TicketConversationFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    readonly dateCreated: TicketConversationFetcher<T & {readonly "dateCreated": string}, TVariables>;

    "dateCreated+"<
        XAlias extends string = "dateCreated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateCreated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): TicketConversationFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateCreated": TicketConversationFetcher<Omit<T, 'dateCreated'>, TVariables>;


    readonly dateUpdated: TicketConversationFetcher<T & {readonly "dateUpdated": string}, TVariables>;

    "dateUpdated+"<
        XAlias extends string = "dateUpdated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateUpdated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): TicketConversationFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateUpdated": TicketConversationFetcher<Omit<T, 'dateUpdated'>, TVariables>;
}

export const ticketConversation$: TicketConversationFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "TicketConversation", 
            "OBJECT", 
            [], 
            [
                {
                    category: "ID", 
                    name: "id"
                }, 
                "ticketId", 
                {
                    category: "SCALAR", 
                    name: "attachFile", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "message", 
                    undefinable: true
                }, 
                "type", 
                {
                    category: "SCALAR", 
                    name: "senderId", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "ticket", 
                    targetTypeName: "Ticket", 
                    undefinable: true
                }, 
                "dateCreated", 
                "dateUpdated"
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const ticketConversation$$ = 
    ticketConversation$
        .id
        .ticketId
        .attachFile
        .message
        .type
        .senderId
        .dateCreated
        .dateUpdated
;

import { CRUDServiceBase } from '@lib/Http'
import { ITableTemplateState } from '@lib/Table'
import { TableToRequestFilter } from '@lib/Helpers'
import { ETicketConversation, ETicketStatus, ETicketType, ITicket, ITicketConversation } from '@shared/Types'
import Graphql from '@coreprj/graphql'

export type TTicket = Graphql.QMediaStore.TTicket
export type TTicketConversation = Graphql.QMediaStore.TTicketConversation
type TTicketFilterParams = Graphql.QMediaStore.TTicketFilterParams

class TicketServiceBase extends CRUDServiceBase<ITicket, string> {
  constructor() {
    super(`/api/v1/user/Ticket`)
  }

  GraphQLSingle = async (params: TTicketFilterParams, signal?: AbortSignal): Promise<TTicket> => {
    const res = await Graphql.MediaStore.Query(Graphql.QMediaStore.UserTicketQuery(params), { signal, delay: 700 })
    return res.mediaStore?.ticket as TTicket
  }
}
const TicketService = new TicketServiceBase()
export default TicketService

class TicketMappingBase {
  tableInfoToFilter = (tableInfo: ITableTemplateState<ITicket>) => {
    return new TableToRequestFilter.Swagger<ITicket>({
      acceptedFileds: ['Subject', 'Type', 'DateCreated', 'DateUpdated', 'Status']
    })
      .fromTable(tableInfo, ['Subject', 'DisplayName'])
      .sort({ field: 'Id', sort: 'asc' })
      .build()
  }

  tickets = (items: TTicket[] = []): ITicket[] => {
    return items.map<ITicket>((item) => ({
      Id: item.id,
      Subject: item.subject ?? '',
      ResourceId: item.resourceId ?? '',
      Type: item.type as ETicketType,
      Status: item.status as ETicketStatus,
      UserCreatedId: item.userCreatedId,
      DisplayName: item.user?.displayName ?? '',
      DateCreated: item.dateCreated
    }))
  }

  ticketConversations = (userId: string, value?: TTicket): ITicketConversation[] => {
    const items = (value?.ticketConversations ?? []) as TTicketConversation[]
    return items.map<ITicketConversation>((item) => {
      const data: ITicketConversation = {
        Id: item?.id,
        AttachFile: item?.attachFile ?? '',
        Message: item?.message ?? '',
        Type: item?.type as ETicketConversation,
        SenderId: item?.senderId ?? '',
        IsMe: item.senderId === userId,
        From: item.senderId === userId ? 'Admin' : value?.user?.displayName ?? 'User',
        DateCreated: item.dateCreated
      }
      return data
    })
  }

  ticket = (userId: string, value?: TTicket): ITicket => {
    const item: ITicket = {
      Id: value?.id ?? '',
      Subject: value?.subject ?? '',
      ResourceId: value?.resourceId ?? '',
      Type: value?.type as ETicketType,
      Status: value?.status as ETicketStatus,
      UserCreatedId: value?.userCreatedId ?? '',
      DateCreated: value?.dateCreated ?? '',
      TicketConversations: this.ticketConversations(userId, value)
    }
    return item
  }
}
export const TicketMapping = new TicketMappingBase()

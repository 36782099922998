import type { FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';
import type {MediaType} from '../enums';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface CartResourceInfoFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'CartResourceInfo', T, TVariables> {

    on<XName extends ImplementationType<'CartResourceInfo'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): CartResourceInfoFetcher<
        XName extends 'CartResourceInfo' ?
        T & X :
        WithTypeName<T, ImplementationType<'CartResourceInfo'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'CartResourceInfo'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): CartResourceInfoFetcher<T, TVariables>;


    readonly __typename: CartResourceInfoFetcher<T & {__typename: ImplementationType<'CartResourceInfo'>}, TVariables>;


    readonly name: CartResourceInfoFetcher<T & {readonly "name"?: string}, TVariables>;

    "name+"<
        XAlias extends string = "name", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"name", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): CartResourceInfoFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~name": CartResourceInfoFetcher<Omit<T, 'name'>, TVariables>;


    readonly id: CartResourceInfoFetcher<T & {readonly "id"?: string}, TVariables>;

    "id+"<
        XAlias extends string = "id", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"id", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): CartResourceInfoFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~id": CartResourceInfoFetcher<Omit<T, 'id'>, TVariables>;


    readonly type: CartResourceInfoFetcher<T & {readonly "type": MediaType}, TVariables>;

    "type+"<
        XAlias extends string = "type", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"type", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): CartResourceInfoFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: MediaType} : 
                {readonly [key in XAlias]: MediaType}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~type": CartResourceInfoFetcher<Omit<T, 'type'>, TVariables>;
}

export const cartResourceInfo$: CartResourceInfoFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "CartResourceInfo", 
            "OBJECT", 
            [], 
            [
                {
                    category: "SCALAR", 
                    name: "name", 
                    undefinable: true
                }, 
                {
                    category: "ID", 
                    name: "id", 
                    undefinable: true
                }, 
                "type"
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const cartResourceInfo$$ = 
    cartResourceInfo$
        .name
        .id
        .type
;

import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Box, Container, Divider, Stack, Grid, styled, Typography } from '@mui/material'
import LanguageIcon from '@mui/icons-material/Language'
import AppConfigs from '../AppConfigs'

const CONFIGS = AppConfigs.footer

interface IProps {}

const AppFooter: FC<IProps> = () => {
  return (
    <footer>
      <Divider />
      <Container sx={{ py: '108px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Stack gap='25px' justifyContent='flex-start'>
              <Stack direction='row'>
                {CONFIGS.social.map((item, i) => (
                  <SocialIcon key={i} href={item.link} target='_blank'>
                    {item.icon}
                  </SocialIcon>
                ))}
              </Stack>
              <Stack direction='row' gap='5px' alignItems='center'>
                <LanguageIcon sx={{ color: '#606060' }} />
                <Text>English</Text>
              </Stack>
              <Box flex='1' minHeight='30px' />
            </Stack>
          </Grid>
          {CONFIGS.nav.map((config) => (
            <Grid key={config.title} item xs={6} md={2}>
              <Stack gap='10px' alignItems='flex-start'>
                <Title>{config.title}</Title>
                <Box height='5px' />
                {config.items.map((item, index) => (
                  <TextHover key={index} {...{ component: Link, to: item.key, onClick: item.onClick }}>
                    {item.name}
                  </TextHover>
                ))}
              </Stack>
            </Grid>
          ))}
          <Grid item xs={12} md={4}>
            <Stack gap='10px' sx={{ mt: { xs: '20px', sm: 0 } }}>
              <Title>About us</Title>
              <Box height='5px' />
              <Text>BERLINTOMEK is a company specializing in the sale and rental of videos and audios</Text>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={{ mt: '25px' }}>
            <CopyrightText>© 2024, BERLINTOMEK Inc.</CopyrightText>
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}
export default AppFooter

const CopyrightText = styled('p')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 400,
  color: '#606060'
})

const Title = styled('h3')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '18px',
  fontWeight: 700,
  textTransform: 'uppercase',
  color: '#606060'
})

const Text = styled(Typography)({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '18px',
  fontWeight: 500,
  color: '#0f0f0f'
})

const TextHover = styled(Text)({
  cursor: 'pointer',
  transition: 'linear 0.2s',
  color: '#606060',
  padding: '0 5px',
  borderBottom: '1px solid transparent',
  textDecoration: 'unset !important',
  '&:hover': {
    color: '#1976D2',
    padding: '0 7.5px',
    borderBottom: '1px solid #1976D2'
  }
})

const SocialIcon = styled('a')({
  textDecoration: 'none',
  cursor: 'pointer',
  padding: '5px 10px',
  '& svg': {
    transition: 'all linear 0.2s',
    color: '#606060'
  },
  '&:hover svg': {
    color: '#1976D2'
  }
})

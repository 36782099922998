import { ClickAwayListener, Box, ButtonGroup, IconButton, IconButtonProps } from '@mui/material'
import { Component } from 'react'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

interface IProps {
  onSubmit?: () => void
  disabled?: boolean
  InnerProps?: IconButtonProps
}
interface IState {
  isConfirm: boolean
}
class ButtonConfirmDelete extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { isConfirm: false }
  }
  handlingClickAway = () => {
    this.setState({ isConfirm: false })
  }
  handlingClick = () => {
    this.setState({ isConfirm: true })
  }
  handleSubmit = () => {
    this.setState({ isConfirm: false }, () => {
      this.props.onSubmit && this.props.onSubmit()
    })
  }
  render() {
    return (
      <ClickAwayListener onClickAway={this.handlingClickAway}>
        <Box sx={{ width: '72px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: '40px' }}>
          {!this.state.isConfirm && (
            <IconButton size='small' color='error' onClick={this.handlingClick} disabled={this.props.disabled} {...this.props.InnerProps}>
              <DeleteOutlineIcon fontSize='small' />
            </IconButton>
          )}
          {this.state.isConfirm && (
            <ButtonGroup variant='text' color='inherit' aria-label='button delete group'>
              <IconButton size='small' color='success' onClick={this.handleSubmit}>
                <DoneIcon fontSize='small' />
              </IconButton>
              <IconButton size='small' color='inherit' onClick={this.handlingClickAway}>
                <CloseIcon fontSize='small' />
              </IconButton>
            </ButtonGroup>
          )}
        </Box>
      </ClickAwayListener>
    )
  }
}
export default ButtonConfirmDelete

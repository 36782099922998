import { createSlice } from '@reduxjs/toolkit'
import { IProfileStateSlice } from '../type'
import { fetchProfileThunk, fetchProfileUpdateThunk } from './Thunks'
import { ELazyStatus } from '@lib/ReduxBase'

// Define the initial state using that type
const initialState: IProfileStateSlice = {
  Status: ELazyStatus.Loading,
  profileContentData: {}
}

const ProfileSlice = createSlice({
  name: 'ProfileSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Loaded
        state.profileContentData = action.payload.profileContentData
      })
      .addCase(fetchProfileThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Error
      })
      .addCase(fetchProfileThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Status = ELazyStatus.Loading
      })

    builder
      .addCase(fetchProfileUpdateThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Loaded
        if (action.payload) {
          state.profileContentData = { ...state.profileContentData, ...action.payload }
        }
      })
      .addCase(fetchProfileUpdateThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Loaded
      })
      .addCase(fetchProfileUpdateThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Status = ELazyStatus.Loading
      })
  }
})

export default ProfileSlice

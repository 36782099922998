import { IAccordionItem } from './ui.accordion'

export const FAQ_LIST: IAccordionItem[] = [
  {
    id: '3c1ed29d342f47deba32e9652f49cbf9',
    title: 'How do I purchase rights to use content in my repository?',
    description:
      'Once logged in, you can use our search tool to browse and select the content you want. We provide detailed instructions on how to purchase rights and licensing agreements.'
  },
  {
    id: '04953cf72531436391c5a2c35fc75b8c',
    title: 'How long does it take to resolve copyright violations?',
    description:
      'We are committed to resolving copyright violations promptly, depending on the complexity and the platform affected. We typically respond within a few business days.'
  },
  {
    id: '0240add98a0a4eeaa926ce51a21e883d',
    title: 'How to track revenue from licensing agreements?',
    description:
      'You will have access to detailed reports on revenue from your licensing and distribution agreements. We provide easy-to-use reporting tools so you can view statistics and analyze performance.'
  },
  {
    id: 'a32214a235464ff69912b590bd2e72a7',
    title: "How do I register for an account and submit my content?",
    description: 'You can register for an account and upload your content through our registration page. All content is carefully reviewed to ensure it meets our licensing standards.'
  },
  {
    id: 'a533724a1f5d4beaa51fe93d66a546b6',
    title: 'What types of content can I submit for licensing?',
    description:
      'You can submit videos, music, and other types of digital content that meet our licensing standards. Content will be reviewed based on quality, originality, and appropriateness.'
  },
  {
    id: '37af4caa74684374a6adab76307a634b',
    title: 'Is my content automatically protected by copyright after upload?',
    description:
      'Yes. When you upload content to our platform, our system automatically activates copyright protection measures to help you monitor and detect violations.'
  },
]

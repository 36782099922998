import { RootState } from '@internal/redux'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { MergeObjects, TableQueryParam } from '@lib/Helpers'
import { ICategory, IMedia, IMediaDelivery } from '@shared/Types'
import { MapTableData, MapTableInfo, TableReduxType } from '@lib/Table'
import { MediaDeliveryMapping } from '@internal/services/MediaDeliveryService'
import MediaService, { MediaMapping } from '@internal/services/MediaService'
import { DefaultTableInfo } from './redux.slice'

interface IResponseThunk extends TableReduxType.ThunkReponse<IMedia> {
  categories?: ICategory[]
  delivery?: IMediaDelivery
}
interface IParamsThunk extends TableReduxType.ThunkParam<IMedia> {
  deliveryId?: string
  clean?: boolean
}
export const fetchMediaThunk = createAsyncThunk<IResponseThunk, IParamsThunk>('fetchMediaThunk', async (param, thunkAPI) => {
  const state = thunkAPI.getState() as RootState
  const tableInfoState = MergeObjects(state.MediaSlice.tableInfo, param?.tableInfo)
  const tableInfoMap = MapTableInfo(tableInfoState, param.tableChange)

  const filter = MediaMapping.tableInfoToFilter(tableInfoMap, param.deliveryId)
  const res = await MediaService.AllGraphQL(filter, param.deliveryId, thunkAPI.signal)

  const tableInfo = MapTableData(tableInfoMap, { Data: MediaMapping.medias(res.medias?.items), Total: res.medias?.totalItems })
  if (!param.deliveryId) {
    TableQueryParam.setParam(tableInfo, DefaultTableInfo)
  }
  return {
    tableInfo,
    delivery: res.mediaDelivery ? MediaDeliveryMapping.delivery(res.mediaDelivery) : undefined,
    categories: MediaMapping.categories(res.categories?.items)
  }
})

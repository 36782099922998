import { RouteKey } from '@internal/route'
import { IMediaFilterDTO } from './types'
import { CreateRoutePath } from '@lib/RouteBase'

export const createExploreUri = (value: IMediaFilterDTO) => {
  return CreateRoutePath(RouteKey.Explore, { query: value })
}

interface IConstantDefault {
  page: number
  pageCount: number
}

export const ConstantDefault: IConstantDefault = {
  page: 1,
  pageCount: 12
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteKey } from '@internal/route'
import { Box, Typography } from '@mui/material'
import { CreateRoutePath } from '@lib/RouteBase'
import { ISelectMultipleOption } from '@lib/Forms/Inputs'
import { CreateHocLazy, ELazyStatus } from '@lib/ReduxBase'
import { MediaMapping } from '@internal/services/MediaService'
import { WindowScrollToTop, TableFormater } from '@lib/Helpers'
import { GetDeliveryId } from '@shared/Services/QueryParamService'
import { CreateTableTemplate, CRUDPannel, MapOprators } from '@lib/Table'
import { EMediaDeliveryStatus, EMediaDeliveryType, IMedia, IMediaDelivery } from '@shared/Types'
import { ApiAlertContext, GlobalModal, IGlobalModalContext, MapGlobalModalContext } from '@lib/ApiContext'
import { MediaActionPannel, MediaBtnLinks, MediaBtnUpload, MediaBtnUploadNote, MediaForms, MediaUI } from '@shared/Pages/Media'
import { DeliveryForms, DeliveryInfoBar, DeliveryInfoBarStatus, DeliveryStatusCell, TProgressItemUpload } from '@shared/Pages/Delivery'
import ContentPreview, { IItemInfo } from '@lib/Component/ContentPreview'
import UploadLayout, { IUploadLayoutContext, UploadLayoutContext } from '@lib/Layout/UploadLayout'
import UIStyleds from '@shared/UIStyleds'
import StatusCell from '@shared/Pages/Media/StatusCell'
import UploadService from '@shared/Services/UploadService'
import { IMediaReduxProps } from './redux.types'
import { mapDispatchToProps, mapStateToProps } from './redux.map'

const Table = CreateTableTemplate<IMedia>('Redux', {
  getRowId: (x) => x.Id,
  config: {
    Name: { type: 'string', flex: 3, minWidth: 200 },
    Extension: { type: 'string', flex: 1, minWidth: 110 },
    Type: { type: 'string', flex: 1, minWidth: 110, filterable: false },
    Size: {
      type: 'number',
      flex: 1,
      minWidth: 100,
      headerAlign: 'left',
      renderCell: (params) => (
        <Typography variant='subtitle2' sx={{ pr: '9px', textAlign: 'left', width: '100%' }}>
          {TableFormater.formatSize(params.value)}
        </Typography>
      )
    },
    Duration: { type: 'string', flex: 1, minWidth: 110, filterable: false, valueFormatter: (params) => TableFormater.formatDuration(params.value) },
    DateCreated: {
      type: 'string',
      headerName: 'Date created',
      minWidth: 190,
      filterable: false,
      renderCell: (params) => TableFormater.tooltipDate(params.value)
    },
    Status: { type: 'string', minWidth: 130, filterable: false, renderCell: (params) => <StatusCell data={params.value} /> }
  },
  filterOperators: MapOprators, //server mode,
  minWidthColumnActions: 110
})

class ViewBase extends Component<IMediaReduxProps> {
  refUploadLayout: UploadLayout | null = null
  refPreview: ContentPreview | null = null

  componentDidMount() {
    WindowScrollToTop()
  }

  componentWillUnmount(): void {
    this.props.clean()
  }

  render() {
    const deliveryId = GetDeliveryId()
    const isDeliveryDetail = !!deliveryId
    return (
      <>
        <UIStyleds.WrapProfile>
          {this.renderInfoBar()}
          <Table
            ReduxOption={this.props.tableInfo}
            onChange={this.props.onTableChange}
            CRUDPannel={!isDeliveryDetail ? () => <CRUDPannel Title='Medias' /> : undefined}
            ActionPannel={(p) => {
              const data = p.data as IMedia
              return <MediaActionPannel mediaType={data.Type} onPreview={() => this.onPreview(data)} />
            }}
          />
        </UIStyleds.WrapProfile>
        <ContentPreview ref={(ref) => (this.refPreview = ref)} />
      </>
    )
  }

  renderInfoBar = () => {
    const deliveryId = GetDeliveryId()
    const isDeliveryDetail = !!deliveryId
    if (!isDeliveryDetail) return <></>
    const deliveryStatus = this.props.stateRedux.delivery?.Status
    const contentType = this.props.stateRedux.delivery?.ContentType
    const isUpload = !!deliveryId && this.props.Status === ELazyStatus.Loaded
    const isDisabled = deliveryStatus !== EMediaDeliveryStatus.Pending
    return (
      <Box>
        <UploadLayout ref={(ref) => (this.refUploadLayout = ref)} UploadExecutor={this.handleUploadExecutor}>
          <GlobalModal>
            <MediaUI.TopBar type={contentType} backLink={CreateRoutePath([RouteKey.Profile, RouteKey.Delivery])} tooltipTitle='Back to Delivery' />
            <DeliveryInfoBar
              data={this.props.stateRedux.delivery}
              statusCell={(p) => {
                if (!p.data) return <></>
                return (
                  <>
                    <DeliveryInfoBarStatus>{!!p.data.Status && <DeliveryStatusCell data={p.data.Status} />}</DeliveryInfoBarStatus>
                    {MapGlobalModalContext((context) => (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '9px' }}>
                        {this.renderLinksModalBtn(context, p.data)}
                        <MediaBtnUpload onClick={isUpload && !isDisabled ? () => this.onUpload(context) : undefined} disabled={isDisabled} />
                        <MediaBtnUploadNote />
                      </Box>
                    ))}
                  </>
                )
              }}
            />
          </GlobalModal>
        </UploadLayout>
      </Box>
    )
  }

  renderLinksModalBtn = (context: IGlobalModalContext, data?: Partial<IMediaDelivery>) => {
    if (data?.ContentType !== EMediaDeliveryType.Url) return <></>
    return (
      <MediaBtnLinks
        onClick={() => {
          context.ShowModal({
            backdropActivated: true,
            ContentModal: () => <DeliveryForms.FormUrlTypePreview data={data} />
          })
        }}
      />
    )
  }

  onUpload = ({ ShowModal, CloseModal }: IGlobalModalContext) => {
    ShowModal({
      backdropActivated: true,
      ContentModal: () => (
        <UploadLayoutContext.Consumer>
          {(context) => (
            <MediaForms.FormUpload
              categories={this.props.stateRedux.categories}
              onSubmit={(value) => this.handleSubmitUpload(context, value, CloseModal)}
            />
          )}
        </UploadLayoutContext.Consumer>
      )
    })
  }
  onPreview = (value: IMedia) => {
    this.refPreview?.handleClickOpen({
      DriveFileId: value.ResourceId,
      DateCreated: '',
      Extension: value.Extension,
      Id: value.Id.toString(),
      Name: value.Name
    } as IItemInfo)
  }

  handleSubmitUpload = async (context: IUploadLayoutContext, value: Partial<MediaForms.IFormSubmitModelReponse>, close: () => void) => {
    let date = new Date().getTime()
    const { files = [], ...model } = value
    const temps: TProgressItemUpload[] = []
    files.forEach((e) => temps.push({ File: e, Id: date++, Name: e.name, Status: 'Pending', Other: model }))
    context.addItems(temps)
    context.Show()
    close()
  }
  handleUploadExecutor = async (processItem: TProgressItemUpload, progress: (value: number) => void) => {
    try {
      const fileType = MediaForms.FileHandler.getFileType(processItem)
      if (!fileType) {
        ApiAlertContext.ApiAlert?.PushWarning('Unsupported file format!')
        throw new Error('Unsupported file format!')
      }
      const extendFile = await MediaForms.FileHandler.getExtendFile(processItem)

      const bodyRequest = { file: processItem.File, FileType: fileType }
      const uploadResult = await UploadService.uploadRequest(bodyRequest, progress, processItem.Signal?.signal)
      if (!uploadResult) {
        throw new Error('Upload failed!')
      }

      const userId = processItem.Other?.UserId
      const categoryIds = this.getCategoryIds(processItem.Other?.Categories)
      this.props.CRUD.Create(MediaMapping.mediaCreateParam({ processItem, extendFile, uploadResult, fileType, userId, categoryIds }))
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  getCategoryIds = (value?: string): string[] => {
    try {
      if (!value) return []
      const list: ISelectMultipleOption[] = JSON.parse(value)
      return list.map((x) => x.Id.toString())
    } catch (error) {
      return []
    }
  }
}

export { fetchMediaThunk } from './redux.thunks'
export { default as MediaSlice } from './redux.slice'

const HocLazyInstance = CreateHocLazy(ViewBase)
export const MediaMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)
// export const MediaModal: React.FC = () => (
//   <LayoutModalRoute.Wrapper size='xl' title='Delivery detail' back={[RouteKey.Profile, RouteKey.Delivery]}>
//     <MediaMapRedux />
//   </LayoutModalRoute.Wrapper>
// )

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { IPrice } from '@shared/Types'
import { RouteKey } from '@internal/route'
import { PriceItem } from '@shared/Pages/Price'
import { CreateRoutePath } from '@lib/RouteBase'
import { Stack, Divider, Container, Box, Grid, styled } from '@mui/material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { IPricingConfigDTO } from '../../type'
import { PricingItemsSkeleton } from './Skeletons'

interface IProps {
  data: IPricingConfigDTO
  prices: IPrice[]
}

class PricingSection extends Component<IProps> {
  render() {
    const { info } = this.props.data
    return (
      <Box>
        <Divider />
        <Container>
          <Box sx={{ display: 'flex', alignItems: 'stretch' }}>
            <Box sx={{ borderRight: '1px solid rgba(0,0,0,0.12)', flexGrow: 1, py: '20px', minHeight: '150px' }}>
              <Title>{info.name ?? 'Package Title'}</Title>
              <Text sx={{ mt: '6px' }}>{info.description ?? 'Package Description'}</Text>
            </Box>
            <Stack minWidth='200px' p='20px' gap='5px' justifyContent='flex-end' alignItems='flex-start'>
              <Text sx={{ fontWeight: 700 }}>Read more</Text>
              <DescriptionLine id='btn-pp' {...{ component: Link, to: CreateRoutePath(RouteKey.PrivacyPolicy) }}>
                <Box component='span'> Privacy Policy</Box>
                <ArrowRightAltIcon />
              </DescriptionLine>
            </Stack>
          </Box>
        </Container>
        <Divider />
        {this.renderContent()}
      </Box>
    )
  }

  renderContent = () => {
    const data = this.props.prices
    if (data.length < 1) return <PricingItemsSkeleton />
    return (
      <Container sx={{ py: '54px' }}>
        <Grid container spacing={4}>
          {data.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <PriceItem data={item} />
            </Grid>
          ))}
        </Grid>
      </Container>
    )
  }
}

export default PricingSection

const Title = styled('h3')(({ theme }) => ({
  fontSize: '36px',
  fontWeight: 600,
  color: '#606060',
  [theme.breakpoints.down('md')]: {
    fontSize: '30px'
  }
}))

const Text = styled('span')({
  display: 'inline-block',
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 500,
  color: '#606060'
})

const DescriptionLine = styled(Box)({
  display: 'inline-flex',
  alignItems: 'center',
  padding: '5px 0 0',
  cursor: 'pointer',
  borderBottom: '1px solid #1976D2',
  color: '#1976D2',
  textDecoration: 'unset !important',
  '& span': {
    fontFamily: "'Exo 2', sans-serif",
    fontSize: '16px',
    textTransform: 'capitalize',
    fontWeight: '500'
  }
})

import { EProfileFormType, IProfileContentData } from '@shared/Pages/Profile'
import { IBusinessInfo, IPaymentInfo, IPersonalInfo, IProfile } from '@shared/Types'

interface IPersonalInfoUpdate {
  FirstName: string
  LastName: string
  DisplayName?: string
  Avatar?: string
  Birthday: string
  Address: string
  PhoneNumber: string
}

interface IBusinessInfoUpdate {
  CompanyName: string
  Position?: string
  Address?: string
  TaxId?: string
}

interface IPaymentUpdate {
  AccountNumber: string
  BankName: string
  IdentityCard: string
  SwiftNumber: string
  BeneficiaryName: string
  BankAddress: string
}

export default class DataMapping {
  static personalInfoUpdate = (item: Partial<IPersonalInfo>): IPersonalInfoUpdate => ({
    FirstName: item.FirstName ?? '',
    LastName: item.LastName ?? '',
    DisplayName: item.DisplayName,
    // Avatar: '',
    Birthday: item.Birthday ?? '',
    Address: item.Address ?? '',
    PhoneNumber: item.PhoneNumber ?? ''
  })

  static businessInfoUpdate = (item: Partial<IBusinessInfo>): IBusinessInfoUpdate => ({
    CompanyName: item.CompanyName ?? '',
    Position: item.Position ?? '',
    Address: item.Address ?? '',
    TaxId: item.TaxId ?? ''
  })

  static paymentInfoUpdate = (item: Partial<IPaymentInfo>): IPaymentUpdate => ({
    AccountNumber: item.AccountNumber ?? '',
    BankName: item.BankName ?? '',
    IdentityCard: item.IdentityCard ?? '',
    SwiftNumber: item.SwiftNumber ?? '',
    BeneficiaryName: item.BeneficiaryName ?? '',
    BankAddress: item.BankAddress ?? ''
  })

  static profileInfos = (value: IProfile): IProfileContentData => {
    const { BusinessInfo, PaymentInfo, ...personal } = value
    const data: IProfileContentData = {
      [EProfileFormType.Personal]: personal as IPersonalInfo,
      [EProfileFormType.Business]: BusinessInfo,
      [EProfileFormType.Payment]: PaymentInfo
    }
    return data
  }
}

import { RouteKey } from '@internal/route'
import * as IconsMaterial from '@mui/icons-material'

export interface IHeaderMenuConfig {
  title: string
  icon: JSX.Element
  bottomLine?: boolean
  key: RouteKey | RouteKey[]
}

export const ProfileMenuHeaderConfig: IHeaderMenuConfig[] = [
  { title: 'Home', key: RouteKey.Home, icon: <IconsMaterial.Home /> },
  { title: 'Explore', key: RouteKey.Explore, icon: <IconsMaterial.Explore />, bottomLine: true },
  { title: 'Profile', key: RouteKey.Profile, icon: <IconsMaterial.AccountCircleOutlined /> },
  { title: 'Orders', key: [RouteKey.Profile, RouteKey.Order], icon: <IconsMaterial.History /> },
  { title: 'Submissions', key: [RouteKey.Profile, RouteKey.Submission], icon: <IconsMaterial.Dns /> },
  { title: 'Medias', key: [RouteKey.Profile, RouteKey.Media], icon: <IconsMaterial.Movie /> },
  { title: 'Deliveries', key: [RouteKey.Profile, RouteKey.Delivery], icon: <IconsMaterial.AllInbox /> },
  { title: 'Billings', key: [RouteKey.Profile, RouteKey.Billing], icon: <IconsMaterial.RequestQuote /> },
  { title: 'Tickets', key: [RouteKey.Profile, RouteKey.Ticket], icon: <IconsMaterial.BugReport />, bottomLine: true },
  { title: 'Privacy Policy', key: RouteKey.PrivacyPolicy, icon: <IconsMaterial.Security /> },
  { title: 'Terms of Submission', key: RouteKey.TermsOfSubmission, icon: <IconsMaterial.Task />, bottomLine: true },
  { title: 'About us', key: [RouteKey.Profile, RouteKey.AboutUs], icon: <IconsMaterial.ErrorOutline /> }
]

interface IProfileMenuConfig {
  icon?: JSX.Element
  title: string
  key: '/' | RouteKey
}

export const ProfileMenuConfig: IProfileMenuConfig[] = [
  { title: 'Profile', key: '/', icon: <IconsMaterial.AccountCircleOutlined /> },
  { title: 'Orders', key: RouteKey.Order, icon: <IconsMaterial.History /> },
  { title: 'Submissions', key: RouteKey.Submission, icon: <IconsMaterial.Dns /> },
  { title: 'Medias', key: RouteKey.Media, icon: <IconsMaterial.Movie /> },
  { title: 'Deliveries', key: RouteKey.Delivery, icon: <IconsMaterial.AllInbox /> },
  { title: 'Billings', key: RouteKey.Billing, icon: <IconsMaterial.RequestQuote /> },
  { title: 'Tickets', key: RouteKey.Ticket, icon: <IconsMaterial.BugReport /> }
]

import { FormValidator, SingleRuleValidate } from '@lib/Forms'
import { ICartItemFormDTO } from '../../type'
import Helpers from '../../helpers'

export const getFormValidate = () => {
  return new FormValidator<Partial<ICartItemFormDTO>>({
    PasteSingleLink: {
      Rules: [
        {
          rule: SingleRuleValidate.Custom,
          message: 'Please add at least 1 link',
          Value: (value: string, model: ICartItemFormDTO) => {
            const list = Helpers.linksToArray(model.PasteLinks)
            return !value && list.length <= 0
          }
        },
        // {
        //   rule: SingleRuleValidate.Custom,
        //   message: 'The maximum number of links cannot exceed 10',
        //   Value: (value: string, model: ICartItemFormDTO) => {
        //     if (!value) return false
        //     return Helpers.linksToArray(value).length > Helpers.maxLink
        //   },
        // },
        {
          rule: SingleRuleValidate.Custom,
          message: 'Links cannot be duplicated',
          Value: (value: string, model: ICartItemFormDTO) => {
            if (!value) return false
            return Helpers.isDuplicatedLink(model.PasteLinks, value)
          }
        },
        {
          rule: SingleRuleValidate.Custom,
          message: 'Link invalid',
          Value: (value: string, model: ICartItemFormDTO) => {
            if (!value) return false
            const arr = Helpers.linksToArray(value)
            return arr.length < 1
          }
        }
      ]
    }
  })
}

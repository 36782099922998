import type { AcceptableVariables, UnresolvedVariables, FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface CategoryPageFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'CategoryPage', T, TVariables> {

    on<XName extends ImplementationType<'CategoryPage'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): CategoryPageFetcher<
        XName extends 'CategoryPage' ?
        T & X :
        WithTypeName<T, ImplementationType<'CategoryPage'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'CategoryPage'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): CategoryPageFetcher<T, TVariables>;


    readonly __typename: CategoryPageFetcher<T & {__typename: ImplementationType<'CategoryPage'>}, TVariables>;


    item<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Category', X, XVariables>
    ): CategoryPageFetcher<
        T & {readonly "item"?: X}, 
        TVariables & XVariables & CategoryPageArgs["item"]
    >;

    item<
        XArgs extends AcceptableVariables<CategoryPageArgs['item']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'Category', X, XVariables>
    ): CategoryPageFetcher<
        T & {readonly "item"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, CategoryPageArgs['item']>
    >;

    item<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "item", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Category', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"item", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): CategoryPageFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & CategoryPageArgs["item"] & XDirectiveVariables
    >;

    item<
        XArgs extends AcceptableVariables<CategoryPageArgs['item']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "item", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'Category', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"item", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): CategoryPageFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, CategoryPageArgs['item']> & XDirectiveVariables
    >;


    items<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Category', X, XVariables>
    ): CategoryPageFetcher<
        T & {readonly "items": ReadonlyArray<X>}, 
        TVariables & XVariables
    >;

    items<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "items", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Category', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"items", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): CategoryPageFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: ReadonlyArray<X>} : 
                {readonly [key in XAlias]: ReadonlyArray<X>}
        ), 
        TVariables & XVariables & XDirectiveVariables
    >;


    readonly hasPreviousPage: CategoryPageFetcher<T & {readonly "hasPreviousPage": boolean}, TVariables>;

    "hasPreviousPage+"<
        XAlias extends string = "hasPreviousPage", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"hasPreviousPage", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): CategoryPageFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: boolean} : 
                {readonly [key in XAlias]: boolean}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~hasPreviousPage": CategoryPageFetcher<Omit<T, 'hasPreviousPage'>, TVariables>;


    readonly hasNextPage: CategoryPageFetcher<T & {readonly "hasNextPage": boolean}, TVariables>;

    "hasNextPage+"<
        XAlias extends string = "hasNextPage", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"hasNextPage", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): CategoryPageFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: boolean} : 
                {readonly [key in XAlias]: boolean}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~hasNextPage": CategoryPageFetcher<Omit<T, 'hasNextPage'>, TVariables>;


    readonly totalItems: CategoryPageFetcher<T & {readonly "totalItems": number}, TVariables>;

    "totalItems+"<
        XAlias extends string = "totalItems", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"totalItems", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): CategoryPageFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: number} : 
                {readonly [key in XAlias]: number}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~totalItems": CategoryPageFetcher<Omit<T, 'totalItems'>, TVariables>;
}

export const categoryPage$: CategoryPageFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "CategoryPage", 
            "EMBEDDED", 
            [], 
            [
                {
                    category: "REFERENCE", 
                    name: "item", 
                    argGraphQLTypeMap: {id: 'ID!'}, 
                    targetTypeName: "Category", 
                    undefinable: true
                }, 
                {
                    category: "LIST", 
                    name: "items", 
                    targetTypeName: "Category"
                }, 
                "hasPreviousPage", 
                "hasNextPage", 
                "totalItems"
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const categoryPage$$ = 
    categoryPage$
        .hasPreviousPage
        .hasNextPage
        .totalItems
;

export interface CategoryPageArgs {

    readonly item: {
        readonly id: string
    }
}

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { RouteKey } from '@internal/route'
import { CreateRoutePath } from '@lib/RouteBase'
import { Box, Button, Grid } from '@mui/material'
import { IMedia, IPrice, EMediaType, EPriceType } from '@shared/Types'
import { MediaItemAudio, MediaItemVideo } from '@internal/shareds/MediaItem'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { IMediaDTO } from './types'

interface IProps {
  mediaType: EMediaType
  mediaPlayed?: IMediaDTO
  medias: IMediaDTO[]
  prices: IPrice[]
  onAddToCart: (media: IMedia, price: IPrice) => void
}

export default class UIRecommendedMedias extends Component<IProps> {
  render() {
    if (this.props.medias.length <= 0) return <React.Fragment />
    const pathToExplore = CreateRoutePath(RouteKey.Explore, { query: { mediaType: this.props.mediaType } })
    return (
      <Grid container spacing={2} sx={{ backgroundColor: '#fff' }}>
        {this.props.medias.map((item, index) => {
          return <React.Fragment key={item.Name + index}>{this.renderItem(item)}</React.Fragment>
        })}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '18px' }}>
            <Button
              color='primary'
              sx={{ color: '#1976D2', fontWeight: 600, width: '120px' }}
              endIcon={<KeyboardDoubleArrowRightIcon />}
              component={Link}
              to={pathToExplore}
            >
              More
            </Button>
          </Box>
        </Grid>
      </Grid>
    )
  }

  renderItem = (item: IMedia) => {
    const prices = this.getPrices()
    const isPlaying = item.Id === this.props.mediaPlayed?.Id
    switch (item.Type) {
      case EMediaType.Audio:
        return (
          <Grid item xs={12}>
            <MediaItemAudio isPlaying={isPlaying} data={item} prices={prices} onAddToCart={this.props.onAddToCart} />
          </Grid>
        )
      case EMediaType.Video:
        return (
          <Grid item xs={12} sm={4} md={3}>
            <MediaItemVideo data={item} prices={prices} onAddToCart={this.props.onAddToCart} />
          </Grid>
        )
      default:
        return <></>
    }
  }

  getPrices = () => {
    return this.props.prices.filter((x) => x.Type === EPriceType.Media)
  }
}

import React, { Component, FC } from 'react'
import { Link } from 'react-router-dom'
import { IconButton, Tooltip } from '@mui/material'
import { EBillingStatus } from '@shared/Types'
import ListAltIcon from '@mui/icons-material/ListAlt'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'

interface IProps {
  to: string
  target?: React.HTMLAttributeAnchorTarget
  disabled?: boolean
}

export class BillingButtonDetail extends Component<IProps> {
  render() {
    return (
      <Tooltip title={this.props.disabled === true ? 'No detail' : 'Detail'}>
        <div>
          <IconButton
            disabled={this.props.disabled}
            color='info'
            {...(this.props.disabled === true ? {} : { component: Link, to: this.props.to, target: this.props.target })}
            sx={{ cursor: 'pointer' }}
          >
            <ListAltIcon />
          </IconButton>
        </div>
      </Tooltip>
    )
  }
}

export const BillingButtonPayment: FC<IProps & { status: EBillingStatus }> = (props) => {
  return (
    <IconButton sx={{ cursor: 'pointer' }} {...{ component: Link, to: props.to, target: props.target }}>
      <MonetizationOnIcon sx={{ color: props.status === EBillingStatus.Paid ? '#357a38' : 'goldenrod' }} />
    </IconButton>
  )
}

import React, { Component } from 'react'
import { MenuProps, Menu, alpha, Button, styled, CircularProgress, Typography, MenuItem, SxProps, Theme } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { TStatusCellConfig } from './CreateStatusCell'

interface IOptions<EModel extends string> {
  name: string
  value: EModel
}

interface IProps<EModel extends string> {
  styleds: TStatusCellConfig<EModel>
  onChangeValue: (value: EModel) => Promise<void>
  options: IOptions<EModel>[]
  defaultValue?: EModel
  sx?: SxProps<Theme>
}
interface IState<EModel extends string> {
  anchorEl: HTMLElement | null
  selected?: EModel
  loading: boolean
}

const CreateSelectSimpleLoading = function <EModel extends string>() {
  class ButtonLoading extends Component<IProps<EModel>, IState<EModel>> {
    constructor(props: IProps<EModel>) {
      super(props)
      this.state = {
        anchorEl: null,
        selected: props.defaultValue,
        loading: false
      }
    }
    render() {
      if (!this.state.selected) return <></>
      const open = Boolean(this.state.anchorEl)
      const sx = this.props.styleds[this.state.selected]
      return (
        <>
          <ButtonCustom
            id='bds-button'
            aria-controls={open ? 'bds-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            variant='contained'
            size='small'
            disableElevation
            onClick={this.showMenu}
            endIcon={this.state.loading ? <CircularProgress size='18px' /> : <KeyboardArrowDownIcon />}
            sx={Object.assign({}, sx, this.props.sx)}
          >
            <Typography variant='subtitle2' component='span'>
              {this.state.selected}
            </Typography>
          </ButtonCustom>
          <StyledMenu
            id='bds-menu'
            MenuListProps={{ 'aria-labelledby': 'bds-button' }}
            anchorEl={this.state.anchorEl}
            open={open}
            onClose={this.closeMenu}
          >
            {this.props.options.map((item, index) => {
              const isActived = item.value === this.state.selected
              return (
                <MenuItem key={index} onClick={() => this.handleChange(item.value)} disableRipple disabled={isActived}>
                  {item.name}
                </MenuItem>
              )
            })}
          </StyledMenu>
        </>
      )
    }

    showMenu = (event: React.MouseEvent<HTMLElement>) => this.setState({ anchorEl: event.currentTarget })
    closeMenu = () => this.setState({ anchorEl: null })

    private clicked?: boolean
    handleChange = (value: string) => {
      if (this.clicked === false) return
      this.clicked = false
      this.setState({ anchorEl: null, loading: true }, async () => {
        try {
          await this.props.onChangeValue(value as EModel)
          this.setState({ selected: value as EModel, anchorEl: null })
        } catch (error) {
          console.log(error)
        } finally {
          this.setState({ loading: false })
        }
        this.clicked = true
      })
    }
  }
  return ButtonLoading
}
export default CreateSelectSimpleLoading

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': { padding: '4px 0' },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}))

const ButtonCustom = styled(Button)({
  textTransform: 'unset',
  fontWeight: 600,
  justifyContent: 'space-between',
  '& .MuiTypography-root': {
    minWidth: '52px',
    flex: '0 0 auto',
    display: 'inline-block',
    textAlign: 'start'
  }
})

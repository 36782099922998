import React, { Component } from 'react'
import { Box, IconButton, Tooltip, TooltipProps } from '@mui/material'
import copy from 'clipboard-copy'
import DoneIcon from '@mui/icons-material/Done'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

interface ICopyToClipboardProps {
  copied: boolean
  copy: () => void
}
interface IProps {
  value: string
  tooltipProps?: Partial<TooltipProps>
  iconButton?: React.ComponentType<ICopyToClipboardProps>
}
interface IState {
  showTooltip: boolean
  copied: boolean
}
class CopyToClipboard extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { showTooltip: false, copied: false }
  }
  render() {
    const leaveDelay = this.state.copied ? this.debounceTime.time - 200 : 0
    const title = this.state.copied ? 'Copied to clipboard!' : 'Copy to clipboard'
    return (
      <Tooltip
        open={this.state.showTooltip}
        title={title}
        leaveDelay={leaveDelay}
        onOpen={() => this.setState({ showTooltip: true })}
        onClose={() => this.setState({ showTooltip: false })}
        {...this.props.tooltipProps}
      >
        <Box>
          <IconButtonInside
            copy={() => {
              this.debounceTime.start()
              this.onCopy()
            }}
            copied={this.state.copied}
          />
        </Box>
      </Tooltip>
    )
  }
  debounceTime = {
    cache: 0 as any,
    time: 1500,
    start: () => {
      this.debounceTime.clear()
      this.setState({ copied: true }, () => {
        this.debounceTime.cache = setTimeout(() => {
          this.setState({ copied: false })
        }, this.debounceTime.time)
      })
    },
    clear: () => {
      clearTimeout(this.debounceTime.cache)
    }
  }
  private onCopy = () => {
    copy(this.props.value)
    this.setState({ showTooltip: true })
  }
}
export default CopyToClipboard

class IconButtonInside extends Component<ICopyToClipboardProps> {
  render() {
    const Icon = this.props.copied ? DoneIcon : ContentCopyIcon
    return (
      <IconButton onClick={this.props.copy}>
        <Icon fontSize='small' sx={{ color: '#606060' }} />
      </IconButton>
    )
  }
}

import { IBusinessInfo, IPaymentInfo, IPersonalInfo } from '@shared/Types'

export enum EProfileFormType {
  Business = 'Business',
  Personal = 'Personal',
  Payment = 'Payment'
}

export interface IProfileContentData {
  [EProfileFormType.Personal]?: IPersonalInfo
  [EProfileFormType.Business]?: IBusinessInfo
  [EProfileFormType.Payment]?: IPaymentInfo
}

export type TUpdateProfileContentParams =
  | { key: EProfileFormType.Personal; value: Partial<IPersonalInfo> }
  | { key: EProfileFormType.Business; value: Partial<IBusinessInfo> }
  | { key: EProfileFormType.Payment; value: Partial<IPaymentInfo> }

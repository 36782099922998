import { RouteKey } from '@internal/route'
import { useLocation } from 'react-router-dom'
import { CreateRoutePath } from '@lib/RouteBase'

export const useCurrentChecking = () => {
  const location = useLocation()
  return (rk: RouteKey): { className?: string } => {
    if (location.pathname.includes(rk)) return { className: 'active' }
    return {}
  }
}

export const useCurrentIsProfile = () => {
  const location = useLocation()
  return location.pathname.includes(RouteKey.Profile)
}

export const useCurrentMenuChecking = () => {
  const location = useLocation()
  return (rk: RouteKey | RouteKey[]): { className?: string } => {
    if (Array.isArray(rk)) {
      const path = CreateRoutePath(rk)
      const isCurrent = location.pathname.startsWith(path)
      if (!!isCurrent) {
        return { className: 'active' }
      }
    } else if (rk === RouteKey.Home) {
      const isHome = location.pathname === CreateRoutePath(RouteKey.Home)
      if (!!isHome) {
        return { className: 'active' }
      }
    } else if (rk === RouteKey.Profile) {
      const isProfile = location.pathname === CreateRoutePath(RouteKey.Profile)
      if (!!isProfile) {
        return { className: 'active' }
      }
    } else if (location.pathname.includes(rk)) {
      return { className: 'active' }
    }
    return {}
  }
}

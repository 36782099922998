import { ELazyStatus } from '@lib/ReduxBase'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { buildThunkGlobal } from '../Helper'

import { fetchBillingThunk } from '../../Views/Billing'
import { fetchBillingDetailThunk } from '../../Views/BillingDetail'
import { fetchCartThunk, fetchCheckOutValidateThunk } from '../../Views/Cart'
import { fetchCheckOutThunk } from '../../Views/CheckOut'
import { fetchDeliveryThunk } from '../../Views/Delivery'
import { fetchExploreThunk } from '../../Views/Explore'
import { fetchHomeThunk } from '../../Views/Home'
import { fetchMediaThunk } from '../../Views/Media'
import { fetchMediaDetailThunk } from '../../Views/MediaDetail'
import { fetchNotificationThunk } from '../../Views/Notification'
import { fetchOrderThunk } from '../../Views/Order'
import { fetchOrderDetailThunk } from '../../Views/OrderDetail'
import { fetchPricingThunk } from '../../Views/Pricing'
import { fetchProfileThunk, fetchProfileUpdateThunk } from '../../Views/Profile'
import { fetchSubmissionThunk } from '../../Views/Submission'
import { fetchSubmissionDetailThunk } from '../../Views/SubmissionDetail'
import { fetchTicketThunk } from '../../Views/Ticket'
import { fetchTicketDetailThunk } from '../../Views/TicketDetail'

export interface LoadingAppState {
  Status: ELazyStatus
}

export interface AppStatusState extends LoadingAppState {}
// Define the initial state using that type
const initialState: AppStatusState = {
  Status: ELazyStatus.Loaded
}

const AppStatusSlice = createSlice({
  name: 'AppStatusSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    changeLoading: (state, action: PayloadAction<ELazyStatus>) => {
      state.Status = action.payload
    }
  },
  extraReducers: (builder) => {
    buildThunkGlobal(builder, fetchBillingThunk)
    buildThunkGlobal(builder, fetchBillingDetailThunk)
    buildThunkGlobal(builder, fetchCartThunk)
    buildThunkGlobal(builder, fetchCheckOutValidateThunk)
    buildThunkGlobal(builder, fetchCheckOutThunk)
    buildThunkGlobal(builder, fetchDeliveryThunk)
    buildThunkGlobal(builder, fetchExploreThunk)
    buildThunkGlobal(builder, fetchHomeThunk)
    buildThunkGlobal(builder, fetchMediaThunk)
    buildThunkGlobal(builder, fetchMediaDetailThunk)
    buildThunkGlobal(builder, fetchNotificationThunk)
    buildThunkGlobal(builder, fetchOrderThunk)
    buildThunkGlobal(builder, fetchOrderDetailThunk)
    buildThunkGlobal(builder, fetchPricingThunk)
    buildThunkGlobal(builder, fetchProfileThunk)
    buildThunkGlobal(builder, fetchProfileUpdateThunk)
    buildThunkGlobal(builder, fetchSubmissionThunk)
    buildThunkGlobal(builder, fetchSubmissionDetailThunk)
    buildThunkGlobal(builder, fetchTicketThunk)
    buildThunkGlobal(builder, fetchTicketDetailThunk)
  }
})
export default AppStatusSlice

import React, { Component } from 'react'
import { RouteKey } from '@internal/route'
import { CreateRoutePath } from '@lib/RouteBase'
import { CreateDatePicker } from '@lib/Forms/Inputs'
import { Box, styled, Typography } from '@mui/material'
import { withNavigate, INavigateProps } from '@lib/Hocs'
import { ISubmission, ESubmissionStep } from '@shared/Types'
import { CreateFormGridLayout, FormValidator, SingleRuleValidate } from '@lib/Forms'
import ActionsBar from './UI/ActionsBar'
import LocalStorageSubmisstion from './Helpers/LocalStorageSubmisstion'

const DatePickerComponent = CreateDatePicker<ISubmission>()

const FormGridLayoutComponent = CreateFormGridLayout<ISubmission>({
  configs: [
    { key: 'Email', label: 'Email' },
    { key: 'FirstName', label: 'First name', reponsives: { md: 6 } },
    { key: 'LastName', label: 'Last name', reponsives: { md: 6 } },
    { key: 'PhoneNumber', label: 'Phone number', reponsives: { md: 6 } },
    { key: 'Birthday', label: 'Birthday', reponsives: { md: 6 }, inputElement: DatePickerComponent }
  ],
  validate: new FormValidator({
    Email: { Rules: [{ rule: SingleRuleValidate.Required }, { rule: SingleRuleValidate.Email }] },
    LastName: { Rules: [{ rule: SingleRuleValidate.Required }] },
    FirstName: { Rules: [{ rule: SingleRuleValidate.Required }] },
    PhoneNumber: {
      Rules: [{ rule: SingleRuleValidate.Required }, { rule: SingleRuleValidate.PhoneNumber }]
    },
    Birthday: { Rules: [{ rule: SingleRuleValidate.Required }] }
  })
})

interface IProps extends INavigateProps {}
class StepAboutYouself extends Component<IProps> {
  render() {
    const data = LocalStorageSubmisstion.getData()
    return (
      <Box sx={{ marginTop: '48px' }}>
        <Title variant='h6'>Tell us more about yourself</Title>
        <FormGridLayoutComponent
          data={data?.item}
          onSubmit={async (value) => {
            LocalStorageSubmisstion.setData(Object.assign({}, data?.item, value), ESubmissionStep.AboutYouself)
            this.props.navigate(CreateRoutePath([RouteKey.Submit, RouteKey.SMTCopyrightOwnership]))
          }}
          slots={{
            sx: { minHeight: '50vh', display: 'flex', alignItems: 'stretch', justifyContent: 'center', flexDirection: 'column' },
            action: (p) => <ActionsBar {...p} onPrev={() => this.props.navigate(CreateRoutePath([RouteKey.Submit, RouteKey.SMTAboutMedia]))} />
          }}
        />
      </Box>
    )
  }
}
export default withNavigate(StepAboutYouself)

const Title = styled(Typography)({
  textTransform: 'uppercase',
  color: '#606060',
  textAlign: 'center',
  fontWeight: 600
})

import type { AcceptableVariables, UnresolvedVariables, FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface ApplicationUserFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'ApplicationUser', T, TVariables> {

    on<XName extends ImplementationType<'ApplicationUser'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): ApplicationUserFetcher<
        XName extends 'ApplicationUser' ?
        T & X :
        WithTypeName<T, ImplementationType<'ApplicationUser'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'ApplicationUser'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): ApplicationUserFetcher<T, TVariables>;


    readonly __typename: ApplicationUserFetcher<T & {__typename: ImplementationType<'ApplicationUser'>}, TVariables>;


    readonly lastName: ApplicationUserFetcher<T & {readonly "lastName"?: string}, TVariables>;

    "lastName+"<
        XAlias extends string = "lastName", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"lastName", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~lastName": ApplicationUserFetcher<Omit<T, 'lastName'>, TVariables>;


    readonly firstName: ApplicationUserFetcher<T & {readonly "firstName"?: string}, TVariables>;

    "firstName+"<
        XAlias extends string = "firstName", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"firstName", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~firstName": ApplicationUserFetcher<Omit<T, 'firstName'>, TVariables>;


    readonly displayName: ApplicationUserFetcher<T & {readonly "displayName"?: string}, TVariables>;

    "displayName+"<
        XAlias extends string = "displayName", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"displayName", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~displayName": ApplicationUserFetcher<Omit<T, 'displayName'>, TVariables>;


    readonly avatar: ApplicationUserFetcher<T & {readonly "avatar"?: string}, TVariables>;

    "avatar+"<
        XAlias extends string = "avatar", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"avatar", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~avatar": ApplicationUserFetcher<Omit<T, 'avatar'>, TVariables>;


    readonly dateCreated: ApplicationUserFetcher<T & {readonly "dateCreated": string}, TVariables>;

    "dateCreated+"<
        XAlias extends string = "dateCreated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateCreated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateCreated": ApplicationUserFetcher<Omit<T, 'dateCreated'>, TVariables>;


    readonly birthday: ApplicationUserFetcher<T & {readonly "birthday": string}, TVariables>;

    "birthday+"<
        XAlias extends string = "birthday", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"birthday", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~birthday": ApplicationUserFetcher<Omit<T, 'birthday'>, TVariables>;


    readonly address: ApplicationUserFetcher<T & {readonly "address"?: string}, TVariables>;

    "address+"<
        XAlias extends string = "address", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"address", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~address": ApplicationUserFetcher<Omit<T, 'address'>, TVariables>;


    readonly isInActive: ApplicationUserFetcher<T & {readonly "isInActive": boolean}, TVariables>;

    "isInActive+"<
        XAlias extends string = "isInActive", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"isInActive", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: boolean} : 
                {readonly [key in XAlias]: boolean}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~isInActive": ApplicationUserFetcher<Omit<T, 'isInActive'>, TVariables>;


    readonly gender: ApplicationUserFetcher<T & {readonly "gender": boolean}, TVariables>;

    "gender+"<
        XAlias extends string = "gender", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"gender", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: boolean} : 
                {readonly [key in XAlias]: boolean}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~gender": ApplicationUserFetcher<Omit<T, 'gender'>, TVariables>;


    readonly passwordRamdom: ApplicationUserFetcher<T & {readonly "passwordRamdom"?: string}, TVariables>;

    "passwordRamdom+"<
        XAlias extends string = "passwordRamdom", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"passwordRamdom", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~passwordRamdom": ApplicationUserFetcher<Omit<T, 'passwordRamdom'>, TVariables>;


    businessInformationById<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'BusinessInformation', X, XVariables>
    ): ApplicationUserFetcher<
        T & {readonly "businessInformationById"?: X}, 
        TVariables & XVariables & ApplicationUserArgs["businessInformationById"]
    >;

    businessInformationById<
        XArgs extends AcceptableVariables<ApplicationUserArgs['businessInformationById']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'BusinessInformation', X, XVariables>
    ): ApplicationUserFetcher<
        T & {readonly "businessInformationById"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, ApplicationUserArgs['businessInformationById']>
    >;

    businessInformationById<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "businessInformationById", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'BusinessInformation', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"businessInformationById", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & ApplicationUserArgs["businessInformationById"] & XDirectiveVariables
    >;

    businessInformationById<
        XArgs extends AcceptableVariables<ApplicationUserArgs['businessInformationById']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "businessInformationById", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'BusinessInformation', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"businessInformationById", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, ApplicationUserArgs['businessInformationById']> & XDirectiveVariables
    >;


    businessInformation<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'BusinessInformation', X, XVariables>
    ): ApplicationUserFetcher<
        T & {readonly "businessInformation"?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables
    >;

    businessInformation<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "businessInformation", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'BusinessInformation', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"businessInformation", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    paymentInformationById<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'PaymentInformation', X, XVariables>
    ): ApplicationUserFetcher<
        T & {readonly "paymentInformationById"?: X}, 
        TVariables & XVariables & ApplicationUserArgs["paymentInformationById"]
    >;

    paymentInformationById<
        XArgs extends AcceptableVariables<ApplicationUserArgs['paymentInformationById']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'PaymentInformation', X, XVariables>
    ): ApplicationUserFetcher<
        T & {readonly "paymentInformationById"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, ApplicationUserArgs['paymentInformationById']>
    >;

    paymentInformationById<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "paymentInformationById", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'PaymentInformation', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"paymentInformationById", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & ApplicationUserArgs["paymentInformationById"] & XDirectiveVariables
    >;

    paymentInformationById<
        XArgs extends AcceptableVariables<ApplicationUserArgs['paymentInformationById']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "paymentInformationById", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'PaymentInformation', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"paymentInformationById", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, ApplicationUserArgs['paymentInformationById']> & XDirectiveVariables
    >;


    paymentInformation<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'PaymentInformation', X, XVariables>
    ): ApplicationUserFetcher<
        T & {readonly "paymentInformation"?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables
    >;

    paymentInformation<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "paymentInformation", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'PaymentInformation', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"paymentInformation", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    readonly id: ApplicationUserFetcher<T & {readonly "id": string}, TVariables>;

    "id+"<
        XAlias extends string = "id", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"id", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~id": ApplicationUserFetcher<Omit<T, 'id'>, TVariables>;


    readonly userName: ApplicationUserFetcher<T & {readonly "userName"?: string}, TVariables>;

    "userName+"<
        XAlias extends string = "userName", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"userName", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~userName": ApplicationUserFetcher<Omit<T, 'userName'>, TVariables>;


    readonly normalizedUserName: ApplicationUserFetcher<T & {readonly "normalizedUserName"?: string}, TVariables>;

    "normalizedUserName+"<
        XAlias extends string = "normalizedUserName", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"normalizedUserName", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~normalizedUserName": ApplicationUserFetcher<Omit<T, 'normalizedUserName'>, TVariables>;


    readonly email: ApplicationUserFetcher<T & {readonly "email"?: string}, TVariables>;

    "email+"<
        XAlias extends string = "email", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"email", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~email": ApplicationUserFetcher<Omit<T, 'email'>, TVariables>;


    readonly normalizedEmail: ApplicationUserFetcher<T & {readonly "normalizedEmail"?: string}, TVariables>;

    "normalizedEmail+"<
        XAlias extends string = "normalizedEmail", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"normalizedEmail", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~normalizedEmail": ApplicationUserFetcher<Omit<T, 'normalizedEmail'>, TVariables>;


    readonly emailConfirmed: ApplicationUserFetcher<T & {readonly "emailConfirmed": boolean}, TVariables>;

    "emailConfirmed+"<
        XAlias extends string = "emailConfirmed", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"emailConfirmed", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: boolean} : 
                {readonly [key in XAlias]: boolean}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~emailConfirmed": ApplicationUserFetcher<Omit<T, 'emailConfirmed'>, TVariables>;


    readonly passwordHash: ApplicationUserFetcher<T & {readonly "passwordHash"?: string}, TVariables>;

    "passwordHash+"<
        XAlias extends string = "passwordHash", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"passwordHash", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~passwordHash": ApplicationUserFetcher<Omit<T, 'passwordHash'>, TVariables>;


    readonly securityStamp: ApplicationUserFetcher<T & {readonly "securityStamp"?: string}, TVariables>;

    "securityStamp+"<
        XAlias extends string = "securityStamp", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"securityStamp", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~securityStamp": ApplicationUserFetcher<Omit<T, 'securityStamp'>, TVariables>;


    readonly concurrencyStamp: ApplicationUserFetcher<T & {readonly "concurrencyStamp"?: string}, TVariables>;

    "concurrencyStamp+"<
        XAlias extends string = "concurrencyStamp", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"concurrencyStamp", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~concurrencyStamp": ApplicationUserFetcher<Omit<T, 'concurrencyStamp'>, TVariables>;


    readonly phoneNumber: ApplicationUserFetcher<T & {readonly "phoneNumber"?: string}, TVariables>;

    "phoneNumber+"<
        XAlias extends string = "phoneNumber", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"phoneNumber", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~phoneNumber": ApplicationUserFetcher<Omit<T, 'phoneNumber'>, TVariables>;


    readonly phoneNumberConfirmed: ApplicationUserFetcher<T & {readonly "phoneNumberConfirmed": boolean}, TVariables>;

    "phoneNumberConfirmed+"<
        XAlias extends string = "phoneNumberConfirmed", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"phoneNumberConfirmed", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: boolean} : 
                {readonly [key in XAlias]: boolean}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~phoneNumberConfirmed": ApplicationUserFetcher<Omit<T, 'phoneNumberConfirmed'>, TVariables>;


    readonly twoFactorEnabled: ApplicationUserFetcher<T & {readonly "twoFactorEnabled": boolean}, TVariables>;

    "twoFactorEnabled+"<
        XAlias extends string = "twoFactorEnabled", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"twoFactorEnabled", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: boolean} : 
                {readonly [key in XAlias]: boolean}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~twoFactorEnabled": ApplicationUserFetcher<Omit<T, 'twoFactorEnabled'>, TVariables>;


    readonly lockoutEnd: ApplicationUserFetcher<T & {readonly "lockoutEnd"?: string}, TVariables>;

    "lockoutEnd+"<
        XAlias extends string = "lockoutEnd", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"lockoutEnd", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~lockoutEnd": ApplicationUserFetcher<Omit<T, 'lockoutEnd'>, TVariables>;


    readonly lockoutEnabled: ApplicationUserFetcher<T & {readonly "lockoutEnabled": boolean}, TVariables>;

    "lockoutEnabled+"<
        XAlias extends string = "lockoutEnabled", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"lockoutEnabled", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: boolean} : 
                {readonly [key in XAlias]: boolean}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~lockoutEnabled": ApplicationUserFetcher<Omit<T, 'lockoutEnabled'>, TVariables>;


    readonly accessFailedCount: ApplicationUserFetcher<T & {readonly "accessFailedCount": number}, TVariables>;

    "accessFailedCount+"<
        XAlias extends string = "accessFailedCount", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"accessFailedCount", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): ApplicationUserFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: number} : 
                {readonly [key in XAlias]: number}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~accessFailedCount": ApplicationUserFetcher<Omit<T, 'accessFailedCount'>, TVariables>;
}

export const applicationUser$: ApplicationUserFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "ApplicationUser", 
            "OBJECT", 
            [], 
            [
                {
                    category: "SCALAR", 
                    name: "lastName", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "firstName", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "displayName", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "avatar", 
                    undefinable: true
                }, 
                "dateCreated", 
                "birthday", 
                {
                    category: "SCALAR", 
                    name: "address", 
                    undefinable: true
                }, 
                "isInActive", 
                "gender", 
                {
                    category: "SCALAR", 
                    name: "passwordRamdom", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "businessInformationById", 
                    argGraphQLTypeMap: {id: 'Int!'}, 
                    targetTypeName: "BusinessInformation", 
                    undefinable: true
                }, 
                {
                    category: "LIST", 
                    name: "businessInformation", 
                    targetTypeName: "BusinessInformation", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "paymentInformationById", 
                    argGraphQLTypeMap: {id: 'Int!'}, 
                    targetTypeName: "PaymentInformation", 
                    undefinable: true
                }, 
                {
                    category: "LIST", 
                    name: "paymentInformation", 
                    targetTypeName: "PaymentInformation", 
                    undefinable: true
                }, 
                {
                    category: "ID", 
                    name: "id"
                }, 
                {
                    category: "SCALAR", 
                    name: "userName", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "normalizedUserName", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "email", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "normalizedEmail", 
                    undefinable: true
                }, 
                "emailConfirmed", 
                {
                    category: "SCALAR", 
                    name: "passwordHash", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "securityStamp", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "concurrencyStamp", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "phoneNumber", 
                    undefinable: true
                }, 
                "phoneNumberConfirmed", 
                "twoFactorEnabled", 
                {
                    category: "SCALAR", 
                    name: "lockoutEnd", 
                    undefinable: true
                }, 
                "lockoutEnabled", 
                "accessFailedCount"
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const applicationUser$$ = 
    applicationUser$
        .lastName
        .firstName
        .displayName
        .avatar
        .dateCreated
        .birthday
        .address
        .isInActive
        .gender
        .passwordRamdom
        .id
        .userName
        .normalizedUserName
        .email
        .normalizedEmail
        .emailConfirmed
        .passwordHash
        .securityStamp
        .concurrencyStamp
        .phoneNumber
        .phoneNumberConfirmed
        .twoFactorEnabled
        .lockoutEnd
        .lockoutEnabled
        .accessFailedCount
;

export interface ApplicationUserArgs {

    readonly businessInformationById: {
        readonly id: number
    }, 

    readonly paymentInformationById: {
        readonly id: number
    }
}

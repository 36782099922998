import React, { Component } from 'react'
import { connect } from 'react-redux'
import { EMediaType } from '@shared/Types'
import { WindowScrollToTop } from '@lib/Helpers'
import { ApiAlertContext } from '@lib/ApiContext'
import { Params, useParams } from 'react-router-dom'
import { CreateHocLazy, ELazyStatus } from '@lib/ReduxBase'
import { GetMediaDetailQueryParam, IMediaDetailQueryParam } from '@shared/Services/QueryParamService'
import { ChooseLicenseMapRedux } from '../Cart'
import { mapDispatchToProps, mapStateToProps } from './redux.map'
import { IMediaDetailReduxProps, TMediaDetailMapRedux } from './types'
import { AudioSingleEventEmitter, IRecommendedMediasContext, RecommendedMediasContext } from './ui/ComponentContext'
import Layout from './ui/Layout'
import UIRecommendedMedias from './ui.recommended.medias'
import MediaPlayerLayout from './ui/MediaPlayerLayout'
import UIRecommendedCategories from './ui.recommended.categories'

interface IProps extends IMediaDetailReduxProps {
  params?: Readonly<Params<string>>
}

class ViewBaseContent extends Component<IProps> implements IRecommendedMediasContext {
  constructor(props: any) {
    super(props)
    this.audioEvents = new AudioSingleEventEmitter()
  }
  audioEvents: AudioSingleEventEmitter
  render() {
    const isLoading = this.props.Status === ELazyStatus.Loading
    const media = this.props.MediaDetailSlice.media
    const mediaType = this.getMediaType()
    const categories = this.props.MediaDetailSlice.categories
    const licenses = this.props.MediaDetailSlice.pricePackages
    return (
      <RecommendedMediasContext.Provider value={this}>
        <Layout
          isLoading={isLoading}
          components={{
            player: <MediaPlayerLayout data={media} mediaType={mediaType} />,
            license: <ChooseLicenseMapRedux pricePackages={licenses} media={media} />,
            category: <UIRecommendedCategories data={categories.map((e) => e.Name)} mediaType={mediaType} />,
            recommend: (
              <UIRecommendedMedias
                mediaType={mediaType}
                mediaPlayed={media}
                medias={this.getRecommendedMedias()}
                prices={licenses}
                onAddToCart={this.props.addToCart}
              />
            )
          }}
        />
      </RecommendedMediasContext.Provider>
    )
  }

  componentDidMount() {
    this.fetchAPI()
  }

  shouldComponentUpdate(): boolean {
    const obj = GetMediaDetailQueryParam()
    if (obj?.id !== this.queryParam?.id) {
      this.fetchAPI()
      return false
    }
    return true
  }

  queryParam?: IMediaDetailQueryParam
  fetchAPI = () => {
    const obj = GetMediaDetailQueryParam()
    this.queryParam = obj
    if (!obj.id) {
      ApiAlertContext.ApiAlert?.PushWarning('No media found!')
      return
    }
    this.props.fetchData({ id: obj.id, type: obj.type })
    WindowScrollToTop()
  }

  getMediaType = () => {
    return this.queryParam?.type || EMediaType.Video
  }

  getRecommendedMedias = () => {
    const list = this.props.MediaDetailSlice.recommends
    return list.filter((x) => x.Id !== this.queryParam?.id)
  }
}
const ViewBase: React.FC<IMediaDetailReduxProps> = (props) => {
  return <ViewBaseContent {...props} params={useParams()} />
}

export * from './redux.thunks'
export { default as MediaDetailSlice } from './redux.slice'

const HocLazyInstance = CreateHocLazy(ViewBase)
export const MediaDetailMapRedux: TMediaDetailMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)

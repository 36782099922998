import { EGalleryListType, EMediaType, IMedia } from '@shared/Types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IPagingModel } from '@lib/Table'
import { ICategory, IPrice } from '@shared/Types'
import PriceGuestService from '@internal/services/PriceService'
import GalleryGuestService from '@internal/services/GalleryGuestService'

interface IHomeThunk {
  // news
  VideoCategory?: Partial<ICategory>[]
  AudioCategory?: Partial<ICategory>[]
  VideoPopular?: Partial<IMedia>[]
  VideoNew?: Partial<IMedia>[]
  AudioHot?: Partial<ICategory>[]
  Prices: IPrice[]
}

export const fetchHomeThunk = createAsyncThunk<IHomeThunk>('fetchHomeThunk', async (_, thunkAPI) => {
  const defaultData = { Data: [], Amount: 0, Page: 0, Total: 0 }
  const prices = await PriceGuestService.All({}, thunkAPI.signal).catch((): IPagingModel<IPrice> => defaultData)
  const res = await GalleryGuestService.AllGraphQL(thunkAPI.signal)
  return {
    VideoCategory: filterCategoryData(res.mediaStore.galleries, EGalleryListType.VideoCategory),
    AudioCategory: filterCategoryData(res.mediaStore.galleries, EGalleryListType.AudioCategory),
    VideoPopular: filterMediaData(res.mediaStore.galleries, EGalleryListType.VideoPopular),
    VideoNew: filterMediaData(res.mediaStore.galleries, EGalleryListType.VideoNew),
    AudioHot: filterCategoryData(res.mediaStore.galleries, EGalleryListType.AudioHot),
    Prices: prices?.Data ?? []
  }
})
type TReturnTypeGallery = Awaited<ReturnType<typeof GalleryGuestService.AllGraphQL>>

const filterCategoryData = (res: TReturnTypeGallery['mediaStore']['galleries'], listType: EGalleryListType) => {
  return res?.items
    .find((x) => new RegExp(listType + '$').test(x.key ?? ''))
    ?.galleryDetails?.map<Partial<ICategory>>((x) => ({
      Name: x?.category?.name,
      ImageUri: x?.category?.imageUri
    }))
}
const filterMediaData = (res: TReturnTypeGallery['mediaStore']['galleries'], listType: EGalleryListType) => {
  return res?.items
    .find((x) => new RegExp(listType + '$', 'gi').test(x.key ?? ''))
    ?.galleryDetails?.filter((x) => !!x?.media)
    .map<Partial<IMedia>>((x) => ({
      Id: x?.media?.id,
      Name: x?.media?.name,
      ResourceId: x?.media?.resourceId,
      Duration: x?.media?.duration,
      Type: x?.media?.type as EMediaType,
      Size: x?.media?.size ?? 0,
      DateCreated: x?.media?.dateCreated
    }))
}

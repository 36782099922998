import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { IPrice } from '@shared/Types'
import { RouteKey } from '@internal/route'
import { CreateRoutePath } from '@lib/RouteBase'
import { Box, Button, Container, Divider, Stack, styled } from '@mui/material'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import { PriceConfigs } from './Configs'
import PricingSection from './ui/PricingSection'

interface IProps {
  data: IPrice[]
  isId?: boolean
}

export class PriceContent extends Component<IProps> {
  render() {
    return (
      <Box>
        {this.props.isId && <Box id='PricingSection' sx={{ height: 'var(--height-header)' }} />}
        {this.renderContent()}
        <Divider />
        <Container id='SubmitVideoSection'>
          <Stack sx={{ gap: '20px', alignItems: 'center', textAlign: 'center', padding: '72px 0 36px' }}>
            <Title>Ready to start generating income?</Title>
            <Text sx={{ width: { xs: 'calc(100% - 24px)', md: '80%' } }}>
              Are you an individual or a business looking to maximize your profits? If you have a digital product, please provide us with information
              about it. We are here to help you optimize your profits. Thank you!
            </Text>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', gap: '18px' }}>
              <CustomButton
                variant='outlined'
                color='inherit'
                startIcon={<OndemandVideoIcon />}
                {...{ component: Link, to: CreateRoutePath(RouteKey.Submit), target: '_blank' }}
              >
                Videos Submissions
              </CustomButton>
              <CustomButton
                variant='outlined'
                color='inherit'
                startIcon={<MusicNoteIcon />}
                {...{ component: Link, to: CreateRoutePath(RouteKey.Submit), target: '_blank' }}
              >
                Audios Submissions
              </CustomButton>
            </Box>
          </Stack>
        </Container>
      </Box>
    )
  }

  renderContent = () => {
    return PriceConfigs.map((item, index) => {
      if (item.disabled) return <Fragment key={index} />
      const prices = this.props.data.filter((x) => x.Type === item.type)
      return <PricingSection key={index} data={item} prices={prices} />
    })
  }
}
export default PriceContent

const Title = styled('h3')(({ theme }) => ({
  fontSize: '36px',
  fontWeight: 600,
  color: '#606060',
  [theme.breakpoints.down('md')]: {
    fontSize: '30px'
  }
}))

const Text = styled('span')({
  display: 'inline-block',
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 500,
  color: '#606060'
})

const CustomButton = styled(Button)({
  textTransform: 'unset',
  color: '#606060',
  fontWeight: 700,
  whiteSpace: 'nowrap',
  transition: 'all linear 0.2s',
  '&:hover': {
    color: '#1976D2'
  }
})

import { Typography, styled } from '@mui/material'

export const TextBase = styled(Typography)({
  fontFamily: "'Exo 2', sans-serif",
  fontWeight: 500,
})

export const Text18 = styled(Typography)({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '18px',
  color: '#606060',
})

export const Text16 = styled(Typography)({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  color: '#606060',
})

export const Text14 = styled(Typography)({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '14px',
  color: '#606060',
})

export const Text12 = styled(Typography)({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '12px',
})

export const TextBig = styled(Typography)({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '1.9rem',
  color: '#606060',
})

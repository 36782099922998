import React, { Component } from 'react'
import { RouteKey } from '@internal/route'
import { ELazyStatus } from '@lib/ReduxBase'
import { CreateRoutePath } from '@lib/RouteBase'
import { UIButtonBack } from '@shared/UIStyleds'
import { Box, Container, Grid } from '@mui/material'

interface IProps {
  status?: ELazyStatus
  payment: JSX.Element
  cart: JSX.Element
}

export default class UILayout extends Component<IProps> {
  render() {
    const backPath = CreateRoutePath(RouteKey.Cart)
    return (
      <Container sx={{ pt: '64px', pb: '128px' }}>
        <Grid container spacing={8}>
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <Box sx={{ minHeight: { md: '50vh' } }}>{this.props.payment}</Box>
            <UIButtonBack to={backPath} sx={{ mt: '24px' }}>
              Continue edit
            </UIButtonBack>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            {this.props.cart}
          </Grid>
        </Grid>
      </Container>
    )
  }
}

import React, { Component } from 'react'
import { Box, Container, Divider, styled } from '@mui/material'

interface IProps {
  isLoading?: boolean
  numberDataDisplayedString: string
  components: {
    banner?: JSX.Element
    top: JSX.Element
    categories: JSX.Element
    content: JSX.Element
    pagination: JSX.Element
  }
}

export default class UILayout extends Component<IProps> {
  render() {
    const { components } = this.props
    return (
      <>
        {components.banner && components.banner}
        <Container>
          {components.top}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ flex: 1 }}>{components.categories}</Box>
            {components.pagination}
          </Box>
          <Box sx={{ minHeight: '500px', pt: '12px' }}>{components.content}</Box>
          <Divider sx={{ mt: '24px' }} />
          <Box sx={{ display: 'flex', alignItems: 'center', mt: '12px' }}>
            <Text sx={{ flex: 1 }}>{this.props.numberDataDisplayedString}</Text>
            {components.pagination}
          </Box>
          <Box sx={{ height: '15vh' }} />
        </Container>
      </>
    )
  }
}

const Text = styled('h3')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 500,
  color: '#606060'
})

import React, { Component } from 'react'
import { Box, Button, styled, Typography, TypographyProps } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

export interface ITopBarProps {
  components?: {
    right?: JSX.Element
    rightable?: boolean
  }
}

interface IProps extends ITopBarProps {
  title?: string | JSX.Element
  btnBackTitle?: string
  onBack?: React.MouseEventHandler<HTMLButtonElement>
}

class MRTopBar extends Component<IProps> {
  render() {
    return (
      <Wrap>
        <Box sx={{ width: '175px', ml: '9px' }}>
          <ButtonBack
            size='small'
            onClick={this.props.onBack}
            startIcon={<ArrowBackIosNewIcon fontSize='small' sx={{ width: '0.9em', height: '0.9em' }} />}
            endIcon={<TextEsc>Esc</TextEsc>}
          >
            <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>
              {this.props.btnBackTitle ?? 'back'}
            </Typography>
          </ButtonBack>
        </Box>
        {this.renderTitle()}
        {this.props.components?.rightable !== true && <Box sx={{ minWidth: '175px', flex: '0 0 auto' }}>{this.props.components?.right}</Box>}
      </Wrap>
    )
  }
  renderTitle = () => {
    if (typeof this.props.title === 'string') {
      return (
        <Typography component='h6' variant='subtitle1' sx={{ fontWeight: 600, color: '#606060' }}>
          {this.props.title}
        </Typography>
      )
    }
    return this.props.title
  }
}
export default MRTopBar

const Wrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 0',
  margin: '-10px -10px 0',
  backgroundColor: '#fff',
  boxShadow: theme.app.boxShadow[1],
  position: 'static',
  zIndex: 5
}))

const ButtonBack = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  textTransform: 'none',
  textDecoration: 'none!important',
  flex: '0 0 auto',
  color: '#818181',
  transition: 'linear 0.2s',
  cursor: 'pointer',
  marginTop: 0,
  paddingRight: '14px',
  paddingLeft: '8px',
  '&:hover': { color: '#006bd6' },
  '&:hover span.MuiTypography-caption': { borderColor: '#006bd6' }
})

const TextEsc = styled(({ children, ...p }: TypographyProps) => (
  <Typography component='span' variant='caption' {...p}>
    {children}
  </Typography>
))({
  fontFamily: `'Exo 2', sans-serif`,
  transition: 'linear 0.2s',
  display: 'inline-block',
  border: '1px solid #818181',
  borderRadius: '5px',
  padding: '0 2px',
  fontSize: '10px!important',
  lineHeight: 1.5,
  fontWeight: 600,
  marginTop: '-1px',
  marginLeft: '3px'
})

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ICart } from '@shared/Types'
import { RouteKey } from '@internal/route'
import { CreateHocLazy } from '@lib/ReduxBase'
import { WindowScrollToTop } from '@lib/Helpers'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { ICheckOutReduxProps } from './redux.types'
import { mapDispatchToProps, mapStateToProps } from './redux.map'
import UILayout from './ui.layout'
import CheckOutError from './ui/CheckOutError'
import CartListContent from './ui/CartListContent'
import InformationPayment from './ui/InformationPayment'

interface IProps extends ICheckOutReduxProps {}

interface IContentProps extends IProps {
  navigate: NavigateFunction
}

class ViewBaseContent extends Component<IContentProps> {
  render() {
    const cartData = this.getCartData()
    return (
      <UILayout
        status={this.props.Status}
        cart={<CartListContent data={cartData} />}
        payment={<InformationPayment onBeforePayment={this.onBeforePayment} />}
      />
    )
  }

  componentDidMount(): void {
    WindowScrollToTop()
  }

  shouldComponentUpdate(nextProps: Readonly<IContentProps>): boolean {
    if (nextProps.CheckOutSlice.loaded && nextProps.CheckOutSlice.carts.length < 1) {
      this.props.navigate(RouteKey.Home)
      return false
    }
    return true
  }

  getCartData = (): ICart[] => {
    return this.props.CheckOutSlice.carts
  }

  onBeforePayment = () => {
    // TODO onBeforePayment
  }
}

export * from './redux.thunks'
export { default as CheckOutSlice } from './redux.slice'

export { default as CheckOutComplete } from './index.complete'

const HocLazyInstance = CreateHocLazy((p: IProps) => <ViewBaseContent {...p} navigate={useNavigate()} />, { ErrorScreen: CheckOutError })
export const CheckOutMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)

import { ModelType } from 'graphql-ts-client-api'
import { query$, mediaStoreGraphDbContext$ } from '../../__generated/media-store/fetchers'

import { applicationUser$, submission$ } from '../../__generated/media-store/fetchers'
import { media$$, mediaPage$, mediaDelivery$$ } from '../../__generated/media-store/fetchers'
import { category$, categoryDetail$$, category$$, categoryPage$ } from '../../__generated/media-store/fetchers'

const MediaSelector = media$$
  .categoryDetails(categoryDetail$$.category(category$.id.name.type.dateCreated.dateUpdated))
  .user(applicationUser$.displayName)

const MediaSelectorFull = media$$
  .categoryDetails(categoryDetail$$.category(category$.id.name.type.dateCreated.dateUpdated))
  .user(applicationUser$.displayName.email)
export type TMedia = ModelType<typeof MediaSelectorFull>

export type TMediasFilterParams = Parameters<typeof mediaStoreGraphDbContext$.medias>[0]
export const MediasQuery = (params: TMediasFilterParams) => {
  const MediaPage = mediaPage$.items(MediaSelector).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(mediaStoreGraphDbContext$.medias(params, MediaPage))
}

const CategorySelector = category$$
export type TMOCategory = ModelType<typeof CategorySelector>

const MediaDeliverySelector = mediaDelivery$$.submission(submission$.title.email)
export type TMOMediaDelivery = ModelType<typeof MediaDeliverySelector>

export type TMediaDeliveryParam = Parameters<typeof mediaStoreGraphDbContext$.mediaDelivery>[0]

export const MediasAndOtherQuery = (params: TMediasFilterParams) => {
  const MediaPage = mediaPage$.items(MediaSelector).hasNextPage.hasPreviousPage.totalItems
  const CategoryPage = categoryPage$.items(CategorySelector).hasNextPage.hasPreviousPage.totalItems
  let query = mediaStoreGraphDbContext$.medias(params, MediaPage).categories({}, CategoryPage)
  return query$.mediaStore(query)
}

export const MediasAndOtherFullQuery = (params: TMediasFilterParams, deliveryId?: string) => {
  const MediaPage = mediaPage$.items(MediaSelector).hasNextPage.hasPreviousPage.totalItems
  const CategoryPage = categoryPage$.items(CategorySelector).hasNextPage.hasPreviousPage.totalItems
  let query = mediaStoreGraphDbContext$
    .medias(params, MediaPage)
    .categories({}, CategoryPage)
    .mediaDelivery({ id: deliveryId ?? '' }, MediaDeliverySelector)
  return query$.mediaStore(query)
}

const MediaUserSelector = media$$.categoryDetails(categoryDetail$$.category(category$.id.name.type.dateCreated.dateUpdated))
export const MediasUserQuery = (params: TMediasFilterParams) => {
  const CategoryPage = categoryPage$.items(CategorySelector).hasNextPage.hasPreviousPage.totalItems
  const MediaPage = mediaPage$.items(MediaUserSelector).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(mediaStoreGraphDbContext$.medias(params, MediaPage).categories({}, CategoryPage))
}

export const MediasUserQueryWithDeliveryId = (params: TMediasFilterParams, deliveryId: string) => {
  const CategoryPage = categoryPage$.items(CategorySelector).hasNextPage.hasPreviousPage.totalItems
  const MediaPage = mediaPage$.items(MediaUserSelector).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(
    mediaStoreGraphDbContext$
      .medias(params, MediaPage)
      .categories({}, CategoryPage)
      .mediaDelivery({ id: deliveryId }, MediaDeliverySelector)
  )
}

type TCategoriesFilterParams = Parameters<typeof mediaStoreGraphDbContext$.categories>[0]
export type TMediaFilterParams = Parameters<typeof mediaStoreGraphDbContext$.media>[0]
export const CategoriesAndCategoryDetailByMediaId = (
  params: TMediaFilterParams,
  categoriesFilter: TCategoriesFilterParams = {}
) => {
  const CategoryPage = categoryPage$.items(CategorySelector).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(mediaStoreGraphDbContext$.media(params, MediaSelector).categories(categoriesFilter, CategoryPage))
}

import { RouteKey } from '@internal/route'
import { QueryParam } from '@lib/Helpers'
import { ECategoryType, EMediaDeliveryType, EMediaType } from '@shared/Types'

export const GetBillingId = (): string | undefined => {
  try {
    const obj = QueryParam.Gets('billingId') as { billingId?: string }
    return obj?.billingId ? obj.billingId : undefined
  } catch (error) {
    return undefined
  }
}

const mapMediaType: { [key: string]: EMediaType } = {
  [EMediaType.Audio]: EMediaType.Audio,
  [EMediaType.Audio.toLocaleLowerCase()]: EMediaType.Audio,
  [EMediaType.Video]: EMediaType.Video,
  [EMediaType.Video.toLocaleLowerCase()]: EMediaType.Video
}

export interface IMediaDetailQueryParam {
  id?: string
  type: EMediaType
}

export const GetMediaDetailQueryParam = (): IMediaDetailQueryParam => {
  const defaultMediaType = EMediaType.Video
  try {
    const obj = QueryParam.Gets<IMediaDetailQueryParam>('id', 'type')
    const mediaType = (obj.type ? mapMediaType[obj.type] : defaultMediaType) || defaultMediaType
    return {
      id: obj.id,
      type: mediaType
    }
  } catch (error) {
    return { type: defaultMediaType }
  }
}

export interface IMediaQueryParam {
  deliveryId?: string
  type?: EMediaDeliveryType
  md?: RouteKey
}

export const GetMediaQueryParam = (): IMediaQueryParam => {
  return QueryParam.Gets<IMediaQueryParam>('deliveryId', 'type')
}

export const GetDeliveryId = (): string | undefined => {
  try {
    const qp = QueryParam.Gets<IMediaQueryParam>('deliveryId')
    const num = qp.deliveryId
    return num
  } catch (error) {
    return undefined
  }
}

export const GetDeliveryType = (): EMediaDeliveryType => {
  try {
    const qp = QueryParam.Gets<{ type?: string }>('type')
    const typeValue = qp.type?.toString().toLowerCase()
    switch (typeValue) {
      case EMediaDeliveryType.Url.toLowerCase():
        return EMediaDeliveryType.Url
      case EMediaDeliveryType.Upload.toLowerCase():
      default:
        return EMediaDeliveryType.Upload
    }
  } catch (error) {
    return EMediaDeliveryType.Upload
  }
}

export const GetGalleryId = (): string | undefined => {
  try {
    const q = QueryParam.Gets<{ galleryId?: string }>('galleryId')
    return q.galleryId
  } catch (error) {
    return undefined
  }
}
export function stringToEnumCategory(value: string): ECategoryType | undefined {
  return ECategoryType[value as keyof typeof ECategoryType]
}

export const GetGalleryQueryParam = () => {
  try {
    const q = QueryParam.Gets<{ galleryId?: string; key?: string }>('galleryId', 'key')
    return q
  } catch (error) {
    return undefined
  }
}

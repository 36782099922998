import React, { Component } from 'react'
import { ClickAwayListener, Box, IconButton, Divider, styled, SxProps, Theme, Button } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'

interface IProps {
  superSmalled?: boolean
  gray?: boolean
  onSubmit?: () => void
  disabled?: boolean
}

interface IState {
  isCheck: boolean
}

export class ButtonClear extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { isCheck: false }
  }
  render() {
    return (
      <ClickAwayListener onClickAway={this.handleClickCancel}>
        <Wrap sx={this.getSxWrap()}>
          {this.renderContent()}
          {this.state.isCheck && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
              <IconButton color='success' size='small' onClick={this.handleClickDone}>
                <DoneIcon sx={{ color: '#2e7d32 !important' }} />
              </IconButton>
              <Divider orientation='vertical' flexItem />
              <IconButton color='error' size='small' onClick={this.handleClickCancel}>
                <CloseIcon sx={{ color: '#d32f2f !important' }} />
              </IconButton>
            </Box>
          )}
        </Wrap>
      </ClickAwayListener>
    )
  }

  renderContent = () => {
    if (this.state.isCheck) return <></>
    return (
      <Button
        color='inherit'
        size='small'
        endIcon={<RefreshIcon />}
        sx={{ textTransform: 'unset!important', color: '#454545', fontWeight: 600, lineHeight: '1rem' }}
        onClick={this.handlingClick}
        disabled={this.props.disabled}
      >
        Clear
      </Button>
    )
  }

  handleClickDone = () => {
    this.props.onSubmit && this.props.onSubmit()
    this.setState({ isCheck: false })
  }

  handleClickCancel = () => {
    this.setState({ isCheck: false })
  }

  handlingClick = () => {
    this.setState({ isCheck: true })
  }

  getSxWrap = (): SxProps<Theme> => {
    let sxSvg: SxProps<Theme> = {}
    if (this.props.superSmalled) sxSvg = { ...sxSvg, fontSize: '1.1rem' }
    if (this.props.gray) sxSvg = { ...sxSvg, color: '#767676' }
    return { '& svg': sxSvg } as SxProps<Theme>
  }
}
export default ButtonClear

const Wrap = styled(Box)({
  height: '32px',
  display: 'flex',
  alignItems: 'center'
})

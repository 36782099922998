import React, { FC } from 'react'
import { EMediaType } from '@shared/Types'
import { Box, BoxProps, Grid, Skeleton, styled } from '@mui/material'

interface IProps {
  mediaType?: EMediaType
}
export const UISkeletonExploreContent: FC<IProps> = (props) => (
  <Grid container spacing={2}>
    {[...Array(props.mediaType === EMediaType.Audio ? 6 : 12)].map((x, i) => {
      switch (props.mediaType) {
        case EMediaType.Audio:
          return (
            <Grid key={i} item xs={12}>
              <Skeleton variant='rounded' width='100%' height='115px' />
            </Grid>
          )
        case EMediaType.Video:
        default:
          return (
            <Grid key={i} item xs={12} sm={4} md={3}>
              <SkeletonWrap>
                <Skeleton variant='rounded' width='100%' height='100%' />
              </SkeletonWrap>
            </Grid>
          )
      }
    })}
  </Grid>
)

const SkeletonWrap = styled(({ children, ...p }: BoxProps) => (
  <Box {...p}>
    <Box>{children}</Box>
  </Box>
))({
  width: '100%',
  paddingBottom: '100%',
  position: 'relative',
  '& > div': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }
})

const WidthRamdom = [43, 40, 84, 71, 52, 75, 48]
export const UISkeletonCategories: FC = () => {
  return (
    <Box sx={{ display: 'flex', gap: '10px', height: '54px', alignItems: 'center' }}>
      {WidthRamdom.map((x, i) => (
        <Skeleton key={i} variant='rounded' height='32px' width={`${x}px`} />
      ))}
    </Box>
  )
}

import { createSlice } from '@reduxjs/toolkit'
import { fetchHomeThunk } from './redux.thunks'
import { IHomeStateSlice } from './redux.types'
import { ELazyStatus } from '@lib/ReduxBase'

// Define the initial state using that type
const initialState: IHomeStateSlice = {
  Status: ELazyStatus.Loading,
  Prices: []
}

const HomeSlice = createSlice({
  name: 'HomeSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Loaded
        state.VideoCategory = action.payload.VideoCategory
        state.AudioCategory = action.payload.AudioCategory
        state.VideoPopular = action.payload.VideoPopular
        state.VideoNew = action.payload.VideoNew
        state.AudioHot = action.payload.AudioHot
        state.Prices = action.payload.Prices
      })
      .addCase(fetchHomeThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Error
      })
      .addCase(fetchHomeThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Status = ELazyStatus.Loading
      })
  }
})

// export const {} = HomeSlice.actions

export default HomeSlice

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { RouteKey } from '@internal/route'
import { ISubmission } from '@shared/Types'
import { CreateRoutePath } from '@lib/RouteBase'
import { CreateCheckboxSingle } from '@lib/Forms/Inputs'
import { Button, Stack, styled, Typography } from '@mui/material'
import { CreateFormGridLayout, IFormBase, FormValidator, SingleRuleValidate } from '@lib/Forms'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'

const CheckboxSingleComponent = CreateCheckboxSingle<ISubmission>()

class Actions extends Component<IFormBase<ISubmission>> {
  render() {
    const isDisabled = Object.keys(this.props.messageErrors ?? {}).filter((x) => !!x).length > 0
    return (
      <Stack sx={{ alignItems: 'flex-start', width: '100%', marginTop: '18px', pl: '12px' }}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          size='large'
          sx={{ minWidth: '200px' }}
          endIcon={<ArrowRightAltIcon />}
          disabled={isDisabled}
        >
          Submit
        </Button>
      </Stack>
    )
  }
}

const FormDigitalSignature = CreateFormGridLayout<ISubmission>({
  configs: [
    {
      key: 'DigitalSignature',
      inputElement: (p) => <input hidden name='DigitalSignature' defaultValue='none' />
    },
    {
      key: 'IsReadAndAgreedTermOdService',
      inputElement: (p) => (
        <CheckboxSingleComponent
          {...p}
          sx={{ marginTop: '-6px' }}
          label={
            <Typography>
              Yes, I have read and agreed to the
              <BackLink {...{ component: Link, to: CreateRoutePath(RouteKey.PrivacyPolicy), target: '_blank' }}>Privacy policy</BackLink>*
            </Typography>
          }
        />
      )
    },
    {
      key: 'IsReadAndAgreedTermOdSubmitssion',
      inputElement: (p) => (
        <CheckboxSingleComponent
          {...p}
          sx={{ marginTop: '-9px' }}
          label={
            <Typography>
              Yes, I have read and agreed to the
              <BackLink {...{ component: Link, to: CreateRoutePath(RouteKey.TermsOfSubmission), target: '_blank' }}>Terms of submission</BackLink>*
            </Typography>
          }
        />
      )
    }
  ],
  validate: new FormValidator({
    IsReadAndAgreedTermOdService: { Rules: [{ rule: SingleRuleValidate.Required }] },
    IsReadAndAgreedTermOdSubmitssion: { Rules: [{ rule: SingleRuleValidate.Required }] }
  }),
  options: {
    action: Actions
  }
})
export default FormDigitalSignature

const BackLink = styled(Typography)({
  marginLeft: '6px',
  color: '#1976d2'
})

import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { Button, ButtonProps, styled } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

interface IProps extends ButtonProps, Pick<LinkProps, 'to'> {}

export const UIButtonBack = styled(({ to, children, ...p }: IProps) => (
  <Button component={Link} {...p} startIcon={<KeyboardBackspaceIcon />} variant='text' to={to}>
    {children}
  </Button>
))({
  textTransform: 'unset',
  color: '#202020',
  fontWeight: 600,
  padding: '7px 12px',
  transition: 'linear 0.2s',
  fontSize: '0.9rem',
  '&:hover': {
    color: '#006bd6'
  }
})

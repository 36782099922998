import { FetchDelay } from '@lib/Helpers'
import { ServiceBase, CreateHttpService } from '@lib/Http'
import { IBusinessInfo, IPaymentInfo, IPersonalInfo, IProfile } from '@shared/Types'
import DataMapping from './DataMapping'

class ProfileServiceBase extends ServiceBase {
  constructor() {
    super(CreateHttpService(`${process.env.REACT_APP_API_URI}/api/v1/user/Profile`))
  }

  private urlProfile = '/GetProfile'
  getProfile = (signal?: AbortSignal) => {
    return FetchDelay(async () => {
      const res = await this.PushNotify(this.Get<IProfile>, this.urlProfile, { signal })
      return DataMapping.profileInfos(res)
    }, 500)
  }

  private urlUpdatePersonal = '/UpdatePersonalInfo'
  updatePersonal = (value: Partial<IPersonalInfo>, signal?: AbortSignal): Promise<IPersonalInfo | undefined> => {
    return FetchDelay(async () => {
      const body = DataMapping.personalInfoUpdate(value)
      const res = await this.PushNotify(this.Post<IPersonalInfo>, this.urlUpdatePersonal, body, { signal })
      return res
    }, 700)
  }

  private urlUpdateBusiness = '/UpdateBusinessInfo'
  updateBusiness = (value: Partial<IBusinessInfo>, signal?: AbortSignal): Promise<IBusinessInfo | undefined> => {
    return FetchDelay(async () => {
      const body = DataMapping.businessInfoUpdate(value)
      const res = await this.PushNotify(this.Post<IBusinessInfo>, this.urlUpdateBusiness, body, { signal, params: { Id: value.Id ?? '' } })
      return res
    }, 700)
  }

  private urlUpdatePayment = '/UpdatePaymentInfo'
  updatePayment = (value: Partial<IPaymentInfo>, signal?: AbortSignal): Promise<IPaymentInfo | undefined> => {
    return FetchDelay(async () => {
      const body = DataMapping.paymentInfoUpdate(value)
      const res = await this.PushNotify(this.Post<IPaymentInfo>, this.urlUpdatePayment, body, { signal, params: { Id: value.Id ?? '' } })
      return res
    }, 700)
  }
}
const ProfileService = new ProfileServiceBase()
export default ProfileService

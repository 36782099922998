import React, { Component } from 'react'
import { Box, Button, Divider, Fade, styled } from '@mui/material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'

interface IActionsBarProps {
  onPrev?: () => void
}

export default class ActionsBar extends Component<IActionsBarProps> {
  render() {
    return (
      <StickyBox>
        <Divider />
        <Wrap>
          <Fade in={!!this.props.onPrev} unmountOnExit>
            <Button color='inherit' onClick={this.props.onPrev} sx={{ minWidth: '120px', pt: '9px' }}>
              Prev
            </Button>
          </Fade>
          <Button type='submit' color='primary' variant='contained' sx={{ minWidth: '140px', pt: '9px' }} endIcon={<ArrowRightAltIcon />}>
            Next
          </Button>
        </Wrap>
      </StickyBox>
    )
  }
}

const Wrap = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '12px',
  padding: '9px 0'
})

const StickyBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: '3px 12px',
  position: 'sticky',
  bottom: 0,
  zIndex: theme.app.zIndex[1]
}))

import React, { Component } from 'react'
import { Box } from '@mui/material'
import { LinkRow } from '@lib/Forms/Inputs'
import { FormModalWrapper } from '@lib/Forms'
import { IMediaDelivery } from '@shared/Types'
import { CopyToClipboard } from '@lib/Component'

interface IProps {
  data?: Partial<IMediaDelivery>
}
export default class FormUrlTypePreview extends Component<IProps> {
  render() {
    return (
      <FormModalWrapper title='Repository urls' size='sm'>
        <Box sx={{ minHeight: `${38 * 5}px`, padding: '12px 12px 0' }}>
          {this.getLinks().map((item, index) => (
            <LinkRow key={index} index={index} value={item} action={<CopyToClipboard value={item} />} />
          ))}
        </Box>
      </FormModalWrapper>
    )
  }
  getLinks = (): string[] => {
    try {
      return JSON.parse(this.props.data?.Content ?? '') ?? []
    } catch (error) {
      console.log(error)
      return []
    }
  }
}

import React, { Component } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, Stack, styled } from '@mui/material'
import { ICart } from '@shared/Types'
import { ECheckOutValidateStatus } from '@shared/Types'
import Helpers from '../helpers'

interface IInfoConfig {
  key: string
  value: string | number
  big?: boolean
}

interface IProps {
  data: ICart[]
  checkOutStatus?: ECheckOutValidateStatus
  onCheckOut?: () => void
  disabled?: boolean
}

export default class SummaryReport extends Component<IProps> {
  render() {
    return (
      <BoxSticky>
        <CustomStack gap="25px">
          <Title>Summary</Title>
          {this.renderInfo()}
          <LoadingButton
            variant="contained"
            size="large"
            color="primary"
            sx={{ flex: '0 0 auto' }}
            disabled={this.props.disabled}
            onClick={this.props.onCheckOut}
            loading={this.props.checkOutStatus === ECheckOutValidateStatus.Loading}
          >
            Check Out
          </LoadingButton>
        </CustomStack>
      </BoxSticky>
    )
  }

  renderInfo = () => {
    const configs = this.getInfoConfig()
    return configs.map((item, index) => (
      <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
        <Text18>{item.key}:</Text18>
        {item.big ? <Title sx={{ fontWeight: 700 }}>{item.value}</Title> : <Text18 sx={{ fontWeight: 700 }}>{item.value}</Text18>}
      </Box>
    ))
  }

  getInfoConfig = (): IInfoConfig[] => {
    const total = Helpers.getTotal(this.props.data)
    const totalLink = Helpers.getTotalLinks(this.props.data)
    const list: IInfoConfig[] = [
      { key: 'Quantity', value: this.props.data.length },
      { key: 'Link totals', value: totalLink },
      { key: 'VAT', value: '10%' },
      { key: 'Total', value: `$ ${total}`, big: true },
    ]
    return list
  }
}

const Title = styled('h1')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '32px',
  fontWeight: 700,
  color: '#606060',
})

const Text18 = styled('p')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '18px',
  fontWeight: 500,
  color: '#606060',
})

const BoxSticky = styled(Box)({
  position: 'sticky',
  top: '121px',
})

const CustomStack = styled(Stack)({
  padding: '35px 25px',
  borderRadius: '6px',
  backgroundColor: '#fafafa',
  border: '1px dashed rgba(0, 0, 0, 0.50)',
})

import { FetchDelay } from '@lib/Helpers'
import { IPrice } from '@shared/Types'
import { ServiceBase, CreateHttpService } from '@lib/Http'
import { IPagingModel } from '@lib/Table'
import { IFilterDTO } from '../types'
import FilterMapping from './FilterMapping'

class PricePackageServiceBase extends ServiceBase {
  constructor() {
    super(CreateHttpService(`${process.env.REACT_APP_API_URI}/api/v1/guest/Price`))
  }

  private urlAll = '/All'
  all = (filter: IFilterDTO, signal?: AbortSignal): Promise<IPrice[]> => {
    return FetchDelay(async () => {
      try {
        const filterReq = FilterMapping.parsePricePackage(filter)
        const res = await this.PushNotify(this.Post<IPagingModel<IPrice>>, this.urlAll, filterReq, { signal })
        return res['Data'] as IPrice[]
      } catch (error) {
        return []
      }
    }, 500)
  }
}
const PricePackageService = new PricePackageServiceBase()
export default PricePackageService

import React, { Component } from 'react'
import { ETicketType } from '@shared/Types'
import { HelperService } from '@shared/Services'
import { TextBase } from '@internal/shareds/Text'
import { MapGlobalModalContext } from '@lib/ApiContext'
import { Box, Button, Grid, styled } from '@mui/material'
import { CreateInputTextMultiline, CreateSelectSimple } from '@lib/Forms/Inputs'
import { CreateFormGridLayout, FormValidator, SingleRuleValidate } from '@lib/Forms'

export interface IFormCreateData {
  Subject: string
  Message: string
  Type: ETicketType
  Fake?: string
}

const SelectSimpleInstance = CreateSelectSimple<IFormCreateData>()
const InputTextMultilineInstance = CreateInputTextMultiline<IFormCreateData>({ options: { multiline: { minRows: 3, maxRows: 5 } } })

const FormGridLayoutInstance = CreateFormGridLayout<IFormCreateData>({
  configs: [
    {
      key: 'Type',
      label: 'Type',
      reponsives: { md: 3 },
      inputElement: (p) => <SelectSimpleInstance {...p} options={HelperService.getTicketTypes()} />
    },
    { key: 'Subject', label: 'Subject', reponsives: { md: 9 } },
    { key: 'Message', label: 'Description', inputElement: InputTextMultilineInstance }
  ],
  validate: new FormValidator({
    Subject: { Rules: [{ rule: SingleRuleValidate.Required }] },
    Message: { Rules: [{ rule: SingleRuleValidate.Required }] }
  })
})

// ========= ========= ========= Form Create ========= ========= =========
interface IFormCreateProps {
  Option?: JSX.Element
  onSubmit: (value: Partial<IFormCreateData>) => Promise<void>
}
export class FormCreate extends Component<IFormCreateProps> {
  render() {
    return MapGlobalModalContext((context) => (
      <Wrap>
        <FormGridLayoutInstance
          slots={{
            action: () => <React.Fragment />,
            components: {
              bottom: (
                <>
                  <Grid container spacing={2}>
                    {this.props.Option && (
                      <Grid item xs={12}>
                        {this.props.Option}
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '9px' }}>
                        <Button size='small' variant='contained' color='success' type='submit'>
                          <TextBase sx={{ fontSize: '14px', textTransform: 'none' }}>Create</TextBase>
                        </Button>
                        <Button size='small' variant='outlined' color='inherit'>
                          <TextBase sx={{ fontSize: '14px', textTransform: 'none' }}>Discard</TextBase>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )
            }
          }}
          onSubmit={(v) => {
            context.CloseModal()
            return this.props.onSubmit(v)
          }}
        />
      </Wrap>
    ))
  }
}

const Wrap = styled(Box)(({ theme }) => ({
  width: '100%',
  margin: '0 auto',
  [theme.breakpoints.up('sm')]: { width: theme.breakpoints.values.sm },
  [theme.breakpoints.up('md')]: { width: theme.breakpoints.values.md }
}))

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { RouteKey } from '@internal/route'
import { CreateRoutePath } from '@lib/RouteBase'
import { IMedia, IPrice, EMediaType } from '@shared/Types'
import { FormatDatetime, FormatTiming } from '@lib/Helpers'
import { IMediaDetailQueryParam } from '@shared/Services/QueryParamService'
import { IApiPopoverContextProps, withApiPopoverContext } from '@lib/ApiContext'
import { Box, IconButton, Stack, Tooltip, Typography, styled } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import FormQuicklyAddToCart from './FormQuicklyAddToCart'

interface IProps extends IApiPopoverContextProps {
  data: Partial<IMedia>
  prices: IPrice[]
  isBlank?: boolean
  play?: (type: EMediaType) => void
  onAddToCart?: (media: IMedia, price: IPrice) => void
}

class MediaVideoItemBase extends Component<IProps> {
  render() {
    return (
      <Wrap>
        <Box sx={{ position: 'relative' }}>
          <CustomImage>
            <Box className='box-background' sx={{ backgroundImage: this.getImageUrl() }} />
          </CustomImage>
          <BoxHover className='box-horver' {...this.componentLink()} />
          <ControlPopsition className='box-icon'>
            <Tooltip title='Add to cart' placement='left'>
              <IconButton onClick={this.handleClickQuicklyAddToCart}>
                <AddShoppingCartIcon fontSize='small' sx={{ color: '#0ED197' }} />
              </IconButton>
            </Tooltip>
          </ControlPopsition>
        </Box>
        <Stack gap='5px' color='#606060' px='5px'>
          <Title>{this.props.data.Name ?? 'Exo 2/Bold/16px'}</Title>
          <Stack direction='row' alignItems='center'>
            <Typography variant='body2' noWrap>
              {FormatDatetime(this.props.data.DateCreated)}
            </Typography>
            <Box flex={1} />
            <Stack direction='row' alignItems='center' gap='2px'>
              <AccessTimeIcon fontSize='small' />
              <Text>{FormatTiming(this.props.data.Duration)}</Text>
            </Stack>
            {/* <Text>{FormatSize(this.props.data.Size)}</Text> */}
          </Stack>
        </Stack>
      </Wrap>
    )
  }

  handleClickQuicklyAddToCart: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    this.props.contextPopover.showPopover({
      anchorEl: event.currentTarget,
      popoverProps: {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transformOrigin: { vertical: 'top', horizontal: 'right' }
      },
      content: (
        <FormQuicklyAddToCart
          prices={this.props.prices}
          media={this.props.data}
          onSubmit={async (value) => {
            const item = this.props.prices.find((x) => x.Id === value.PriceSelectedId)
            if (!item) return
            // TODO check update
            this.props.onAddToCart && this.props.onAddToCart(this.props.data as IMedia, item)
            this.props.contextPopover.closePopover()
          }}
        />
      )
    })
  }

  getImageUrl = () => {
    if (!!this.props.data.ResourceId) return `url(/api/v1/guest/Upload/VideoThumb/${this.props.data.ResourceId})`
    return `url(${process.env.PUBLIC_URL + '/images/error.png'})`
  }

  componentLink = () => {
    const path = CreateRoutePath<IMediaDetailQueryParam>(RouteKey.Detail, {
      query: { type: EMediaType.Video, id: this.props.data.Id }
    })
    return { component: Link, to: path, target: this.props.isBlank ? '_blank' : '_self' }
  }
}
export const MediaItemVideo = withApiPopoverContext(MediaVideoItemBase)
export default MediaItemVideo

const Wrap = styled(Stack)(({ theme }) => ({
  maxWidth: '400px',
  borderRadius: '6px',
  backgroundColor: '#fff',
  overflow: 'hidden',
  gap: '10px',
  boxShadow: '0 2px 14px rgba(0,0,0,0)',
  transition: 'all linear 0.3s',
  '&:hover .box-horver, &:hover .box-icon': { opacity: 1 },
  [theme.breakpoints.down('md')]: { '& .box-horver, & .box-icon': { opacity: 1 } },
  '&:hover .box-background': { transform: 'translate(-50%, -50%) scale(1.1)' }
}))

const CustomImage = styled('div')({
  width: '100%',
  paddingBottom: '82%',
  overflow: 'hidden',
  borderRadius: '6px',
  position: 'relative',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  background: '#fafafa',
  '& .box-background': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transform: 'translate(-50%, -50%) scale(1)',
    transition: 'all .3s'
  }
})

const BoxHover = styled(Box)({
  cursor: 'pointer',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  transition: 'all linear 0.3s',
  opacity: 0,
  borderRadius: '4px',
  background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%)'
})

const ControlPopsition = styled('div')({
  borderRadius: '8px',
  backgroundColor: '#fff',
  boxShadow: '0 2px 14px rgba(0,0,0,0.23)',
  position: 'absolute',
  top: '12px',
  right: '12px',
  transition: 'all linear 0.3s',
  opacity: 0
})

const Title = styled('h3')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 700,
  flexGrow: 0,
  flexBasis: 'auto',
  padding: '5px 0',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
})

const Text = styled('span')({
  display: 'inline-block',
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 500,
  color: '#606060',
  marginBottom: '1px'
})

import React, { Component } from 'react'
import { Box } from '@mui/material'
import { Navigate } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames, authService } from 'partner-oidc-auth'

export interface AuthorizeRouteProps {
  path?: string
  element: JSX.Element
  roles?: string[][]
}
interface AuthorizeRouteState {
  ready: boolean
  authenticated: boolean
  roles: string[]
}
export interface IAuthorizeRouteContext {
  state: AuthorizeRouteState
}
export const AuthorizeRouteContext = React.createContext<IAuthorizeRouteContext>({} as any)
export class AuthorizeRoute extends Component<AuthorizeRouteProps, AuthorizeRouteState> {
  constructor(props: AuthorizeRouteProps) {
    super(props)

    this.state = {
      ready: false,
      authenticated: false,
      roles: []
    }
  }
  _subscription?: number
  componentDidMount() {
    this._subscription = authService.subscribe(() => this.authenticationChanged())
    this.populateAuthenticationState()
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription ?? 0)
  }
  isAllow = () => (!this.props.roles ? true : this.state.roles?.some((x) => this.IsAllowAccess(x, this.props.roles)) ?? false)
  IsAllowAccess = (role: string, roles?: string[][]) => {
    if (!roles || !roles.length || !roles[0].length) return true
    return roles.every((x) => x.some((y) => y.toLowerCase() === role.toLowerCase()))
  }

  renderContent = (redirectUrl: string) => {
    const { authenticated } = this.state
    if (!authenticated) {
      return <Navigate replace to={redirectUrl} />
    }
    const { element } = this.props
    return this.isAllow() ? React.cloneElement(element, { roles: this.state.roles }) : <Navigate replace to={ApplicationPaths.AccessDenied} />
  }

  getRedirectUrl = () => {
    const link = document.createElement('a')
    let path = this.props.path ?? ''
    path = path[0] !== '/' ? '/' + path : path
    path = window.location.pathname?.includes(path) ? window.location.href : this.props.path ?? ''
    link.href = path
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`
    return `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`
  }
  Element: JSX.Element | undefined
  render() {
    const { ready } = this.state
    let content = this.Element ? this.Element : <div></div>
    if (ready) {
      content = <AuthorizeRouteContext.Provider value={this}>{this.renderContent(this.getRedirectUrl())}</AuthorizeRouteContext.Provider>
      this.Element = content
    }
    return (
      <>
        {content}
        <Box
          sx={{
            display: ready ? 'none' : 'block',
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0
          }}
        ></Box>
      </>
    )
  }
  getAuthInfo = () => Promise.all([authService.isAuthenticated(), authService.getRoles()])
  async populateAuthenticationState() {
    let [authenticated, roles] = await this.getAuthInfo()
    if (!authenticated) {
      await authService.signIn(this.getRedirectUrl())
      ;[authenticated, roles] = await this.getAuthInfo()
    }
    this.setState({ ready: true, authenticated, roles: roles ?? [] })
  }
  authenticationChanged() {
    this.setState({ ready: false, authenticated: false }, this.populateAuthenticationState)
  }
}

import { AppDispatch, RootState } from '@internal/redux'
import { fetchPricingThunk } from './redux.thunks'
import { IPricingDispatchRedux, IPricingStateRedux } from './type'

export const mapStateToProps = (state: RootState): IPricingStateRedux => ({
  Status: state.PricingSlice.Status,
  PricingSlice: state.PricingSlice
})

export const mapDispatchToProps = (dispatch: AppDispatch): IPricingDispatchRedux => ({
  FetchData: () => {
    return dispatch(fetchPricingThunk())
  }
})

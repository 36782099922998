import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteKey } from '@internal/route'
import { GlobalModal } from '@lib/ApiContext'
import { CreateHocLazy } from '@lib/ReduxBase'
import { CreateRoutePath } from '@lib/RouteBase'
import { ITicket, ETicketStatus } from '@shared/Types'
import { WindowScrollToTop, TableFormater } from '@lib/Helpers'
import { CreateTableTemplate, CRUDPannel, MapOprators } from '@lib/Table'
import { TUIBCreate, CustomActionPannel, FormConfirmReopen, StatusCell, TUIBConversation } from '@shared/Pages/Ticket'
import UIStyleds from '@shared/UIStyleds'
import { ITicketReduxProps } from './redux.types'
import { mapDispatchToProps, mapStateToProps } from './redux.map'

const Table = CreateTableTemplate<ITicket>('Redux', {
  getRowId: (x) => x.Id,
  config: {
    Subject: { type: 'string', flex: 1, minWidth: 190 },
    Type: { type: 'string', minWidth: 140, filterable: false },
    DateCreated: { type: 'string', minWidth: 175, filterable: false, renderCell: (params) => TableFormater.tooltipDate(params.value) },
    Status: { type: 'string', minWidth: 140, filterable: false, renderCell: (params) => <StatusCell status={params.value} /> }
  },
  filterOperators: MapOprators, //server mode,
  minWidthColumnActions: 140
})

class ViewBase extends Component<ITicketReduxProps> {
  render() {
    return (
      <GlobalModal>
        <UIStyleds.WrapProfile>
          <Table
            ReduxOption={this.props.tableInfo}
            onChange={this.props.onTableChange}
            CRUDPannel={() => (
              <CRUDPannel
                Title='Tickets'
                Buttons={{ ExtendAfter: [<TUIBCreate to={CreateRoutePath([RouteKey.Profile, RouteKey.TicketCreate])} />] }}
              />
            )}
            ActionPannel={(p) => {
              const data = p.data as ITicket
              return (
                <CustomActionPannel
                  ticketStatus={data.Status}
                  Edit={
                    <TUIBConversation
                      to={CreateRoutePath([RouteKey.Profile, RouteKey.Ticket], { query: { id: data.Id }, modal: RouteKey.TicketDetail })}
                    />
                  }
                  CloseIssue={<FormConfirmReopen data={data} onSubmit={() => this.props.CRUD.Update(data.Id, { Status: ETicketStatus.Closed })} />}
                />
              )
            }}
          />
        </UIStyleds.WrapProfile>
      </GlobalModal>
    )
  }

  componentDidMount() {
    WindowScrollToTop()
  }

  handleSubmitCloseIssue = async (value: ITicket) => {
    this.props.CRUD.Update(value.Id, { Status: ETicketStatus.Closed })
  }
}

export { fetchTicketThunk } from './redux.thunks'
export { default as TicketSlice } from './redux.slice'

const HocLazyInstance = CreateHocLazy(ViewBase)
export const TicketMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)

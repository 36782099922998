import { EMediaType, EPriceType } from '@shared/Types'
import { ICategory, IMedia, IPrice } from '@shared/Types'
import { EOperator, IModelFilter, PropFilter } from '@lib/Table'
import { IFilterDTO } from '../types'

export type TMediaFilter = IModelFilter<IMedia>
type TCategoryFilter = IModelFilter<ICategory>
type TPricePackageFilter = IModelFilter<IPrice>

class FilterMappingBase {
  parseMedia = (value: IFilterDTO, defaultFilter?: TMediaFilter): TMediaFilter => {
    const filter: TMediaFilter = Object.assign({ Page: 0, Amount: 12 }, defaultFilter)
    let propFilters: PropFilter<IMedia>[] = [{ FieldName: 'Type', Value: value.mediaType ?? EMediaType.Video, Operator: EOperator.Equal }]
    filter.PropFilters = propFilters
    return filter
  }

  parseCategory = (value: IFilterDTO): TCategoryFilter => {
    const filter: TCategoryFilter = {}
    let propFilters: PropFilter<ICategory>[] = [{ FieldName: 'Type', Value: value.mediaType ?? EMediaType.Video, Operator: EOperator.Equal }]
    filter.PropFilters = propFilters
    return filter
  }

  parsePricePackage = (value: IFilterDTO): TPricePackageFilter => {
    const filter: TPricePackageFilter = {}
    let propFilters: PropFilter<IPrice>[] = [{ FieldName: 'Type', Value: value.pricePackagetype ?? EPriceType.Media, Operator: EOperator.Equal }]
    filter.PropFilters = propFilters
    return filter
  }
}

const FilterMapping = new FilterMappingBase()
export default FilterMapping

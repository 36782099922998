import React, { Component, Fragment, ReactNode } from 'react'
import { SvgLogo } from '@shared/Icons'
import { TableFormater } from '@lib/Helpers'
import { ELazyStatus } from '@lib/ReduxBase'
import { ITicketConversation } from '@shared/Types'
import { Avatar, Box, CircularProgress, Stack, Typography, TypographyProps, styled } from '@mui/material'
import { parseAttachFiles } from './helpers'
import AttachFiles, { IAttachFile } from './AttachFiles'

interface IProps {
  status?: ELazyStatus
  data?: ITicketConversation[]
  renderIcon?: (item: ITicketConversation) => JSX.Element | undefined
}

export class ContentTimeline extends Component<IProps> {
  render() {
    const list = this.props.data ?? []
    return (
      <Stack sx={{ minHeight: '100%' }}>
        <Box sx={{ flex: 1 }} />
        <Box component='ul' sx={{ py: '18px' }}>
          {list.map((item, index) => (
            <Fragment key={index}>{this.renderItem(item)}</Fragment>
          ))}
        </Box>
        {list.length < 1 && this.props.status === ELazyStatus.Loading && (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', py: '9px' }}>
            <CircularProgress size='24px' />
          </Box>
        )}
      </Stack>
    )
  }

  renderItem = (item: ITicketConversation) => {
    const color = item.IsMe ? '#1976d2' : '#818181'
    const defaultIcon = <SvgLogo fontSize='small' fill='#fff' />
    const icon = this.props.renderIcon ? this.props.renderIcon(item) : undefined
    const title = item.From ?? (item.IsMe ? 'Me' : 'Feedback')
    return (
      <WrapItem component='li'>
        <Box sx={{ order: 1 }}>
          <Avatar sx={{ width: 28, height: 28, bgcolor: color }}>{icon ?? defaultIcon}</Avatar>
        </Box>
        <Box className='content' sx={{ order: 2 }}>
          <Box sx={{ backgroundColor: '#fff', borderRight: '6px', padding: '9px 14px', minWidth: '240px', maxWidth: '70%' }}>
            {this.renderText(title, { variant: 'subtitle1', sx: { fontWeight: 600, lineHeight: 1.3, color } })}
            {this.renderText(TableFormater.formatDate(item.DateCreated), { variant: 'caption', sx: { color: '#818181' } })}
            {this.renderText(
              <Typography noWrap sx={{ whiteSpace: 'pre-wrap' }}>
                {item.Message}
              </Typography>
            )}
            {this.renderImage(item.AttachFile)}
          </Box>
        </Box>
      </WrapItem>
    )
  }

  renderImage = (value: string) => {
    const images = parseAttachFiles(value)
    return <AttachFiles data={images.map<IAttachFile>((x) => ({ value: x }))} />
  }

  renderImageSimple = (value: string) => {
    const images = parseAttachFiles(value)
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '6px' }}>
        {images.map((item, index) => (
          <Box key={index} sx={{ height: '200px' }}>
            <Box component='img' src={item} sx={{ width: 'auto', height: '100%', borderRadius: '6px' }} />
          </Box>
        ))}
      </Box>
    )
  }

  renderText = (value: ReactNode, innerProps?: TypographyProps) => {
    if (!value) return <></>
    if (typeof value === 'string') {
      return (
        <Typography variant='body1' {...innerProps}>
          {value}
        </Typography>
      )
    }
    return value
  }
}
export default ContentTimeline

const WrapItem = styled(Box)({
  padding: '0 12px',
  display: 'flex',
  alignItems: 'flex-start',
  '& .content': {
    borderLeft: '1px solid #E9ECEE',
    marginLeft: '-14px',
    marginTop: '-4px',
    padding: '3px 24px 26px 28px',
    width: '100%'
  },
  '&:last-child .content': {
    borderLeftColor: 'transparent !important'
  }
})

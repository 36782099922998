import React, { FC } from 'react'
import { Box, Container, Grid, Skeleton, Stack } from '@mui/material'

export const PricingItemsSkeleton: FC = () => (
  <Container sx={{ py: '54px' }}>
    <Grid container spacing={4}>
      {['#ffeb3b', '#006bd6', '#ff8a42'].map((item, index) => (
        <Grid item key={index} xs={12} md={4}>
          <Box sx={{ borderBottom: `8px solid ${item}`, borderRadius: '6px', overflow: 'hidden' }}>
            <Skeleton variant='rounded' sx={{ width: '100%', height: '275px' }} />
          </Box>
          <Stack sx={{ gap: '12px', marginTop: '18px' }}>
            <Skeleton variant='rounded' sx={{ borderRadius: '6px', width: '100%', height: '184px' }} />
          </Stack>
        </Grid>
      ))}
    </Grid>
  </Container>
)

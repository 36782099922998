import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { EMediaType } from '@shared/Types'
import { RouteKey } from '@internal/route'
import { FormatTiming } from '@lib/Helpers'
import { IMedia, IPrice } from '@shared/Types'
import { CreateRoutePath } from '@lib/RouteBase'
import { Stack, Box, Tooltip, IconButton, styled, alpha } from '@mui/material'
import { IApiPopoverContextProps, withApiPopoverContext } from '@lib/ApiContext'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { AudioPlayEventProvider } from './EventProvider'
import FormQuicklyAddToCart from './FormQuicklyAddToCart'
import { IMediaDetailQueryParam } from '@shared/Services/QueryParamService'

interface IProps extends IApiPopoverContextProps {
  data: IMedia
  prices: IPrice[]
  isBlank?: boolean
  isPlaying?: boolean
  play?: (type: EMediaType) => void
  onAddToCart?: (media: IMedia, price: IPrice) => void
}

class MediaAudioItemBase extends Component<IProps> {
  render() {
    return (
      <Wrapper>
        <Info {...this.componentLink()} onClick={(e) => AudioPlayEventProvider.PushEventSource(e.nativeEvent)}>
          <WraperImage color={this.props.isPlaying ? '#767676' : '#1976D2'}>
            {this.props.isPlaying ? <PlayCircleOutlineIcon fontSize='large' /> : <MusicNoteIcon fontSize='large' />}
          </WraperImage>
          <Stack sx={{ gap: '5px', pt: '5px', width: 'calc(100% - 84px)', justifyContent: 'center' }}>
            <Title>{this.props.data.Name ?? 'Exo 2/Bold/16px'}</Title>
            <TextDes>{this.props.data ? this.props.data.Description : 'Author/Exo 2/Medium/16px'}</TextDes>
          </Stack>
        </Info>
        <Text>{FormatTiming(this.props.data.Duration)}</Text>
        {/* <Text>{FormatSize(this.props.data.Size)}</Text> */}
        <Box width='35px' />
        <Box sx={{ display: 'flex', gap: '6px', paddingRight: '24px' }}>
          <Tooltip title='Add to cart'>
            <IconButton sx={{ flex: '0 0 auto' }} onClick={this.handleClickQuicklyAddToCart}>
              <AddShoppingCartIcon sx={{ color: '#0ED197' }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Wrapper>
    )
  }

  handleClickQuicklyAddToCart: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    this.props.contextPopover.showPopover({
      anchorEl: event.currentTarget,
      popoverProps: {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transformOrigin: { vertical: 'top', horizontal: 'right' }
      },
      content: (
        <FormQuicklyAddToCart
          prices={this.props.prices}
          media={this.props.data}
          onSubmit={async (value) => {
            const item = this.props.prices.find((x) => x.Id === value.PriceSelectedId)
            if (!item) return
            this.props.onAddToCart && this.props.onAddToCart(this.props.data, item)
            this.props.contextPopover.closePopover()
          }}
        />
      )
    })
  }

  componentLink = () => {
    const path = CreateRoutePath<IMediaDetailQueryParam>(RouteKey.Detail, {
      query: { type: EMediaType.Audio, id: this.props.data.Id }
    })
    return { component: Link, to: path, target: this.props.isBlank ? '_blank' : '_self' }
  }
}
export const MediaItemAudio = withApiPopoverContext(MediaAudioItemBase)
export default MediaItemAudio

const Wrapper = styled(Box)({
  display: 'flex',
  gap: '10px',
  padding: '6px',
  alignItems: 'center',
  borderRadius: '4px',
  transition: 'all linear 0.2s',
  overflow: 'hidden',
  backgroundColor: '#fafafa',
  '&:hover': {
    backgroundColor: '#fdfdfd'
  },
  '& .btn-open-detail': {
    transition: 'all linear 0.2s',
    opacity: 0,
    transform: 'translateX(-50%)'
  },
  '&:hover .btn-open-detail': {
    opacity: 1,
    transform: 'translateX(0)'
  }
})

const Info = styled(Box)({
  display: 'flex',
  flex: 1,
  gap: '10px',
  cursor: 'pointer',
  width: '350px',
  textDecoration: 'unset !important',
  color: '#202020'
})

const Title = styled('h3')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 700,
  padding: '5px 0',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
})

const Text = styled('span')({
  display: 'inline-block',
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 500,
  color: '#606060',
  overflow: 'hidden'
})

const TextDes = styled(Text)({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
})

const WraperImage = styled(Box)<{ color?: string }>(({ color }) => ({
  backgroundColor: alpha(color ?? '#1976D2', 0.15),
  flex: '0 0 auto',
  width: '84px',
  height: '84px',
  margin: '-6px 0 -6px -6px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

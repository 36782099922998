import React, { Component } from 'react'
import { Box, Fade } from '@mui/material'
import MovieIcon from '@mui/icons-material/Movie'
import AudioFileIcon from '@mui/icons-material/AudioFile'
import { FileHandler } from '../FileHandler'
import UploadContent, { TFileItemConfig } from './UploadContent'
import ContentControl from './ContentControl'

interface IProps {
  value?: File[]
  onChange?: (value: File[]) => void
}

export default class AreaFileInputs extends Component<IProps> {
  render() {
    const isExistFiles = this.getIsExistFiles()
    const accepts = FileHandler.accepts.join(',')
    return (
      <>
        <Fade in={!isExistFiles} unmountOnExit timeout={{ appear: 0, enter: 300, exit: 0 }}>
          <Box sx={{ display: 'flex', flex: 1 }}>
            <ContentControl accept={accepts} onChange={(value) => this.onChangeFiles(Array.from(value))} />
          </Box>
        </Fade>
        <Fade in={isExistFiles} unmountOnExit timeout={{ appear: 0, enter: 300, exit: 0 }}>
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <UploadContent isShow={isExistFiles} config={this.getConfigRenderFiles()} onClickDelete={this.handleClickDelete} />
          </Box>
        </Fade>
      </>
    )
  }

  getFiles = () => {
    return this.props.value ?? []
  }

  onChangeFiles = (files: File[]) => {
    this.props.onChange && this.props.onChange(files)
  }

  handleClickDelete = (fileName: string) => {
    const files = this.getFiles()
    const list = files.filter((e) => e.name !== fileName)
    this.onChangeFiles(list)
  }

  getIsExistFiles = () => {
    const files = this.getFiles()
    return files.length > 0
  }

  getConfigRenderFiles = (): TFileItemConfig[] => {
    const files = this.getFiles()
    const slice = FileHandler.sliceFiles(files)
    return [
      {
        type: 'file',
        files: slice.audios,
        icon: <AudioFileIcon fontSize='large' sx={{ color: '#F03A17' }} />,
        name: 'Audio'
      },
      { type: 'line', isShow: slice.audios.length > 0 && slice.videos.length > 0 },
      {
        type: 'file',
        files: slice.videos,
        icon: <MovieIcon fontSize='large' sx={{ color: '#00bdff' }} />,
        name: 'Video'
      }
    ]
  }
}

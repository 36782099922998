import { FC } from 'react'
import { Box, Grid, Skeleton, Stack, styled, useMediaQuery, useTheme } from '@mui/material'
import HeightEqualWidth from '../HeightEqualWidth'
import { EMediaType } from '@shared/Types'

export const PlayListSkeleton: FC = () => {
  return (
    <Stack direction='row' py='10px' mx='-7.5px'>
      {[...Array(5)].map((e, index) => {
        return (
          <Box key={index} sx={{ flex: '0 0 auto', margin: '0 7.5px', width: { xs: '50%', sm: '25%', md: '20%' } }}>
            <HeightEqualWidth>
              <Skeleton variant='rounded' width='100%' height='100%' />
            </HeightEqualWidth>
          </Box>
        )
      })}
      <Box sx={{ width: '10px', flex: '0 0 auto' }} />
    </Stack>
  )
}

export const CategoriesSkeletonV2: FC = () => {
  const match = useMediaQuery('(max-width: 768px)')
  const data = match ? [1, 2, 3] : [1, 2, 3, 4, 5]
  return (
    <Stack direction='row' gap='10px' py='15px'>
      {data.map((e) => {
        return (
          <Stack key={e} flex='1' gap='5px'>
            <Box sx={{ position: 'relative', width: '100%', pb: '100%' }}>
              <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                <Skeleton variant='rounded' width='100%' height='100%' />
              </Box>
            </Box>
            <Skeleton variant='text' sx={{ fontSize: '0.9rem' }} />
          </Stack>
        )
      })}
    </Stack>
  )
}

export const CategoriesSkeleton: FC = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  const _getData = (max: number) => {
    let t: number[] = []
    let i = 0
    while (i < max) {
      t.push(i)
      i++
    }
    return t
  }
  const _dataTemp = matches ? _getData(8) : _getData(6)
  return (
    <Box sx={{ padding: '24px 8px', width: '100%' }}>
      <Grid container spacing={2} sx={{ backgroundColor: '#fff' }}>
        {_dataTemp.map((e) => {
          return (
            <Grid item xs={6} sm={4} md={3} key={e}>
              <Stack direction='row' width='100%' gap='15px'>
                <Skeleton variant='rounded' width={84} height={84} />
                <Stack sx={{ py: '5px', flex: 1 }}>
                  <Skeleton variant='text' sx={{ fontSize: '1.1rem' }} />
                  <Skeleton variant='text' sx={{ fontSize: '0.9rem' }} />
                </Stack>
              </Stack>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

interface IProductsSkeletonProps {
  items?: {
    xs: number
    md: number
  }
  status?: EMediaType
}
export const ProductsSkeleton: FC<IProductsSkeletonProps> = (props) => {
  if (props.status === EMediaType.Audio) return <AudioSkeleton {...props} />
  return <VideoSkeleton {...props} />
}

const VideoSkeleton: FC<IProductsSkeletonProps> = (props) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const initData = (max: number) => {
    let i = 0
    let list = []
    while (i < max) {
      list.push(i)
      i++
    }
    return list
  }

  const config = props.items
  const _dataTemp = matches ? initData(config ? config.md : 12) : initData(config ? config.xs : 6)
  return (
    <Grid container spacing={2} sx={{ marginTop: '24px' }}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Skeleton variant='text' sx={{ fontSize: '1.2rem', width: '160px' }} />
          <Skeleton variant='text' sx={{ fontSize: '1.2rem', width: '120px' }} />
        </Box>
      </Grid>
      {_dataTemp.map((e) => {
        return (
          <Grid item xs={12} sm={6} md={3} key={e.toString()}>
            <Box>
              <Box position='relative' width='100%'>
                <Box sx={{ position: 'relative', width: '100%', pb: '82%' }}>
                  <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                    <Skeleton variant='rounded' width='100%' height='100%' />
                  </Box>
                </Box>
              </Box>
              <Stack direction='row' gap='5px' padding='5px 2px' alignItems='center'>
                <Skeleton variant='text' sx={{ flex: 1, fontSize: '1.2rem' }} />
                <Skeleton variant='rounded' width={25} height={16} />
              </Stack>
            </Box>
          </Grid>
        )
      })}
    </Grid>
  )
}

const AudioSkeleton: FC<IProductsSkeletonProps> = (props) => {
  return (
    <Grid container spacing={2}>
      {[1, 2, 3, 4, 5, 6, 7, 8].map((e) => {
        return (
          <Grid item xs={12} key={e}>
            <Stack direction='row' gap='10px' p='10px 5px' alignItems='center'>
              <Stack flex='0 0 auto' width='350px' direction='row' gap='10px'>
                <Box sx={{ flex: '0 0 auto', width: '84px' }}>
                  <HeightEqualWidth />
                </Box>
                <Stack gap='15px' px='5px' flexGrow='1'>
                  <Skeleton variant='text' sx={{ fontSize: '1.2rem' }} />
                  <Skeleton variant='text' sx={{ fontSize: '0.9rem' }} />
                </Stack>
              </Stack>
              <Box flexGrow={1} />
              <Stack direction='row' gap='10px' p='0 15px' flex='0 0 auto'>
                {[1, 2, 3].map((e) => {
                  return <Skeleton variant='text' sx={{ fontSize: '0.9rem', width: '75px' }} key={e} />
                })}
              </Stack>
              <Box flexGrow={1} />
              <Stack direction='row' gap='20px'>
                <Skeleton variant='rounded' width={24} height={24} />
                <Skeleton variant='rounded' width={24} height={24} />
                <Skeleton variant='rounded' width={24} height={24} />
              </Stack>
            </Stack>
          </Grid>
        )
      })}
    </Grid>
  )
}

export const ChooseLicenseSkeleton: FC = () => {
  const _data = [0, 1, 2, 3]
  return (
    <Stack sx={{ gap: '9px', backgroundColor: '#FFFFFF', padding: '5px' }}>
      <Skeleton variant='text' sx={{ fontSize: '16px' }} />
      <Skeleton variant='text' sx={{ fontSize: '16px' }} />
      <Box height='5px' />
      <Stack>
        {_data.map((e) => {
          return (
            <Box key={e} sx={{ height: '42px', display: 'flex', alignItems: 'center', gap: '9px' }}>
              <Skeleton variant='circular' width={24} height={24} />
              <Skeleton variant='text' sx={{ fontSize: '17px', flex: 1 }} />
            </Box>
          )
        })}
      </Stack>
      <Skeleton variant='rounded' width='100%' height={36.5} />
    </Stack>
  )
}

export const AudioDetailSkeleton: FC = () => {
  return (
    <Stack sx={{ gap: '9px', backgroundColor: '#FFFFFF', padding: '5px', height: '450px' }}>
      <Skeleton variant='text' sx={{ fontSize: '16px' }} />
      <Stack direction='row' sx={{ gap: '10px' }}>
        <Skeleton variant='rounded' width={132} height={132} />
        <Stack sx={{ gap: '10px', flex: 1 }}>
          <Skeleton variant='text' sx={{ fontSize: '22px' }} />
          <Skeleton variant='text' sx={{ fontSize: '18px' }} />
          <Skeleton variant='rounded' width='100%' height={3} />
          <Stack direction='row' justifyContent='space-between'>
            <Skeleton variant='rounded' width={210} height={31} />
            <Skeleton variant='rounded' width={100} height={31} />
          </Stack>
        </Stack>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Skeleton variant='text' width={150} sx={{ fontSize: '16px' }} />
        <Skeleton variant='text' width={150} sx={{ fontSize: '16px' }} />
        <Skeleton variant='text' width={150} sx={{ fontSize: '16px' }} />
      </Stack>
      <Skeleton variant='text' sx={{ fontSize: '16px' }} />
    </Stack>
  )
}

export const VideoDetailSkeleton: FC = () => {
  const imageSource = process.env.PUBLIC_URL + '/images/video-player.png'
  return (
    <Stack sx={{ gap: '9px', backgroundColor: '#FFFFFF', padding: '5px' }}>
      <Skeleton variant='text' sx={{ fontSize: '16px' }} />
      <Box position='relative'>
        <BoxSkeleton>
          <Skeleton variant='rounded' width='100%' height='100%' />
        </BoxSkeleton>
        <CustomImage src={imageSource} />
      </Box>
      <Skeleton variant='text' sx={{ fontSize: '22px' }} />
      <Skeleton variant='text' sx={{ fontSize: '18px' }} />
      <Stack direction='row' justifyContent='space-between'>
        <Skeleton variant='text' width={150} sx={{ fontSize: '16px' }} />
        <Skeleton variant='text' width={150} sx={{ fontSize: '16px' }} />
        <Skeleton variant='text' width={150} sx={{ fontSize: '16px' }} />
      </Stack>
      <Skeleton variant='text' sx={{ fontSize: '16px' }} />
    </Stack>
  )
}

const CustomImage = styled('img')({
  width: '100%',
  opacity: 0
})

const BoxSkeleton = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%'
})

export const SearchBoxSkeleton: FC = () => {
  return (
    <Stack sx={{ pt: '50px', gap: '15px', alignItems: 'center' }}>
      <Skeleton variant='text' sx={{ fontSize: '36.5px', width: '225px' }} />
      <Stack sx={{ gap: '5px', width: '100%', alignItems: 'center' }}>
        <Skeleton variant='text' sx={{ fontSize: '16px', width: { xs: '80%', sm: '55%', md: '35%' } }} />
        <Skeleton variant='text' sx={{ fontSize: '16px', width: { xs: '90%', sm: '65%', md: '45%' } }} />
      </Stack>
      <Skeleton variant='rounded' height={56} sx={{ borderRadius: '28px', width: { xs: '90%', sm: '450px' } }} />
    </Stack>
  )
}

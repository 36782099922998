import { Draft, PayloadAction, Update, EntityAdapter, EntityState } from '@reduxjs/toolkit'
import { ISliceBase } from './types'

export const CRUDActionRedux = <TModel, TState extends ISliceBase<TModel>>(SliceAdapter: EntityAdapter<TModel>) => ({
  Update: (state: Draft<TState>, action: PayloadAction<Update<TModel>>) => {
    SliceAdapter.updateOne(state.Data as EntityState<TModel>, action.payload)
  },
  Add: (state: Draft<TState>, action: PayloadAction<TModel>) => {
    SliceAdapter.addOne(state.Data as EntityState<TModel>, action.payload)
  },
  Remove: (state: Draft<TState>, action: PayloadAction<string | number>) => {
    SliceAdapter.removeOne(state.Data as EntityState<TModel>, action.payload)
  },
  RemoveAll: (state: Draft<TState>) => {
    SliceAdapter.removeAll(state.Data as EntityState<TModel>)
  },
  UpsetMany: (state: Draft<TState>, action: PayloadAction<TModel[]>) => {
    SliceAdapter.removeAll(state.Data as EntityState<TModel>)
    SliceAdapter.upsertMany(state.Data as EntityState<TModel>, action.payload)
  }
})
// export const CRUDTableActionRedux = <TModel, TState extends ITableReduxSliceBase<TModel>>(selectId: (model: TModel) => any) => ({
//   Update: (state: Draft<TState>, action: PayloadAction<Update<TModel>>) => {
//     const data = state.tableInfo.PageInfo.data
//     const temp = data[action.payload.id]
//     data[action.payload.id] = Object.assign(temp ?? {}, action.payload.changes) as Draft<TModel> | undefined
//   },
//   Add: (state: Draft<TState>, action: PayloadAction<TModel>) => {
//     state.tableInfo.PageInfo.data[selectId(action.payload)] = action.payload as Draft<TModel> | undefined
//   },
//   Remove: (state: Draft<TState>, action: PayloadAction<string | number>) => {
//     const temp = { ...state.tableInfo.PageInfo.data }
//     delete temp[action.payload]
//     state.tableInfo.PageInfo.data = temp
//   },
//   RemoveAll: (state: Draft<TState>) => {
//     state.tableInfo.PageInfo.data = {}
//   },
//   UpsetMany: (state: Draft<TState>, action: PayloadAction<TModel[]>) => {
//     state.tableInfo.PageInfo.data = {}
//     state.tableInfo.PageInfo.data = action.payload.reduce<Dictionary<TModel>>((a, b) => {
//       a[selectId(b)] = b
//       return a
//     }, {}) as Draft<Dictionary<TModel>>
//   }
// })

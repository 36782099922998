import type { AcceptableVariables, UnresolvedVariables, FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';
import type {AuthenticationGraphDbContextUsersSortInput} from '../inputs';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface AuthenticationGraphDbContextFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'AuthenticationGraphDbContext', T, TVariables> {

    on<XName extends ImplementationType<'AuthenticationGraphDbContext'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): AuthenticationGraphDbContextFetcher<
        XName extends 'AuthenticationGraphDbContext' ?
        T & X :
        WithTypeName<T, ImplementationType<'AuthenticationGraphDbContext'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'AuthenticationGraphDbContext'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): AuthenticationGraphDbContextFetcher<T, TVariables>;


    readonly __typename: AuthenticationGraphDbContextFetcher<T & {__typename: ImplementationType<'AuthenticationGraphDbContext'>}, TVariables>;


    user<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'ApplicationUser', X, XVariables>
    ): AuthenticationGraphDbContextFetcher<
        T & {readonly "user"?: X}, 
        TVariables & XVariables & AuthenticationGraphDbContextArgs["user"]
    >;

    user<
        XArgs extends AcceptableVariables<AuthenticationGraphDbContextArgs['user']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'ApplicationUser', X, XVariables>
    ): AuthenticationGraphDbContextFetcher<
        T & {readonly "user"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, AuthenticationGraphDbContextArgs['user']>
    >;

    user<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "user", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'ApplicationUser', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"user", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): AuthenticationGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & AuthenticationGraphDbContextArgs["user"] & XDirectiveVariables
    >;

    user<
        XArgs extends AcceptableVariables<AuthenticationGraphDbContextArgs['user']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "user", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'ApplicationUser', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"user", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): AuthenticationGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, AuthenticationGraphDbContextArgs['user']> & XDirectiveVariables
    >;


    users<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'ApplicationUserPage', X, XVariables>
    ): AuthenticationGraphDbContextFetcher<
        T & {readonly "users"?: X}, 
        TVariables & XVariables & AuthenticationGraphDbContextArgs["users"]
    >;

    users<
        XArgs extends AcceptableVariables<AuthenticationGraphDbContextArgs['users']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'ApplicationUserPage', X, XVariables>
    ): AuthenticationGraphDbContextFetcher<
        T & {readonly "users"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, AuthenticationGraphDbContextArgs['users']>
    >;

    users<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "users", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'ApplicationUserPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"users", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): AuthenticationGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & AuthenticationGraphDbContextArgs["users"] & XDirectiveVariables
    >;

    users<
        XArgs extends AcceptableVariables<AuthenticationGraphDbContextArgs['users']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "users", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'ApplicationUserPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"users", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): AuthenticationGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, AuthenticationGraphDbContextArgs['users']> & XDirectiveVariables
    >;
}

export const authenticationGraphDbContext$: AuthenticationGraphDbContextFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "AuthenticationGraphDbContext", 
            "EMBEDDED", 
            [], 
            [
                {
                    category: "REFERENCE", 
                    name: "user", 
                    argGraphQLTypeMap: {id: 'String!'}, 
                    targetTypeName: "ApplicationUser", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "users", 
                    argGraphQLTypeMap: {
                        sort: '[AuthenticationGraphDbContextUsersSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "ApplicationUserPage", 
                    undefinable: true
                }
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export interface AuthenticationGraphDbContextArgs {

    readonly user: {
        readonly id: string
    }, 

    readonly users: {
        readonly sort?: ReadonlyArray<AuthenticationGraphDbContextUsersSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }
}

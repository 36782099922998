export enum EOpenType {
  audio = 'audio',
  video = 'video',
  text = 'text',
  image = 'image',
  other = 'other'
}

export interface PreviewInfo {
  Type: EOpenType
  content: string
}

export interface TimeSpan {
  Ticks: number
  Days: number
  Hours: number
  Milliseconds: number
  Minutes: number
  Seconds: number
  TotalDays: number
  TotalHours: number
  TotalMilliseconds: number
  TotalMinutes: number
  TotalSeconds: number
}

export interface IItemInfo {
  Id: string
  Name: string
  DateCreated: string
  Extension: string
  DriveFileId: string
  Size: number
  Length?: TimeSpan
  Icon?: string
  MetaFolderId: string
  HasThumbnail: boolean
}

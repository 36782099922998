import { CRUDServiceBase } from '@lib/Http'
import { EOperator, ITableTemplateState } from '@lib/Table'
import { TableToRequestFilter } from '@lib/Helpers'
import { IBillingDetail, IBillingDetailResourceInfo } from '@shared/Types'
import Graphql from '@coreprj/graphql'

export type TBillingDetail = Graphql.QMediaStore.TBillingDetail
export type TBillingDetailsFilterParams = Graphql.QMediaStore.TBillingDetailsFilterParams

export interface IBillingDetailDTO extends IBillingDetail {
  Name?: string
  ExistedIds?: string[]
}

class BillingDetailServiceBase extends CRUDServiceBase<IBillingDetail, string> {
  billingId: string
  constructor(id: string) {
    super(`/api/v1/user/Billing/${id}/Detail`)
    this.billingId = id
  }
}
export const CreateBillingDetailService = (id: string) => new BillingDetailServiceBase(id)

class BillingDetailServiceGraphql {
  AllGraphQL = async (filter: TBillingDetailsFilterParams, signal?: AbortSignal): Promise<Graphql.TGraphQlResult<TBillingDetail> | undefined> => {
    const res = await Graphql.MediaStore.Query(Graphql.QMediaStore.BillingDetailsQuery(filter), { signal })
    return res.mediaStore.billingDetails as Graphql.TGraphQlResult<TBillingDetail>
  }
}
export const BillingDetailService = new BillingDetailServiceGraphql()

class BillingDetailMappingBase {
  tableInfoToFilter = (tableInfo: ITableTemplateState<IBillingDetailDTO>, billingId: string) => {
    return new TableToRequestFilter.Graphql<IBillingDetailDTO, TBillingDetail>({
      fieldMapping: {
        Name: { field: 'resourceInfo' },
        BillingId: { field: 'billingId' },
        ResourceId: { field: 'resourceId' },
        Payout: { field: 'payout', type: 'double' },
        DateCreated: { field: 'dateCreated' }
      }
    })
      .fromTable(tableInfo, [])
      .sort({ field: 'Id', sort: 'asc' })
      .filter({ field: 'BillingId', operator: EOperator.Equal, value: billingId })
      .build()
  }

  parseResourceInfo = (value: string = ''): IBillingDetailResourceInfo => {
    try {
      return JSON.parse(value)
    } catch (error) {
      return {}
    }
  }

  billingDetails = (items: TBillingDetail[] = []): IBillingDetailDTO[] => {
    const existedIds = Array.from(new Set(items.map((x) => x.resourceId)))
    return items.map((item) => {
      const info = this.parseResourceInfo(item.resourceInfo)
      const data: IBillingDetailDTO = {
        Id: item.id ?? '',
        BillingId: item.billingId ?? '',
        ResourceId: item.resourceId ?? '',
        Payout: item.payout ?? '',
        ResourceInfo: item.resourceInfo ?? '',
        Name: info.MediaInfo?.Name,
        ExistedIds: existedIds.filter((x) => x !== item.resourceId),
        DateCreated: item.dateCreated
      }
      return data
    })
  }
}
export const BillingDetailMapping = new BillingDetailMappingBase()

import { FetchDelay } from '@lib/Helpers'
import { ServiceBase, CreateHttpService } from '@lib/Http'
import { Dictionary } from '@reduxjs/toolkit'
import { ICart, IOrderDetail, ECartType, IPaypalOrder, IResourceInfoOrderDetail, EMediaType } from '@shared/Types'

interface IValidateResult {
  ReferenceId: string
  OrderDetails: IOrderDetail[]
}

class CheckOutServiceBase extends ServiceBase {
  constructor() {
    super(CreateHttpService(`${process.env.REACT_APP_API_URI}/api/v1/user/Checkout`))
  }
  private urlValidate = '/Validate'
  validate = (value: ICart[], signal?: AbortSignal): Promise<boolean> => {
    return FetchDelay(async () => {
      const bodyRequest = CheckOutMapping.validate(value)
      const result = await this.PushNotify(this.Post<boolean>, this.urlValidate, bodyRequest, { signal })
      return result
    }, 2000)
  }

  private urlCreateOrder = '/CreateOrder'
  createOrder = (value: ICart[], referenceId?: string, signal?: AbortSignal): Promise<IValidateResult> => {
    return FetchDelay(async () => {
      const bodyRequest = CheckOutMapping.validate(value)
      const result = await this.PushNotify(this.Post<IValidateResult>, this.urlCreateOrder, bodyRequest, { signal, params: { referenceId } })
      return result
    }, 700)
  }

  private urlPayPalCreate = (referenceId: string) => `/paypal/Create?referenceId=${referenceId}`
  payPalCreate = (referenceId: string, signal?: AbortSignal): Promise<IPaypalOrder | null | undefined> => {
    return FetchDelay(async () => {
      const result = await this.TryPushNotify(this.Post<IPaypalOrder>, this.urlPayPalCreate(referenceId), {}, { signal })
      return result
    }, 1500)
  }

  private urlPayPalCapture = '/paypal/Capture'
  payPalCapture = (value: { orderId: string; token: string }, signal?: AbortSignal): Promise<number> => {
    return FetchDelay(async () => {
      const params = `?ApprovedId=${value.orderId}&Token=${value.token}`
      const result = await this.PushNotify(this.Get<number>, this.urlPayPalCapture + params, { signal })
      return result
    }, 1500)
  }
}

const CheckOutService = new CheckOutServiceBase()
export default CheckOutService

class CheckOutMappingBase {
  validate = (value: ICart[]): Partial<ICart>[] => {
    return value.map<Partial<ICart>>((item) => ({
      ProductId: item.Id.toString(),
      PricePackageId: item.PriceOrigin?.Id ?? -1,
      Amount: item.JsonContent?.Links?.length ?? 0,
      SocialUrl: JSON.stringify(item.JsonContent?.Links ?? '[]'),
      Type: item.PriceOrigin?.Type as ECartType,
      ProductName: item.Name
    }))
  }

  carts = (value: IOrderDetail[], cartLocals: ICart[]): ICart[] => {
    const obj = cartLocals.reduce<Dictionary<ICart>>((a, b) => {
      a[b.Id] = b
      return a
    }, {})
    return value.map<ICart>((item) => {
      const info: IResourceInfoOrderDetail = JSON.parse(item.ResourceInfo)
      const data: ICart = {
        Id: item.ResourceId,
        Name: info.Name,
        Type: obj[item.ResourceId]?.Type ?? EMediaType.Video,
        ImageUri: obj[item.ResourceId]?.ImageUri,
        Description: info.Name,
        JsonContent: { Links: JSON.parse(item.SocialUrl) },
        PriceOrigin: obj[item.ResourceId]?.PriceOrigin,
        Amount: item.Amount,
        Price: item.Price
      }
      return data
    })
  }
}
export const CheckOutMapping = new CheckOutMappingBase()

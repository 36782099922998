import { createAsyncThunk } from '@reduxjs/toolkit'
import { ICategory, IMedia, IPrice } from '@shared/Types'
import PriceService from '@internal/services/PriceService'
import { IExploreStateSlice } from './redux.types'
import MediaService from './service.media'
import CategoryService from './service.category'

interface IReponseThunk {
  categories: ICategory[]
  medias: IMedia[]
  mediaTotal: number
}

export const fetchExploreThunk = createAsyncThunk<IReponseThunk>('fetchExploreThunk', async (_, thunkAPI) => {
  const state = thunkAPI.getState() as { ExploreSlice: IExploreStateSlice }
  const filter = state.ExploreSlice.filter
  const [medias, categories] = await Promise.all([MediaService.filter(filter, thunkAPI.signal), CategoryService.filter(filter, thunkAPI.signal)])
  const dataReponse: IReponseThunk = { categories, medias: medias.Data, mediaTotal: medias.Total }
  return dataReponse
})

// ========= ========= ========= First Thunk ========= ========= =========
interface IReponseFirstThunk {
  prices: IPrice[]
}

export const fetchExploreFirstThunk = createAsyncThunk<IReponseFirstThunk>('fetchExploreFirstThunk', async (_, thunkAPI) => {
  const prices = await PriceService.All({ PropFilters: [{ FieldName: 'Type', Value: 'Media' }] }, thunkAPI.signal).catch(() => ({ Data: [] }))
  const dataReponse: IReponseFirstThunk = {
    prices: prices?.Data ?? []
  }
  return dataReponse
})

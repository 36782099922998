import React, { Component } from 'react'
import { motion } from 'framer-motion'
import { Box, Button, Collapse, styled } from '@mui/material'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import { ConfirmButton } from '../Buttons'
import { Text16 } from '@internal/shareds/Text'
import Helpers from '../../helpers'

interface IProps {
  data: string[]
  onDelete?: (value: string) => void
  onClear?: () => void
}

export default class LinkRows extends Component<IProps> {
  render() {
    const pr = this.props.data.length > Helpers.maxLink ? '34px' : '46px'
    return (
      <Box sx={{ transition: 'leaner 0.2s', paddingRight: pr }}>
        {this.renderItems()}
        <Collapse in={this.props.data.length > 0} unmountOnExit>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '18px' }}>
            <ConfirmButton
              superSmalled
              onSubmit={this.props.onClear}
              renderChild={(onClick) => (
                <Button
                  color="inherit"
                  size="small"
                  endIcon={<ClearAllIcon />}
                  sx={{ textTransform: 'unset!important', minWidth: '120px', color: '#454545', fontWeight: 600 }}
                  onClick={onClick}
                >
                  Clear all
                </Button>
              )}
            />
          </Box>
        </Collapse>
      </Box>
    )
  }

  renderItems = () => {
    const list = this.props.data
    return (
      <WrapList length={list.length} max={Helpers.maxLink}>
        {list.map((item, index) => (
          <li key={item}>
            <Item
              sx={{ borderRadius: '6px' }}
              initial={{ opacity: 0.3, backgroundColor: '#dedede' }}
              animate={{ opacity: 1, backgroundColor: '#fafafa' }}
              transition={{ ease: 'linear', duration: 1 }}
            >
              <div>
                <Text16 className="noselect">{index + 1}.</Text16>
                <CustomText noWrap {...{ component: 'a', href: item, target: '_blank' }}>
                  {item}
                </CustomText>
                <ConfirmButton gray superSmalled onSubmit={() => this.handleClick(item)} />
              </div>
            </Item>
          </li>
        ))}
      </WrapList>
    )
  }

  handleClick = (value: string) => {
    this.props.onDelete && this.props.onDelete(value)
  }
}

const WrapList = styled('ul')<{ length: number; max: number }>(({ length, max }) => ({
  marginTop: '12px',
  maxHeight: `${max * 42}px`,
  overflow: 'auto',
  paddingRight: length > max ? `4px` : 0,
}))

const Item = styled(motion.div)({
  '& > div': {
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    marginTop: '4px',
    padding: '3px 9px',
  },
})

const CustomText = styled(Text16)({
  flex: 1,
  textDecoration: 'unset !important',
  '&:hover': {
    color: '#1976d2',
  },
})

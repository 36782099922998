import React, { Component } from 'react'
import { Skeleton } from '@mui/material'
import { EMediaType } from '@shared/Types'
import { IMediaDTO } from '../../types'
import AudioMediaPlayerLayout from './Audio'
import VideoMediaPlayerLayout from './Video'
import { AudioDetailSkeleton, VideoDetailSkeleton } from '@internal/shareds/Skeletons'

interface IProps {
  data?: IMediaDTO
  mediaType?: EMediaType
}

export default class MediaPlayerLayout extends Component<IProps> {
  render() {
    const { data, mediaType } = this.props
    if (mediaType === EMediaType.Audio) {
      return !data ? <AudioDetailSkeleton /> : <AudioMediaPlayerLayout data={data} />
    } else if (mediaType === EMediaType.Video) {
      return !data ? <VideoDetailSkeleton /> : <VideoMediaPlayerLayout data={data} />
    } else {
      return <Skeleton variant='rounded' width='100%' height='450px' />
    }
  }
}

import { CRUDServiceBase } from '@lib/Http'
import { ITableTemplateState } from '@lib/Table'
import { TableToRequestFilter } from '@lib/Helpers'
import { EOrderPayment, EOrderStatus, IOrder, IOrderDetail } from '@shared/Types'
import Graphql from '@coreprj/graphql'

export type TOrder = Graphql.QMediaStore.TOrder
type TOrdersFilterParams = Graphql.QMediaStore.TOrdersFilterParams
type TAllResult = Graphql.TGraphQlResult<TOrder>

export type TOrderDetail = Graphql.QMediaStore.TOrderDetail
type TOrderFilterParams = Graphql.QMediaStore.TOrderFilterParams

class OrderServiceBase extends CRUDServiceBase<IOrder, number> {
  constructor() {
    super(`/api/v1/user/Order`)
  }

  GraphQLAll = async (params: TOrdersFilterParams, signal?: AbortSignal): Promise<TAllResult> => {
    const res = await Graphql.MediaStore.Query(Graphql.QMediaStore.UserOrdersQuery(params), { signal, delay: 500 })
    return res.mediaStore.orders as TAllResult
  }

  GraphQLSingle = async (params: TOrderFilterParams, signal?: AbortSignal): Promise<TOrder> => {
    const res = await Graphql.MediaStore.Query(Graphql.QMediaStore.UserOrderQuery(params), { signal, delay: 500 })
    return res.mediaStore.order as TOrder
  }
}
const OrderService = new OrderServiceBase()
export default OrderService

class OrderMappingBase {
  tableInfoToFilter = (tableInfo: ITableTemplateState<IOrder>) => {
    return new TableToRequestFilter.Graphql<IOrder, TOrder>({
      fieldMapping: {
        Name: { field: 'name' },
        ReferenceId: { field: 'referenceId' },
        TransactionId: { field: 'transactionId' },
        Method: { field: 'method' },
        Status: { field: 'status' },
        DateCreated: { field: 'dateCreated' },
        DateUpdated: { field: 'dateUpdated' }
      }
    })
      .fromTable(tableInfo, ['Name', 'DisplayName', 'ReferenceId', 'TransactionId'])
      .sort({ field: 'Id', sort: 'asc' })
      .build()
  }

  orders = (items: TOrder[] = []): IOrder[] => {
    return items.map<IOrder>((item) => ({
      Id: item.id,
      UserId: item.userId,
      Name: item.name,
      DisplayName: item.user?.displayName ?? '',
      Description: item.description ?? '',
      ReferenceId: item.referenceId,
      Status: item.status as EOrderStatus,
      Method: item.method as EOrderPayment,
      TransactionId: item.transactionId ?? '',
      TransactionInfo: item.transactionInfo ?? '',
      DateCreated: item.dateCreated
    }))
  }

  orderDetail = (items: TOrderDetail[] = []): IOrderDetail[] => {
    return items.map<IOrderDetail>((item, index) => ({
      Id: item?.id ?? '',
      OrderId: item?.orderId ?? '',
      ResourceId: item?.resourceId ?? '',
      Price: item?.price ?? 0,
      Amount: item?.amount ?? 0,
      PricePackageId: item?.pricePackageId ?? '',
      SocialUrl: item?.socialUrl ?? '',
      Type: item?.type ?? '',
      ResourceInfo: item?.resourceInfo ?? '',
      DateCreated: item?.dateCreated ?? ''
    }))
  }

  order = (value?: TOrder): IOrder => {
    const data: IOrder = {
      Id: value?.id ?? '',
      UserId: value?.userId ?? '',
      Name: value?.name ?? '',
      Description: value?.description ?? '',
      ReferenceId: value?.referenceId ?? '',
      Status: value?.status as EOrderStatus,
      Method: value?.method as EOrderPayment.Paypal,
      TransactionId: value?.transactionId ?? '',
      TransactionInfo: value?.transactionInfo ?? '',
      DateCreated: value?.dateCreated ?? ''
    }
    data.OrderDetails = this.orderDetail(value?.orderDetails as TOrderDetail[])
    return data
  }
}
export const OrderMapping = new OrderMappingBase()

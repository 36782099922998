import { EOperator } from '../../Table'

export type TFieldConfig<TKey> = {
  field: TKey
  type?: 'string' | 'int' | 'double'
}

export type TFieldMapping<TModel, TGraphQlModel extends Object> = {
  [key in keyof TModel]?: TFieldConfig<keyof TGraphQlModel>
}

type TSingleOrArray<T> = T | T[]

export type TFieldMappingDeep<TModel, TGraphQlModel extends Object> = {
  [key in keyof TModel]?: {
    [keyGraphql in keyof NonNullable<TGraphQlModel>]?: TSingleOrArray<TFieldConfig<keyof NonNullable<TGraphQlModel[keyGraphql]>>>
  }
}

export interface TablePaginationModel {
  /**
   * Set the number of rows in one page.
   * If some of the rows have children (for instance in the tree data), this number represents the amount of top level rows wanted on each page.
   * @default 100
   */
  pageSize: number
  /**
   * The zero-based index of the current page.
   * @default 0
   */
  page: number
}

export type TTableSortDirection = 'asc' | 'desc' | null | undefined
export interface ITableSortItem<T> {
  /**
   * The column field identifier.
   */
  field: keyof T
  /**
   * The direction of the column that the grid should sort.
   */
  sort: TTableSortDirection
}

export enum ETableLogicOperator {
  And = 'and',
  Or = 'or'
}
export interface ITableFilterItem<T> {
  /**
   * Must be unique.
   * Only useful when the model contains several items.
   */
  id?: number | string
  /**
   * The column from which we want to filter the rows.
   */
  field: keyof T
  /**
   * The filtering value.
   * The operator filtering function will decide for each row if the row values is correct compared to this value.
   */
  value?: any
  /**
   * The name of the operator we want to apply.
   */
  operator: EOperator
  /**
   * - `GridLogicOperator.And`: the row must pass all the filter items.
   * - `GridLogicOperator.Or`: the row must pass at least on filter item.
   * @default `GridLogicOperator.Or`
   */
  logicOperator?: ETableLogicOperator
}
export interface ITableQuickSearch<T> {
  items?: string[]
  selectKeys?: (keyof T)[]
}

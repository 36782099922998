import React, { Component } from 'react'
import { Tooltip, IconButton, styled } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface IProps {
  onClick: () => void
}

export default class IconButtonClose extends Component<IProps> {
  render() {
    return (
      <Tooltip title='Close'>
        <CustomIconButton onClick={this.props.onClick}>
          <CloseIcon />
        </CustomIconButton>
      </Tooltip>
    )
  }
}

const CustomIconButton = styled(IconButton)({
  flex: '0 0 auto',
  '& svg': { transition: 'all 0.2s', color: '#3c3c3c' },
  '&:hover svg': { color: '#ff200c' }
})

import { ITicket } from '@shared/Types'
import { TableQueryParam } from '@lib/Helpers'
import { AppDispatch, RootState } from '@internal/redux'
import TicketService from '@internal/services/TicketService'
import { fetchTicketThunk } from './redux.thunks'
import { ITicketMapDispatchRedux, ITicketMapStateRedux } from './redux.types'
import TicketSlice, { DefaultTableInfo } from './redux.slice'

export const mapStateToProps = (state: RootState): ITicketMapStateRedux => ({
  Status: state.TicketSlice.Status,
  tableInfo: state.TicketSlice.tableInfo
})

type TRequest = { abort: () => void }
const FetchAccessory: { request?: TRequest } = {}
export const mapDispatchToProps = (dispatch: AppDispatch): ITicketMapDispatchRedux => ({
  FetchData: () => {
    const tableInfo = TableQueryParam.getParam<ITicket>(DefaultTableInfo)
    return dispatch(fetchTicketThunk({ tableInfo }))
  },
  onTableChange: (key, value, details) => {
    if (key === 'FilterModel') {
      dispatch(TicketSlice.actions.onChange({ key, value, details }))
    }
    FetchAccessory.request?.abort()
    FetchAccessory.request = dispatch(fetchTicketThunk({ tableChange: { key, value: value, details } }))
  },
  CRUD: {
    Update: async (Id, model) => {
      await TicketService.Update(Id, model)
      dispatch(fetchTicketThunk())
    }
  }
})

import React, { Component } from 'react'
import { INotification } from '@shared/Types'
import { Box, Divider, Paper, Stack, Typography, styled } from '@mui/material'
import Item from './Item'
import NoRowsOverlay from './NoRowsOverlay'

interface IProps {
  data: INotification[]
  onClickItem: (value: INotification) => void
}

export default class Content extends Component<IProps> {
  render() {
    return (
      <Wrapper elevation={0}>
        <Box sx={{ padding: '6px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: '4px' }}>
            <Title>Notifications</Title>
          </Box>
          <Divider />
        </Box>
        <ScrollWrap>
          <ScrollBox>
            <Box component='ul'>
              {this.props.data.map((item) => (
                <Box component='li' key={item.Id}>
                  <Item data={item} onClick={() => this.props.onClickItem(item)} />
                </Box>
              ))}
            </Box>
            {this.renderNoData()}
            <Box sx={{ height: '10vh' }} />
          </ScrollBox>
        </ScrollWrap>
      </Wrapper>
    )
  }

  renderNoData = () => {
    if (this.props.data.length > 0) return <></>
    return (
      <Stack sx={{ minHeight: '300px' }}>
        <NoRowsOverlay />
      </Stack>
    )
  }
}

const Wrapper = styled(Paper)({
  width: '500px',
  minHeight: '500px',
  maxHeight: '50vh',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  padding: '9px'
})

const ScrollWrap = styled(Box)({
  flex: 1,
  position: 'relative',
  marginRight: '-6px'
})

const ScrollBox = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  paddingRight: '6px'
})

const Title = styled(Typography)({
  color: '#1976d2',
  fontWeight: 600,
  letterSpacing: '1.1px',
  wordSpacing: '1px'
})

import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { Image, ImageGroup } from './FullScreenImage'

export interface IAttachFile {
  value: string
}

interface IProps {
  data: IAttachFile[]
}

export default class AttachFiles extends Component<IProps> {
  render() {
    const data = this.props.data
    return (
      <ImageGroup>
        <Grid container spacing={1}>
          {data.map((item, index) => (
            <Grid item key={index} xs={12} sm={4}>
              <Image src={item.value} alt='mountains' />
            </Grid>
          ))}
        </Grid>
      </ImageGroup>
    )
  }
}

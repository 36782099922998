import { FooterNavConfigs, FooterSocialConfigs } from './footer'
import { ProfileMenuConfig, ProfileMenuHeaderConfig } from './profile'

const AppConfigs = {
  profile: {
    menuHeader: ProfileMenuHeaderConfig,
    menu: ProfileMenuConfig
  },
  footer: {
    nav: FooterNavConfigs,
    social: FooterSocialConfigs
  }
}
export default AppConfigs

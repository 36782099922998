import { IAnswer } from '@lib/Forms/CreateSetOfQuestion'

export enum EQuestionKey {
  PeopleAppearing = 'PeopleAppearing',
  PersonWhoFilmed = 'PersonWhoFilmed',
  SubmitedSocial = 'SubmitedSocial',
  AnyoneReach = 'AnyoneReach',
  LicensingAgreement = 'LicensingAgreement'
}

export const MapQuestionList: { [key in EQuestionKey]: string } = {
  [EQuestionKey.PeopleAppearing]: 'Are There People Appearing In The Video?',
  [EQuestionKey.PersonWhoFilmed]: 'The Person Who Filmed This Video Is',
  [EQuestionKey.SubmitedSocial]: 'Did You Send/Submit/Upload This Video To A Website And/Or Social Media Account?',
  [EQuestionKey.AnyoneReach]: 'Did Anyone Reach You About Using This Video?',
  [EQuestionKey.LicensingAgreement]: 'Did You Sign A Licensing Agreement For This Video With Another Company/Page?'
}

export const AnswerPeopleAppearing = {
  Yes: 'Yes',
  No: 'No'
}

export const AnswerPersonWhoFilmed = {
  Me: 'Me',
  key1: 'A Family Member',
  key2: 'A Friend',
  key3: 'Someone I Know',
  key4: 'A Dashcam',
  key5: 'A Security Camera',
  Other: 'Other'
}

export const AnswerSubmitedSocial = {
  No: 'No, I Did Not',
  key1: 'Yes, Another Company Contacted Me',
  key2: 'Yes, A Facebook Page',
  key3: 'Yes, A Twitter Account',
  key4: 'Yes, An Instagram Account',
  key5: 'Yes, A YouTube Channel',
  key6: 'Yes, A Snapchat Account',
  key7: 'Yes, A TikTok Account'
}

export const AnswerAnyoneReach = {
  No: 'No, You Are The First Company',
  key1: 'Yes, Another Company Contacted Me',
  key2: 'Yes, Some Social Media Accounts Messaged Me'
}

export const AnswerLicensingAgreement = {
  No: 'No, I Did Not',
  key1: 'Yes, I Have Filled An Online Form.',
  key2: 'Yes, I Have Signed An Exclusive Agreement.',
  key3: 'Yes, I Have Signed A Non-Exclusive Agreement.'
}

export const MapAnswers = {
  [EQuestionKey.PeopleAppearing]: AnswerPeopleAppearing,
  [EQuestionKey.PersonWhoFilmed]: AnswerPersonWhoFilmed,
  [EQuestionKey.SubmitedSocial]: AnswerSubmitedSocial,
  [EQuestionKey.AnyoneReach]: AnswerAnyoneReach,
  [EQuestionKey.LicensingAgreement]: AnswerLicensingAgreement
}

interface ISubQuestion {
  question?: string
  label?: string
  placeholder?: string
}

export const MapSubQuestion: { [key in EQuestionKey]?: ISubQuestion } = {
  [EQuestionKey.PeopleAppearing]: { question: 'Who Are They?', placeholder: 'Please mention them all' },
  [EQuestionKey.PersonWhoFilmed]: { placeholder: 'Your Answer' },
  [EQuestionKey.SubmitedSocial]: { question: 'Where Did You Submit It?', placeholder: 'Please list all the pages and websites' },
  [EQuestionKey.AnyoneReach]: {
    question: 'Please Share With Us The Name Of The Company/Page:',
    placeholder: 'Please list all the pages and websites'
  },
  [EQuestionKey.LicensingAgreement]: { question: 'Name Of The Company/Page' }
}

export const MapAnswerConfings = function <T extends Object, K extends Object = any>(value: K, disabledSub?: (keyof K)[]): IAnswer<T>[] {
  const setActivatedSub = new Set<string>(disabledSub?.map<string>((x) => x?.toString()))
  const keys = Object.keys(value) as (keyof Object)[]
  const answers = keys.map<IAnswer<T>>((key) => ({
    key: key,
    label: value[key]?.toString(),
    disabledSub: setActivatedSub.has(key?.toString())
  }))
  return answers
}

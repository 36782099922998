import { AppDispatch, RootState } from '@internal/redux'
import { QuicklyAddToCart } from '../Cart'
import { fetchHomeThunk } from './redux.thunks'
import { IHomeDispatchRedux, IHomeStateRedux } from './redux.types'

export const mapStateToProps = (state: RootState): IHomeStateRedux => ({
  Status: state.HomeSlice.Status,
  StateSlice: state.HomeSlice
})

export const mapDispatchToProps = (dispatch: AppDispatch): IHomeDispatchRedux => ({
  FetchData: () => {
    return dispatch(fetchHomeThunk())
  },
  addToCart: (media, price) => {
    QuicklyAddToCart(dispatch, media, price)
  }
})

import React from 'react'
import { connect } from 'react-redux'
import { RouteKey } from '@internal/route'
import { CreateHocLazy } from '@lib/ReduxBase'
import { ESubmissionStep } from '@shared/Types'
import { CreateRoutePath } from '@lib/RouteBase'
import { IRouterProps, withRouter } from '@lib/Hocs'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { Box, Container, Stack, styled, Typography } from '@mui/material'
import { IStepperBarConfig } from './UI/StepperBar'
import { ISubmitMediaReduxProps } from './Redux/types'
import { mapDispatchToProps, mapStateToProps } from './Redux/Map'
import StepComplete from './StepComplete'
import StepperLayout from './StepperLayout'
import StepAboutMedia from './StepAboutMedia'
import StepAboutYouself from './StepAboutYouself'
import StepDigitalSignature from './StepDigitalSignature'
import StepCopyrightOwnership from './StepCopyrightOwnership'
import LocalStorageSubmisstion from './Helpers/LocalStorageSubmisstion'

const MapStep: { [key: number]: ESubmissionStep } = {
  0: ESubmissionStep.AboutMedia,
  1: ESubmissionStep.AboutYouself,
  2: ESubmissionStep.CopyrightOwnership,
  3: ESubmissionStep.DigitalSignature
}

const StepperBarConfigs: IStepperBarConfig[] = [
  {
    key: ESubmissionStep.AboutMedia,
    title: `Let's learn more about your video`,
    to: CreateRoutePath([RouteKey.Submit, RouteKey.SMTAboutMedia])
  },
  {
    key: ESubmissionStep.AboutYouself,
    title: `Tell us more about yourself`,
    to: CreateRoutePath([RouteKey.Submit, RouteKey.SMTAboutYouself])
  },
  {
    key: ESubmissionStep.CopyrightOwnership,
    title: `Let's verify the copyright ownership`,
    to: CreateRoutePath([RouteKey.Submit, RouteKey.SMTCopyrightOwnership])
  },
  {
    key: ESubmissionStep.DigitalSignature,
    title: `Digital Signature`,
    to: CreateRoutePath([RouteKey.Submit, RouteKey.SMTDigitalSignature])
  }
]

export const SumissionStepComponents: { [key in ESubmissionStep]: React.ComponentType } = {
  [ESubmissionStep.AboutMedia]: (p) => (
    <StepperLayout configs={StepperBarConfigs}>
      <StepAboutMedia {...p} />
    </StepperLayout>
  ),
  [ESubmissionStep.AboutYouself]: (p) => (
    <StepperLayout configs={StepperBarConfigs}>
      <StepAboutYouself {...p} />
    </StepperLayout>
  ),
  [ESubmissionStep.CopyrightOwnership]: (p) => (
    <StepperLayout configs={StepperBarConfigs}>
      <StepCopyrightOwnership {...p} />
    </StepperLayout>
  ),
  [ESubmissionStep.DigitalSignature]: (p) => (
    <StepperLayout configs={StepperBarConfigs}>
      <StepDigitalSignature {...p} />
    </StepperLayout>
  ),
  [ESubmissionStep.Complete]: StepComplete
}

const imageSource = process.env.PUBLIC_URL + '/images/bg-home.png'

interface IProps extends ISubmitMediaReduxProps, IRouterProps {}
const SubmitMediaBase: React.FC<IProps> = () => {
  const navigate = useNavigate()
  React.useEffect(() => {
    const pathSubmit = CreateRoutePath(RouteKey.Submit)
    const data = LocalStorageSubmisstion.getData()
    if (!data) {
      navigate(pathSubmit)
      return
    }
    const step = MapStep[data.stepDones.length] ?? ESubmissionStep.AboutMedia
    const config = StepperBarConfigs.find((x) => x.key === step)
    navigate(config?.to ?? pathSubmit)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box sx={{ minHeight: 'calc(100vh - var(--height-header))' }}>
      <CustomBgImage>
        <Content>
          <Title sx={{ color: '#fff' }}>Ready to start generating income?</Title>
        </Content>
        <Box className='box-dark' />
      </CustomBgImage>
      <Container>
        <Stack direction='row' gap='10px' pb='35px'>
          <Title>Have questions? Check out our</Title>
          <TitleLink {...{ component: Link, to: CreateRoutePath(RouteKey.FQAs) }}>FQAs</TitleLink>
        </Stack>
        <Outlet />
        <Box sx={{ height: '15vh' }} />
      </Container>
    </Box>
  )
}

export { default as SubmitMediaSlice } from './Redux/Slice'

const HocLazyInstance = CreateHocLazy(withRouter(SubmitMediaBase))
export const SubmitMediaMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)

const Title = styled(Typography)({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '36px',
  fontWeight: 700
})

const TitleLink = styled(Title)({
  color: '#1976D2',
  transition: 'all linear 0.2s',
  cursor: 'pointer',
  borderBottom: '2px solid #1976D2',
  padding: '0 2.5px'
})

const CustomBgImage = styled('div')({
  backgroundImage: `url(${imageSource})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  display: 'flex',
  '& .box-dark': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.45)'
  }
})

const Content = styled(Container)({
  position: 'relative',
  zIndex: 1,
  height: `calc(50vh - var(--height-header))`,
  display: 'flex',
  alignItems: 'flex-end',
  paddingBottom: '20px'
})

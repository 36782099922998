import { ModelType } from 'graphql-ts-client-api'
import { query$, mediaStoreGraphDbContext$ } from '../../__generated/media-store/fetchers'

import { applicationUser$ } from '../../__generated/media-store/fetchers'
import { billing$$, billingPage$ } from '../../__generated/media-store/fetchers'
import { billingDetail$$, billingDetailPage$ } from '../../__generated/media-store/fetchers'

const BillingFull = billing$$.user(applicationUser$.email.displayName)
export type TBilling = ModelType<typeof BillingFull>

const BillingSelector = billing$$.user(applicationUser$.displayName)
export type TBillingFilterParams = Parameters<typeof mediaStoreGraphDbContext$.billing>[0]
export const BillingQuery = (params: TBillingFilterParams) => {
  return query$.mediaStore(mediaStoreGraphDbContext$.billing(params, BillingSelector))
}

export type TBillingsFilterParams = Parameters<typeof mediaStoreGraphDbContext$.billings>[0]
export const BillingsQuery = (params: TBillingsFilterParams) => {
  const PageSelector = billingPage$.items(BillingSelector).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(mediaStoreGraphDbContext$.billings(params, PageSelector))
}

const BillingUserSelector = billing$$
export const BillingsUserQuery = (params: TBillingsFilterParams) => {
  const PageSelector = billingPage$.items(BillingUserSelector).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(mediaStoreGraphDbContext$.billings(params, PageSelector))
}

const BillingDetailSelector = billingDetail$$
export type TBillingDetail = ModelType<typeof BillingDetailSelector>

export type TBillingDetailsFilterParams = Parameters<typeof mediaStoreGraphDbContext$.billingDetails>[0]
export const BillingDetailsQuery = (params: TBillingDetailsFilterParams) => {
  const PageSelector = billingDetailPage$.items(BillingDetailSelector).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(mediaStoreGraphDbContext$.billingDetails(params, PageSelector))
}

import { RootState, AppDispatch } from '@internal/redux'
import { QueryParam } from '@lib/Helpers'
import { fetchSubmissionDetailThunk } from './Thunks'
import { ISubmissionDetailMapDispatchRedux, ISubmissionDetailMapStateRedux } from './types'

export const mapStateToProps = (state: RootState): ISubmissionDetailMapStateRedux => ({
  Status: state.SubmissionDetailSlice.Status,
  StateRedux: state.SubmissionDetailSlice
})

export const mapDispatchToProps = (dispatch: AppDispatch): ISubmissionDetailMapDispatchRedux => ({
  FetchData: () => {
    const { id } = QueryParam.Gets<{ id: string }>('id')
    return dispatch(fetchSubmissionDetailThunk({ id: parseInt(id?.toString() ?? '-1') }))
  }
})

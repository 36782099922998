import { FetchDelay } from '@lib/Helpers'
import { ICart } from '@shared/Types'

class CartLocalStorageBase {
  private key = 'clsb_01'

  getDataAsync = (): Promise<ICart[]> => {
    return FetchDelay(async () => this.getData(), 500)
  }

  getData = (): ICart[] => {
    try {
      const data = localStorage.getItem(this.key)
      const carts: ICart[] = JSON.parse(data ?? '[]')
      return carts
    } catch (error) {
      return []
    }
  }

  setData = (value: ICart[]) => {
    localStorage.setItem(this.key, JSON.stringify(value))
  }

  removeAll = () => {
    localStorage.removeItem(this.key)
  }
}
const CartLocalStorage = new CartLocalStorageBase()
export default CartLocalStorage

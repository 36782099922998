import { ITicketConversation } from '@shared/Types'
import { CreateCRUDSlice2, ELazyStatus } from '@lib/ReduxBase'
import { ITicketDetailStateSlice } from './redux.types'
import { fetchTicketDetailThunk } from './redux.thunks'

const TicketDetailSlice = CreateCRUDSlice2<ITicketConversation>()({
  name: 'TicketDetailSlice',
  selectId: (x) => x.Id,
  Initial: (x) => {
    const init: ITicketDetailStateSlice = { ...x }
    return init
  },
  extraReducers: (builder, adapter) => {
    builder
      .addCase(fetchTicketDetailThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Loaded

        state.dataDetail = action.payload.data
        state.refreshStatus = false
      })
      .addCase(fetchTicketDetailThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Error
        state.refreshStatus = false
      })
      .addCase(fetchTicketDetailThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Status = ELazyStatus.Loading
        if (action.meta.arg?.cleaned) state.dataDetail = undefined
        if (action.meta.arg?.isRefresh) state.refreshStatus = true
      })
  },
  reducers: {}
})
export default TicketDetailSlice

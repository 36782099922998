import { EMediaStatus } from '@shared/Types'
import CreateStatusCell, { TStatusCellConfig } from '@shared/UIStyleds/CreateStatusCell'

export const MediaStatusCellConfig: TStatusCellConfig<EMediaStatus> = {
  [EMediaStatus.Pending]: { background: 'linear-gradient(to right,#0d6efd,#82baff)' },
  [EMediaStatus.Approved]: { background: 'linear-gradient(to right,#357a38,#1edd9b)' },
  [EMediaStatus.Rejected]: { background: 'linear-gradient(to right,#e72134,#eba1a1)' }
}

const MediaStatusCell = CreateStatusCell<EMediaStatus>(MediaStatusCellConfig, { sxInner: { minWidth: '90px' } })
export default MediaStatusCell

import React, { Component } from 'react'
import { Box, Divider, Typography, alpha, styled } from '@mui/material'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import { ICart } from '@shared/Types'
import { EMediaType } from '@shared/Types'
import { Text14, Text16, TextBig } from '@internal/shareds/Text'
import CheckOutHelper from '../index.helper'

interface IProps {
  data: ICart[]
}

export default class CartListContent extends Component<IProps> {
  render() {
    const total = CheckOutHelper.getTotal(this.props.data)
    return (
      <Box>
        <Typography sx={{ fontSize: '1.1rem', color: '#606060', fontWeight: 600 }}>Your cart</Typography>
        <Box component='ul' sx={{ pt: '9px', pb: '18px' }}>
          {this.props.data.map((item, index) => (
            <li key={index}>{this.renderItem(item)}</li>
          ))}
        </Box>
        <Divider />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px', pt: '24px' }}>
          <TextBig sx={{ flex: 1 }}>Total</TextBig>
          {/* <Text14 sx={{ mt: '12px' }}>USD</Text14> */}
          <TextBig>$ {total}</TextBig>
        </Box>
      </Box>
    )
  }

  renderItem = (value: ICart) => {
    const subTotal = CheckOutHelper.getSubTotal(value)
    return (
      <Box sx={{ display: 'flex', alignItems: 'stretch', gap: '18px', marginTop: '18px' }}>
        <Box>{this.renderImage(value)}</Box>
        <Box sx={{ flex: 1, height: '100%' }}>
          <LicenseTitle color={CheckOutHelper.getColor(value)} noWrap>
            {value.PriceOrigin?.Name ?? 'Pricing package name/Exo 2/Regular/16px'}
          </LicenseTitle>
          <Text14 sx={{ mt: '6px' }}>{value.Name ?? 'Cart item name/Exo 2/Regular/14px'}</Text14>
          <Text14 sx={{ mt: '6px' }}>{this.getAmountStringLink(value)}</Text14>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Text16>$ {subTotal}</Text16>
        </Box>
      </Box>
    )
  }

  renderImage = (value: ICart) => {
    switch (value.Type) {
      case EMediaType.Audio:
        return (
          <WraperImage>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <MusicNoteIcon sx={{ color: '#1976D2' }} />
            </Box>
          </WraperImage>
        )
      case EMediaType.Video:
        return (
          <WraperImage>
            <Box sx={{ backgroundImage: `url(${value.ImageUri})` }} />
          </WraperImage>
        )
      default:
        return <></>
    }
  }

  getAmountStringLink = (value: ICart) => {
    if (value.JsonContent?.Links?.length) return `${value.JsonContent?.Links?.length} links`
    return 'Amount links/Exo 2/Regular/14px'
  }
}

const LicenseTitle = styled(Text16)<{ color?: string }>(({ color }) => ({
  fontWeight: 600,
  borderBottom: `2px solid ${color}`,
  display: 'inline-block',
  lineHeight: 1.2
}))

const WraperImage = styled(Box)({
  flex: '0 0 auto',
  width: '72px',
  paddingBottom: '100%',
  borderRadius: '4px',
  position: 'relative',
  '& > div': {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: alpha('#1976D2', 0.15),
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '4px',
    boxShadow: '0px 2px 14px 0px rgba(0, 0, 0, 0.15)'
  }
})

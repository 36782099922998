import React, { Component } from 'react'
import { EOrder } from '@lib/Table'
import { connect } from 'react-redux'
import { WindowScrollToTop } from '@lib/Helpers'
import { EMediaType, ICategory } from '@shared/Types'
import { CreateHocLazy, ELazyStatus } from '@lib/ReduxBase'
import { IMediaFilterDTO } from './types'
import { IExploreReduxProps } from './redux.types'
import { mapDispatchToProps, mapStateToProps } from './redux.map'
import SectionSearch, { IFormSearch } from './ui/SectionSearch'
import UILayout from './ui.layout'
import TopBar from './ui/TopBar'
import FormInputSearch from './form-input.search'
import UIPagination from './ui.pagination'
import MediaTypeTabs from './MediaTypeTabs'
import UIExploreContent from './ui.explore.content'
import UIRecommendedCategories from './ui.recommended.categories'

interface IProps extends IExploreReduxProps {}

class ViewBase extends Component<IProps> {
  componentDidUpdate(prevProps: Readonly<IProps>) {
    const check1 = JSON.stringify(prevProps.Slice.filter) !== JSON.stringify(this.props.Slice.filter)
    const check2 = prevProps.Slice.medias.ids.length !== this.props.Slice.medias.ids.length
    if (check1 || check2) {
      WindowScrollToTop()
    }
  }

  componentDidMount() {
    WindowScrollToTop()
  }

  render() {
    const categories = this.getCategories().map((e) => e.Name)
    const categorySelected = this.props.Slice.filter.categories

    const mediaTotal = this.props.Slice.mediaInfo.total ?? 0
    const page = this.props.Slice.filter.page ?? 0
    const amount = this.props.Slice.filter.amount ?? 0
    const totalString = this.getTotalString(page, amount, mediaTotal)
    const count = Math.ceil(mediaTotal / amount)

    const formSearchDataDefault: IFormSearch = {
      MediaType: this.props.Slice.filter.mediaType ?? EMediaType.Video,
      Value: this.props.Slice.filter.searchKey ?? ''
    }

    return (
      <UILayout
        isLoading={this.props.Status === ELazyStatus.Loading}
        numberDataDisplayedString={totalString}
        components={{
          banner: <SectionSearch data={formSearchDataDefault} onSearch={this.handleSearch} />,
          top: (
            <TopBar
              count={mediaTotal}
              subFilterString={this.getSubFilter()}
              sortMenuProps={{
                onChange: this.handleChangeSortMenu,
                value: this.props.Slice.filter.sort?.type
              }}
            />
          ),
          categories: (
            <UIRecommendedCategories
              loading={categories.length < 1 && this.props.Status === ELazyStatus.Loading}
              data={categories}
              onClick={this.handleClickCategory}
              selected={categorySelected}
            />
          ),
          content: <UIExploreContent {...this.props} onAddToCart={this.props.onAddToCart} />,
          pagination: <UIPagination count={count} page={page} onChange={this.handleChangePagination} />
        }}
      />
    )
  }

  getSubFilter = (): string => {
    const { filter } = this.props.Slice
    const list: string[] = [(filter.mediaType ?? EMediaType.Video).toString()]
    if (filter.searchKey) list.push(filter.searchKey)
    if (filter.categories) list.push(filter.categories)
    return `filter by: ${list.join(' | ')}`
  }

  getTotalString = (page: number, amount: number, total: number) => {
    const numFrom = (page - 1) * amount + 1
    const numTo = Math.min(page * amount, total)
    return `${numFrom}-${numTo} of ${total} items`
  }

  getCategories = () => {
    const list = Object.values(this.props.Slice.categories.entities)
    return list as ICategory[]
  }

  handleSearch = async (value: IMediaFilterDTO) => {
    this.props.putFilters([
      { key: 'searchKey', value: value.searchKey },
      { key: 'mediaType', value: value.mediaType }
    ])
  }

  handleChangeSortMenu = (value?: EOrder) => {
    this.props.putFilter('sort', value ? { field: 'Name', type: value } : undefined)
  }

  handleChangePagination = (page: number) => {
    this.props.putFilter('page', page)
  }

  handleClickCategory = (value?: string) => {
    this.props.putFilter('categories', value)
  }
}

export * from './redux.thunks'

export { default as ExploreSlice } from './redux.slice'

export { putFilterExplore } from './redux.map'

const HocLazyInstance = CreateHocLazy(ViewBase)
export const ExploreMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)

export const SearchInputMapRedux = connect(mapStateToProps, mapDispatchToProps)(FormInputSearch)

export const MediaTypeTabsMapRedux = connect(mapStateToProps, mapDispatchToProps)(MediaTypeTabs)

import { IRoute } from '@lib/RouteBase'
import { ApplicationPaths } from 'partner-oidc-auth'
import { ApiAuthorizationRoutes } from '@lib/ApiAuthorization'
import LayoutProfile from '@internal/shareds/LayoutProfile'
import RouteKey from './RouteKey'

import { BillingMapRedux } from '../Views/Billing'
import { BillingDetailModal } from '../Views/BillingDetail'
import { CartMapRedux } from '../Views/Cart'
import { CheckOutMapRedux, CheckOutComplete } from '../Views/CheckOut'
import { DeliveryMapRedux } from '../Views/Delivery'
import { ExploreMapRedux } from '../Views/Explore'
import { HomeMapRedux } from '../Views/Home'
import { MediaMapRedux } from '../Views/Media'
import { MediaDetailMapRedux } from '../Views/MediaDetail'
import { OrderMapRedux } from '../Views/Order'
import { OrderDetailModal } from '../Views/OrderDetail'
import { PricingMapRedux } from '../Views/Pricing'
import { ProfileMapRedux } from '../Views/Profile'
import { SumissionStepComponents } from '../Views/SubmitMedia'
import { SubmissionDetailModal } from '../Views/SubmissionDetail'
import { SubmissionMapRedux } from '../Views/Submission'
import { SubmitMediaMapRedux } from '../Views/SubmitMedia'
import { TicketMapRedux } from '../Views/Ticket'
import { TicketDetailModal } from '../Views/TicketDetail'
import FAQ from '../Views/FAQ'
import AboutUsPage from '../Views/AboutUs'
import ReportScreen from '../Views/ReportScreen'
import AccessDenied from '../Views/AccessDenied'
import TicketCreate from '../Views/TicketCreate'
import PrivacyPolicyPage from '../Views/PrivacyPolicyScreen'
import TermsOfSubmission from '../Views/TermsOfSubmission'

const MapApiAuthorizationRoutes = () => {
  return ApiAuthorizationRoutes.map<IRoute>((item, index) => {
    return { element: item.element, Path: item.path }
  })
}

const AppName = 'BERLINTOMEK'

export const RouteConfigs: IRoute[] = [
  {
    Path: RouteKey.InitialPath,
    element: <HomeMapRedux />,
    Prefix: AppName
  },
  {
    Path: RouteKey.Home,
    element: <HomeMapRedux />,
    Prefix: AppName
  },
  {
    Path: RouteKey.Explore,
    element: <ExploreMapRedux />,
    Prefix: AppName
  },
  {
    Path: RouteKey.Cart,
    element: <CartMapRedux />,
    Prefix: AppName
  },
  {
    Path: RouteKey.CheckOut,
    element: <CheckOutMapRedux />,
    roles: [[]],
    Prefix: AppName
  },
  {
    Path: RouteKey.CheckOutComplete,
    element: <CheckOutComplete />,
    Prefix: AppName
  },
  {
    Path: RouteKey.Report,
    element: <ReportScreen />,
    Prefix: AppName
  },
  {
    Path: RouteKey.Detail,
    element: <MediaDetailMapRedux />,
    Prefix: AppName
  },
  {
    Path: RouteKey.Submit,
    element: <SubmitMediaMapRedux />,
    Prefix: AppName,
    roles: [[]],
    Children: [
      {
        Path: '/',
        element: <SumissionStepComponents.AboutMedia />,
        roles: [[]],
        Prefix: AppName
      },
      {
        Path: RouteKey.SMTAboutMedia,
        element: <SumissionStepComponents.AboutMedia />,
        roles: [[]],
        Prefix: AppName
      },
      {
        Path: RouteKey.SMTAboutYouself,
        element: <SumissionStepComponents.AboutYouself />,
        roles: [[]],
        Prefix: AppName
      },
      {
        Path: RouteKey.SMTCopyrightOwnership,
        element: <SumissionStepComponents.CopyrightOwnership />,
        roles: [[]],
        Prefix: AppName
      },
      {
        Path: RouteKey.SMTDigitalSignature,
        element: <SumissionStepComponents.DigitalSignature />,
        roles: [[]],
        Prefix: AppName
      },
      {
        Path: RouteKey.SMTComplete,
        element: <SumissionStepComponents.Complete />,
        roles: [[]],
        Prefix: AppName
      }
    ]
  },
  {
    Path: RouteKey.FQAs,
    element: <FAQ />,
    Prefix: AppName
  },
  {
    Path: RouteKey.PrivacyPolicy,
    element: <PrivacyPolicyPage />,
    Prefix: AppName
  },
  {
    Path: RouteKey.TermsOfSubmission,
    element: <TermsOfSubmission />,
    Prefix: AppName
  },

  {
    Path: RouteKey.Pricing,
    element: <PricingMapRedux />,
    Prefix: AppName
  },
  {
    Path: RouteKey.Profile,
    element: <LayoutProfile />,
    roles: [[]],
    Children: [
      {
        Path: '/',
        element: <ProfileMapRedux />,
        roles: [[]],
        Prefix: AppName
      },
      {
        Path: RouteKey.Order,
        element: <OrderMapRedux />,
        roles: [[]],
        Prefix: AppName
      },
      {
        Path: RouteKey.AboutUs,
        element: <AboutUsPage />,
        roles: [[]],
        Prefix: AppName
      },
      {
        Path: RouteKey.Ticket,
        element: <TicketMapRedux />,
        roles: [[]],
        Prefix: AppName
      },
      {
        Path: RouteKey.TicketCreate,
        element: <TicketCreate />,
        roles: [[]],
        Prefix: AppName
      },
      {
        Path: RouteKey.Submission,
        element: <SubmissionMapRedux />,
        roles: [[]],
        Prefix: AppName
      },
      {
        Path: RouteKey.Media,
        element: <MediaMapRedux />,
        roles: [[]],
        Prefix: AppName
      },
      {
        Path: RouteKey.DeliveryDetail,
        element: <MediaMapRedux />,
        roles: [[]],
        Prefix: AppName
      },
      {
        Path: RouteKey.Delivery,
        element: <DeliveryMapRedux />,
        roles: [[]],
        Prefix: AppName
      },
      {
        Path: RouteKey.Billing,
        element: <BillingMapRedux />,
        roles: [[]],
        Prefix: AppName
      }
    ]
  },
  {
    Path: RouteKey.OrderDetail,
    element: <OrderDetailModal />,
    roles: [[]],
    Prefix: AppName
  },
  {
    Path: RouteKey.TicketDetail,
    element: <TicketDetailModal />,
    roles: [[]],
    Prefix: AppName
  },
  {
    Path: RouteKey.SubmissionDetail,
    element: <SubmissionDetailModal />,
    roles: [[]],
    Prefix: AppName
  },
  {
    Path: RouteKey.BillingDetail,
    element: <BillingDetailModal />,
    roles: [[]],
    Prefix: AppName
  },
  {
    Path: RouteKey.AboutUs,
    element: <AboutUsPage />,
    roles: [[]],
    Prefix: AppName
  },
  {
    Path: ApplicationPaths.AccessDenied,
    element: <AccessDenied />,
    Prefix: AppName
  },
  ...MapApiAuthorizationRoutes()
]
export default RouteConfigs

import React, { Component } from 'react'
import ReactAudioPlayer from 'react-audio-player'
import './index.css'
interface AudioViewProps {
  AudioUrl: string
}
export default class AudioView extends Component<AudioViewProps> {
  render() {
    return (
      <div className='audio-view-container'>
        <ReactAudioPlayer src={this.props.AudioUrl} autoPlay controls controlsList='nodownload' />
      </div>
    )
  }
}

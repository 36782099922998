import React, { Component } from 'react'
import { Pagination } from '@mui/material'

interface IProps {
  count: number
  page: number
  onChange?: (page: number) => void
}

export default class UIPagination extends Component<IProps> {
  render() {
    if (this.props.count < 2) return <></>
    return <Pagination count={this.props.count} page={this.props.page} onChange={this.handleChangePagination} />
  }

  handleChangePagination = (_: React.ChangeEvent<unknown>, page: number) => {
    this.props.onChange && this.props.onChange(page)
  }
}

import { QueryParam } from '@lib/Helpers'
import { RootState, AppDispatch } from '@internal/redux'
import CreateTicketConversationService from '@internal/services/TicketConversationService'
import { fetchTicketDetailThunk } from './redux.thunks'
import { ITicketDetailMapDispatchRedux, ITicketDetailMapStateRedux } from './redux.types'

const getTicketId = (): string => {
  const param = QueryParam.Gets<{ id?: string }>('id')
  return param.id?.toString() ?? ''
}

export const mapStateToProps = (state: RootState): ITicketDetailMapStateRedux => ({
  Status: state.TicketDetailSlice.Status,
  requestedId: state.TicketDetailSlice.requestedId,
  dataDetail: state.TicketDetailSlice.dataDetail,
  stateRedux: state.TicketDetailSlice
})

export const mapDispatchToProps = (dispatch: AppDispatch): ITicketDetailMapDispatchRedux => ({
  FetchData: () => {
    return dispatch(fetchTicketDetailThunk({ ticketId: getTicketId(), cleaned: true }))
  },
  CRUD: {
    Create: async (model) => {
      await CreateTicketConversationService(getTicketId()).Create(model)
      dispatch(fetchTicketDetailThunk({ ticketId: getTicketId() }))
    }
  },
  refresh: async () => {
    dispatch(fetchTicketDetailThunk({ ticketId: getTicketId(), isRefresh: true }))
  }
})

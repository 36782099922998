import { ITicket } from '@shared/Types'
import { RootState } from '@internal/redux'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { MergeObjects, TableQueryParam } from '@lib/Helpers'
import { TableReduxType, MapTableInfo, MapTableData } from '@lib/Table'
import TicketService, { TicketMapping } from '@internal/services/TicketService'
import { DefaultTableInfo } from './redux.slice'

interface IParamsThunk extends TableReduxType.ThunkParam<ITicket> {}

interface IResponseThunk extends TableReduxType.ThunkReponse<ITicket> {}

export const fetchTicketThunk = createAsyncThunk<IResponseThunk, IParamsThunk | undefined>('fetchTicketThunk', async (param, thunkAPI) => {
  const state = thunkAPI.getState() as RootState
  const tableInfoState = MergeObjects(state.TicketSlice.tableInfo, param?.tableInfo)
  const tableInfoMap = MapTableInfo(tableInfoState, param?.tableChange)

  const filter = TicketMapping.tableInfoToFilter(tableInfoMap)
  const res = await TicketService.All(filter, thunkAPI.signal)

  const tableInfo = MapTableData(tableInfoMap, { Data: res?.Data, Total: res?.Total })
  TableQueryParam.setParam(tableInfo, DefaultTableInfo)
  return { tableInfo }
})

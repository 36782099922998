import type { FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';
import type {PricePackageType} from '../enums';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface OrderDetailFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'OrderDetail', T, TVariables> {

    on<XName extends ImplementationType<'OrderDetail'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): OrderDetailFetcher<
        XName extends 'OrderDetail' ?
        T & X :
        WithTypeName<T, ImplementationType<'OrderDetail'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'OrderDetail'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): OrderDetailFetcher<T, TVariables>;


    readonly __typename: OrderDetailFetcher<T & {__typename: ImplementationType<'OrderDetail'>}, TVariables>;


    readonly id: OrderDetailFetcher<T & {readonly "id": string}, TVariables>;

    "id+"<
        XAlias extends string = "id", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"id", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): OrderDetailFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~id": OrderDetailFetcher<Omit<T, 'id'>, TVariables>;


    readonly orderId: OrderDetailFetcher<T & {readonly "orderId": string}, TVariables>;

    "orderId+"<
        XAlias extends string = "orderId", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"orderId", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): OrderDetailFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~orderId": OrderDetailFetcher<Omit<T, 'orderId'>, TVariables>;


    readonly resourceId: OrderDetailFetcher<T & {readonly "resourceId": string}, TVariables>;

    "resourceId+"<
        XAlias extends string = "resourceId", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"resourceId", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): OrderDetailFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~resourceId": OrderDetailFetcher<Omit<T, 'resourceId'>, TVariables>;


    readonly price: OrderDetailFetcher<T & {readonly "price": number}, TVariables>;

    "price+"<
        XAlias extends string = "price", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"price", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): OrderDetailFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: number} : 
                {readonly [key in XAlias]: number}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~price": OrderDetailFetcher<Omit<T, 'price'>, TVariables>;


    readonly amount: OrderDetailFetcher<T & {readonly "amount": number}, TVariables>;

    "amount+"<
        XAlias extends string = "amount", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"amount", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): OrderDetailFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: number} : 
                {readonly [key in XAlias]: number}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~amount": OrderDetailFetcher<Omit<T, 'amount'>, TVariables>;


    readonly pricePackageId: OrderDetailFetcher<T & {readonly "pricePackageId": string}, TVariables>;

    "pricePackageId+"<
        XAlias extends string = "pricePackageId", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"pricePackageId", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): OrderDetailFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~pricePackageId": OrderDetailFetcher<Omit<T, 'pricePackageId'>, TVariables>;


    readonly socialUrl: OrderDetailFetcher<T & {readonly "socialUrl"?: string}, TVariables>;

    "socialUrl+"<
        XAlias extends string = "socialUrl", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"socialUrl", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): OrderDetailFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~socialUrl": OrderDetailFetcher<Omit<T, 'socialUrl'>, TVariables>;


    readonly type: OrderDetailFetcher<T & {readonly "type": PricePackageType}, TVariables>;

    "type+"<
        XAlias extends string = "type", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"type", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): OrderDetailFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: PricePackageType} : 
                {readonly [key in XAlias]: PricePackageType}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~type": OrderDetailFetcher<Omit<T, 'type'>, TVariables>;


    readonly resourceInfo: OrderDetailFetcher<T & {readonly "resourceInfo"?: string}, TVariables>;

    "resourceInfo+"<
        XAlias extends string = "resourceInfo", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"resourceInfo", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): OrderDetailFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~resourceInfo": OrderDetailFetcher<Omit<T, 'resourceInfo'>, TVariables>;


    cartResourceInfo<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'CartResourceInfo', X, XVariables>
    ): OrderDetailFetcher<
        T & {readonly "cartResourceInfo"?: X}, 
        TVariables & XVariables
    >;

    cartResourceInfo<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "cartResourceInfo", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'CartResourceInfo', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"cartResourceInfo", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): OrderDetailFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    order<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Order', X, XVariables>
    ): OrderDetailFetcher<
        T & {readonly "order"?: X}, 
        TVariables & XVariables
    >;

    order<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "order", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Order', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"order", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): OrderDetailFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    pricePackage<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'PricePackage', X, XVariables>
    ): OrderDetailFetcher<
        T & {readonly "pricePackage"?: X}, 
        TVariables & XVariables
    >;

    pricePackage<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "pricePackage", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'PricePackage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"pricePackage", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): OrderDetailFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    readonly dateCreated: OrderDetailFetcher<T & {readonly "dateCreated": string}, TVariables>;

    "dateCreated+"<
        XAlias extends string = "dateCreated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateCreated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): OrderDetailFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateCreated": OrderDetailFetcher<Omit<T, 'dateCreated'>, TVariables>;


    readonly dateUpdated: OrderDetailFetcher<T & {readonly "dateUpdated": string}, TVariables>;

    "dateUpdated+"<
        XAlias extends string = "dateUpdated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateUpdated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): OrderDetailFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateUpdated": OrderDetailFetcher<Omit<T, 'dateUpdated'>, TVariables>;
}

export const orderDetail$: OrderDetailFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "OrderDetail", 
            "OBJECT", 
            [], 
            [
                {
                    category: "ID", 
                    name: "id"
                }, 
                "orderId", 
                "resourceId", 
                "price", 
                "amount", 
                "pricePackageId", 
                {
                    category: "SCALAR", 
                    name: "socialUrl", 
                    undefinable: true
                }, 
                "type", 
                {
                    category: "SCALAR", 
                    name: "resourceInfo", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "cartResourceInfo", 
                    targetTypeName: "CartResourceInfo", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "order", 
                    targetTypeName: "Order", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "pricePackage", 
                    targetTypeName: "PricePackage", 
                    undefinable: true
                }, 
                "dateCreated", 
                "dateUpdated"
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const orderDetail$$ = 
    orderDetail$
        .id
        .orderId
        .resourceId
        .price
        .amount
        .pricePackageId
        .socialUrl
        .type
        .resourceInfo
        .dateCreated
        .dateUpdated
;

import React, { Component } from 'react'
import { Typography } from '@mui/material'

export default class AccessDenied extends Component {
  render() {
    return (
      <Typography variant='h2' color='red'>
        Access Denied!
      </Typography>
    )
  }
}

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, IconButton } from '@mui/material'
import ListAltIcon from '@mui/icons-material/ListAlt'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'

interface ILinkProps {
  to: string
  target?: React.HTMLAttributeAnchorTarget
}

export class Detail extends Component<ILinkProps> {
  render() {
    return (
      <IconButton color='info' {...{ component: Link, to: this.props.to, target: this.props.target }} sx={{ cursor: 'pointer' }}>
        <ListAltIcon />
      </IconButton>
    )
  }
}

export class Settings extends Component<ILinkProps> {
  render() {
    return (
      <Button
        variant='contained'
        color='primary'
        size='small'
        startIcon={<SettingsOutlinedIcon />}
        sx={{ minWidth: '110px' }}
        {...{ component: Link, to: this.props.to, target: this.props.target }}
      >
        Settings
      </Button>
    )
  }
}

import { NavigateFunction, useNavigate } from 'react-router-dom'

export interface INavigateProps {
  navigate: NavigateFunction
}

export const withNavigate = <P extends INavigateProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof INavigateProps>) => {
    const navigate = useNavigate()
    return <Component {...(props as P)} navigate={navigate} />
  }
}
export default withNavigate

import React, { Component } from 'react'
import { Box, Collapse, IconButton, InputAdornment, TextField, TextFieldProps, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddLinkIcon from '@mui/icons-material/AddLink'
import { IFormInputBase } from '../../types'
import { getErrorMessage } from '../../helper'
import { ConfirmButton } from './ConfirmButton'
import LinkRows from './LinkRows'
import InputPasteLinksHelper from './helper'

export * from './LinkRows'

const MaxLinkDefault = 10
const PlaceholderDefault = `Enter all pages and websites - ${MaxLinkDefault} links`

interface ISlots {}

interface IParams extends ISlots {
  maxLink?: number
}

const CreateInputPasteLinks = function <TModel>(params?: IParams) {
  interface IProps extends IFormInputBase<TModel> {
    options?: ISlots
  }
  interface IState {
    links: string[]
    notify?: { error?: boolean; message?: string }
  }
  class InputPasteLinks extends Component<IProps, IState> {
    refInput: HTMLInputElement | HTMLTextAreaElement | null = null
    refInputHidden: HTMLInputElement | HTMLTextAreaElement | null = null
    constructor(props: IProps) {
      super(props)
      this.state = { links: this.getDefaultValue() }
    }

    render() {
      const { options: slots, defaultValue, ...otherProps } = this.props
      const dValue = this.state.links.length > 0 ? JSON.stringify(this.state.links) : ''
      return (
        <Box>
          <textarea hidden name={otherProps.name?.toString()} defaultValue={dValue} ref={(ref) => (this.refInputHidden = ref)} />
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '6px' }}>{this.renderInput()}</Box>
          <LinkRows
            data={this.state.links}
            maxLink={5}
            components={{
              actionRow: this.renderAction,
              bottom: (
                <Collapse in={this.state.links.length > 0} unmountOnExit>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '18px 0 9px' }}>
                    <ConfirmButton onSubmit={this.handleClearAll} slots={{ size: 'micro' }} />
                  </Box>
                </Collapse>
              )
            }}
          />
        </Box>
      )
    }

    renderAction = (item: string) => (
      <ConfirmButton
        onSubmit={() => this.deleteSingle(item)}
        slots={{
          color: 'inherit',
          size: 'micro',
          buttonElement: ({ onClick }) => (
            <IconButton onClick={onClick}>
              <DeleteIcon fontSize='small' />
            </IconButton>
          )
        }}
      />
    )

    renderInput = () => {
      const eMessage = getErrorMessage(this.props.messageErrors, this.props.name)
      const tfp: TextFieldProps = {
        fullWidth: true,
        multiline: true,
        variant: 'outlined',
        maxRows: 7,
        inputRef: (ref) => (this.refInput = ref),
        label: this.props.label,
        placeholder: this.props?.placeholder ?? PlaceholderDefault,
        onKeyDown: this.handleKeyDown,
        onPaste: this.handlePaste,
        onFocus: this.handleFocus,
        onBlur: () => {
          if (!this.props.name) return
          this.props.onBlur && this.props.onBlur(this.props.name)
        },
        error: this.state.notify?.error ?? eMessage.error,
        helperText: this.state.notify?.message ?? eMessage.message,
        InputProps: {
          endAdornment: (
            <InputAdornment position='end'>
              <Tooltip title='Add links'>
                <IconButton color='primary' sx={{ mt: '2px' }} onClick={this.checkValidateAndUpdate}>
                  <AddLinkIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          )
        }
      }
      return <TextField {...tfp} />
    }

    handleClearAll = () => {
      if (!this.refInput || !this.refInputHidden) return
      this.refInputHidden.value = ''
      this.setState({ links: [], notify: undefined })
    }

    handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
      const pasteData = event.clipboardData.getData('text')
      if (pasteData) {
        event.preventDefault()
        if (!this.refInput || !this.refInputHidden) return
        this.refInput.value = pasteData
        this.checkValidateAndUpdate()
        // this.refInput.value = ''
      }
    }

    handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && this.refInput && this.refInputHidden) {
        event.preventDefault()
        this.checkValidateAndUpdate()
      }
    }

    handleFocus = () => {
      this.setState({ notify: undefined })
    }

    checkValidateAndUpdate = () => {
      if (!this.refInput || !this.refInputHidden) return
      if (InputPasteLinksHelper.linkArrayByString(this.refInput.value).length < 1) {
        this.setState({ notify: { error: true, message: !this.refInput.value ? 'Please fill in your answer' : 'Link invalid!' } })
        this.refInput.blur()
        return
      }
      this.setLinksState(this.getLinks(this.refInput.value))
    }

    deleteSingle = (value: string) => {
      const list = new Set(this.state.links)
      list.delete(value)
      this.setLinksState(Array.from(list))
    }

    setLinksState = (value: string[]) => {
      this.setState({ links: value }, () => {
        if (this.refInputHidden) this.refInputHidden.value = JSON.stringify(this.state.links)
        if (this.refInput) {
          this.refInput.value = ''
          this.refInput.blur()
        }
      })
    }

    getLinks = (value?: string) => {
      const maxLink = params?.maxLink ?? MaxLinkDefault
      const pLinks = InputPasteLinksHelper.linkArrayByString(value)
      return InputPasteLinksHelper.linkAddSingle({ links: this.state.links, newLinks: pLinks, maxLink }).slice(0, maxLink)
    }

    getDefaultValue = (): string[] => {
      const { name, defaultValue, data: Model } = this.props
      return InputPasteLinksHelper.parseStringArray(defaultValue ?? (name ? Model?.[name] : undefined))
    }
  }
  return InputPasteLinks
}
export default CreateInputPasteLinks
export type InputPasteLinksType<TModel> = ReturnType<typeof CreateInputPasteLinks<TModel>>

import { Box, Button, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Error404 extends Component {
    render() {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography variant='h2'>Not found resource (error 404)</Typography>
                <Button variant='outlined' component={Link} to="/">Go Home</Button>
            </Box>
        )
    }
}

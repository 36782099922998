import { createTheme } from '@mui/material'

declare module '@mui/material/styles' {
  interface ITheme {
    app: {
      fonts: { exo2: string }
      header: {
        height: number
      }
      zIndex: { [key in 1 | 2 | 3 | 4 | 5 | 'infinity' | 'header' | 'modal' | 'backdrop']: number }
      boxShadow: { [key in 1]: string }
    }
  }
  export interface Theme extends ITheme {}
  // allow configuration using `createTheme`
  export interface ThemeOptions extends Partial<ITheme> {}
}

export const theme = createTheme({
  typography: {
    fontFamily: `'Exo 2', sans-serif`,
    fontWeightRegular: 500
  },
  app: {
    fonts: { exo2: `'Exo 2', sans-serif` },
    header: { height: 96 },
    zIndex: {
      1: 100,
      2: 200,
      3: 300,
      4: 400,
      5: 500,
      infinity: 2000,
      header: 1110,
      modal: 1150,
      backdrop: 1200
    },
    boxShadow: {
      1: 'rgba(145, 158, 171, 0.1) 0px 0px 2px 0px,rgba(145, 158, 171, 0.12) 0px 0px 24px -4px'
    }
  }
})

import React, { Component } from 'react'
import { ApiAlertContext } from '@lib/ApiContext'
import { getDeliveryStatus } from '@shared/Services'
import { CreateSelectSimpleLoading } from '@shared/UIStyleds'
import { EMediaDeliveryStatus, IMediaDelivery } from '@shared/Types'
import { DeliveryStatusCellConfig } from './StatusCell'

const ButtonLoadding = CreateSelectSimpleLoading<EMediaDeliveryStatus>()
interface IProps {
  data?: Partial<IMediaDelivery>
  onChangeValue?: (id: string, value: Partial<IMediaDelivery>) => Promise<void>
}
export default class ButtonDeliveryStatus extends Component<IProps> {
  render() {
    return (
      <ButtonLoadding
        styleds={DeliveryStatusCellConfig}
        options={getDeliveryStatus()}
        defaultValue={this.props.data?.Status}
        onChangeValue={this.handleChange}
        sx={{ minWidth: '115px' }}
      />
    )
  }
  handleChange = async (value: EMediaDeliveryStatus) => {
    const data: Partial<IMediaDelivery> = { ...this.props.data, Status: value as EMediaDeliveryStatus }
    if (!data.Id) {
      ApiAlertContext.ApiAlert?.PushWarning('Delivery ID is not found!')
      return
    }
    this.props.onChangeValue && (await this.props.onChangeValue(data.Id, data))
  }
}

import { ELazyStatus } from '@lib/ReduxBase'
import { ActionReducerMapBuilder, AsyncThunk } from '@reduxjs/toolkit'

const MapRequests = new Set<string>()

export const buildThunkGlobal = (builder: ActionReducerMapBuilder<{ Status: ELazyStatus }>, fetchThunk: AsyncThunk<any, any, any>) => {
  builder
    // Output
    .addCase(fetchThunk.fulfilled, (state, action) => {
      MapRequests.delete(action.meta.requestId)
      if (!MapRequests.size) {
        state.Status = ELazyStatus.Loaded
      }
    })
    .addCase(fetchThunk.rejected, (state, action) => {
      MapRequests.delete(action.meta.requestId)
      if (!MapRequests.size) {
        state.Status = ELazyStatus.Loaded
      }
    })
    // Middle
    // Input
    .addCase(fetchThunk.pending, (state, action) => {
      state.Status = ELazyStatus.Loading
      MapRequests.add(action.meta.requestId)
    })
}

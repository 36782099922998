import React, { Component } from 'react'
import { TryParseArray } from '@lib/Helpers'
import { Box, Divider, SxProps, Theme } from '@mui/material'
import { EMediaDeliveryType, IMediaDelivery } from '@shared/Types'
import { GetDeliveryType } from '@shared/Services/QueryParamService'
import { CreateInfoBar, TInfoBarConfig, IInfoBarItemProps } from '@shared/UIStyleds'

interface IInfoSectionProps {
  data?: Partial<IMediaDelivery>
  statusCell?: React.ComponentType<IInfoBarItemProps<IMediaDelivery>>
}

export default class DeliveryInfoBar extends Component<IInfoSectionProps> {
  render() {
    let repoLinks: TInfoBarConfig<IMediaDelivery> = {}
    if (GetDeliveryType() === EMediaDeliveryType.Url) {
      repoLinks = {
        Content: {
          label: 'Repository URLs',
          valueFormater: (value) => {
            const l = TryParseArray(value)
            return l.length > 0 ? l.length.toString() : undefined
          }
        }
      }
    }
    const InfoBar = CreateInfoBar<IMediaDelivery>({
      Name: { label: 'Name', sx: { minWidth: '80px' } },
      Email: { label: 'Email', sx: { minWidth: '95px' } },
      Title: { label: 'Sunmission', sx: { minWidth: '80px' } },
      Description: { label: 'Description' },
      ...repoLinks,
      ...(this.props.statusCell ? { Status: { label: 'Status', element: this.props.statusCell } } : {})
    })
    return <InfoBar data={this.props.data} sx={{ mb: '9px', flexWrap: 'wrap' }} />
  }
}

export const DeliveryInfoBarStatus: React.FC<React.PropsWithChildren<{ sx?: SxProps<Theme> }>> = ({ sx, children }) => {
  return (
    <>
      <Divider variant='middle' orientation='vertical' flexItem sx={{ my: '4px' }} />
      <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', ...sx }}>{children}</Box>
    </>
  )
}

import { IOrder } from '@shared/Types'
import { CreateCRUDSlice2, ELazyStatus } from '@lib/ReduxBase'
import { IOrderDetailStateSlice } from './type'
import { fetchOrderDetailThunk } from './Thunks'

const OrderDetailSlice = CreateCRUDSlice2<IOrder>()({
  name: 'OrderDetailSlice',
  selectId: (x) => x.Id,
  Initial: (x) => {
    const init: IOrderDetailStateSlice = { ...x }
    return init
  },
  extraReducers: (builder, adapter) => {
    builder
      .addCase(fetchOrderDetailThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Loaded
        state.dataDetail = action.payload.dataDetail
      })
      .addCase(fetchOrderDetailThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Error
      })
      .addCase(fetchOrderDetailThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Status = ELazyStatus.Loading
        state.dataDetail = undefined
      })
  },
  reducers: {}
})
export default OrderDetailSlice

import React, { Component } from 'react'
import { Box } from '@mui/material'

export default class NoData extends Component {
  render() {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: '56px' }}>
        <Box component="img" src="images/no-result.png" sx={{ height: '280px', width: '280px' }} />
      </Box>
    )
  }
}

interface ILinkAddSingleParams {
  links: string[]
  newLinks: string[]
  maxLink: number
}

export default class InputPasteLinksHelper {
  static linkArrayByString = (value: string = '') => {
    const updatedText = value.split('http').join(' http')
    return (
      updatedText
        .match(/[hH][tT][tT][pP][sS]?:\/\/[^\s]+/g)
        ?.filter((x) => x)
        .map((x) => x.trim()) ?? []
    )
  }

  static linkAddSingle = (params: ILinkAddSingleParams): string[] => {
    const linkSet = new Set([...params.newLinks, ...params.links].filter((e) => !!e))
    return Array.from(linkSet)
  }

  static parseStringArray = (value?: any): string[] => {
    try {
      if (Array.isArray(value)) return value
      const pValue = JSON.parse(value ?? '')
      if (Array.isArray(pValue)) return pValue
      return []
    } catch (error) {
      return []
    }
  }
}

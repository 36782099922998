import React, { Component, ReactElement } from 'react'
import { Box, Divider, IconButton, Stack, Typography, styled } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

export type TFileItemConfig = { 
  type: 'file'; 
  icon: JSX.Element; 
  files: File[];
  // data: {file: File, message:}[]
  name: string } | { type: 'line'; isShow: boolean }

interface IProps {
  isShow: boolean
  config: TFileItemConfig[]
  onClickDelete?: (fileName: string) => void
}

export default class UploadContent extends Component<IProps> {
  render() {
    return (
      <Box sx={{ flex: 1, padding: '0 0 15vh 0', ml: '-6px' }}>
        {this.props.config.reduce<ReactElement[]>((a, b) => {
          switch (b.type) {
            case 'file':
              b.files.forEach((e, i) => {
                a.push(
                  <ItemBox key={b.name + i}>
                    <IconBox>{b.icon}</IconBox>
                    <Stack sx={{ flex: 1 }}>
                      <Typography variant='h6' sx={{ fontWeight: 600, color: '#3c3c3c', fontSize: '1.15rem' }}>
                        {e.name}
                      </Typography>
                      <Typography variant='subtitle2'>{b.name}</Typography>
                    </Stack>
                    <IconButton onClick={() => this.handleClickDelete(e.name)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  </ItemBox>
                )
              })
              break
            case 'line':
            default:
              if (b.isShow) a.push(<Divider key='line' sx={{ mr: '-12px', my: '12px' }} />)
              break
          }
          return a
        }, [])}
      </Box>
    )
  }

  handleClickDelete = (fileName: string) => {
    this.props.onClickDelete && this.props.onClickDelete(fileName)
  }
}

const ItemBox = styled(Box)({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  padding: '6px 6px 6px 12px',
  '&:hover': { backgroundColor: '#f0f0f0' }
})

const IconBox = styled(Box)({
  flex: '0 0 auto',
  width: '56px',
  height: '56px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #DADCE0',
  borderRadius: '6px',
  backgroundColor: '#fff'
})

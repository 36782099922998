import { createAsyncThunk } from '@reduxjs/toolkit'
import { IBilling, IBillingDetail } from '@shared/Types'
import { MapTableData, MapTableInfo, TableReduxType } from '@lib/Table'
import BillingService, { BillingMapping } from '@internal/services/BillingService'
import { BillingDetailService, BillingDetailMapping } from '@internal/services/BillingDetailService'
import { IBillingDetailStateSlice } from './redux.types'

interface IThunkParam extends TableReduxType.ThunkParam<IBillingDetail> {
  billingId: string
  clear?: boolean
}
interface IThunkResponse extends TableReduxType.ThunkReponse<IBillingDetail> {}

export const fetchBillingDetailThunk = createAsyncThunk<IThunkResponse, IThunkParam>('fetchBillingDetailThunk', async (param, thunkAPI) => {
  const state = thunkAPI.getState() as { BillingDetailSlice: IBillingDetailStateSlice }
  const tableInfo = MapTableInfo(state.BillingDetailSlice.tableInfo, param.tableChange)

  const filter = BillingDetailMapping.tableInfoToFilter(tableInfo, param.billingId)
  const res = await BillingDetailService.AllGraphQL(filter, thunkAPI.signal)

  return { tableInfo: MapTableData(tableInfo, { Data: BillingDetailMapping.billingDetails(res?.items), Total: res?.totalItems }) }
})

export const fetchBillingDetailInitialThunk = createAsyncThunk<{ Billing?: IBilling }, IThunkParam>(
  'fetchBillingDetailInitialThunk',
  async (param, thunkAPI) => {
    // const state = thunkAPI.getState() as { BillingDetailSlice: IBillingDetailStateSlice }
    thunkAPI.dispatch(fetchBillingDetailThunk(param))

    const res = await BillingService.SingleGraphQL({ id: param.billingId }, thunkAPI.signal)
    return { Billing: BillingMapping.billing(res) }
  }
)

import { CRUDServiceBase } from '@lib/Http'
import { IProcessItem } from '@lib/Layout/UploadLayout'
import { IExtendFile } from '@shared/Pages/Media/Forms'
import { EOperator, ITableTemplateState } from '@lib/Table'
import { IUploadResult } from '@shared/Services/UploadService'
import { FileUploadType } from '@shared/Services/ResumableUploadService'
import { ETableLogicOperator, FetchDelay, TableToRequestFilter } from '@lib/Helpers'
import { ECategoryType, EMediaStatus, EMediaType, ICategory, ICategoryDetail, IMedia } from '@shared/Types'
import Graphql from '@coreprj/graphql'

export type TMedia = Graphql.QMediaStore.TMedia
type TMediasFilterParams = Graphql.QMediaStore.TMediasFilterParams

export type TCategory = Graphql.QMediaStore.TMOCategory
type TCategoryGraphQlResult = Graphql.TGraphQlResult<TCategory> | undefined

export type TMediaDelivery = Graphql.QMediaStore.TMediaDelivery

type TAllResult = {
  medias?: Graphql.TGraphQlResult<TMedia>
  categories?: TCategoryGraphQlResult
  mediaDelivery?: TMediaDelivery
}

class MediaServiceBase extends CRUDServiceBase<IMedia, number> {
  constructor() {
    super(`/api/v1/user/Media`)
  }

  AllGraphQL = async (filter: TMediasFilterParams, deliveryId?: string, signal?: AbortSignal): Promise<TAllResult> => {
    if (!!deliveryId) {
      const res = await Graphql.MediaStore.Query(Graphql.QMediaStore.MediasUserQueryWithDeliveryId(filter, deliveryId), { signal, delay: 700 })
      return {
        medias: res.mediaStore.medias as Graphql.TGraphQlResult<TMedia>,
        categories: res.mediaStore.categories as TCategoryGraphQlResult,
        mediaDelivery: res.mediaStore.mediaDelivery as TMediaDelivery
      }
    } else {
      const res = await Graphql.MediaStore.Query(Graphql.QMediaStore.MediasUserQuery(filter), { signal, delay: 700 })
      return {
        medias: res.mediaStore.medias as Graphql.TGraphQlResult<TMedia>,
        categories: res.mediaStore.categories as TCategoryGraphQlResult
      }
    }
  }

  DeleveryDetailCreate = async (model: Partial<IMedia>, signal?: AbortSignal) => {
    return FetchDelay(async () => {
      const data = await this.TryPushNotify(this.Post<IMedia>, 'DeleveryDetailCreate', model, { signal })
      return data
    }, 500)
  }
}
const MediaService = new MediaServiceBase()
export default MediaService

interface IMediaCreateParams {
  processItem: IProcessItem
  extendFile: IExtendFile
  uploadResult: IUploadResult
  fileType: FileUploadType
  userId?: string
  categoryIds?: string[]
}
class MediaMappingBase {
  tableInfoToFilter = (tableInfo: ITableTemplateState<IMedia>, mediaDeliveryId?: string) => {
    let filter = new TableToRequestFilter.Graphql<IMedia, TMedia>({
      fieldMapping: {
        Name: { field: 'name' },
        ResourceId: { field: 'resourceId' },
        Description: { field: 'description' },
        Duration: { field: 'duration' },
        Size: { field: 'size', type: 'double' },
        Extension: { field: 'extension' },
        Type: { field: 'type' },
        Status: { field: 'status' },
        Reason: { field: 'reason' },
        DateCreated: { field: 'dateCreated' },
        DateUpdated: { field: 'dateUpdated' }
      }
    })
      .fromTable(tableInfo, ['Name', 'Description', 'DisplayName'])
      .sort({ field: 'Id', sort: 'asc' })
    if (!!mediaDeliveryId) {
      filter = filter.filter({ field: 'DeliveryId', value: mediaDeliveryId, operator: EOperator.Equal, logicOperator: ETableLogicOperator.And })
    }
    return filter.build()
  }

  medias = (items: TMedia[] = []): IMedia[] => {
    return items.map<IMedia>((item) => ({
      Id: item.id,
      UserCreatedId: item.userCreatedId,
      Name: item.name ?? '',
      Description: item.description ?? '',
      Duration: item.duration,
      Size: item.size,
      Extension: item.extension ?? '',
      ResourceId: item.resourceId ?? '',
      Type: item.type as EMediaType,
      Status: item.status as EMediaStatus,
      Reason: item.reason ?? '',
      DisplayName: item.user?.displayName,
      DeliveryId: item.deliveryId?.toString() ?? '',
      CategoryNames: item.categoryDetails?.map((x) => x?.category?.name).filter((x) => !!x) as string[],
      CategoryDetails: item.categoryDetails?.reduce<ICategoryDetail[]>((a, b) => {
        if (!b?.category?.id) return a
        a.push({
          Id: b.id,
          CategoryId: b.categoryId,
          MediaId: b.mediaId,
          DateCreated: b.dateCreated,
          DateUpdated: b.dateUpdated,
          Category: {
            Id: b.category.id,
            Name: b.category.name ?? '',
            DateCreated: b.category.dateCreated,
            DateUpdated: b.category.dateUpdated
          } as ICategory
        })
        return a
      }, []),
      DateCreated: item.dateCreated,
      DateUpdated: item.dateUpdated
    }))
  }

  mediaCreate = (item: Partial<IMedia>): Partial<IMedia> => {
    if (item.CategoryNames) delete item.CategoryNames
    return item
  }

  mediaCreateParam = (params: IMediaCreateParams): Partial<IMedia> => ({
    UserCreatedId: params.userId,
    Name: params.processItem.File.name,
    Description: params.processItem.File.name,
    Duration: params.extendFile.duration,
    Size: params.processItem.File.size,
    Extension: params.uploadResult?.fileExtension ?? '',
    ResourceId: params.uploadResult?.id ?? '',
    Type: params.fileType as any,
    CategoryIds: params.categoryIds,
    Status: EMediaStatus.Pending
  })

  categories = (items: TCategory[] = []): ICategory[] => {
    return items.map<ICategory>((item) => ({
      Id: item.id,
      Name: item.name ?? '',
      Description: item.description ?? '',
      ImageUri: item.imageUri ?? '',
      Type: item.type as ECategoryType,
      DateCreated: item.dateCreated ?? ''
    }))
  }
}
export const MediaMapping = new MediaMappingBase()

import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { EMediaType } from '@shared/Types'
import { ELazyStatus } from '@lib/ReduxBase'
import { IMedia, IPrice } from '@shared/Types'
import { IExploreReduxProps } from './redux.types'
import { MediaItemAudio, MediaItemVideo } from '@internal/shareds/MediaItem'
import { UINoData } from './ui.nodata'
import { UISkeletonExploreContent } from './ui.skeleton'

interface IProps extends IExploreReduxProps {
  onAddToCart: (media: IMedia, price: IPrice) => void
}

export default class UIExploreContent extends Component<IProps> {
  render() {
    const list = this.getData()
    // return <UIExploreContentSkeleton mediaType={this.props.Slice.filter.mediaType} />

    if (list.length < 1 && this.props.Status === ELazyStatus.Loaded) return <UINoData />
    if (list.length < 1) return <UISkeletonExploreContent mediaType={this.props.Slice.filter.mediaType} />
    return (
      <Grid container spacing={2} sx={{ backgroundColor: '#fff' }}>
        {list.map((item, index) => {
          return <React.Fragment key={item.Name + index}>{this.renderItem(item)}</React.Fragment>
        })}
      </Grid>
    )
  }

  renderItem = (item: IMedia) => {
    switch (item.Type) {
      case EMediaType.Audio:
        return (
          <Grid item xs={12}>
            <MediaItemAudio data={item} prices={this.props.Slice.prices} onAddToCart={this.props.onAddToCart} />
          </Grid>
        )
      case EMediaType.Video:
        return (
          <Grid item xs={12} sm={4} md={3}>
            <MediaItemVideo data={item} prices={this.props.Slice.prices} onAddToCart={this.props.onAddToCart} />
          </Grid>
        )
      default:
        return <></>
    }
  }

  getData = () => {
    const { ids, entities } = this.props.Slice.medias
    const list = ids.map((id) => entities[id])
    return list as IMedia[]
  }
}

export const MaximumFileSizeLimit = 1024 * 1024 // 1 MB = 1024 * 1024 bytes

export const validateFilesSize = (files: File[], options?: { multiple?: boolean }) => {
  if (options?.multiple === true) {
    const size = files.reduce((a, b) => {
      a += b.size
      return a
    }, 0)
    return size < MaximumFileSizeLimit
  }
  return files.every((file) => file.size < MaximumFileSizeLimit)
}

export const filesToArrayBase64 = (files: File[] = []): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    if (!files || files.length < 1) {
      resolve([])
    }

    const base64Array: string[] = []
    let filesProcessed = 0

    files.forEach((file) => {
      const reader = new FileReader()

      reader.onloadend = () => {
        base64Array.push(reader.result as string)
        filesProcessed++
        if (filesProcessed === files.length) {
          resolve(base64Array)
        }
      }

      reader.onerror = (error) => {
        reject(error)
      }

      reader.readAsDataURL(file)
    })
  })
}

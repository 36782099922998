import React, { Component } from 'react'
import { Box, Button, Divider, styled, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { RouteKey } from '@internal/route'
import { CreateRoutePath } from '@lib/RouteBase'

const textContentHeight = '85px'

export class BackLinkPricing extends Component {
  render() {
    return (
      <FormWrap>
        <TextTitle>Privacy policy</TextTitle>
        <Divider />
        <TextContent>You can read our policy here before using the service. That will ensure the safety of your digital assets</TextContent>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: '9px', pr: '12px' }}>
          <Button color='primary' sx={{ fontWeight: 600, textTransform: 'unset' }} component={Link} to={CreateRoutePath(RouteKey.PrivacyPolicy)}>
            Read
          </Button>
        </Box>
      </FormWrap>
    )
  }
}

export class BackLinkFQAs extends Component {
  render() {
    return (
      <FormWrap>
        <TextTitle variant='subtitle1'>FQAs</TextTitle>
        <Divider />
        <TextContent variant='subtitle2'>Questions are frequently asked by our customers. Hope it helps you</TextContent>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: '9px', pr: '12px' }}>
          <Button color='primary' sx={{ fontWeight: 600, textTransform: 'unset' }} component={Link} to={CreateRoutePath(RouteKey.FQAs)}>
            Read
          </Button>
        </Box>
      </FormWrap>
    )
  }
}

const TextTitle = styled(Typography)({
  fontWeight: 600,
  marginTop: '-6px',
  paddingBottom: '6px'
})

const TextContent = styled(Typography)({
  textAlign: 'justify',
  height: textContentHeight,
  padding: '12px 24px 12px 0'
})

const FormWrap = styled(Box)({
  padding: '24px 0 0 24px',
  border: '1px solid #DADCE0',
  marginTop: '24px',
  borderRadius: '4px'
})

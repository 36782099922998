import { AppDispatch, RootState } from '@internal/redux'
import { fetchCheckOutThunk } from './redux.thunks'
import { ICheckOuIDispatchRedux, ICheckOuIStateRedux } from './redux.types'

export const mapStateToProps = (state: RootState): ICheckOuIStateRedux => ({
  Status: state.CheckOutSlice.Status,
  CheckOutSlice: state.CheckOutSlice
})

export const mapDispatchToProps = (dispatch: AppDispatch): ICheckOuIDispatchRedux => {
  return {
    FetchData: () => {
      return dispatch(fetchCheckOutThunk())
    }
  }
}

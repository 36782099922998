import { IOrder } from '@shared/Types'
import { TableQueryParam } from '@lib/Helpers'
import { AppDispatch, RootState } from '@internal/redux'
import { fetchOrderThunk } from './redux.thunks'
import { IOrderMapDispatchRedux, IOrderMapStateRedux } from './redux.types'
import OrderSlice, { DefaultTableInfo } from './redux.slice'

export const mapStateToProps = (state: RootState): IOrderMapStateRedux => ({
  Status: state.OrderSlice.Status,
  ReduxState: state.OrderSlice,
  tableInfo: state.OrderSlice.tableInfo
})

type TRequest = { abort: () => void }
const FetchAccessory: { request?: TRequest } = {}
export const mapDispatchToProps = (dispatch: AppDispatch): IOrderMapDispatchRedux => ({
  FetchData: () => {
    const tableInfo = TableQueryParam.getParam<IOrder>(DefaultTableInfo)
    return dispatch(fetchOrderThunk({ tableInfo }))
  },
  onTableChange: (key, value, details) => {
    if (key === 'FilterModel') {
      dispatch(OrderSlice.actions.onChange({ key, value, details }))
    }
    FetchAccessory.request?.abort()
    FetchAccessory.request = dispatch(fetchOrderThunk({ tableChange: { key, value: value, details } }))
  }
})

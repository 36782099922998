import React, { Component } from 'react'
import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import AppHeader from '../AppHeader'
import AppFooter from '../AppFooter'

export default class LayoutGlobal extends Component {
  render() {
    return (
      <>
        <AppHeader fixed />
        <Box sx={{ minHeight: 'calc(100vh - var(--height-header))' }}>
          <Outlet />
        </Box>
        <AppFooter />
      </>
    )
  }
}

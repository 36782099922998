import { AxiosError, AxiosRequestConfig, CancelToken } from 'axios'
import { Fetcher } from 'graphql-ts-client-api'
import ServiceBase from '@lib/Http/ServiceBase'
import { FetchDelay } from './helper'
import { EGraphqlSite } from '../models'
import { AppProfile } from '../utilities'
import { CreateHttpService } from './Getway'
import { GraphQLExecutor, execute } from '../__generated/media-store'

class GraphqlRequest extends ServiceBase {
  private constructor(url: string) {
    super(CreateHttpService(url))
  }
  setGraphQLExecutor = async (request: string, variables: object, signal?: AbortSignal) => {
    return this.Post('', { query: request, variables }, { signal })
  }
  static CreateInstan(urlGraphql: string) {
    return new GraphqlRequest(urlGraphql)
  }

  getSite = async () => {
    const windowTemp = global.window as any
    if (typeof windowTemp['wwfd'] !== 'object') {
      windowTemp['wwfd'] = {}
    }
    if (!windowTemp['wwfd'].GraphqlSite) {
      windowTemp['wwfd'].GraphqlSite = await AppProfile.getGraphqlSite()
    }
    return windowTemp['wwfd'].GraphqlSite
  }

  autoDetectSite = async () => {
    const site = await this.getSite()
    this.SetSite(site)
  }
  private addSite(config?: AxiosRequestConfig) {
    if (this._site && config?.headers) {
      config.headers['graphql-site'] = this._site
    }
    return config ?? {}
  }
  async addCustomHeader(config?: AxiosRequestConfig) {
    const cfg = await super.addCustomHeader(config)
    return this.addSite(cfg)
  }
  private _site?: EGraphqlSite
  SetSite = (site: EGraphqlSite) => {
    this._site = site
  }
  async Query<TData extends object, TVariables extends object>(
    fetcher: Fetcher<'Query' | 'Mutation', TData, TVariables>,
    options?: {
      readonly operationName?: string
      readonly variables?: TVariables
      readonly executor?: GraphQLExecutor
      readonly signal?: AbortSignal
      readonly cancelToken?: CancelToken
      readonly delay?: number
      readonly autoDetectSite?: boolean
      readonly site?: EGraphqlSite
    }
  ) {
    if (options?.autoDetectSite !== false) {
      await this.autoDetectSite()
    } else if (options.site) {
      this.SetSite(options.site)
    }

    const res = await execute(fetcher, {
      executor: (...params) => {
        options?.signal && params.push(options?.signal)
        options?.cancelToken && params.push(options?.cancelToken)
        return FetchDelay(() => this.setGraphQLExecutor(...params), options?.delay ?? 500)
      },
      ...(options ? options : {})
    })
    return res
  }

  async QueryPushNotify<TData extends object, TVariables extends object>(
    fetcher: Fetcher<'Query' | 'Mutation', TData, TVariables>,
    options?: {
      readonly operationName?: string
      readonly variables?: TVariables
      readonly executor?: GraphQLExecutor
      readonly signal?: AbortSignal
      readonly cancelToken?: CancelToken
      readonly delay?: number
    }
  ) {
    try {
      return await this.Query(fetcher, options)
    } catch (err) {
      const error = err as AxiosError
      const data = error.response?.data as any
      if (data) {
        const keys = Object.keys(data)
        keys.forEach((key) => {
          const errors = data[key] as any
          if (Array.isArray(errors)) {
            errors.forEach((message) => {
              // ApiAlertContext.ApiAlert?.PushError(message)
            })
          } else if (typeof errors.message === 'string') {
            // ApiAlertContext.ApiAlert?.PushError(errors.message)
          }
        })
      }
      throw error
    }
  }
}

export * as QMediaStore from '../queries/MediaStore'

export const MediaStore = GraphqlRequest.CreateInstan(process.env.REACT_APP_MEDIA_STORE_URL_API ?? '')
export const GeustMediaStore = GraphqlRequest.CreateInstan(process.env.REACT_APP_MEDIA_STORE_URL_API_GUEST ?? '')

export * from './type'

export * from './RequestParam'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IBilling } from '@shared/Types'
import { RouteKey } from '@internal/route'
import { TableFormater } from '@lib/Helpers'
import { CreateHocLazy } from '@lib/ReduxBase'
import { CreateRoutePath } from '@lib/RouteBase'
import { CreateTableTemplate, MapOprators, CRUDPannel, ActionPannel } from '@lib/Table'
import { BillingButtonDetail, BillingStatusCell, TotalMoneyCell } from '@shared/Pages/Billing'
import UIStyleds from '@shared/UIStyleds'
import { IBillingReduxProps } from './redux.types'
import { mapDispatchToProps, mapStateToProps } from './redux.map'

const Table = CreateTableTemplate<IBilling>('Redux', {
  getRowId: (x) => x.Id,
  config: {
    Name: { type: 'string', flex: 2, minWidth: 200 },
    Description: { type: 'string', flex: 1, minWidth: 150 },
    Payout: {
      type: 'number',
      flex: 1,
      minWidth: 175,
      headerName: 'Payout (USD)',
      headerAlign: 'left',
      renderCell: (params) => <TotalMoneyCell Usd={params.value} UsdRate={0} />
    },
    Type: { type: 'string', width: 120, filterable: false },
    DateCreated: {
      type: 'string',
      width: 180,
      headerName: 'Date created',
      filterable: false,
      renderCell: (params) => TableFormater.tooltipDate(params.value)
    },
    TransactionId: { type: 'string', flex: 1, headerName: 'Transaction ID' },
    DatePaid: {
      type: 'string',
      width: 180,
      headerName: 'Date paid',
      filterable: false,
      renderCell: (params) => TableFormater.tooltipDate(params.value)
    },
    Status: { type: 'string', width: 115, filterable: false, renderCell: (params) => <BillingStatusCell data={params.value} /> }
  },
  filterOperators: MapOprators, //server mode
  GridColumnVisibilityModel: {
    DatePaid: false,
    TransactionId: false
  },
  minWidthColumnActions: 75
})

interface IProps extends IBillingReduxProps {}
class ViewBase extends Component<IProps> {
  render() {
    return (
      <UIStyleds.WrapProfile>
        <Table
          CRUDPannel={(p) => <CRUDPannel Title='Billings' />}
          ActionPannel={(p) => {
            const data = p.data as IBilling
            return (
              <ActionPannel
                Buttons={{
                  Details: (
                    <BillingButtonDetail
                      disabled={data.NoDetail}
                      to={CreateRoutePath([RouteKey.Profile, RouteKey.Billing], { query: { billingId: data.Id, md: RouteKey.BillingDetail } })}
                    />
                  )
                }}
              />
            )
          }}
          ReduxOption={this.props.tableInfo}
          onChange={this.props.onTableChange}
        />
      </UIStyleds.WrapProfile>
    )
  }
}

export * from './redux.thunks'
export { default as BillingSlice } from './redux.slice'

const HocLazyInstance = CreateHocLazy(ViewBase)
export const BillingMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)

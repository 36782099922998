import { Link } from 'react-router-dom'
import { RouteKey } from '@internal/route'
import { QueryParam } from '@lib/Helpers'
import { ICategory, IGalleryDetail, IMedia } from '@shared/Types'
import { ECategoryType, EMediaStatus, EMediaType } from '@shared/Types'
import { IMediaFilter } from './types'

export const createExploreUri = (value: IMediaFilter) => {
  const filterString = QueryParam.stringify(value)
  return `${RouteKey.Explore}${filterString}`
}

export const mapLink = (uri: string, disabledBlank?: boolean) => ({
  component: Link,
  to: uri,
  target: disabledBlank ? '_self' : '_blank'
})

export const mapLinkExplore = (value: IMediaFilter, isBlank?: boolean) => {
  const uri = createExploreUri(value)
  return { component: Link, to: uri, target: isBlank ? '_blank' : '_self' }
}

export const createImageUri = (value: string) => `${process.env.PUBLIC_URL}${value}`

export const mapGalleryDetailToMedia = (value: IGalleryDetail): IMedia => ({
  Id: value.Media?.Id ?? '',
  Name: value.Media?.Name ?? '',
  Duration: value.Media?.Duration ?? 0,
  Size: 0,
  Type: value.Media?.Type as EMediaType,
  ResourceId: value.ResourceId,
  UserCreatedId: '',
  Description: '',
  Extension: '',
  CategoryNames: [],
  Status: EMediaStatus.Pending,
  Reason: '',
  DateCreated: ''
})

export type TCategoryByType = { [key in ECategoryType]: ICategory[] }
export const filterCategoriesByType = (items: ICategory[]): TCategoryByType => ({
  [ECategoryType.Video]: items.filter((x) => x.Type === ECategoryType.Video),
  [ECategoryType.Audio]: items.filter((x) => x.Type === ECategoryType.Audio)
})

import { ISubmission } from '@shared/Types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import SubmissionService from '@internal/services/SubmissionService'

// ========= ========= ========= Main Redux ========= ========= =========
interface IParamsThunk {
  id: number
}

interface IResponseThunk {
  data?: ISubmission | null
}

export const fetchSubmissionDetailThunk = createAsyncThunk<IResponseThunk, IParamsThunk>('fetchSubmissionDetailThunk', async (params, thunkAPI) => {
  // const state = thunkAPI.getState() as { SubmissionDetailSlice: ISubmissionDetailStateSlice }
  const result = await SubmissionService.Single(params.id, thunkAPI.signal)
  return { data: result }
})

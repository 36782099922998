import { IProcessItem } from '@lib/Layout/UploadLayout'
import { FileUploadType } from '@shared/Services/ResumableUploadService'

export interface IExtendFile {
  duration: number
}

class FileHandlerBase {
  accepts = ['audio/*', 'video/*,.mkv']

  isAudioType = (fileType: string) => /^audio\//.test(fileType)

  isVideoType = (fileType: string) => /^video\//.test(fileType)

  getFileType = (item: IProcessItem) => {
    if (this.isAudioType(item.File.type)) return FileUploadType.Audio
    else if (this.isVideoType(item.File.type)) return FileUploadType.Video
    return undefined
  }

  sliceFiles = (files: File[]) => ({
    audios: files.filter((e) => this.isAudioType(e.type)),
    videos: files.filter((e) => this.isVideoType(e.type))
  })

  private getVideoDuration = (url: string): Promise<number> => {
    return new Promise((resolve) => {
      const video = document.createElement('video')
      video.src = url
      video.onloadedmetadata = () => {
        resolve(video.duration)
        URL.revokeObjectURL(url) // Clean up the object URL
      }
    })
  }
  getAudioDuration = (src: string): Promise<number> => {
    return new Promise((res, rej) => {
      const audio = new Audio(src)
      audio.onloadedmetadata = () => {
        res(audio.duration)
      }
      audio.onerror = rej
    })
  }
  getExtendFile = async (item: IProcessItem): Promise<IExtendFile> => {
    const fileType = this.getFileType(item)
    const url = URL.createObjectURL(item.File)
    switch (fileType) {
      case FileUploadType.Audio: {
        const duration = await this.getAudioDuration(url)
        return { duration }
      }
      case FileUploadType.Video: {
        const duration = await this.getVideoDuration(url)
        return { duration }
      }
      default:
        return { duration: 0 }
    }
  }
}
export const FileHandler = new FileHandlerBase()

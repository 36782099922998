import React, { Component } from 'react'
import { ICart } from '@shared/Types'
import { Link } from 'react-router-dom'
import { EMediaType } from '@shared/Types'
import { RouteKey } from '@internal/route'
import { CreateRoutePath } from '@lib/RouteBase'
import { Box, Button, Divider, IconButton, MenuItem, Typography, alpha, styled } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout'
import Helpers from '../helpers'

interface IProps {
  data: ICart[]
  onClose?: () => void
  onRemove?: (value: ICart) => void
}

export default class CartBadgeContent extends Component<IProps> {
  render() {
    return (
      <Box>
        <Box sx={{ padding: '16px', display: 'flex', whiteSpace: 'nowrap' }}>
          <Text18>Your Cart {`(${Helpers.getTotalLinks(this.props.data)})`}</Text18>
          <Box width='100%' />
          <Text18>Total: $ {Helpers.getTotal(this.props.data)}</Text18>
        </Box>
        <Divider />
        {this.renderItems()}
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '0 20px' }}>
          <Button
            color='inherit'
            endIcon={<ShoppingCartCheckoutIcon />}
            sx={{ fontWeight: 700 }}
            component={Link}
            to={RouteKey.Cart}
            onClick={this.props.onClose}
          >
            Show in cart
          </Button>
        </Box>
      </Box>
    )
  }

  renderItems = () => {
    if (this.props.data.length < 1) return <Title sx={{ textAlign: 'center', py: '20px' }}>No data</Title>
    return this.props.data.map((item, index) => {
      const mediaDetailPath = CreateRoutePath(RouteKey.Detail, { query: { type: item.Type, id: item.Id } })
      return (
        <MenuItem key={index} sx={{ marginTop: '6px', gap: '9px', width: '100%', py: '12px' }}>
          {this.renderImage(item)}
          <MenuItemContent to={mediaDetailPath} onClick={this.props.onClose}>
            <Text18 sx={{ flex: 1 }} noWrap>
              {item.Name}
            </Text18>
            <Box sx={{ width: '100px', display: 'flex', alignItems: 'flex-end', gap: '2px', justifyContent: 'flex-end' }}>
              <Title sx={{ flex: 1, textAlign: 'right' }}>$ {item.PriceOrigin?.Price ?? 0}</Title>
              <Text18 sx={{ fontSize: '14px', mb: '3px', color: '#818181' }}>x{item.JsonContent?.Links?.length ?? 0} urls</Text18>
            </Box>
            <IconButton onClick={this.handleClickRemove(item)}>
              <RemoveIcon />
            </IconButton>
          </MenuItemContent>
        </MenuItem>
      )
    })
  }

  renderImage = (value: ICart) => {
    switch (value.Type) {
      case EMediaType.Audio:
        return (
          <WraperImage>
            <MusicNoteIcon sx={{ color: '#1976D2' }} />
          </WraperImage>
        )
      case EMediaType.Video:
        return (
          <WraperImage>
            <img src={value.ImageUri} alt='media-pic' />
          </WraperImage>
        )
      default:
        return <></>
    }
  }

  handleClickRemove =
    (value: ICart): React.MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      this.props.onRemove && this.props.onRemove(value)
    }
}

const Title = styled(Typography)({
  fontWeight: 600,
  fontSize: '18px',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
})

const Text18 = styled(Typography)({
  fontFamily: "'Exo 2', sans-serif",
  fontWeight: 500,
  color: '#606060',
  fontSize: '18px'
})

const MenuItemContent = styled(Link)({
  color: '#606060',
  textDecoration: 'unset !important',
  display: 'flex',
  alignItems: 'center',
  gap: '9px',
  width: 'calc(100% - 56px)'
})

const WraperImage = styled(Box)({
  flex: '0 0 auto',
  width: '48px',
  height: '48px',
  backgroundColor: alpha('#1976D2', 0.15),
  boxShadow: '0 2px 14px rgba(0,0,0,0.15)',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  '& img': {
    width: '100%',
    height: '100%'
  }
})

import React, { Component } from 'react'
import { IFormInputBase } from '@lib/Forms'
import { IPersonalInfo } from '@shared/Types'
import { Box, Typography } from '@mui/material'
import { CopyToClipboard } from '@lib/Component'

export default class ControlCopy extends Component<IFormInputBase<IPersonalInfo>> {
  render() {
    const { name, data } = this.props
    const value = this.props.defaultValue ?? (!!name ? data?.[name] : undefined)
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography className='noselect' sx={{ fontFamily: "'Exo 2', sans-serif", fontWeight: 500, flex: 1, color: '#999999' }}>
          {value ?? 'none'}
        </Typography>
        {value && (
          <Box sx={{ my: '-12px' }}>
            <CopyToClipboard value={value} />
          </Box>
        )}
      </Box>
    )
  }
}

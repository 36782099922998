import { IOrder } from '@shared/Types'
import { PayloadAction } from '@reduxjs/toolkit'
import { CreateCRUDSlice2 } from '@lib/ReduxBase'
import { ELazyStatus } from '@lib/ReduxBase/types'
import { TableInitialSliceRedux, TableReduxType } from '@lib/Table'
import { fetchOrderThunk } from './redux.thunks'
import { IOrderStateSlice } from './redux.types'

export const DefaultTableInfo = TableInitialSliceRedux<IOrder>({ GridSortModel: [{ field: 'DateCreated', sort: 'desc' }] })

const OrderSlice = CreateCRUDSlice2<IOrder>()({
  name: 'OrderSlice',
  selectId: (x) => x.Id,
  Initial: (x) => {
    const state: IOrderStateSlice = { ...x, tableInfo: DefaultTableInfo }
    return state
  },
  extraReducers: (builder, adapter) => {
    builder
      .addCase(fetchOrderThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Loaded
        state.tableInfo = action.payload.tableInfo
      })
      .addCase(fetchOrderThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Error
        state.tableInfo.isLoading = true
      })
      .addCase(fetchOrderThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Status = ELazyStatus.Loading
        state.tableInfo.isLoading = true
      })
  },
  reducers: {
    onChange: (state, action: PayloadAction<TableReduxType.OnChangeParam<IOrder>>) => {
      state.tableInfo[action.payload.key] = action.payload.value
      state.tableInfo.details = action.payload.details
    }
  }
})
export default OrderSlice

import { configureStore } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import CombinedReducers from './CombinedReducers'

const store = configureStore({
  reducer: CombinedReducers,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat([thunkMiddleware])
  }
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

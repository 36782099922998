import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteKey } from '@internal/route'
import { CreateHocLazy } from '@lib/ReduxBase'
import { ETicketConversation } from '@shared/Types'
import { LayoutMakeupInstance } from '@shared/Instances'
import { TicketDetailContent, TUIBRefresh } from '@shared/Pages/Ticket'
import LayoutModalRoute from '@lib/Layout/ModalRoute'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import { ITicketDetailProps } from './redux.types'
import { mapDispatchToProps, mapStateToProps } from './redux.map'
import { Typography } from '@mui/material'
import { CapitalizeFirstText } from '@lib/Helpers'

class ViewBase extends Component<ITicketDetailProps> {
  render() {
    return (
      <>
        <TicketDetailContent
          status={this.props.Status}
          data={this.props.dataDetail}
          onSubmit={(value) => this.props.CRUD.Create({ Message: value.Message, Type: ETicketConversation.Text })}
          requestedId={this.props.requestedId}
          renderRepresentIcon={(value) => (value.IsMe === true ? <PersonOutlineOutlinedIcon fontSize='small' sx={{ color: '#fff' }} /> : undefined)}
        />
        {LayoutMakeupInstance.ReplaceMake(
          'TicketDetailTitle',
          <Typography component='h6' variant='subtitle1' sx={{ fontWeight: 600, color: '#606060' }}>
            {CapitalizeFirstText(this.props.dataDetail?.Subject ?? '')}
          </Typography>
        )}
        {LayoutMakeupInstance.ReplaceMake(
          'TicketDetailAction',
          <TUIBRefresh onClick={this.props.refresh} loading={this.props.stateRedux.refreshStatus} />
        )}
      </>
    )
  }
}

export { fetchTicketDetailThunk } from './redux.thunks'
export { default as TicketDetailSlice } from './redux.slice'

const HocLazyInstance = CreateHocLazy(ViewBase)
export const TicketDetailMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)
export const TicketDetailModal: React.FC = () => (
  <LayoutModalRoute.Wrapper
    size='lg'
    title={LayoutMakeupInstance.GetMakeup('TicketDetailTitle')}
    back={[RouteKey.Profile, RouteKey.Ticket]}
    // disabledBackdrop={true}
    slots={{ topBarRight: LayoutMakeupInstance.GetMakeup('TicketDetailAction') }}
  >
    <TicketDetailMapRedux />
  </LayoutModalRoute.Wrapper>
)

import type { AcceptableVariables, UnresolvedVariables, FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';
import type {NotificationGraphDbContextNotificationInfosSortInput} from '../inputs';
import type {NotificationGraphDbContextNotificationStatusesSortInput} from '../inputs';
import type {NotificationGraphDbContextNotificationUsersSortInput} from '../inputs';
import type {NotificationGraphDbContextUserExtendsSortInput} from '../inputs';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface NotificationGraphDbContextFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'NotificationGraphDbContext', T, TVariables> {

    on<XName extends ImplementationType<'NotificationGraphDbContext'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): NotificationGraphDbContextFetcher<
        XName extends 'NotificationGraphDbContext' ?
        T & X :
        WithTypeName<T, ImplementationType<'NotificationGraphDbContext'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'NotificationGraphDbContext'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): NotificationGraphDbContextFetcher<T, TVariables>;


    readonly __typename: NotificationGraphDbContextFetcher<T & {__typename: ImplementationType<'NotificationGraphDbContext'>}, TVariables>;


    notificationInfo<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'NotificationInfo', X, XVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly "notificationInfo"?: X}, 
        TVariables & XVariables & NotificationGraphDbContextArgs["notificationInfo"]
    >;

    notificationInfo<
        XArgs extends AcceptableVariables<NotificationGraphDbContextArgs['notificationInfo']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'NotificationInfo', X, XVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly "notificationInfo"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, NotificationGraphDbContextArgs['notificationInfo']>
    >;

    notificationInfo<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "notificationInfo", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'NotificationInfo', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"notificationInfo", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & NotificationGraphDbContextArgs["notificationInfo"] & XDirectiveVariables
    >;

    notificationInfo<
        XArgs extends AcceptableVariables<NotificationGraphDbContextArgs['notificationInfo']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "notificationInfo", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'NotificationInfo', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"notificationInfo", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, NotificationGraphDbContextArgs['notificationInfo']> & XDirectiveVariables
    >;


    notificationInfos<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'NotificationInfoPage', X, XVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly "notificationInfos"?: X}, 
        TVariables & XVariables & NotificationGraphDbContextArgs["notificationInfos"]
    >;

    notificationInfos<
        XArgs extends AcceptableVariables<NotificationGraphDbContextArgs['notificationInfos']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'NotificationInfoPage', X, XVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly "notificationInfos"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, NotificationGraphDbContextArgs['notificationInfos']>
    >;

    notificationInfos<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "notificationInfos", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'NotificationInfoPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"notificationInfos", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & NotificationGraphDbContextArgs["notificationInfos"] & XDirectiveVariables
    >;

    notificationInfos<
        XArgs extends AcceptableVariables<NotificationGraphDbContextArgs['notificationInfos']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "notificationInfos", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'NotificationInfoPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"notificationInfos", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, NotificationGraphDbContextArgs['notificationInfos']> & XDirectiveVariables
    >;


    notificationStatuses<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'NotificationStatusPage', X, XVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly "notificationStatuses"?: X}, 
        TVariables & XVariables & NotificationGraphDbContextArgs["notificationStatuses"]
    >;

    notificationStatuses<
        XArgs extends AcceptableVariables<NotificationGraphDbContextArgs['notificationStatuses']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'NotificationStatusPage', X, XVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly "notificationStatuses"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, NotificationGraphDbContextArgs['notificationStatuses']>
    >;

    notificationStatuses<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "notificationStatuses", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'NotificationStatusPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"notificationStatuses", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & NotificationGraphDbContextArgs["notificationStatuses"] & XDirectiveVariables
    >;

    notificationStatuses<
        XArgs extends AcceptableVariables<NotificationGraphDbContextArgs['notificationStatuses']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "notificationStatuses", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'NotificationStatusPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"notificationStatuses", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, NotificationGraphDbContextArgs['notificationStatuses']> & XDirectiveVariables
    >;


    notificationUsers<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'NotificationUserPage', X, XVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly "notificationUsers"?: X}, 
        TVariables & XVariables & NotificationGraphDbContextArgs["notificationUsers"]
    >;

    notificationUsers<
        XArgs extends AcceptableVariables<NotificationGraphDbContextArgs['notificationUsers']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'NotificationUserPage', X, XVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly "notificationUsers"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, NotificationGraphDbContextArgs['notificationUsers']>
    >;

    notificationUsers<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "notificationUsers", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'NotificationUserPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"notificationUsers", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & NotificationGraphDbContextArgs["notificationUsers"] & XDirectiveVariables
    >;

    notificationUsers<
        XArgs extends AcceptableVariables<NotificationGraphDbContextArgs['notificationUsers']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "notificationUsers", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'NotificationUserPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"notificationUsers", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, NotificationGraphDbContextArgs['notificationUsers']> & XDirectiveVariables
    >;


    userExtend<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'UserExtend', X, XVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly "userExtend"?: X}, 
        TVariables & XVariables & NotificationGraphDbContextArgs["userExtend"]
    >;

    userExtend<
        XArgs extends AcceptableVariables<NotificationGraphDbContextArgs['userExtend']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'UserExtend', X, XVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly "userExtend"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, NotificationGraphDbContextArgs['userExtend']>
    >;

    userExtend<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "userExtend", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'UserExtend', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"userExtend", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & NotificationGraphDbContextArgs["userExtend"] & XDirectiveVariables
    >;

    userExtend<
        XArgs extends AcceptableVariables<NotificationGraphDbContextArgs['userExtend']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "userExtend", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'UserExtend', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"userExtend", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, NotificationGraphDbContextArgs['userExtend']> & XDirectiveVariables
    >;


    userExtends<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'UserExtendPage', X, XVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly "userExtends"?: X}, 
        TVariables & XVariables & NotificationGraphDbContextArgs["userExtends"]
    >;

    userExtends<
        XArgs extends AcceptableVariables<NotificationGraphDbContextArgs['userExtends']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'UserExtendPage', X, XVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly "userExtends"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, NotificationGraphDbContextArgs['userExtends']>
    >;

    userExtends<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "userExtends", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'UserExtendPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"userExtends", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & NotificationGraphDbContextArgs["userExtends"] & XDirectiveVariables
    >;

    userExtends<
        XArgs extends AcceptableVariables<NotificationGraphDbContextArgs['userExtends']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "userExtends", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'UserExtendPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"userExtends", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): NotificationGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, NotificationGraphDbContextArgs['userExtends']> & XDirectiveVariables
    >;
}

export const notificationGraphDbContext$: NotificationGraphDbContextFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "NotificationGraphDbContext", 
            "EMBEDDED", 
            [], 
            [
                {
                    category: "REFERENCE", 
                    name: "notificationInfo", 
                    argGraphQLTypeMap: {id: 'ID!'}, 
                    targetTypeName: "NotificationInfo", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "notificationInfos", 
                    argGraphQLTypeMap: {
                        sort: '[NotificationGraphDbContextNotificationInfosSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "NotificationInfoPage", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "notificationStatuses", 
                    argGraphQLTypeMap: {
                        sort: '[NotificationGraphDbContextNotificationStatusesSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "NotificationStatusPage", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "notificationUsers", 
                    argGraphQLTypeMap: {
                        sort: '[NotificationGraphDbContextNotificationUsersSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "NotificationUserPage", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "userExtend", 
                    argGraphQLTypeMap: {id: 'String!'}, 
                    targetTypeName: "UserExtend", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "userExtends", 
                    argGraphQLTypeMap: {
                        sort: '[NotificationGraphDbContextUserExtendsSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "UserExtendPage", 
                    undefinable: true
                }
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export interface NotificationGraphDbContextArgs {

    readonly notificationInfo: {
        readonly id: string
    }, 

    readonly notificationInfos: {
        readonly sort?: ReadonlyArray<NotificationGraphDbContextNotificationInfosSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }, 

    readonly notificationStatuses: {
        readonly sort?: ReadonlyArray<NotificationGraphDbContextNotificationStatusesSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }, 

    readonly notificationUsers: {
        readonly sort?: ReadonlyArray<NotificationGraphDbContextNotificationUsersSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }, 

    readonly userExtend: {
        readonly id: string
    }, 

    readonly userExtends: {
        readonly sort?: ReadonlyArray<NotificationGraphDbContextUserExtendsSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }
}

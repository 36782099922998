import { ISubmission } from '@shared/Types'
import { ELazyStatus } from '@lib/ReduxBase'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISubmitMediaStateSlice } from './types'

const initialState: ISubmitMediaStateSlice = {
  Status: ELazyStatus.Loaded,
  submitModel: { IsPeopleAppearingVerify: true }
}

const SubmitMediaSlice = createSlice({
  name: 'SubmitMediaSlice',
  initialState,
  reducers: {
    updateSubmitModel: (state, action: PayloadAction<Partial<ISubmission>>) => {
      const newSubmitModel = Object.assign({}, state.submitModel, action.payload)
      state.submitModel = newSubmitModel
    }
  }
})
export default SubmitMediaSlice

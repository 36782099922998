import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Box, BoxProps, Button, styled, Typography } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

interface IProps {
  to?: string
  btnBackTitle?: string
  title?: string
  rootProps?: BoxProps
}

export class TopBar extends Component<IProps> {
  onBackClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!this.props.to) {
      e.preventDefault()
      e.stopPropagation()
      window.history.back()
    }
  }
  render() {
    return (
      <Box {...this.props.rootProps} sx={{ mt: '4px', mb: '4px', backgroundColor: '#fff', padding: '24px 0 9px', ...this.props.rootProps?.sx }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <ButtonBack color='inherit' {...{ component: Link, to: this.props.to }} onClick={this.onBackClick} startIcon={<KeyboardBackspaceIcon />}>
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>{this.props.btnBackTitle ?? 'back'}</Typography>
          </ButtonBack>
          {this.props.title && <Typography sx={{ fontSize: '18px', fontWeight: 600, color: '#606060' }}>{this.props.title}</Typography>}
          <Box sx={{ width: '160px', flex: '0 0 auto' }} />
        </Box>
      </Box>
    )
  }
}
export default TopBar

const ButtonBack = styled(Button)({
  textTransform: 'none',
  textDecoration: 'none!important',
  color: '#818181',
  width: '160px',
  flex: '0 0 auto',
  '&:hover': { color: '#006bd6' }
})

import { ESubmissionStep } from '@shared/Types'
import { ISubmission } from '@shared/Types'

/**
 * Submisstion LocalStorage Data
 */
interface ISubmisstionLSD {
  item: ISubmission
  stepDones: ESubmissionStep[]
}

class LocalStorageSubmisstionBase {
  private key = 'lssd_01'

  setData = (item: ISubmission, stepDone: ESubmissionStep) => {
    const stepDones = new Set<ESubmissionStep>(this.getData()?.stepDones ?? [])
    stepDones.add(stepDone)
    const str = JSON.stringify({ item, stepDones: Array.from(stepDones) })
    localStorage.setItem(this.key, str)
  }

  getData = (): ISubmisstionLSD | undefined => {
    try {
      const str = localStorage.getItem(this.key) ?? ''
      return JSON.parse(str)
    } catch (error) {}
  }

  clear = () => {
    localStorage.removeItem(this.key)
  }
}
export const LocalStorageSubmisstion = new LocalStorageSubmisstionBase()
export default LocalStorageSubmisstion

import React, { Component } from 'react'
import { ETicketStatus } from '@shared/Types'
import { Box, SxProps, Theme, Typography, styled } from '@mui/material'

const StatusColors: { [key in ETicketStatus]: SxProps<Theme> } = {
  Open: {
    background: 'linear-gradient(to right,#00b93b,#5adc82)'
  },
  Closed: {
    background: 'linear-gradient(to right,#c2c2c2,#dedede)'
  }
}

interface IProps {
  status: ETicketStatus
}

export class StatusCell extends Component<IProps> {
  render() {
    const { status } = this.props
    return (
      <Wrapper>
        <CustomTypography sx={StatusColors[status]}>{status}</CustomTypography>
      </Wrapper>
    )
  }
}

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%'
})

const CustomTypography = styled(Typography)({
  borderRadius: '4px',
  fontSize: '0.8rem',
  padding: '4px 7px',
  fontWeight: '400',
  minWidth: '70px',
  textAlign: 'center',
  color: '#fff'
})

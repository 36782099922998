import React, { Component } from 'react'
import { authService } from 'partner-oidc-auth'
import { AuthStringAvatar } from '@lib/ApiAuthorization'
import { Avatar, Stack, Typography } from '@mui/material'

interface IProps {}

interface IState {
  displayName?: string
  avatar?: string
}

export default class AvatarBox extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {}
  }

  async populateState() {
    const user = await authService.getUser()
    this.setState({
      displayName: user?.nickname,
      avatar: user?.picture
    })
  }

  componentDidMount(): void {
    this.populateState()
  }

  render() {
    return (
      <Stack sx={{ alignItems: 'center', gap: '6px' }}>
        <Avatar src={this.state.avatar} {...AuthStringAvatar(this.state.displayName ?? 'User', { width: 110, height: 110 })} />
        <Typography variant='subtitle2' sx={{ fontWeight: '600', textAlign: 'center' }}>
          Welcome, <i>{this.state.displayName}</i>
        </Typography>
      </Stack>
    )
  }
}

import { FetchDelay } from '@lib/Helpers'
import { IModelFilter, IPagingModel } from '@lib/Table'
import { CreateHttpService } from './Getway'
import ServiceBase from './ServiceBase'

export class CRUDServiceBase<TModel, TKey> extends ServiceBase {
  constructor(baseUri: string) {
    super(CreateHttpService(`${process.env.REACT_APP_ADMIN_API || window.location.origin}${baseUri}`))
  }
  All = (Filters: Partial<IModelFilter<TModel>>, signal?: AbortSignal, options?: { delay?: number }) => {
    return FetchDelay(async () => {
      const data = await this.TryPushNotify(this.Post<IPagingModel<TModel>>, 'All', Filters, {
        headers: { 'Content-Type': 'application/json' },
        signal
      })
      return data
    }, options?.delay ?? 500)
  }
  Single = (Id: TKey, signal?: AbortSignal) => {
    return FetchDelay(async () => {
      const data = await this.TryGet<TModel>('Get', { params: { Id }, signal })
      return data
    }, 500)
  }
  Create = (model: Partial<TModel>, signal?: AbortSignal, options?: { delay?: number }) => {
    return FetchDelay(async () => {
      const data = await this.PushNotify(this.Post<TModel>, 'Create', model, { signal })
      return data
    }, options?.delay ?? 500)
  }
  Update = (Id: TKey, model: Partial<TModel>, signal?: AbortSignal) => {
    return FetchDelay(async () => {
      const data = await this.PushNotify(this.Put<TModel>, 'Update', model, { signal, params: { Id: Id } })
      return data
    }, 500)
  }
  Remove = (Id: TKey, signal?: AbortSignal) => {
    return FetchDelay(async () => {
      const data = await this.PushNotify(this.Delete<TModel>, 'Delete', { params: { Id: Id }, signal })
      return data
    }, 500)
  }
}

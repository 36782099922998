export const AddAudioDrawListener = (audio: HTMLMediaElement, canvasId: string) => {
  const canvas = document.getElementById(canvasId) as HTMLCanvasElement | null
  if (!canvas) return
  const canvasCtx = canvas.getContext('2d')
  if (!canvasCtx) return

  const audioCtx = new (window.AudioContext || (window as any).webkitAudioContext)()
  const analyser = audioCtx.createAnalyser()
  const source = audioCtx.createMediaElementSource(audio)

  source.connect(analyser)
  analyser.connect(audioCtx.destination)
  analyser.fftSize = 2048

  const bufferLength = analyser.frequencyBinCount
  const dataArray = new Uint8Array(bufferLength)

  canvas.width = canvas.clientWidth
  canvas.height = canvas.clientHeight

  function draw() {
    if (!canvasCtx || !canvas) return
    requestAnimationFrame(draw)
    canvasCtx.clearRect(0, 0, canvas.width, canvas.height)

    analyser.getByteTimeDomainData(dataArray)

    canvasCtx.fillStyle = 'rgba(0, 0, 0, 0.0)'
    canvasCtx.fillRect(0, 0, canvas.width, canvas.height)

    canvasCtx.lineWidth = 2
    canvasCtx.strokeStyle = 'rgb(255, 255, 255)'

    canvasCtx.beginPath()

    const sliceWidth = (canvas.width * 1.0) / bufferLength
    let x = 0

    for (let i = 0; i < bufferLength; i++) {
      const v = dataArray[i] / 128.0
      const y = (v * canvas.height) / 2

      if (i === 0) {
        canvasCtx.moveTo(x, y)
      } else {
        canvasCtx.lineTo(x, y)
      }

      x += sliceWidth
    }

    canvasCtx.lineTo(canvas.width, canvas.height / 2)
    canvasCtx.stroke()
  }

  audio.onplay = () => {
    if (audioCtx.state === 'suspended') {
      audioCtx.resume()
    }
    draw()
  }
}

import React, { Component } from 'react'
import { Divider, Box, Typography, Button, styled } from '@mui/material'
import UploadIcon from '@mui/icons-material/Upload'

interface IProps {
  counts: { audio: number; video: number }
}

export default class BottomBar extends Component<IProps> {
  render() {
    return (
      <Wrapper>
        <Divider />
        <Box className='content'>
          <Typography sx={{ flex: 1 }}>
            <span>Audios </span>
            <span style={{ color: '#F03A17' }}>{this.props.counts.audio}</span>
            <span> • Videos </span>
            <span style={{ color: '#00bdff' }}>{this.props.counts.video}</span>
          </Typography>
          <Button type='submit' variant='contained' color='primary' size='small' startIcon={<UploadIcon />}>
            Submit
          </Button>
        </Box>
      </Wrapper>
    )
  }
}

const Wrapper = styled(Box)({
  backgroundColor: '#fff',
  margin: '0 -12px',
  padding: '0 12px',
  '& > .content': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '48px'
  }
})

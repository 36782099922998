import { createAsyncThunk } from '@reduxjs/toolkit'
import { ICart } from '@shared/Types'
import { QueryParam } from '@lib/Helpers'
import { CartLocalStorage } from '@internal/shareds/LocalStorages'
import CheckOutService, { CheckOutMapping } from '@internal/services/CheckoutService'

interface IQParams {
  referenceId: string
}

const setQueryParams = (value: IQParams) => {
  QueryParam.Put(value)
}

const getQueryParams = (): Partial<IQParams> => {
  const all = QueryParam.GetAll() as Partial<IQParams>
  return all
}

interface ICartResult {
  carts: ICart[]
  referenceId: string
}

export const fetchCheckOutThunk = createAsyncThunk<ICartResult>('fetchCheckOutThunk', async (_, thunkAPI) => {
  // const state = thunkAPI.getState() as { CartSlice: ICartStateSlice }
  const localData = CartLocalStorage.getData()
  const qParams = getQueryParams()
  const res = await CheckOutService.createOrder(localData, qParams.referenceId, thunkAPI.signal)
  const carts = CheckOutMapping.carts(res.OrderDetails, localData)
  if (res.ReferenceId) setQueryParams({ referenceId: res.ReferenceId })
  return { carts, referenceId: res.ReferenceId }
})

import { EnumInputMetadataBuilder } from 'graphql-ts-client-api';

const builder = new EnumInputMetadataBuilder();

builder.add("SortDirectionEnum");

builder.add("AuthenticationGraphDbContextUsersSortInput", [
    {name: "lastName", typeName: "SortDirectionEnum"}, 
    {name: "firstName", typeName: "SortDirectionEnum"}, 
    {name: "displayName", typeName: "SortDirectionEnum"}, 
    {name: "avatar", typeName: "SortDirectionEnum"}, 
    {name: "dateCreated", typeName: "SortDirectionEnum"}, 
    {name: "birthday", typeName: "SortDirectionEnum"}, 
    {name: "address", typeName: "SortDirectionEnum"}, 
    {name: "isInActive", typeName: "SortDirectionEnum"}, 
    {name: "gender", typeName: "SortDirectionEnum"}, 
    {name: "passwordRamdom", typeName: "SortDirectionEnum"}, 
    {name: "id", typeName: "SortDirectionEnum"}, 
    {name: "userName", typeName: "SortDirectionEnum"}, 
    {name: "normalizedUserName", typeName: "SortDirectionEnum"}, 
    {name: "email", typeName: "SortDirectionEnum"}, 
    {name: "normalizedEmail", typeName: "SortDirectionEnum"}, 
    {name: "emailConfirmed", typeName: "SortDirectionEnum"}, 
    {name: "passwordHash", typeName: "SortDirectionEnum"}, 
    {name: "securityStamp", typeName: "SortDirectionEnum"}, 
    {name: "concurrencyStamp", typeName: "SortDirectionEnum"}, 
    {name: "phoneNumber", typeName: "SortDirectionEnum"}, 
    {name: "phoneNumberConfirmed", typeName: "SortDirectionEnum"}, 
    {name: "twoFactorEnabled", typeName: "SortDirectionEnum"}, 
    {name: "lockoutEnd", typeName: "SortDirectionEnum"}, 
    {name: "lockoutEnabled", typeName: "SortDirectionEnum"}, 
    {name: "accessFailedCount", typeName: "SortDirectionEnum"}
]);

builder.add("BillingStatus");

builder.add("BillingType");

builder.add("CategoryType");

builder.add("EAreaType");

builder.add("EMediaDelivery");

builder.add("EMediaDeliveryStatus");

builder.add("EMediaDeliveryType");

builder.add("ERepositoryType");

builder.add("ESendType");

builder.add("ESubmissionStatus");

builder.add("ETicketConversation");

builder.add("ETicketStatus");

builder.add("ETicketType");

builder.add("GalleryType");

builder.add("MediaStatus");

builder.add("MediaStoreGraphDbContextBillingDetailsSortInput", [
    {name: "id", typeName: "SortDirectionEnum"}, 
    {name: "billingId", typeName: "SortDirectionEnum"}, 
    {name: "resourceId", typeName: "SortDirectionEnum"}, 
    {name: "payout", typeName: "SortDirectionEnum"}, 
    {name: "resourceInfo", typeName: "SortDirectionEnum"}, 
    {name: "dateCreated", typeName: "SortDirectionEnum"}, 
    {name: "dateUpdated", typeName: "SortDirectionEnum"}
]);

builder.add("MediaStoreGraphDbContextBillingsSortInput", [
    {name: "id", typeName: "SortDirectionEnum"}, 
    {name: "userId", typeName: "SortDirectionEnum"}, 
    {name: "paymentInfoId", typeName: "SortDirectionEnum"}, 
    {name: "name", typeName: "SortDirectionEnum"}, 
    {name: "status", typeName: "SortDirectionEnum"}, 
    {name: "noDetail", typeName: "SortDirectionEnum"}, 
    {name: "payout", typeName: "SortDirectionEnum"}, 
    {name: "transactionId", typeName: "SortDirectionEnum"}, 
    {name: "description", typeName: "SortDirectionEnum"}, 
    {name: "type", typeName: "SortDirectionEnum"}, 
    {name: "datePaid", typeName: "SortDirectionEnum"}, 
    {name: "dateCreated", typeName: "SortDirectionEnum"}, 
    {name: "dateUpdated", typeName: "SortDirectionEnum"}
]);

builder.add("MediaStoreGraphDbContextCategoriesSortInput", [
    {name: "id", typeName: "SortDirectionEnum"}, 
    {name: "name", typeName: "SortDirectionEnum"}, 
    {name: "description", typeName: "SortDirectionEnum"}, 
    {name: "imageUri", typeName: "SortDirectionEnum"}, 
    {name: "type", typeName: "SortDirectionEnum"}, 
    {name: "dateCreated", typeName: "SortDirectionEnum"}, 
    {name: "dateUpdated", typeName: "SortDirectionEnum"}
]);

builder.add("MediaStoreGraphDbContextGalleriesSortInput", [
    {name: "id", typeName: "SortDirectionEnum"}, 
    {name: "userCreatedId", typeName: "SortDirectionEnum"}, 
    {name: "name", typeName: "SortDirectionEnum"}, 
    {name: "description", typeName: "SortDirectionEnum"}, 
    {name: "type", typeName: "SortDirectionEnum"}, 
    {name: "imageUri", typeName: "SortDirectionEnum"}, 
    {name: "key", typeName: "SortDirectionEnum"}, 
    {name: "dateCreated", typeName: "SortDirectionEnum"}, 
    {name: "dateUpdated", typeName: "SortDirectionEnum"}
]);

builder.add("MediaStoreGraphDbContextGalleryDetailsSortInput", [
    {name: "id", typeName: "SortDirectionEnum"}, 
    {name: "galleryId", typeName: "SortDirectionEnum"}, 
    {name: "resourceId", typeName: "SortDirectionEnum"}, 
    {name: "dateCreated", typeName: "SortDirectionEnum"}, 
    {name: "dateUpdated", typeName: "SortDirectionEnum"}
]);

builder.add("MediaStoreGraphDbContextMediaDeliveriesSortInput", [
    {name: "id", typeName: "SortDirectionEnum"}, 
    {name: "name", typeName: "SortDirectionEnum"}, 
    {name: "type", typeName: "SortDirectionEnum"}, 
    {name: "resourceId", typeName: "SortDirectionEnum"}, 
    {name: "creatorId", typeName: "SortDirectionEnum"}, 
    {name: "description", typeName: "SortDirectionEnum"}, 
    {name: "contentType", typeName: "SortDirectionEnum"}, 
    {name: "content", typeName: "SortDirectionEnum"}, 
    {name: "status", typeName: "SortDirectionEnum"}, 
    {name: "dateCreated", typeName: "SortDirectionEnum"}, 
    {name: "dateUpdated", typeName: "SortDirectionEnum"}
]);

builder.add("MediaStoreGraphDbContextMediasSortInput", [
    {name: "id", typeName: "SortDirectionEnum"}, 
    {name: "userCreatedId", typeName: "SortDirectionEnum"}, 
    {name: "name", typeName: "SortDirectionEnum"}, 
    {name: "description", typeName: "SortDirectionEnum"}, 
    {name: "duration", typeName: "SortDirectionEnum"}, 
    {name: "size", typeName: "SortDirectionEnum"}, 
    {name: "extension", typeName: "SortDirectionEnum"}, 
    {name: "resourceId", typeName: "SortDirectionEnum"}, 
    {name: "type", typeName: "SortDirectionEnum"}, 
    {name: "status", typeName: "SortDirectionEnum"}, 
    {name: "reason", typeName: "SortDirectionEnum"}, 
    {name: "deliveryId", typeName: "SortDirectionEnum"}, 
    {name: "dateCreated", typeName: "SortDirectionEnum"}, 
    {name: "dateUpdated", typeName: "SortDirectionEnum"}
]);

builder.add("MediaStoreGraphDbContextOrdersSortInput", [
    {name: "id", typeName: "SortDirectionEnum"}, 
    {name: "userId", typeName: "SortDirectionEnum"}, 
    {name: "name", typeName: "SortDirectionEnum"}, 
    {name: "description", typeName: "SortDirectionEnum"}, 
    {name: "referenceId", typeName: "SortDirectionEnum"}, 
    {name: "status", typeName: "SortDirectionEnum"}, 
    {name: "method", typeName: "SortDirectionEnum"}, 
    {name: "transactionId", typeName: "SortDirectionEnum"}, 
    {name: "transactionInfo", typeName: "SortDirectionEnum"}, 
    {name: "dateCreated", typeName: "SortDirectionEnum"}, 
    {name: "dateUpdated", typeName: "SortDirectionEnum"}
]);

builder.add("MediaStoreGraphDbContextSubmissionsSortInput", [
    {name: "id", typeName: "SortDirectionEnum"}, 
    {name: "title", typeName: "SortDirectionEnum"}, 
    {name: "kindlyDescribe", typeName: "SortDirectionEnum"}, 
    {name: "repositoryType", typeName: "SortDirectionEnum"}, 
    {name: "flatformRepository", typeName: "SortDirectionEnum"}, 
    {name: "repositoryUrl", typeName: "SortDirectionEnum"}, 
    {name: "firstName", typeName: "SortDirectionEnum"}, 
    {name: "lastName", typeName: "SortDirectionEnum"}, 
    {name: "email", typeName: "SortDirectionEnum"}, 
    {name: "phoneNumber", typeName: "SortDirectionEnum"}, 
    {name: "birthday", typeName: "SortDirectionEnum"}, 
    {name: "socialMedia", typeName: "SortDirectionEnum"}, 
    {name: "isPeopleAppearingVerify", typeName: "SortDirectionEnum"}, 
    {name: "peopleAppearingInfosVerify", typeName: "SortDirectionEnum"}, 
    {name: "personWhoFilmedVerify", typeName: "SortDirectionEnum"}, 
    {name: "isSubmitedSocialVerify", typeName: "SortDirectionEnum"}, 
    {name: "submitedSocialUrlsVerify", typeName: "SortDirectionEnum"}, 
    {name: "isAnyoneReachVerify", typeName: "SortDirectionEnum"}, 
    {name: "anyoneReachUrlsVerify", typeName: "SortDirectionEnum"}, 
    {name: "isLicensingAgreementVerify", typeName: "SortDirectionEnum"}, 
    {name: "licensingAgreementUrlsVerify", typeName: "SortDirectionEnum"}, 
    {name: "digitalSignature", typeName: "SortDirectionEnum"}, 
    {name: "resourceId", typeName: "SortDirectionEnum"}, 
    {name: "status", typeName: "SortDirectionEnum"}, 
    {name: "note", typeName: "SortDirectionEnum"}, 
    {name: "userId", typeName: "SortDirectionEnum"}, 
    {name: "dateCreated", typeName: "SortDirectionEnum"}, 
    {name: "dateUpdated", typeName: "SortDirectionEnum"}
]);

builder.add("MediaStoreGraphDbContextTicketsSortInput", [
    {name: "id", typeName: "SortDirectionEnum"}, 
    {name: "type", typeName: "SortDirectionEnum"}, 
    {name: "resourceId", typeName: "SortDirectionEnum"}, 
    {name: "subject", typeName: "SortDirectionEnum"}, 
    {name: "userCreatedId", typeName: "SortDirectionEnum"}, 
    {name: "status", typeName: "SortDirectionEnum"}, 
    {name: "dateCreated", typeName: "SortDirectionEnum"}, 
    {name: "dateUpdated", typeName: "SortDirectionEnum"}
]);

builder.add("MediaType");

builder.add("NotificationGraphDbContextNotificationInfosSortInput", [
    {name: "id", typeName: "SortDirectionEnum"}, 
    {name: "title", typeName: "SortDirectionEnum"}, 
    {name: "subTitle", typeName: "SortDirectionEnum"}, 
    {name: "content", typeName: "SortDirectionEnum"}, 
    {name: "dateExpired", typeName: "SortDirectionEnum"}, 
    {name: "sendType", typeName: "SortDirectionEnum"}, 
    {name: "areaType", typeName: "SortDirectionEnum"}, 
    {name: "dateCreated", typeName: "SortDirectionEnum"}, 
    {name: "dateUpdated", typeName: "SortDirectionEnum"}
]);

builder.add("NotificationGraphDbContextNotificationStatusesSortInput", [
    {name: "notificationId", typeName: "SortDirectionEnum"}, 
    {name: "userId", typeName: "SortDirectionEnum"}, 
    {name: "dateCreated", typeName: "SortDirectionEnum"}, 
    {name: "dateUpdated", typeName: "SortDirectionEnum"}
]);

builder.add("NotificationGraphDbContextNotificationUsersSortInput", [
    {name: "notificationId", typeName: "SortDirectionEnum"}, 
    {name: "userId", typeName: "SortDirectionEnum"}
]);

builder.add("NotificationGraphDbContextUserExtendsSortInput", [
    {name: "displayName", typeName: "SortDirectionEnum"}, 
    {name: "id", typeName: "SortDirectionEnum"}
]);

builder.add("OrderPayment");

builder.add("OrderStatus");

builder.add("PaymentType");

builder.add("PricePackageType");

export const ENUM_INPUT_METADATA = builder.build();

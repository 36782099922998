import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { EMediaDeliveryType } from '@shared/Types'
import { Box, Button, Chip, Fade, Tooltip, Typography } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

interface IProps {
  type?: EMediaDeliveryType
  backLink: string
  tooltipTitle?: string
}
export const TopBar: FC<IProps> = (props) => (
  <Box sx={{ display: 'flex', alignItems: 'center', height: '56px' }}>
    <Tooltip title={props.tooltipTitle || 'Go back'} arrow placement='right'>
      <Button
        size='small'
        color='inherit'
        sx={{ p: '4px 7px' }}
        startIcon={<ArrowBackIosNewIcon fontSize='small' sx={{ width: '0.9em', height: '0.9em' }} />}
        {...{ component: Link, to: props.backLink }}
      >
        <Typography variant='subtitle1' sx={{ textTransform: 'none', fontWeight: 700, flex: 1 }}>
          Delivery detail
        </Typography>
      </Button>
    </Tooltip>
    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignContent: 'center', padding: '0 12px' }}>
      <Fade in={!!props.type}>
        <Chip label={props.type} size='small' sx={{ fontStyle: 'italic', color: '#606060', fontSize: '11px', fontWeight: 600 }} />
      </Fade>
    </Box>
  </Box>
)

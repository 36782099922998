import 'react-quill/dist/quill.snow.css'
import React, { Component } from 'react'
import { INotification } from '@shared/Types'
import { Box, Button, styled, Paper, Typography, Divider, IconButton } from '@mui/material'
import TextEditor from '@lib/Component/TextEditor'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

interface IProps {
  data: INotification[]
  onRead: (value: INotification) => void
  onClose: () => void
}

interface IState {
  indexSelected: number
}

export default class NotifyPopupContent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { indexSelected: 0 }
  }

  render() {
    const data = this.getData()
    if (!data) return <></>
    return (
      <Wrapper sx={{ backgroundColor: '#fff' }}>
        <Box sx={{ padding: '8px 16px 2px', width: '100%' }}>
          <Box>
            <Title variant='subtitle1' sx={{ mt: '12px', width: '100%' }}>
              {data.Title}
            </Title>
          </Box>
          <Divider flexItem />
        </Box>
        <TextEditorWrapper>
          <TextEditor key={data.Id} name='' defautValue={data.Content} readOnly={true} />
        </TextEditorWrapper>
        <FlexCenter sx={{ justifyContent: 'space-between', padding: '6px 16px 12px', gap: '8px', width: '100%' }}>
          {this.renderControl()}
          <FlexCenter>
            <Button color='inherit' onClick={this.props.onClose} sx={{ textTransform: 'unset' }}>
              Remind me later
            </Button>
            <Button color='error' onClick={this.handleRead} sx={{ textTransform: 'unset', fontWeight: 600 }}>
              Read
            </Button>
          </FlexCenter>
        </FlexCenter>
      </Wrapper>
    )
  }

  renderControl = () => {
    if (this.props.data.length < 2) return <Box />
    return (
      <FlexCenter>
        <IconButton disabled={this.state.indexSelected <= 0} onClick={this.handleClickLeft}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Typography>
          {this.state.indexSelected + 1} / {this.props.data.length}
        </Typography>
        <IconButton disabled={this.state.indexSelected >= this.props.data.length - 1} onClick={this.handleClickRight}>
          <KeyboardArrowRightIcon />
        </IconButton>
      </FlexCenter>
    )
  }

  handleClickLeft = () => {
    const index = this.state.indexSelected - 1
    if (index < 0) return
    this.setState({ indexSelected: index })
  }

  handleClickRight = () => {
    const index = this.state.indexSelected + 1
    if (index >= this.props.data.length) return
    this.setState({ indexSelected: index })
  }

  handleRead = () => {
    const data = this.props.data[this.state.indexSelected]
    this.props.onRead(data)
    this.handleClickRight()
    if (this.state.indexSelected >= this.props.data.length - 1) this.props.onClose()
  }

  getData = () => {
    const { data } = this.props
    const { indexSelected } = this.state
    const item = data[indexSelected]
    if (!item && indexSelected !== 0) {
      this.setState({ indexSelected: 0 })
      return
    } else {
      return item
    }
  }
}

const Wrapper = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  width: 'calc(100vw - 48px)',
  margin: '0 auto',
  overflowX: 'hidden',
  overflowY: 'auto',
  minHeight: '200px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: { width: theme.breakpoints.values.md }
}))

const Title = styled(Typography)({
  color: '#222f44',
  fontWeight: 600,
  letterSpacing: '1.1px',
  wordSpacing: '1.5px',
  lineHeight: 1.5
})

const TextEditorWrapper = styled(Box)({
  width: '100%',
  minHeight: '350px',
  maxHeight: 'calc(100vh - 128px)',
  overflow: 'auto',
  margin: '0 4px',
  padding: '0 8px',
  '& .ql-toolbar.ql-snow': { display: 'none' },
  '& .ql-container.ql-snow': { border: 'none' },
  '& .ql-editor': {
    paddingLeft: 0,
    paddingRight: 0,
    height: 'unset'
  }
})

const FlexCenter = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px'
})

import { Typography } from '@mui/material'
import { CreateFormComfirm } from '@lib/Forms'
import { IMediaDelivery } from '@shared/Types'

export { default as FormCreate } from './form.create'

export { default as FormEdit } from './form.edit'

export { default as FormUrlTypePreview } from './FormUrlTypePreview'

// ========= ========= ========= Form Delete ========= ========= =========
export const FormDelete = CreateFormComfirm<IMediaDelivery>({
  title: 'Are yout sure delete?',
  content: (x) => <Typography variant='subtitle2'>{x?.Id}</Typography>
})

import { INotification } from '@shared/Types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import NotificationService, { NotificationMapping } from '@internal/services/NotificationService'
import { GetLocalStorageNotification, SetLocalStorageNotification } from './Services/LocalStorageNotification'

interface IParamThunk {}
interface IResponseThunk {
  notifications: INotification[]
}
export const fetchNotificationThunk = createAsyncThunk<IResponseThunk, IParamThunk | undefined>('fetchNotificationThunk', async (_, thunkAPI) => {
  // const state = thunkAPI.getState() as { NotificationSlice: INotificationStateSlice }
  const res = await NotificationService.All({}, thunkAPI.signal)

  const notificationIds = NotificationMapping.getReadedNotificationIds(res?.NotificationInfos?.items)
  if (notificationIds.length > 0) SetLocalStorageNotification(notificationIds)

  return {
    notifications: NotificationMapping.notifications(res?.NotificationInfos?.items, GetLocalStorageNotification())
  }
})

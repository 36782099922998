import { ITicketConversation } from '@shared/Types'
import { CRUDServiceBase } from '@lib/Http'

class TicketConversationServiceBase extends CRUDServiceBase<ITicketConversation, string> {
  constructor(ticketId: string) {
    super(`/api/v1/user/Ticket/${ticketId}/Conversation`)
  }
}
const CreateTicketConversationService = (ticketId: string) => new TicketConversationServiceBase(ticketId)
export default CreateTicketConversationService

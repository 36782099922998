import { FC, useEffect, useState } from 'react'
import { RouteKey } from '@internal/route'
import { CreateRoutePath } from '@lib/RouteBase'
import { Link, useLocation } from 'react-router-dom'
import { Box, BoxProps, Divider, Stack, Typography, styled } from '@mui/material'
import { useCurrentChecking } from './hooks'
import { MediaTypeTabsMapRedux } from '../../Views/Explore'

interface IProps {}

const HeaderNav: FC<IProps> = () => {
  const currentChecking = useCurrentChecking()
  return (
    <Stack direction='row' sx={{ display: { xs: 'none', md: 'flex' }, gap: { xs: '15px', md: '24px' } }}>
      <MediaTypeTabsMapRedux />
      <Divider orientation='vertical' sx={{ height: '28px' }} />
      <Item className='' {...{ component: Link, to: CreateRoutePath(RouteKey.FQAs), ...currentChecking(RouteKey.FQAs) }}>
        <Text>FQAs</Text>
      </Item>
      <PricingItem />
      <Item {...{ component: Link, to: CreateRoutePath(RouteKey.Submit), ...currentChecking(RouteKey.Submit) }}>
        <Text>Submit media</Text>
      </Item>
    </Stack>
  )
}
export default HeaderNav

const PricingItem: FC = () => {
  const location = useLocation()
  const currentChecking = useCurrentChecking()
  const [value, setValue] = useState<any>({})
  useEffect(() => {
    let obj: BoxProps = {}
    const targetElement = document.getElementById('PricingSection')
    if (!!targetElement) {
      obj.onClick = () => {
        targetElement.scrollIntoView({ behavior: 'smooth' })
      }
    } else {
      obj = { ...obj, component: Link, to: CreateRoutePath(RouteKey.Pricing), ...currentChecking(RouteKey.Pricing) } as any
    }
    setValue(obj)
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])
  return (
    <Item {...value}>
      <Text>Pricing</Text>
    </Item>
  )
}

const Text = styled(Typography)({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '18px',
  fontWeight: '500',
  whiteSpace: 'nowrap'
})

const Item = styled(Box)({
  display: 'flex',
  gap: '5px',
  color: '#606060',
  cursor: 'pointer',
  borderBottom: '1px solid transparent',
  transition: 'all linear 0.2s',
  textDecoration: 'unset',
  '&.active': {
    color: '#1976D2',
    borderBottom: '1px solid #1976D2!important'
  },
  '&:hover': {
    borderBottom: '1px solid #606060'
  }
})

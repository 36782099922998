import { Box } from '@mui/material'
import { CreateFormUI } from '@lib/Forms'
import { ISubmission } from '@shared/Types'
import { CopyToClipboard } from '@lib/Component'
import { CreateTextOnly, LinkRow } from '@lib/Forms/Inputs'
import { EQuestionKey, MapQuestionList } from './Questions'
import QuestionPreviewItem, { IQuestionItemPreview } from './question-preview.item'

const TextOnlyInstance = CreateTextOnly<ISubmission>()

const FormAboutMedia = CreateFormUI<ISubmission>({
  title: 'About your media',
  configs: [
    {
      name: 'RepositoryUrl',
      inputElement: (p) => {
        const value = !!p.data && !!p.name ? p.data[p.name]?.toString() ?? '' : ''
        return (
          <Box sx={{ ml: '-9px' }}>
            <LinkRow value={value} action={<CopyToClipboard value={value} />} />
          </Box>
        )
      },
      options: { sx: { alignItems: 'center' } }
    },
    { name: 'Title' },
    { name: 'KindlyDescribe' }
  ],
  options: {
    alwaysAction: false,
    inputElementDefault: ({ options, ...p }) => <TextOnlyInstance {...p} sx={{ mt: '5px' }} />
  }
})

const FormAboutYourself = CreateFormUI<ISubmission>({
  title: 'About yourself',
  configs: [
    { name: 'Email' },
    { name: 'FirstName' },
    { name: 'LastName' },
    { name: 'PhoneNumber' },
    { name: 'Birthday' } // valueFormatter: (value) => TableFormater.formatDate(value, 'DD/MM/YYYY')
  ],
  options: {
    alwaysAction: false,
    inputElementDefault: ({ options, ...p }) => <TextOnlyInstance {...p} sx={{ mt: '5px' }} />
  }
})

const FormCopyrightOwnership = CreateFormUI<ISubmission, IQuestionItemPreview>({
  title: 'Copyright ownership',
  configs: [
    {
      name: 'IsPeopleAppearingVerify',
      label: MapQuestionList[EQuestionKey.PeopleAppearing],
      options: { subAnswer: 'PeopleAppearingInfosVerify', prefixTitle: true }
    },
    {
      name: 'PersonWhoFilmedVerify',
      label: MapQuestionList[EQuestionKey.PersonWhoFilmed]
    },
    {
      name: 'IsSubmitedSocialVerify',
      label: MapQuestionList[EQuestionKey.SubmitedSocial],
      options: { subAnswer: 'SubmitedSocialUrlsVerify', pasteLinks: true }
    },
    {
      name: 'IsAnyoneReachVerify',
      label: MapQuestionList[EQuestionKey.AnyoneReach],
      options: { subAnswer: 'AnyoneReachUrlsVerify', pasteLinks: true }
    },
    {
      name: 'IsLicensingAgreementVerify',
      label: MapQuestionList[EQuestionKey.LicensingAgreement],
      options: { subAnswer: 'LicensingAgreementUrlsVerify', pasteLinks: true }
    }
  ],
  options: {
    alwaysAction: false,
    inputElementDefault: ({ options, ...p }) => <TextOnlyInstance {...p} />,
    itemElementDefault: QuestionPreviewItem
  }
})

// interface IProps {
//   data?: Partial<ISubmission>
// }
// class DigitalSignature extends Component<IProps> {
//   render() {
//     const imgBase64 = this.props.data?.DigitalSignature
//     return (
//       <Box sx={{ display: 'flex', padding: '12px 0', justifyContent: 'center' }}>
//         <Box sx={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px' }}>
//           {imgBase64 && <Box component='img' src={imgBase64} />}
//         </Box>
//       </Box>
//     )
//   }
// }

export const QuestionPreviews = {
  AboutMedia: FormAboutMedia,
  AboutYourself: FormAboutYourself,
  CopyrightOwnership: FormCopyrightOwnership
  // DigitalSignature: DigitalSignature
}

import { connect } from 'react-redux'
import { AppDispatch, RootState } from '@internal/redux'
import { removeAllCartThunk } from '../Cart'
import { IPayPalDispatchRedux, IPayPalStateRedux } from './redux.types'
import CheckOutSlice from './redux.slice'
import PaypalMethod from './ui/PaymentMethods/Paypal'

const mapStateToProps = (state: RootState): IPayPalStateRedux => ({
  Status: state.CheckOutSlice.Status,
  CheckOutSlice: state.CheckOutSlice
})

const mapDispatchToProps = (dispatch: AppDispatch): IPayPalDispatchRedux => ({
  removeAll: () => {
    dispatch(removeAllCartThunk())
  },
  changeStatus: (value) => {
    dispatch(CheckOutSlice.actions.changeStatus(value))
  }
})

export const PaypalMethodMapRedux = connect(mapStateToProps, mapDispatchToProps)(PaypalMethod)

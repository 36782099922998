import React, { Component } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, alpha, Box, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Text18 } from '@internal/shareds/Text'
import { EPaymentMethod } from '@shared/Types'

interface IBaseConfig {
  title?: string
  subTitle?: JSX.Element
  body: JSX.Element
}

type TPaymentMethodConfig = { [key in EPaymentMethod]?: IBaseConfig }

interface IProps {
  config?: TPaymentMethodConfig
  value?: EPaymentMethod
  onChange?: (value: EPaymentMethod) => void
}

export default class PaymentMethodAccordions extends Component<IProps> {
  render() {
    const keys = Object.keys(this.props.config ?? {}) as EPaymentMethod[]
    return (
      <Box sx={{ mt: '12px' }}>
        {keys.map((key) => {
          const item = this.props.config?.[key]
          return (
            <Accordion key={key} expanded={key === this.props.value} onChange={() => this.handleChange(EPaymentMethod.PayPal)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1bh-content' id='panel1bh-header'>
                <Text18 sx={{ width: '33%', flexShrink: 0 }}>{item?.title ?? key.toString()}</Text18>
                {item?.subTitle ?? ''}
              </AccordionSummary>
              <AccordionDetails>{item?.body ?? ''}</AccordionDetails>
            </Accordion>
          )
        })}
        {keys.length < 1 && (
          <Box sx={{ padding: '9px 12px', background: alpha('#0ED197', 0.1), borderRadius: '6px' }}>
            <Typography variant='caption' sx={{ fontWeight: 600, color: '#0ED197' }}>
              Payments are not currently supported, we will update them soon
            </Typography>
          </Box>
        )}
      </Box>
    )
  }

  getConfigs = () => {}

  handleChange = (value: EPaymentMethod) => {
    this.props.onChange && this.props.onChange(value)
  }
}

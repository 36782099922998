import React, { Component } from 'react'
import { Box, CircularProgress } from '@mui/material'
import './index.css'

interface ImageViewProps {
  content: string
}
interface VideoViewState {
  horizontal?: boolean
  width?: number
  height?: number
}
interface ISize {
  width: number
  height: number
}
export default class ImageView extends Component<ImageViewProps, VideoViewState> {
  constructor(props: ImageViewProps) {
    super(props)
    this.state = {}
  }
  calculateWidthDisplay = (dimension: ISize, content: ISize): ISize => {
    if (dimension.width > content.width && dimension.height <= content.height) {
      const ratioW = content.width / dimension.width
      return {
        width: content.width,
        height: ratioW * dimension.height
      }
    } else if (dimension.width < content.width && dimension.height >= content.height) {
      const ratioH = content.height / dimension.height
      return {
        width: ratioH * dimension.width,
        height: content.height
      }
    } else if (dimension.width > content.width && dimension.height > content.height) {
      const ratioH = content.height / dimension.height
      return this.calculateWidthDisplay(
        {
          width: ratioH * dimension.width,
          height: content.height
        },
        content
      )
    } else {
      return dimension
    }
  }
  onPrevent: React.MouseEventHandler<HTMLImageElement> = (e) => {
    e.stopPropagation()
  }

  getImageSize = (url: string) => {
    return new Promise<{ width: number; height: number }>((resolve) => {
      const img = document.createElement('img')
      img.src = url
      img.onload = () => {
        resolve({ width: img.naturalWidth, height: img.naturalHeight })
      }
    })
  }
  async componentDidMount() {
    const dimension = await this.getImageSize(this.props.content)
    const container = document.querySelector<HTMLDivElement>('#video-view-image')

    if (!container) return
    const temp = this.calculateWidthDisplay(dimension, {
      height: container.offsetHeight,
      width: container.offsetWidth
    })
    this.setState({
      horizontal: dimension.width > dimension.height,
      ...temp
    })

    window.addEventListener('resize', () => {
      const container = document.querySelector<HTMLDivElement>('#video-view-image')
      if (!container) return
      const temp = this.calculateWidthDisplay(dimension, {
        height: container.offsetHeight,
        width: container.offsetWidth
      })
      this.setState({ ...temp })
    })
  }
  async componentDidUpdate() {
    const dimension = await this.getImageSize(this.props.content)
    const container = document.querySelector<HTMLDivElement>('#video-view-image')

    if (!container) return
    const temp = this.calculateWidthDisplay(dimension, {
      height: container.offsetHeight,
      width: container.offsetWidth
    })
    this.setState({
      horizontal: dimension.width > dimension.height,
      ...temp
    })
  }
  render() {
    const dimension =
      this.state.horizontal === undefined
        ? {}
        : this.state.horizontal
          ? { maxWidth: '100%', height: 'auto' }
          : { width: '100%', maxHeight: '90%' }
    return (
      <div className='video-view-image' id='video-view-image'>
        <div style={{ maxWidth: '1000px', display: 'flex', alignItems: 'center', height: '100%' }}>
          {this.state.horizontal === undefined ? (
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </Box>
          ) : (
            <img
              width={this.state.width}
              height={this.state.height}
              // onMouseDown={this.onPrevent}
              style={dimension}
              src={this.props.content}
              alt=''
            />
          )}
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import { Box, styled } from '@mui/material'
import { IInfoItemCartConfigDTO } from '../type'
import { Text16 } from '@internal/shareds/Text'

interface IProps {
  data?: IInfoItemCartConfigDTO[]
}

export default class CardItemInfo extends Component<IProps> {
  render() {
    const list = this.props.data ?? []
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
        {list.map((e, i) => (
          <Wrap key={i}>
            <Text16>{e.key}:</Text16>
            <Text16 sx={{ fontWeight: 700 }}>{e.value}</Text16>
          </Wrap>
        ))}
        <Box width="10px" />
      </Box>
    )
  }
}

const Wrap = styled(Box)({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  marginRight: '18px',
})

import { ServiceBase } from '@lib/Http'
import { FileUploadType, ResumableUploadService } from './ResumableUploadService'

type TUploadRequestParams = {
  file: File
  FileType: FileUploadType
}

export interface IUploadResult {
  id: string
  name: string
  createdTime: string
  fileExtension: string
  size: string
}

type TUploadRequest = (params: TUploadRequestParams, progress: (value: number) => void, signal?: AbortSignal) => Promise<IUploadResult | undefined>

class UploadServiceBase extends ServiceBase {
  private urlGetUploadCacheUrl = '/api/v1/user/Upload/InitialUpload'
  uploadRequest: TUploadRequest = async (params, progress, signal) => {
    const accessToken = (await this.getToken()) || ''
    const uploadService = new ResumableUploadService({
      urlInitialSession: this.urlGetUploadCacheUrl,
      fields: ['id'],
      accessToken,
      query: { supportsAllDrives: true },
      file: params.file,
      parents: []
    })

    uploadService.addListener('OnNext', (status, pro) => {
      console.log((pro.byteSent * 100) / pro.total)
      progress((pro.byteSent * 100) / pro.total)
    })
    uploadService.addListener('OnEnd', (status, pro) => {
      console.log(100, pro)
    })

    try {
      const res = await uploadService.NewUpload({ FileType: params.FileType }, signal)
      return res ? (res as IUploadResult) : undefined
    } catch (error) {
      const err = error as Error
      try {
        const messageData = JSON.parse(err.message)
        const temp = Object.values(messageData)[0]
        console.log(temp)
      } catch {
        throw err
      }
      throw err
    }
  }

  _urlCheckExistUploadName = '/api/v1/user/Upload/CheckExistUploadName'
  CheckExistUploadName = (fileName: string) => {
    return this.Post<string[]>(this._urlCheckExistUploadName, null, { params: { fileName } })
  }
}

const UploadService = new UploadServiceBase()
export default UploadService

import React, { Component } from 'react'
import { Box, styled } from '@mui/material'
import ReactSignatureCanvas from 'react-signature-canvas'
import { getErrorMessage } from '../../helper'
import withSize, { ISizeProps } from './withSize'
import ButtonClear from './ButtonClear'
import { IFormInputBase } from '@lib/Forms/types'

const CreateSignatureCanvas = function <TModel>() {
  interface IProps extends IFormInputBase<TModel> {
    options?: any
  }
  class InputSignatureCanvas extends Component<IProps> {
    constructor(props: IProps) {
      super(props)
      this.RSCanvas = this.getRSCanvas()
    }

    RSCanvas: ReturnType<typeof this.getRSCanvas>
    refInput: HTMLInputElement | null = null
    refSignatureCanvas: ReactSignatureCanvas | null = null

    render() {
      const eMessage = getErrorMessage(this.props.messageErrors, this.props.name)
      const name = this.props.name
      const { RSCanvas } = this
      return (
        <Box>
          {name && <input ref={(ref) => (this.refInput = ref)} readOnly hidden name={name.toString()} />}
          <Wrap status={eMessage.error ? 'error' : 'nomal'}>
            <RSCanvas />
          </Wrap>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '9px' }}>
            <ButtonClear
              superSmalled
              onSubmit={() => {
                if (!!this.refInput) this.refInput.value = ''
                this.refSignatureCanvas?.clear()
              }}
            />
          </Box>
        </Box>
      )
    }

    getRSCanvas = () => {
      return withSize(({ wrapSize }: ISizeProps) => (
        <ReactSignatureCanvas
          ref={(ref) => (this.refSignatureCanvas = ref)}
          onEnd={() => {
            if (!this.refInput) return
            this.refInput.value = this.refSignatureCanvas?.toDataURL() ?? ''
            if (!this.props.name) return
            this.props.onBlur && this.props.onBlur(this.props.name)
          }}
          penColor='#000000'
          canvasProps={{
            width: wrapSize.width,
            height: wrapSize.height,
            className: 'sigCanvas'
          }}
        />
      ))
    }
  }
  return InputSignatureCanvas
}
export default CreateSignatureCanvas
export type SignatureCanvastType<TModel> = ReturnType<typeof CreateSignatureCanvas<TModel>>

const Wrap = styled(Box)<{ status: 'nomal' | 'error' }>(({ status }) => ({
  border: status === 'error' ? '1px solid #d81b60' : '1px solid #DADCE0',
  borderRadius: '4px'
}))

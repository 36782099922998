import React, { Component } from 'react'
import { ICart } from '@shared/Types'
import { Link } from 'react-router-dom'
import { EMediaType } from '@shared/Types'
import { RouteKey } from '@internal/route'
import { CreateRoutePath } from '@lib/RouteBase'
import { Text14, Text18 } from '@internal/shareds/Text'
import { Grid, Box, styled, alpha } from '@mui/material'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import { ConfirmButton } from '../Buttons'
import { mapCartItemContext } from './Context'
import { ICartItemContentProps } from './type'
import Helpers from '../../helpers'
import CardItemInfo from '../ItemInfo'
import PasteLinksInput from '../PasteLinksInput'

interface IProps extends ICartItemContentProps {}

export default class CartItemContent extends Component<IProps> {
  render() {
    const { data } = this.props
    const infoConfigs = Helpers.getCartItemInfos(this.props.data)
    const mediaDetailPath = CreateRoutePath(RouteKey.Detail, { query: { id: data.Id, type: data.Type } })
    return (
      <Grid container spacing={3} sx={{ pb: '24px' }}>
        <Grid item xs={2}>
          {this.renderImage(this.props.data)}
        </Grid>
        <Grid item xs={10}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '9px' }}>
            <CustomText18 {...{ component: Link, to: mediaDetailPath }}>{this.props.data.Name}</CustomText18>
            <ConfirmButton onSubmit={this.handleClickDelete} />
          </Box>
          <Text14>{this.props.data.Description}</Text14>
          <CardItemInfo data={infoConfigs} />
          {this.renderPasteInput()}
        </Grid>
      </Grid>
    )
  }

  renderImage = (value: ICart) => {
    const { data } = this.props
    const obj = { component: Link, to: CreateRoutePath(RouteKey.Detail, { query: { id: data.Id, type: data.Type } }) }
    switch (value.Type) {
      case EMediaType.Audio:
        return (
          <WraperImage {...obj}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <MusicNoteIcon fontSize='large' sx={{ color: '#1976D2' }} />
            </Box>
          </WraperImage>
        )
      case EMediaType.Video:
        return (
          <WraperImage {...obj}>
            <Box sx={{ backgroundImage: `url(${this.props.data.ImageUri})` }} />
          </WraperImage>
        )
      default:
        return <></>
    }
  }

  renderPasteInput = () => {
    return mapCartItemContext((context) => {
      const links = this.props.data.JsonContent?.Links
      const flinks = Helpers.linksToArray(context.modelState?.PasteLinks)
      const value = flinks.length > 0 ? flinks : links
      return <PasteLinksInput defaultValue={value} cartContext={context} />
    })
  }

  handleClickDelete = () => {
    this.props.onDelete && this.props.onDelete(this.props.data)
  }
}

const CustomText18 = styled(Text18)({
  flex: 1,
  fontWeight: 700,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  textDecoration: 'unset!important'
})

const WraperImage = styled(Box)({
  flex: '0 0 auto',
  width: '100%',
  paddingBottom: '100%',
  borderRadius: '4px',
  position: 'relative',
  display: 'block',
  '& > div': {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: alpha('#1976D2', 0.15),
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '4px',
    boxShadow: '0px 2px 14px 0px rgba(0, 0, 0, 0.15)'
  }
})

import React, { Component } from 'react'
import { Box, BoxProps, Chip, styled } from '@mui/material'
import { UISkeletonCategories } from './ui.skeleton'

interface IProps {
  data: string[]
  loading?: boolean
  selected?: string
  onClick?: (value?: string) => void
}

export default class UIRecommendedCategories extends Component<IProps> {
  skeleton = (<UISkeletonCategories />)
  render() {
    if (!!this.props.loading) return this.skeleton
    return (
      <ScrollWrap>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <Chip label='All' variant={!this.props.selected ? 'filled' : 'outlined'} onClick={() => this.handleClick()} />
          {this.props.data.map((e, i) => (
            <Chip key={i} label={e} variant={this.props.selected === e ? 'filled' : 'outlined'} onClick={() => this.handleClick(e)} />
          ))}
          <Box sx={{ width: '25px', flex: '0 0 auto' }} />
        </Box>
      </ScrollWrap>
    )
  }

  handleClick = (value?: string) => {
    this.props.onClick && this.props.onClick(value)
  }
}

const ScrollWrap = styled(({ children, ...p }: BoxProps) => (
  <Box {...p}>
    <Box>{children}</Box>
  </Box>
))({
  height: '42px',
  marginTop: '12px',
  width: '100%',
  position: 'relative',
  '& > div': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflowX: 'auto'
  },
  '& > div::-webkit-scrollbar': {
    width: '6px',
    height: '6px'
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    right: 0,
    top: 0,
    background: 'linear-gradient(to right, transparent, #fff)',
    display: 'block',
    height: '100%',
    width: '50px'
  }
})

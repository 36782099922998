import React, { Component, PropsWithChildren } from 'react'
import { IHocLazyWrapperProps, IErrorViewDefaultProps } from './Views'
import { ELazyStatus, IStateRedux, IDispatchRedux, IReturnDispatch } from './types'

interface IHocLazyBase<TModel extends Object> extends IStateRedux, IDispatchRedux<TModel> {}
export interface IOptionBase {
  ErrorScreen?: React.ComponentType<IErrorViewDefaultProps>
  LazySpinner: React.ComponentType<PropsWithChildren<IHocLazyWrapperProps>>
}

export interface IHocLazyOtherProps<TModel extends Object> {
  param?: TModel
}
export interface IHocLazyOptions<TModel extends Object> extends IHocLazyOtherProps<TModel>, Omit<IOptionBase, 'LazySpinner'> {
  LazySpinner?: React.ComponentType<PropsWithChildren<IHocLazyWrapperProps>>
}

export const EmptyComponent: React.FC<React.PropsWithChildren<any>> = ({ children }) => <>{children}</>
export const OptionHocLazyDefault: IOptionBase = {
  LazySpinner: EmptyComponent
}

const CreateHocLazy = function <TChildProp extends IHocLazyBase<TModel>, TModel extends Object>(
  ChildComponent: React.ComponentType<TChildProp>,
  options?: IHocLazyOptions<TModel>
) {
  type TProps = TChildProp & IHocLazyOtherProps<TModel>
  class HocComponent extends Component<TProps> {
    OptionsMerge: IOptionBase
    /**
     *
     */
    constructor(props: TProps) {
      super(props)
      this.OptionsMerge = Object.assign({}, OptionHocLazyDefault, options) as IOptionBase
    }
    tokenSources?: AbortController
    fetchSource?: IReturnDispatch
    render() {
      const { OptionsMerge } = this
      switch (this.props.Status) {
        case ELazyStatus.Loading:
        case ELazyStatus.Loaded:
          return (
            <OptionsMerge.LazySpinner loading={this.props.Status === ELazyStatus.Loading}>
              <ChildComponent {...this.props} />
            </OptionsMerge.LazySpinner>
          )
        case ELazyStatus.Error:
          return <div>Error...</div>
        default:
          return <div></div>
      }
    }
    initialParam = (): TModel => {
      const oData = options?.param ?? {}
      const pData = this.props?.param ?? {}
      return Object.assign({}, oData, pData) as TModel
    }
    componentDidMount = () => {
      if (this.props.FetchData) {
        const param = this.initialParam()
        this.tokenSources?.abort()
        this.tokenSources = new AbortController()
        this.fetchSource = this.props.FetchData(param, this.tokenSources.signal)
      }
    }
    componentWillUnmount() {
      this.tokenSources?.abort()
      this.fetchSource?.abort && this.fetchSource.abort()
    }
  }
  return HocComponent
}
export default CreateHocLazy

import { NoInfer } from 'react-redux'
import { createEntityAdapter, createSlice, PayloadAction, Update } from '@reduxjs/toolkit'
import { ActionReducerMapBuilder, EntityAdapter, EntityState, IdSelector } from '@reduxjs/toolkit'
import { ISliceBase, ELazyStatus } from './types'

const CreateCRUDSlice = <TModel, TSliceState extends ISliceBase<TModel> = ISliceBase<TModel>>(
  name: string,
  selectId?: IdSelector<TModel>,
  Initial: (state: ISliceBase<TModel>) => TSliceState = (s) => s as TSliceState,
  extraReducers?: (builder: ActionReducerMapBuilder<NoInfer<TSliceState>>, adapter: EntityAdapter<TModel>) => void
) => {
  const SliceAdapter = createEntityAdapter<TModel>({ selectId })
  // Define the initial state using that type
  const initialState: TSliceState = Initial({
    Status: ELazyStatus.Loading,
    Data: SliceAdapter.getInitialState()
  })

  const ModelSlice = createSlice({
    name,
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
      Update: (state, action: PayloadAction<Update<TModel>>) => {
        SliceAdapter.updateOne(state.Data as EntityState<TModel>, action.payload)
      },
      Add: (state, action: PayloadAction<TModel>) => {
        SliceAdapter.addOne(state.Data as EntityState<TModel>, action.payload)
      },
      Remove: (state, action: PayloadAction<string>) => {
        SliceAdapter.removeOne(state.Data as EntityState<TModel>, action.payload)
      },
      RemoveAll: (state) => {
        SliceAdapter.removeAll(state.Data as EntityState<TModel>)
      },
      UpsetMany: (state, action: PayloadAction<TModel[]>) => {
        SliceAdapter.removeAll(state.Data as EntityState<TModel>)
        SliceAdapter.upsertMany(state.Data as EntityState<TModel>, action.payload)
      }
    },
    extraReducers: (builder) => extraReducers && extraReducers(builder, SliceAdapter)
  })
  return ModelSlice
}
export default CreateCRUDSlice

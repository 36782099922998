import React, { Component } from 'react'
import { connect } from 'react-redux'
import { WindowScrollToTop } from '@lib/Helpers'
import { Box, Container, Grid } from '@mui/material'
import { CreateHocLazy, ELazyStatus } from '@lib/ReduxBase'
import { FormUIBusinessInfo, FormUIPaymentInfo, GetFormUIPersonalInfo, AvatarBox, EProfileFormType } from '@shared/Pages/Profile'
import { IProfileReduxProps } from './type'
import { BackLinkFQAs, BackLinkPricing } from './ui.other'
import { mapDispatchToProps, mapStateToProps } from './Redux/Map'

const FormUIPersonalInfo = GetFormUIPersonalInfo('Client')

interface IProps extends IProfileReduxProps {}
export default class ViewBase extends Component<IProps> {
  componentDidMount() {
    WindowScrollToTop()
  }
  render() {
    const data = this.props.ProfileSlice.profileContentData
    return (
      <Container maxWidth={false}>
        {this.renderTop()}
        <FormUIPersonalInfo
          disabled={this.props.Status === ELazyStatus.Loading}
          data={data[EProfileFormType.Personal]}
          onSubmit={async (value) => this.props.updateProfile({ key: EProfileFormType.Personal, value })}
        />
        <FormUIBusinessInfo
          disabled={this.props.Status === ELazyStatus.Loading}
          data={data[EProfileFormType.Business]}
          onSubmit={async (value) => this.props.updateProfile({ key: EProfileFormType.Business, value })}
        />
        <FormUIPaymentInfo
          disabled={this.props.Status === ELazyStatus.Loading}
          data={data[EProfileFormType.Payment]}
          onSubmit={async (value) => this.props.updateProfile({ key: EProfileFormType.Payment, value })}
        />
      </Container>
    )
  }

  renderTop = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ height: '5rem' }} />
          <AvatarBox />
        </Grid>
        <Grid item xs={12} md={6}>
          <BackLinkPricing />
        </Grid>
        <Grid item xs={12} md={6}>
          <BackLinkFQAs />
        </Grid>
      </Grid>
    )
  }
}

export * from './Redux/Thunks'
export { default as ProfileSlice } from './Redux/Slice'

const HocLazyInstance = CreateHocLazy(ViewBase)
export const ProfileMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)

import React, { Component, Fragment } from 'react'
import { CreateFormUI } from '@lib/Forms'
import { CreateTextOnly } from '@lib/Forms/Inputs'
import { IOrder, IOrderDetail } from '@shared/Types'
import { Box, Divider, Typography } from '@mui/material'
import { TableFormater, TryParseArray } from '@lib/Helpers'
import { FieldSocialUrl } from './FieldSocialUrl'

const TextOnlyInstance = CreateTextOnly<IOrder>()

const parseNameResourceInfo = (value?: string): string => {
  try {
    return JSON.parse(value ?? '{}')['Name'] ?? ''
  } catch (error) {
    return ''
  }
}

const CreateOrderInfo = (value?: Partial<IOrder>) => {
  return CreateFormUI<IOrder>({
    title: (
      <Typography variant='subtitle1' sx={{ fontWeight: 600, color: '#0094fe' }}>
        Order infomation
      </Typography>
    ),
    description: value?.Name ?? '',
    configs: [{ name: 'Method', label: 'Method' }],
    options: {
      alwaysAction: false,
      inputElementDefault: ({ options, ...p }) => <TextOnlyInstance {...p} sx={{ mt: '5px' }} />
    }
  })
}

const CreateOrderDetail = (item: IOrderDetail, index: number) => {
  const urls = TryParseArray(item.SocialUrl)
  const price = TableFormater.formatCurrency(item.Price * urls.length)
  const descriptions = [item.Type, price, TableFormater.formatDate(item.DateCreated)].filter((x) => !!x)
  return CreateFormUI<IOrderDetail>({
    title: (
      <Typography variant='subtitle2' sx={{ fontWeight: 600 }}>
        <span className='noselect'>{index + 1}. </span>
        {parseNameResourceInfo(item.ResourceInfo)}
      </Typography>
    ),
    description: (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '18px' }}>
        {descriptions.map((item, index) => (
          <Fragment key={item + index}>
            <Typography variant='caption' {...{ component: 'span' }}>
              {item}
            </Typography>
            {index < descriptions.length - 1 && <Divider orientation='vertical' flexItem />}
          </Fragment>
        ))}
      </Box>
    ),
    configs: [{ name: 'SocialUrl', label: 'Social URLs' }],
    options: {
      alwaysAction: false,
      itemElementDefault: FieldSocialUrl
    }
  })
}

interface IProps {
  data?: Partial<IOrder>
}

export default class OrderDetailContent extends Component<IProps> {
  render() {
    const list = this.props.data?.OrderDetails ?? []
    return (
      <Box sx={{ mt: '6px' }}>
        {this.renderBasicInfo(this.props.data)}
        {list.map(this.renderItem)}
      </Box>
    )
  }
  renderItem = (item: IOrderDetail, index: number) => {
    const OrderDetail = CreateOrderDetail(item, index)
    const name = parseNameResourceInfo(item.ResourceInfo)
    return <OrderDetail key={name + index} data={item} />
  }
  renderBasicInfo = (item?: Partial<IOrder>) => {
    const OrderInfo = CreateOrderInfo(item)
    return <OrderInfo key='OrderInfo' data={item} />
  }
}

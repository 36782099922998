import React, { Component, FC } from 'react'
import { RouteKey } from '@internal/route'
import { ELazyStatus } from '@lib/ReduxBase'
import { CreateRoutePath } from '@lib/RouteBase'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import CheckOutService from '@internal/services/CheckoutService'
import { IPayPalReduxProps } from '../../redux.types'

export interface IPaypalMethodProps extends IPayPalReduxProps {
  onBeforePayment?: () => void
}

interface IPaypalMethodBaseProps extends IPaypalMethodProps {
  navigate: NavigateFunction
}

class PaypalMethodBase extends Component<IPaypalMethodBaseProps> {
  Token = ''
  render() {
    return (
      <PayPalScriptProvider
        options={{
          clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID as any,
          disableFunding: 'paylater', // Disable PayPal Pay Later button
          environment: process.env.REACT_APP_PAYPAL as any
        }}
      >
        <PayPalButtons
          style={{ height: 42 }}
          disabled={this.props.Status === ELazyStatus.Loading}
          createOrder={this.handleCreateOrder}
          onApprove={async (data) => this.handleApprove(data.orderID)}
          fundingSource='paypal'
        />
      </PayPalScriptProvider>
    )
  }

  handleCreateOrder = async (): Promise<string> => {
    this.props.onBeforePayment && this.props.onBeforePayment()
    const referenceId = this.getReferenceId()
    if (!referenceId) return ''
    const res = await CheckOutService.payPalCreate(referenceId)
    this.Token = res?.Token ?? ''
    return res?.Id ?? ''
  }

  handleApprove = async (orderId: string) => {
    try {
      this.props.changeStatus(ELazyStatus.Loading)
      await CheckOutService.payPalCapture({ orderId, token: this.Token })
      this.props.removeAll()
      this.props.navigate(CreateRoutePath(RouteKey.CheckOutComplete), { replace: true })
    } catch (error) {
      // Xử lý khi có lỗi lúc Approve
      console.log(error)
    }
    this.props.changeStatus(ELazyStatus.Loaded)
  }

  getReferenceId = () => {
    return this.props.CheckOutSlice.referenceId
  }
}

const PaypalMethod: FC<IPaypalMethodProps> = (props) => {
  return <PaypalMethodBase {...props} navigate={useNavigate()} />
}

export default PaypalMethod

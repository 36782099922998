import React, { Component } from 'react'
import { IMedia, IPrice } from '@shared/Types'
import { EMediaType, EPriceType } from '@shared/Types'
import { MediaItemVideo } from '@internal/shareds/MediaItem'
import { ProductsSkeleton } from '@internal/shareds/Skeletons'
import { Box, styled, Container, Typography } from '@mui/material'
import { mapLinkExplore } from '../../helper'
import Carousel from './Carousel'

interface IProps {
  videoPopular?: Partial<IMedia>[]
  videoNew?: Partial<IMedia>[]
  prices: IPrice[]
  onAddToCart: (media: IMedia, price: IPrice) => void
}

export class VideoGalleries extends Component<IProps> {
  renderItems = (list: Partial<IMedia>[], name: string) => {
    if (!list || list.length < 1) return <ProductsSkeleton items={{ xs: 3, md: 4 }} />

    const prices = this.getPrices()
    return (
      <Box sx={{ marginTop: '6px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: '6px' }}>
          <SubTitle>{name}</SubTitle>
          <SMText {...mapLinkExplore({ mediaType: EMediaType.Video })}>See more</SMText>
        </Box>
        <Carousel
          data={list}
          renderItem={(e, i) => (
            <Box key={i.toString()} sx={{ padding: '7.5px' }}>
              <MediaItemVideo key={prices.length} prices={prices} data={e} onAddToCart={this.props.onAddToCart} />
            </Box>
          )}
        />
      </Box>
    )
  }

  render() {
    return (
      <Container sx={{ marginTop: '36px' }}>
        <Title>Video Colections</Title>
        <SubTitle sx={{ fontWeight: '400' }}>Download or stream - Get unlimited access to +40,000 media</SubTitle>
        <Box sx={{ marginTop: '24px' }}>
          {this.renderItems(this.props.videoPopular ?? [], 'Popular videos')}
          {this.renderItems(this.props.videoNew ?? [], 'New videos')}
        </Box>
      </Container>
    )
  }

  getPrices = () => {
    return this.props.prices.filter((x) => x.Type === EPriceType.Media)
  }
}

export default VideoGalleries

const Title = styled('h3')(({ theme }) => ({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '36px',
  fontWeight: 600,
  textAlign: 'center',
  color: '#606060',
  [theme.breakpoints.down('md')]: {
    fontSize: '30px'
  }
}))

const SubTitle = styled('h3')(({ theme }) => ({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '20px',
  fontWeight: 500,
  textAlign: 'center',
  color: '#606060',
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem'
  }
}))

const SMText = styled(Typography)({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 700,
  textTransform: 'none',
  color: '#606060',
  cursor: 'pointer',
  textDecoration: 'unset !important',
  transition: 'linear 0.2s',
  borderBottom: '1px solid #fff',
  '&:hover': {
    borderBottomColor: '#1976d2',
    color: '#1976d2'
  }
})

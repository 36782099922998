import { IBilling } from '@shared/Types'
import { CRUDServiceBase } from '@lib/Http'
import { ITableTemplateState } from '@lib/Table'
import { TableToRequestFilter } from '@lib/Helpers'
import { EBillingStatus, EBillingType } from '@shared/Types'
import Graphql from '@coreprj/graphql'

export type TBilling = Graphql.QMediaStore.TBilling
type TBillingFilterParams = Graphql.QMediaStore.TBillingFilterParams
type TBillingsFilterParams = Graphql.QMediaStore.TBillingsFilterParams

class BillingServiceBase extends CRUDServiceBase<IBilling, number> {
  constructor() {
    super(`/api/v1/user/Billing`)
  }

  SingleGraphQL = async (params: TBillingFilterParams, signal?: AbortSignal): Promise<TBilling> => {
    const res = await Graphql.MediaStore.Query(Graphql.QMediaStore.BillingQuery(params), { signal, delay: 500 })
    return res.mediaStore.billing as TBilling
  }

  AllGraphQL = async (params: TBillingsFilterParams, signal?: AbortSignal): Promise<Graphql.TGraphQlResult<TBilling> | undefined> => {
    const res = await Graphql.MediaStore.Query(Graphql.QMediaStore.BillingsUserQuery(params), { signal, delay: 500 })
    return res.mediaStore.billings as Graphql.TGraphQlResult<TBilling>
  }
}
const BillingService = new BillingServiceBase()
export default BillingService

class BillingMappingBase {
  tableInfoToFilter = (tableInfo: ITableTemplateState<IBilling>) => {
    return new TableToRequestFilter.Graphql<IBilling, TBilling>({
      fieldMapping: {
        Name: { field: 'name' },
        Description: { field: 'description' },
        Payout: { field: 'payout', type: 'double' },
        Type: { field: 'type' },
        TransactionId: { field: 'transactionId' },
        DatePaid: { field: 'datePaid' },
        Status: { field: 'status' },
        DateCreated: { field: 'dateCreated' },
        DateUpdated: { field: 'dateUpdated' }
      }
    })
      .fromTable(tableInfo, ['Name', 'Description', 'DisplayName'])
      .sort({ field: 'Id', sort: 'asc' })
      .build()
  }

  billing = (item?: TBilling): IBilling => ({
    Id: item?.id ?? '',
    UserId: item?.userId ?? '',
    PaymentInfoId: item?.paymentInfoId ?? '',
    Name: item?.name ?? '',
    Description: item?.description ?? '',
    Status: item?.status as EBillingStatus,
    NoDetail: item?.noDetail ?? false,
    Payout: item?.payout ?? 0,
    TransactionId: item?.transactionId ?? '',
    Type: item?.type as EBillingType,
    DatePaid: item?.datePaid ?? '',
    DisplayName: item?.user?.displayName ?? '',
    DateCreated: item?.dateCreated ?? '',
    DateUpdated: item?.dateUpdated ?? ''
  })

  billings = (items: TBilling[] = []): IBilling[] => items.map(this.billing)
}
export const BillingMapping = new BillingMappingBase()

import type { FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';
import type {ERepositoryType} from '../enums';
import type {ESubmissionStatus} from '../enums';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface SubmissionFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'Submission', T, TVariables> {

    on<XName extends ImplementationType<'Submission'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): SubmissionFetcher<
        XName extends 'Submission' ?
        T & X :
        WithTypeName<T, ImplementationType<'Submission'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'Submission'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): SubmissionFetcher<T, TVariables>;


    readonly __typename: SubmissionFetcher<T & {__typename: ImplementationType<'Submission'>}, TVariables>;


    readonly id: SubmissionFetcher<T & {readonly "id": string}, TVariables>;

    "id+"<
        XAlias extends string = "id", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"id", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): SubmissionFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~id": SubmissionFetcher<Omit<T, 'id'>, TVariables>;


    readonly title: SubmissionFetcher<T & {readonly "title"?: string}, TVariables>;

    "title+"<
        XAlias extends string = "title", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"title", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): SubmissionFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~title": SubmissionFetcher<Omit<T, 'title'>, TVariables>;


    readonly kindlyDescribe: SubmissionFetcher<T & {readonly "kindlyDescribe"?: string}, TVariables>;

    "kindlyDescribe+"<
        XAlias extends string = "kindlyDescribe", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"kindlyDescribe", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): SubmissionFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~kindlyDescribe": SubmissionFetcher<Omit<T, 'kindlyDescribe'>, TVariables>;


    readonly repositoryType: SubmissionFetcher<T & {readonly "repositoryType": ERepositoryType}, TVariables>;

    "repositoryType+"<
        XAlias extends string = "repositoryType", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"repositoryType", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): SubmissionFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: ERepositoryType} : 
                {readonly [key in XAlias]: ERepositoryType}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~repositoryType": SubmissionFetcher<Omit<T, 'repositoryType'>, TVariables>;


    readonly flatformRepository: SubmissionFetcher<T & {readonly "flatformRepository"?: string}, TVariables>;

    "flatformRepository+"<
        XAlias extends string = "flatformRepository", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"flatformRepository", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): SubmissionFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~flatformRepository": SubmissionFetcher<Omit<T, 'flatformRepository'>, TVariables>;


    readonly repositoryUrl: SubmissionFetcher<T & {readonly "repositoryUrl"?: string}, TVariables>;

    "repositoryUrl+"<
        XAlias extends string = "repositoryUrl", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"repositoryUrl", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): SubmissionFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~repositoryUrl": SubmissionFetcher<Omit<T, 'repositoryUrl'>, TVariables>;


    readonly firstName: SubmissionFetcher<T & {readonly "firstName"?: string}, TVariables>;

    "firstName+"<
        XAlias extends string = "firstName", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"firstName", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): SubmissionFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~firstName": SubmissionFetcher<Omit<T, 'firstName'>, TVariables>;


    readonly lastName: SubmissionFetcher<T & {readonly "lastName"?: string}, TVariables>;

    "lastName+"<
        XAlias extends string = "lastName", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"lastName", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): SubmissionFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~lastName": SubmissionFetcher<Omit<T, 'lastName'>, TVariables>;


    readonly email: SubmissionFetcher<T & {readonly "email"?: string}, TVariables>;

    "email+"<
        XAlias extends string = "email", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"email", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): SubmissionFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~email": SubmissionFetcher<Omit<T, 'email'>, TVariables>;


    readonly phoneNumber: SubmissionFetcher<T & {readonly "phoneNumber"?: string}, TVariables>;

    "phoneNumber+"<
        XAlias extends string = "phoneNumber", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"phoneNumber", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): SubmissionFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~phoneNumber": SubmissionFetcher<Omit<T, 'phoneNumber'>, TVariables>;


    readonly birthday: SubmissionFetcher<T & {readonly "birthday": string}, TVariables>;

    "birthday+"<
        XAlias extends string = "birthday", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"birthday", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): SubmissionFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~birthday": SubmissionFetcher<Omit<T, 'birthday'>, TVariables>;


    readonly socialMedia: SubmissionFetcher<T & {readonly "socialMedia"?: string}, TVariables>;

    "socialMedia+"<
        XAlias extends string = "socialMedia", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"socialMedia", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): SubmissionFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~socialMedia": SubmissionFetcher<Omit<T, 'socialMedia'>, TVariables>;


    readonly isPeopleAppearingVerify: SubmissionFetcher<T & {readonly "isPeopleAppearingVerify": boolean}, TVariables>;

    "isPeopleAppearingVerify+"<
        XAlias extends string = "isPeopleAppearingVerify", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"isPeopleAppearingVerify", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): SubmissionFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: boolean} : 
                {readonly [key in XAlias]: boolean}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~isPeopleAppearingVerify": SubmissionFetcher<Omit<T, 'isPeopleAppearingVerify'>, TVariables>;


    readonly peopleAppearingInfosVerify: SubmissionFetcher<T & {readonly "peopleAppearingInfosVerify"?: string}, TVariables>;

    "peopleAppearingInfosVerify+"<
        XAlias extends string = "peopleAppearingInfosVerify", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"peopleAppearingInfosVerify", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): SubmissionFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~peopleAppearingInfosVerify": SubmissionFetcher<Omit<T, 'peopleAppearingInfosVerify'>, TVariables>;


    readonly personWhoFilmedVerify: SubmissionFetcher<T & {readonly "personWhoFilmedVerify"?: string}, TVariables>;

    "personWhoFilmedVerify+"<
        XAlias extends string = "personWhoFilmedVerify", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"personWhoFilmedVerify", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): SubmissionFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~personWhoFilmedVerify": SubmissionFetcher<Omit<T, 'personWhoFilmedVerify'>, TVariables>;


    readonly isSubmitedSocialVerify: SubmissionFetcher<T & {readonly "isSubmitedSocialVerify": boolean}, TVariables>;

    "isSubmitedSocialVerify+"<
        XAlias extends string = "isSubmitedSocialVerify", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"isSubmitedSocialVerify", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): SubmissionFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: boolean} : 
                {readonly [key in XAlias]: boolean}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~isSubmitedSocialVerify": SubmissionFetcher<Omit<T, 'isSubmitedSocialVerify'>, TVariables>;


    readonly submitedSocialUrlsVerify: SubmissionFetcher<T & {readonly "submitedSocialUrlsVerify"?: string}, TVariables>;

    "submitedSocialUrlsVerify+"<
        XAlias extends string = "submitedSocialUrlsVerify", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"submitedSocialUrlsVerify", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): SubmissionFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~submitedSocialUrlsVerify": SubmissionFetcher<Omit<T, 'submitedSocialUrlsVerify'>, TVariables>;


    readonly isAnyoneReachVerify: SubmissionFetcher<T & {readonly "isAnyoneReachVerify": boolean}, TVariables>;

    "isAnyoneReachVerify+"<
        XAlias extends string = "isAnyoneReachVerify", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"isAnyoneReachVerify", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): SubmissionFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: boolean} : 
                {readonly [key in XAlias]: boolean}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~isAnyoneReachVerify": SubmissionFetcher<Omit<T, 'isAnyoneReachVerify'>, TVariables>;


    readonly anyoneReachUrlsVerify: SubmissionFetcher<T & {readonly "anyoneReachUrlsVerify"?: string}, TVariables>;

    "anyoneReachUrlsVerify+"<
        XAlias extends string = "anyoneReachUrlsVerify", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"anyoneReachUrlsVerify", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): SubmissionFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~anyoneReachUrlsVerify": SubmissionFetcher<Omit<T, 'anyoneReachUrlsVerify'>, TVariables>;


    readonly isLicensingAgreementVerify: SubmissionFetcher<T & {readonly "isLicensingAgreementVerify": boolean}, TVariables>;

    "isLicensingAgreementVerify+"<
        XAlias extends string = "isLicensingAgreementVerify", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"isLicensingAgreementVerify", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): SubmissionFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: boolean} : 
                {readonly [key in XAlias]: boolean}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~isLicensingAgreementVerify": SubmissionFetcher<Omit<T, 'isLicensingAgreementVerify'>, TVariables>;


    readonly licensingAgreementUrlsVerify: SubmissionFetcher<T & {readonly "licensingAgreementUrlsVerify"?: string}, TVariables>;

    "licensingAgreementUrlsVerify+"<
        XAlias extends string = "licensingAgreementUrlsVerify", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"licensingAgreementUrlsVerify", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): SubmissionFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~licensingAgreementUrlsVerify": SubmissionFetcher<Omit<T, 'licensingAgreementUrlsVerify'>, TVariables>;


    readonly digitalSignature: SubmissionFetcher<T & {readonly "digitalSignature"?: string}, TVariables>;

    "digitalSignature+"<
        XAlias extends string = "digitalSignature", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"digitalSignature", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): SubmissionFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~digitalSignature": SubmissionFetcher<Omit<T, 'digitalSignature'>, TVariables>;


    readonly resourceId: SubmissionFetcher<T & {readonly "resourceId"?: string}, TVariables>;

    "resourceId+"<
        XAlias extends string = "resourceId", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"resourceId", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): SubmissionFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~resourceId": SubmissionFetcher<Omit<T, 'resourceId'>, TVariables>;


    readonly status: SubmissionFetcher<T & {readonly "status": ESubmissionStatus}, TVariables>;

    "status+"<
        XAlias extends string = "status", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"status", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): SubmissionFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: ESubmissionStatus} : 
                {readonly [key in XAlias]: ESubmissionStatus}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~status": SubmissionFetcher<Omit<T, 'status'>, TVariables>;


    readonly note: SubmissionFetcher<T & {readonly "note"?: string}, TVariables>;

    "note+"<
        XAlias extends string = "note", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"note", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): SubmissionFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~note": SubmissionFetcher<Omit<T, 'note'>, TVariables>;


    readonly userId: SubmissionFetcher<T & {readonly "userId"?: string}, TVariables>;

    "userId+"<
        XAlias extends string = "userId", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"userId", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): SubmissionFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~userId": SubmissionFetcher<Omit<T, 'userId'>, TVariables>;


    readonly dateCreated: SubmissionFetcher<T & {readonly "dateCreated": string}, TVariables>;

    "dateCreated+"<
        XAlias extends string = "dateCreated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateCreated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): SubmissionFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateCreated": SubmissionFetcher<Omit<T, 'dateCreated'>, TVariables>;


    readonly dateUpdated: SubmissionFetcher<T & {readonly "dateUpdated": string}, TVariables>;

    "dateUpdated+"<
        XAlias extends string = "dateUpdated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateUpdated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): SubmissionFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateUpdated": SubmissionFetcher<Omit<T, 'dateUpdated'>, TVariables>;


    user<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'ApplicationUser', X, XVariables>
    ): SubmissionFetcher<
        T & {readonly "user"?: X}, 
        TVariables & XVariables
    >;

    user<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "user", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'ApplicationUser', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"user", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): SubmissionFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    medias<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Media', X, XVariables>
    ): SubmissionFetcher<
        T & {readonly "medias"?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables
    >;

    medias<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "medias", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Media', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"medias", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): SubmissionFetcher<
        T & {readonly [key in XAlias]?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables & XDirectiveVariables
    >;
}

export const submission$: SubmissionFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "Submission", 
            "OBJECT", 
            [], 
            [
                {
                    category: "ID", 
                    name: "id"
                }, 
                {
                    category: "SCALAR", 
                    name: "title", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "kindlyDescribe", 
                    undefinable: true
                }, 
                "repositoryType", 
                {
                    category: "SCALAR", 
                    name: "flatformRepository", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "repositoryUrl", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "firstName", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "lastName", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "email", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "phoneNumber", 
                    undefinable: true
                }, 
                "birthday", 
                {
                    category: "SCALAR", 
                    name: "socialMedia", 
                    undefinable: true
                }, 
                "isPeopleAppearingVerify", 
                {
                    category: "SCALAR", 
                    name: "peopleAppearingInfosVerify", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "personWhoFilmedVerify", 
                    undefinable: true
                }, 
                "isSubmitedSocialVerify", 
                {
                    category: "SCALAR", 
                    name: "submitedSocialUrlsVerify", 
                    undefinable: true
                }, 
                "isAnyoneReachVerify", 
                {
                    category: "SCALAR", 
                    name: "anyoneReachUrlsVerify", 
                    undefinable: true
                }, 
                "isLicensingAgreementVerify", 
                {
                    category: "SCALAR", 
                    name: "licensingAgreementUrlsVerify", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "digitalSignature", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "resourceId", 
                    undefinable: true
                }, 
                "status", 
                {
                    category: "SCALAR", 
                    name: "note", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "userId", 
                    undefinable: true
                }, 
                "dateCreated", 
                "dateUpdated", 
                {
                    category: "REFERENCE", 
                    name: "user", 
                    targetTypeName: "ApplicationUser", 
                    undefinable: true
                }, 
                {
                    category: "LIST", 
                    name: "medias", 
                    targetTypeName: "Media", 
                    undefinable: true
                }
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const submission$$ = 
    submission$
        .id
        .title
        .kindlyDescribe
        .repositoryType
        .flatformRepository
        .repositoryUrl
        .firstName
        .lastName
        .email
        .phoneNumber
        .birthday
        .socialMedia
        .isPeopleAppearingVerify
        .peopleAppearingInfosVerify
        .personWhoFilmedVerify
        .isSubmitedSocialVerify
        .submitedSocialUrlsVerify
        .isAnyoneReachVerify
        .anyoneReachUrlsVerify
        .isLicensingAgreementVerify
        .licensingAgreementUrlsVerify
        .digitalSignature
        .resourceId
        .status
        .note
        .userId
        .dateCreated
        .dateUpdated
;

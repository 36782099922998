import React, { Component } from 'react'
import { Stack, Box, Typography, styled, Grid, Collapse } from '@mui/material'

interface IProps {
  title: string
  isExistFiles: boolean
  components: {
    closeButton: JSX.Element
    body: JSX.Element
    action: JSX.Element
    topOtherView?: JSX.Element
  }
}

export default class Layout extends Component<IProps> {
  render() {
    const { title, isExistFiles, components } = this.props
    return (
      <Wrapper>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <TitleBox>
              <Box className='content'>
                <Typography variant='subtitle1' sx={{ lineHeight: 1, fontWeight: 600, color: '#202020', mt: '4px' }}>
                  {title}
                </Typography>
                <Box flex={1} />
                {components.closeButton}
              </Box>
              <Box sx={{ p: '6px 12px 0' }}>{this.props.components.topOtherView}</Box>
            </TitleBox>
          </Grid>
          <Grid item xs={12}>
            <ScrollWrap>{components.body}</ScrollWrap>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={isExistFiles} unmountOnExit>
              <Box>{components.action}</Box>
            </Collapse>
          </Grid>
        </Grid>
      </Wrapper>
    )
  }
}

const Wrapper = styled(Stack)(({ theme }) => ({
  boxSizing: 'border-box',
  backgroundColor: '#fff',
  borderRadius: '8px',
  overflow: 'hidden',
  padding: '0 12px 6px',
  width: theme.breakpoints.values.sm,
  [theme.breakpoints.up('md')]: { width: theme.breakpoints.values.md }
}))

const ScrollWrap = styled(Box)({
  minHeight: '40vh',
  maxHeight: 'calc(100vh - 225px)',
  overflowY: 'auto',
  padding: '12px 12px 0 6px',
  marginRight: '-6px',
  overflowX: 'hidden',
  display: 'flex'
})

const TitleBox = styled(Box)({
  position: 'sticky',
  top: 0,
  zIndex: 10,
  margin: '-6px -12px 0',
  backgroundColor: '#fff',
  '& > .content': {
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
    padding: '6px 12px',
    gap: '24px',
    boxShadow: 'rgba(145, 158, 171, 0.1) 0px 0px 2px 0px,rgba(145, 158, 171, 0.12) 0px 0px 24px -4px'
  }
})

import { Box, SxProps, Theme } from '@mui/material'
import { FC, ReactNode, useEffect, useRef } from 'react'

interface IProps {
  sx?: SxProps<Theme>
  children?: ReactNode
  ratio?: number
  revert?: boolean
}

export const HeightEqualWidth: FC<IProps> = (props) => {
  const boxRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const setHeightToWidth = () => {
      if (boxRef.current) {
        const { ratio = 1 } = props
        const width = boxRef.current.offsetWidth * ratio
        boxRef.current.style.height = `${width}px`
      }
    }
    const timer = setTimeout(() => {
      setHeightToWidth()
    }, 50)
    window.addEventListener('resize', setHeightToWidth)
    return () => {
      clearTimeout(timer)
      window.removeEventListener('resize', setHeightToWidth)
    }
  }, [props])

  return (
    <Box {...props} ref={boxRef} sx={{ width: '100%', display: 'flex', alignItems: 'stretch', ...props.sx }}>
      {props.children}
    </Box>
  )
}

export default HeightEqualWidth

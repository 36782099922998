import { ModelType } from 'graphql-ts-client-api'
import { query$, mediaStoreGraphDbContext$ } from '../../__generated/media-store/fetchers'

import { applicationUser$ } from '../../__generated/media-store/fetchers'
import { ticketConversation$$ } from '../../__generated/media-store/fetchers'
import { ticket$$, ticketPage$ } from '../../__generated/media-store/fetchers'

const TicketSelectorFull = ticket$$.user(applicationUser$.displayName.email).ticketConversations(ticketConversation$$)
export type TTicket = ModelType<typeof TicketSelectorFull>

const TicketSelector = ticket$$.user(applicationUser$.displayName).ticketConversations(ticketConversation$$)
export type TTicketConversation = ModelType<typeof ticketConversation$$>

const TicketsSelector = ticket$$.user(applicationUser$.displayName)
export type TTicketsFilterParams = Parameters<typeof mediaStoreGraphDbContext$.tickets>[0]
export const TicketsQuery = (params: TTicketsFilterParams) => {
  const TicketPage = ticketPage$.items(TicketsSelector).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(mediaStoreGraphDbContext$.tickets(params, TicketPage))
}

export type TTicketFilterParams = Parameters<typeof mediaStoreGraphDbContext$.ticket>[0]
export const TicketQuery = (params: TTicketFilterParams) => {
  return query$.mediaStore(mediaStoreGraphDbContext$.ticket(params, TicketSelector))
}

const UserTicketSelector = ticket$$.ticketConversations(ticketConversation$$)
export const UserTicketQuery = (params: TTicketFilterParams) => {
  return query$.mediaStore(mediaStoreGraphDbContext$.ticket(params, UserTicketSelector))
}

import React, { Component } from 'react'

interface IProps {
  isLoading?: boolean
  components: {
    content: JSX.Element
  }
}

export default class Layout extends Component<IProps> {
  render() {
    return <>{this.props.components.content}</>
  }
}

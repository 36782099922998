import React, { Component } from 'react'
import { Typography } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import InputFiles from './InputFiles'

interface IProps {
  accept: string
  onChange: (files: FileList) => void
}

export default class ContentControl extends Component<IProps> {
  render() {
    const { accept, onChange } = this.props
    return (
      <InputFiles accept={accept} sx={{ flex: 1, mx: '-4px', marginBottom: '8px' }} onChange={onChange}>
        <CloudUploadIcon sx={{ fontSize: '64px' }} />
        <Typography variant='subtitle1' sx={{ fontWeight: 700 }}>
          Click or drag to upload file
        </Typography>
        <Typography variant='body2' sx={{ fontWeight: 400, textAlign: 'center', maxWidth: '80%' }}>
          Upload your audio and media
        </Typography>
      </InputFiles>
    )
  }
}

import { ModelType } from 'graphql-ts-client-api'
import { query$, mediaStoreGraphDbContext$ } from '../../__generated/media-store/fetchers'

import { orderDetail$$ } from '../../__generated/media-store/fetchers'
import { applicationUser$ } from '../../__generated/media-store/fetchers'
import { order$$, orderPage$ } from '../../__generated/media-store/fetchers'
import { pricePackage$$, cartResourceInfo$$ } from '../../__generated/media-store/fetchers'

const OrderDetailSelector = orderDetail$$.pricePackage(pricePackage$$).cartResourceInfo(cartResourceInfo$$)
const OrderSelector = order$$.user(applicationUser$.displayName).orderDetails(OrderDetailSelector)
export type TOrderDetail = ModelType<typeof OrderDetailSelector>

const OrdersSelector = order$$.user(applicationUser$.displayName)
export type TOrdersFilterParams = Parameters<typeof mediaStoreGraphDbContext$.orders>[0]
export const OrdersQuery = (params: TOrdersFilterParams) => {
  const OrderPage = orderPage$.items(OrdersSelector).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(mediaStoreGraphDbContext$.orders(params, OrderPage))
}

export type TOrderFilterParams = Parameters<typeof mediaStoreGraphDbContext$.order>[0]
export const OrderQuery = (params: TOrderFilterParams) => {
  return query$.mediaStore(mediaStoreGraphDbContext$.order(params, OrderSelector))
}

const OrderSelectorFull = order$$.user(applicationUser$.displayName.email).orderDetails(OrderDetailSelector)
export type TOrder = ModelType<typeof OrderSelectorFull>

export const UserOrdersQuery = (params: TOrdersFilterParams) => {
  const OrderPage = orderPage$.items(order$$).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(mediaStoreGraphDbContext$.orders(params, OrderPage))
}

const UserOrderSelector = order$$.orderDetails(OrderDetailSelector)
export const UserOrderQuery = (params: TOrderFilterParams) => {
  return query$.mediaStore(mediaStoreGraphDbContext$.order(params, UserOrderSelector))
}

import { FormatterUSD, FormatterVN } from '@lib/Helpers'
import { Box, Typography } from '@mui/material'
import React, { Component } from 'react'

interface IProps {
  Usd: number
  UsdRate: number
}

export default class TotalMoneyCell extends Component<IProps> {
  render() {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Typography variant='subtitle2'>{FormatterUSD(this.props.Usd)}</Typography>
        <Typography variant='subtitle2'>{FormatterVN(this.props.Usd * this.props.UsdRate)}</Typography>
      </Box>
    )
  }
}

import { ModelType } from 'graphql-ts-client-api'
import { query$, mediaStoreGraphDbContext$ } from '../../__generated/media-store/fetchers'

import { applicationUser$ } from '../../__generated/media-store/fetchers'
import { submission$$, submissionPage$ } from '../../__generated/media-store/fetchers'

const SubmissionSelectorFull = submission$$.user(applicationUser$.displayName.email)
export type TSubmission = ModelType<typeof SubmissionSelectorFull>

const SubmissionSelector = submission$$.user(applicationUser$.displayName)

export type TSubmissionsFilterParams = Parameters<typeof mediaStoreGraphDbContext$.submissions>[0]
export const SubmissionsQuery = (params: TSubmissionsFilterParams) => {
  const SubmissionPage = submissionPage$.items(SubmissionSelector).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(mediaStoreGraphDbContext$.submissions(params, SubmissionPage))
}

export type TSubmissionFilterParams = Parameters<typeof mediaStoreGraphDbContext$.ticket>[0]
export const SubmissionQuery = (params: TSubmissionFilterParams) => {
  return query$.mediaStore(mediaStoreGraphDbContext$.submission(params, SubmissionSelector))
}

import { ISubmission } from '@shared/Types'
import { CRUDServiceBase } from '@lib/Http'
import { ITableTemplateState } from '@lib/Table'
import { TableToRequestFilter } from '@lib/Helpers'

class SubmissionServiceBase extends CRUDServiceBase<ISubmission, number> {
  constructor() {
    super(`/api/v1/user/Submission`)
  }
}
const SubmissionService = new SubmissionServiceBase()
export default SubmissionService

class SubmissionMappingBase {
  tableInfoToFilter = (tableInfo: ITableTemplateState<ISubmission>) => {
    return new TableToRequestFilter.Swagger<ISubmission>({
      acceptedFileds: ['Title', 'RepositoryUrl', 'KindlyDescribe', 'Email', 'FirstName', 'LastName', 'PhoneNumber', 'DateCreated', 'DateUpdated']
    })
      .fromTable(tableInfo, ['Title', 'DisplayName', 'RepositoryUrl', 'Email', 'FirstName', 'LastName', 'PhoneNumber'])
      .sort({ field: 'Id', sort: 'asc' })
      .build()
  }
}
export const SubmissionMapping = new SubmissionMappingBase()

import { TableReduxType } from '@lib/Table'
import { EntityState } from '@reduxjs/toolkit'

export enum ELazyStatus {
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error'
}

export interface ISliceBase<TModel> {
  Status: ELazyStatus
  Data?: EntityState<TModel>
  requestedId?: string
}
export interface ITableReduxSliceBase<TModel> extends TableReduxType.Slice<TModel> {
  Status: ELazyStatus
  requestedId?: string
}
export interface IStateRedux {
  requestedId?: string
  Status: ELazyStatus
}

export interface IReturnDispatch {
  abort?: () => void
}
export interface IDispatchRedux<T = any> {
  FetchData?: (param?: T, signal?: AbortSignal) => IReturnDispatch
}

interface ICRUDBase<TModel, Id extends keyof TModel> {
  Create: (model: Partial<TModel>) => Promise<void>
  Update: (Id: TModel[Id], model: Partial<TModel>) => Promise<void>
  Delete: (Id: TModel[Id], model?: Partial<TModel>) => Promise<void>
}

export type CRUDActionReduxType<TModel, Id extends keyof TModel, Key extends keyof ICRUDBase<TModel, Id> = keyof ICRUDBase<TModel, Id>> = Pick<
  ICRUDBase<TModel, Id>,
  Key
>

export interface CRUDSliceReduxType<TModel> {
  data: EntityState<TModel>
}

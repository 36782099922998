import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteKey } from '@internal/route'
import { Box, styled } from '@mui/material'
import { ELazyStatus } from '@lib/ReduxBase'
import { AppDispatch } from '@internal/redux'
import { CreateRoutePath } from '@lib/RouteBase'
import { AppStatusSlice } from '@internal/redux/Slices'
import { INavigateProps, withNavigate } from '@lib/Hocs'
import { QuestionPreviews } from '@shared/Pages/Submission'
import SubmissionService from '@internal/services/SubmissionService'
import { FormDigitalSignature } from './Forms'
import { MapSubmitData } from './Services/DataMapping'
import LocalStorageSubmisstion from './Helpers/LocalStorageSubmisstion'

interface IDispatchRedux {
  onChangeStatus: (value: ELazyStatus) => void
}
interface IProps extends INavigateProps, IDispatchRedux {}
class StepDigitalSignature extends Component<IProps> {
  render() {
    const data = LocalStorageSubmisstion.getData()
    return (
      <Box sx={{ marginTop: '48px' }}>
        <QuestionPreviews.AboutMedia data={data?.item ?? {}} />
        <QuestionPreviews.AboutYourself data={data?.item ?? {}} />
        <QuestionPreviews.CopyrightOwnership data={data?.item ?? {}} />
        <DigitalSignatureWrap>
          <FormDigitalSignature
            slots={{ sx: { maxHeight: 'unset!important' } }}
            data={data?.item}
            onSubmit={async (value) => {
              this.props.onChangeStatus(ELazyStatus.Loading)
              try {
                await SubmissionService.Create(MapSubmitData(value))
                // TODO SubmitMedia - open/close clear function
                LocalStorageSubmisstion.clear()
                this.props.onChangeStatus(ELazyStatus.Loaded)
                this.props.navigate(CreateRoutePath([RouteKey.Submit, RouteKey.SMTComplete]))
              } catch (error) {
                console.log(error)
                this.props.onChangeStatus(ELazyStatus.Error)
              }
            }}
          />
        </DigitalSignatureWrap>
      </Box>
    )
  }
}
const mapDispatchToProps = (dispatch: AppDispatch): IDispatchRedux => ({
  onChangeStatus: (value) => {
    dispatch(AppStatusSlice.actions.changeLoading(value))
  }
})
const StepDigitalSignatureMapRedux = connect(() => ({}), mapDispatchToProps)(StepDigitalSignature)
export default withNavigate(StepDigitalSignatureMapRedux)

const DigitalSignatureWrap = styled(Box)({
  width: '100%',
  marginTop: '36px',
  padding: '0 16px'
})

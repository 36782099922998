import * as React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const SvgLogo: React.FC<SvgIconProps & { fill?: string }> = (props) => {
  return (
    <SvgIcon {...props}>
      {/* credit: plus icon from https://heroicons.com/ */}
      <svg
        version='1.0'
        xmlns='http://www.w3.org/2000/svg'
        width='100.000000pt'
        height='100.000000pt'
        viewBox='0 0 100.000000 100.000000'
        preserveAspectRatio='xMidYMid meet'
      >
        <g transform='translate(0.000000,100.000000) scale(0.100000,-0.100000)' fill={props.fill ?? '#000'} stroke='none'>
          <path d='M425 906 c-45 -45 -51 -118 -16 -185 7 -11 81 -62 167 -112 109 -65 152 -95 145 -103 -5 -6 -61 -49 -125 -96 -63 -46 -113 -86 -111 -88 2 -2 51 24 110 58 131 76 170 90 257 92 37 1 68 3 68 4 0 2 -273 160 -350 202 -25 14 -47 27 -49 28 -2 2 6 27 19 56 25 59 23 117 -6 149 -25 27 -79 25 -109 -5z m80 -40 c13 -20 14 -31 5 -63 -6 -21 -16 -49 -24 -62 l-13 -23 -21 23 c-29 31 -30 97 -2 127 26 28 36 28 55 -2z' />
          <path d='M258 581 l3 -228 -56 -5 c-91 -8 -146 -64 -124 -126 28 -81 178 -60 220 31 16 36 19 67 19 215 0 94 1 172 3 172 2 0 67 -29 145 -64 78 -35 142 -61 142 -58 -1 4 -44 32 -97 62 -113 65 -167 110 -214 179 -19 28 -36 51 -39 51 -3 0 -4 -103 -2 -229z m12 -285 c0 -13 -12 -35 -27 -50 -22 -23 -34 -26 -72 -24 -38 2 -47 7 -49 24 -4 30 26 51 88 62 30 5 56 10 58 11 1 0 2 -10 2 -23z' />
          <path d='M371 410 c-1 -153 -14 -224 -50 -286 -27 -46 -26 -50 7 -28 15 10 103 62 197 116 l169 99 33 -38 c18 -21 32 -41 33 -44 0 -4 18 -14 40 -23 47 -19 81 -10 106 28 21 33 13 65 -27 111 -40 45 -95 62 -147 47 -20 -6 -95 -46 -167 -88 -72 -42 -135 -78 -141 -81 -6 -2 -15 46 -24 134 -20 203 -28 217 -29 53z m470 -89 c30 -31 35 -46 20 -70 -14 -22 -59 -5 -97 37 -45 49 -44 62 7 62 31 0 47 -6 70 -29z' />
          <path d='M452 449 c0 -80 0 -80 21 -66 12 7 43 26 70 41 26 15 47 29 47 31 0 3 -128 75 -134 75 -2 0 -4 -36 -4 -81z' />
        </g>
      </svg>
    </SvgIcon>
  )
}
export default SvgLogo

import React, { Component } from 'react'
import { Box, Grid } from '@mui/material'
import { ICategory } from '@shared/Types'
import { CreateFormBase, IFormBase } from '@lib/Forms'
import { MapGlobalModalContext } from '@lib/ApiContext'
import { FormValidator, SingleRuleValidate } from '@lib/Forms'
import { CreateSelectMultiple, CreateSelectWithApi, ISelectMultipleOption, ISelectWithApiOption } from '@lib/Forms/Inputs'
import { IFormUploadModel } from './type'
import { FileHandler } from './FileHandler'
import Layout from './Layout'
import BottomBar from './BottomBar'
import AreaFileInputs from './AreaFileInputs'
import IconButtonClose from './IconButtonClose'

export * from './type'
export * from './FileHandler'

const formValidate = new FormValidator<Partial<IFormUploadModel>>({
  UserId: { Rules: [{ rule: SingleRuleValidate.Required }] },
  Categories: {
    Rules: [
      { rule: SingleRuleValidate.Required },
      {
        rule: SingleRuleValidate.Custom,
        Value: (v, m) => {
          console.log({ v, m })

          return false
        }
      }
    ]
  }
})
const SelectWithApi = CreateSelectWithApi<IFormUploadModel>()
const SelectMultiple = CreateSelectMultiple<IFormUploadModel>()
const FormBaseInstance = CreateFormBase<IFormUploadModel>()

export interface IFormSubmitModelReponse extends IFormUploadModel {
  files: File[]
}

interface IBaseProps {
  categories?: ICategory[]
  fetchUsers?: (value?: string, signal?: AbortSignal) => Promise<ISelectWithApiOption[]>
}
interface IFormUploadProps extends IBaseProps {
  onSubmit?: (value: Partial<IFormSubmitModelReponse>) => Promise<void>
}

export default class FormUpload extends Component<IFormUploadProps> {
  refFormUploadBase: FormContent | null = null
  render() {
    const validate = this.props.fetchUsers ? formValidate : new FormValidator<Partial<IFormUploadModel>>({})
    return (
      <FormBaseInstance.Form onSubmit={this.handleSubmit} validate={validate}>
        {FormBaseInstance.ContextMapping((context) => (
          <FormContent
            ref={(ref) => (this.refFormUploadBase = ref)}
            onBlur={context.onBlur}
            messageErrors={context.messageErrors}
            categories={this.props.categories}
            fetchUsers={this.props.fetchUsers}
          />
        ))}
      </FormBaseInstance.Form>
    )
  }

  handleSubmit = async (data: Partial<IFormUploadModel>) => {
    const files = this.refFormUploadBase?.getUploadedFiles()
    await (this.props.onSubmit && this.props.onSubmit({ ...data, files }))
  }
}

interface IFormContentProps extends IFormBase<IFormUploadModel>, IBaseProps {}
interface IFormContentState {
  files: File[]
}
class FormContent extends Component<IFormContentProps, IFormContentState> {
  constructor(props: IFormContentProps) {
    super(props)
    this.state = { files: [] }
  }
  render() {
    const { categories, ...otherProps } = this.props
    const files = this.getUploadedFiles()
    const slice = FileHandler.sliceFiles(files)
    return (
      <Layout
        title='Upload media'
        isExistFiles={files.length > 0}
        components={{
          closeButton: MapGlobalModalContext((context) => <IconButtonClose onClick={context.CloseModal} />),
          topOtherView: (
            <Box sx={{ my: '8px' }}>
              <Grid container spacing={2}>
                {this.props.fetchUsers && (
                  <Grid item xs={12}>
                    <SelectWithApi name='UserId' label='User' fetchData={this.props.fetchUsers} {...otherProps} />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <SelectMultiple {...this.props} name='Categories' label='Categories' options={this.getCategories()} />
                </Grid>
              </Grid>
            </Box>
          ),
          body: (
            <AreaFileInputs
              value={files}
              onChange={(value) => {
                this.setState({ files: Array.from(value) })
              }}
            />
          ),
          action: <BottomBar counts={{ audio: slice.audios.length, video: slice.videos.length }} />
        }}
      />
    )
  }

  getCategories = (): ISelectMultipleOption[] => {
    return this.props.categories?.map<ISelectMultipleOption>((x) => ({ Id: x.Id, Label: x.Name })) ?? []
  }
  getUploadedFiles = (): File[] => this.state.files ?? []
}

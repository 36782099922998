import { EOperator } from '@lib/Table'
import Graphql from '@coreprj/graphql'
import { TFieldConfig, TFieldMapping, TFieldMappingDeep } from './types'

interface IMapOperatorFunctionFilterParam<TGraphQlModel extends Object> {
  operator: EOperator
  config: TFieldConfig<keyof TGraphQlModel>
  value: string
  requestParam: Graphql.RequestParam<TGraphQlModel>
  options?: Partial<Graphql.IFilterOption>
}

export const MapGraphqlFilterOperator = <TGraphQlModel extends Object>(param: IMapOperatorFunctionFilterParam<TGraphQlModel>) => {
  switch (param.operator) {
    case EOperator.Contains:
      return param.requestParam.filterContains(param.config.field, param.value, param.options)
    case EOperator.Equal: {
      if (param.config.type === 'int' || param.config.type === 'double') {
        return param.requestParam.filterNumber(param.config.field, '==', param.value, param.options)
      }
      return param.requestParam.filter(param.config.field, param.value, param.options)
    }
    case EOperator.GreaterThan:
      return param.requestParam.filterNumber(param.config.field, '>', param.value, param.options)
    case EOperator.LessThan:
      return param.requestParam.filterNumber(param.config.field, '<', param.value, param.options)
    case EOperator.NotEqual:
      return param.requestParam.filterNumber(param.config.field, '!=', param.value, param.options)
    case EOperator.GreaterThanOrEqual:
      return param.requestParam.filterNumber(param.config.field, '>=', param.value, param.options)
    case EOperator.LessThanOrEqual:
      return param.requestParam.filterNumber(param.config.field, '<=', param.value, param.options)
    default:
      return param.requestParam.filter(param.config.field, param.value, param.options)
  }
}

const GetFieldDeepConfigs = function <TModel, TGraphQlModel extends Object>(
  key: keyof TModel,
  fieldMappingDeep: TFieldMappingDeep<TModel, TGraphQlModel>
): TFieldConfig<keyof TGraphQlModel>[] {
  const configsDeep = fieldMappingDeep[key]
  if (!!configsDeep && Object.keys(configsDeep).length > 0) {
    const graphqlModelKey = Object.keys(configsDeep)[0] as keyof TGraphQlModel
    const temp = configsDeep[graphqlModelKey]

    const configs = (temp ? (Array.isArray(temp) ? temp : [temp]) : []) as TFieldConfig<keyof TGraphQlModel>[]
    return configs.map<TFieldConfig<keyof TGraphQlModel>>((x) => {
      const deepKey = `${graphqlModelKey?.toString()}.${x.field?.toString()}` as keyof TGraphQlModel
      return { ...x, field: deepKey }
    })
  }
  return []
}

export const GetFiledConfig = function <TModel, TGraphQlModel extends Object>(
  key?: keyof TModel,
  fieldMapping?: TFieldMapping<TModel, TGraphQlModel>
): TFieldConfig<keyof TGraphQlModel> | undefined {
  return fieldMapping?.[key]
}

export const GetFiledConfigs = function <TModel, TGraphQlModel extends Object>(
  key: keyof TModel,
  fieldMapping: TFieldMapping<TModel, TGraphQlModel>,
  fieldMappingDeep?: TFieldMappingDeep<TModel, TGraphQlModel>
): TFieldConfig<keyof TGraphQlModel>[] {
  const config = fieldMapping[key]
  let configs: TFieldConfig<keyof TGraphQlModel>[] = config ? [config] : []
  if (configs.length < 1 && !!fieldMappingDeep) {
    configs = GetFieldDeepConfigs(key, fieldMappingDeep)
  }
  return configs
}

import type { FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface PaymentInformationFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'PaymentInformation', T, TVariables> {

    on<XName extends ImplementationType<'PaymentInformation'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): PaymentInformationFetcher<
        XName extends 'PaymentInformation' ?
        T & X :
        WithTypeName<T, ImplementationType<'PaymentInformation'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'PaymentInformation'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): PaymentInformationFetcher<T, TVariables>;


    readonly __typename: PaymentInformationFetcher<T & {__typename: ImplementationType<'PaymentInformation'>}, TVariables>;


    readonly id: PaymentInformationFetcher<T & {readonly "id": number}, TVariables>;

    "id+"<
        XAlias extends string = "id", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"id", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): PaymentInformationFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: number} : 
                {readonly [key in XAlias]: number}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~id": PaymentInformationFetcher<Omit<T, 'id'>, TVariables>;


    readonly userId: PaymentInformationFetcher<T & {readonly "userId"?: string}, TVariables>;

    "userId+"<
        XAlias extends string = "userId", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"userId", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): PaymentInformationFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~userId": PaymentInformationFetcher<Omit<T, 'userId'>, TVariables>;


    readonly accountNumber: PaymentInformationFetcher<T & {readonly "accountNumber"?: string}, TVariables>;

    "accountNumber+"<
        XAlias extends string = "accountNumber", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"accountNumber", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): PaymentInformationFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~accountNumber": PaymentInformationFetcher<Omit<T, 'accountNumber'>, TVariables>;


    readonly bankName: PaymentInformationFetcher<T & {readonly "bankName"?: string}, TVariables>;

    "bankName+"<
        XAlias extends string = "bankName", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"bankName", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): PaymentInformationFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~bankName": PaymentInformationFetcher<Omit<T, 'bankName'>, TVariables>;


    readonly identityCard: PaymentInformationFetcher<T & {readonly "identityCard"?: string}, TVariables>;

    "identityCard+"<
        XAlias extends string = "identityCard", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"identityCard", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): PaymentInformationFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~identityCard": PaymentInformationFetcher<Omit<T, 'identityCard'>, TVariables>;


    readonly swiftNumber: PaymentInformationFetcher<T & {readonly "swiftNumber"?: string}, TVariables>;

    "swiftNumber+"<
        XAlias extends string = "swiftNumber", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"swiftNumber", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): PaymentInformationFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~swiftNumber": PaymentInformationFetcher<Omit<T, 'swiftNumber'>, TVariables>;


    readonly beneficiaryName: PaymentInformationFetcher<T & {readonly "beneficiaryName"?: string}, TVariables>;

    "beneficiaryName+"<
        XAlias extends string = "beneficiaryName", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"beneficiaryName", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): PaymentInformationFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~beneficiaryName": PaymentInformationFetcher<Omit<T, 'beneficiaryName'>, TVariables>;


    readonly bankAddress: PaymentInformationFetcher<T & {readonly "bankAddress"?: string}, TVariables>;

    "bankAddress+"<
        XAlias extends string = "bankAddress", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"bankAddress", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): PaymentInformationFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~bankAddress": PaymentInformationFetcher<Omit<T, 'bankAddress'>, TVariables>;


    user<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'ApplicationUser', X, XVariables>
    ): PaymentInformationFetcher<
        T & {readonly "user"?: X}, 
        TVariables & XVariables
    >;

    user<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "user", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'ApplicationUser', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"user", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): PaymentInformationFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    readonly dateCreated: PaymentInformationFetcher<T & {readonly "dateCreated": string}, TVariables>;

    "dateCreated+"<
        XAlias extends string = "dateCreated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateCreated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): PaymentInformationFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateCreated": PaymentInformationFetcher<Omit<T, 'dateCreated'>, TVariables>;


    readonly dateUpdated: PaymentInformationFetcher<T & {readonly "dateUpdated": string}, TVariables>;

    "dateUpdated+"<
        XAlias extends string = "dateUpdated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateUpdated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): PaymentInformationFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateUpdated": PaymentInformationFetcher<Omit<T, 'dateUpdated'>, TVariables>;
}

export const paymentInformation$: PaymentInformationFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "PaymentInformation", 
            "OBJECT", 
            [], 
            [
                {
                    category: "ID", 
                    name: "id"
                }, 
                {
                    category: "SCALAR", 
                    name: "userId", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "accountNumber", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "bankName", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "identityCard", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "swiftNumber", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "beneficiaryName", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "bankAddress", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "user", 
                    targetTypeName: "ApplicationUser", 
                    undefinable: true
                }, 
                "dateCreated", 
                "dateUpdated"
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const paymentInformation$$ = 
    paymentInformation$
        .id
        .userId
        .accountNumber
        .bankName
        .identityCard
        .swiftNumber
        .beneficiaryName
        .bankAddress
        .dateCreated
        .dateUpdated
;

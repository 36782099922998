import React, { Component } from 'react'
import './index.css'

interface TextViewProps {
  contentText: string
}

export default class TextView extends Component<TextViewProps> {
  onPrevent: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
  }
  resizeWindow = () => {
    const pre = document.querySelector<HTMLPreElement>('.pre-content')
    if (!pre) return
    if (window.innerWidth < 1000 || window.innerWidth < pre.clientWidth) {
      pre.style.width = window.innerWidth - 20 + 'px'
    } else {
      pre.style.width = '1000px'
    }
  }
  componentDidMount() {
    this.resizeWindow()
    window.addEventListener('resize', this.resizeWindow)
  }
  // componentWillMount = () => {
  //   window.removeEventListener('resize', this.resizeWindow)
  // }
  render() {
    return (
      <div className='text-view-container '>
        {/* <div className='text-view-content' onMouseDown={this.onPrevent}> */}
        <div className='text-view-content'>
          <div style={{ height: 0 }}>
            <pre className='pre-content'>{this.props.contentText}</pre>
          </div>
        </div>
      </div>
    )
  }
}

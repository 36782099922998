import { GridCallbackDetails } from '@mui/x-data-grid'
import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { DecodeBase64, DeepMerge, EncodeBase64, QueryParam, TryParseJson } from '@lib/Helpers'
import { IPagingModel, ITableTemplateState, TFetchChange, TOnTableTemplateChange } from '@lib/Table'

namespace ReduxBaseTable {
  export interface Base<TModel> {
    tableInfo: ITableTemplateState<TModel>
  }

  // ========= ========= ========= Slice Type Redux ========= ========= =========
  export interface MapState<TModel> extends Base<TModel> {}

  // ========= ========= ========= Slice Type Redux ========= ========= =========
  export interface MapDispatch<TModel> {
    onTableChange: TOnTableTemplateChange<TModel, TFetchChange<TModel>>
  }

  // ========= ========= ========= Slice Type Redux ========= ========= =========
  export interface SliceState<TModel> extends Base<TModel> {}

  interface OnChangeParam<TModel> {
    key: keyof ITableTemplateState<TModel>
    value: any
    details: GridCallbackDetails
  }

  interface TableReducers<TState, TModel> {
    onChange: CaseReducer<TState, PayloadAction<OnChangeParam<TModel>>>
  }

  interface InitialSlice<TState, TModel> {
    initialState: SliceState<TModel>
    reducers: TableReducers<TState, TModel>
  }

  export const getInitialSlice = <TState extends Base<TModel>, TModel>(
    state?: Partial<ITableTemplateState<TModel>>
  ): InitialSlice<TState, TModel> => {
    const defaultValue: ITableTemplateState<TModel> = { isLoading: true, PageInfo: { data: [], page: 0, pageSize: 25 } }
    return {
      initialState: { tableInfo: DeepMerge<ITableTemplateState<TModel>>(defaultValue, state) },
      reducers: {
        onChange: (state, action) => {
          state.tableInfo[action.payload.key] = action.payload.value
          state.tableInfo.details = action.payload.details
        }
      }
    }
  }

  // ========= ========= ========= Thunk Type Redux ========= ========= =========
  export interface ThunkParam<TModel> extends Partial<Base<TModel>> {
    tableChange?: OnChangeParam<TModel>
  }

  export interface ThunkReponse<TModel> extends Base<TModel> {}

  export const ThunkParamMapping = <TModel>(state: ITableTemplateState<TModel>, tableChange?: OnChangeParam<TModel>) => {
    const tts: ITableTemplateState<TModel> = Object.assign({}, state)
    if (!!tableChange) {
      const { key, value, details } = tableChange
      tts[key] = value
      tts.details = details
    }
    return tts
  }

  export const ThunkReponseMapping = <TModel>(state: ITableTemplateState<TModel>, tableData: Partial<IPagingModel<TModel>>) => {
    const { Data = [], Page = 0, Amount = 25, Total = 100 } = tableData

    const temp: Partial<ITableTemplateState<TModel>> = {
      isLoading: false,
      PageInfo: { data: Data, page: Page, pageSize: Amount, rowTotal: Total }
    }

    const obj: ITableTemplateState<TModel> = Object.assign({}, state, temp)
    return obj
  }

  // ========= ========= ========= Query Param ========= ========= =========
  interface ITableQueryParam {
    table?: string
  }

  const extractData = <TModel>(tableInfo: Partial<ITableTemplateState<TModel>>): Partial<ITableTemplateState<TModel>> => {
    const { FilterModel, GridSortModel, PageInfo } = tableInfo
    const items = FilterModel?.items.filter((x) => !!x.value) ?? []
    const obj: Partial<ITableTemplateState<TModel>> = {
      GridSortModel,
      PageInfo: { data: [], pageSize: PageInfo?.pageSize ?? 0, page: PageInfo?.page ?? 0, rowTotal: 0 }
    }
    if (items.length > 0) {
      obj.FilterModel = { ...FilterModel, items: FilterModel?.items.filter((x) => !!x.value) ?? [] }
    } else {
      delete obj.FilterModel
    }
    return obj
  }

  const objectEquals = <TModel>(tableInfo: Partial<ITableTemplateState<TModel>>, tableInfoOld: Partial<ITableTemplateState<TModel>>) => {
    return JSON.stringify(extractData(tableInfo)) === JSON.stringify(extractData(tableInfoOld))
  }

  export const setParam = <TModel>(tableInfo: Partial<ITableTemplateState<TModel>>, defaultValue: ITableTemplateState<TModel>) => {
    try {
      if (!objectEquals(tableInfo, defaultValue)) {
        const data = extractData(tableInfo)
        const tqp: ITableQueryParam = { table: EncodeBase64(JSON.stringify(data)) }
        QueryParam.Patch(tqp)
      } else {
        QueryParam.Deletes<ITableQueryParam>('table')
      }
    } catch (error) {
      console.log(error)
    }
  }

  export const getParam = <TModel>(defaultValue: ITableTemplateState<TModel>): ITableTemplateState<TModel> => {
    const temp = QueryParam.GetAll() as ITableQueryParam
    if (!temp?.table) return defaultValue
    const data = TryParseJson<ITableTemplateState<TModel>>(DecodeBase64(temp.table ?? ''))
    if (Object.keys(data).length < 1) return defaultValue
    return data as ITableTemplateState<TModel>
  }
}
export default ReduxBaseTable

import { CreateFormUI, FormValidator, IFormUIConfig, SingleRuleValidate } from '@lib/Forms'
import { CreateDatePicker } from '@lib/Forms/Inputs'
import { IBusinessInfo, IPaymentInfo, IPersonalInfo } from '@shared/Types'
import ControlCopy from './ControlCopy'

const DatePickerInstance = CreateDatePicker<IPersonalInfo>({
  datePickerProps: {
    sx: { pr: '12px' },
    slotProps: { textField: { variant: 'standard', label: undefined } }
  }
})

export const GetFormUIPersonalInfo = (site: 'Client' | 'Admin') => {
  const configs: IFormUIConfig<IPersonalInfo>[] = [
    { name: 'Email', label: 'Email', inputElement: ControlCopy },
    { name: 'FirstName', label: 'First name' },
    { name: 'LastName', label: 'Last name' },
    { name: 'DisplayName', label: 'Display name' },
    { name: 'PhoneNumber', label: 'Phone number' },
    { name: 'Birthday', label: 'Birthday', inputElement: DatePickerInstance },
    { name: 'Address', label: 'Address' }
  ]
  if (site === 'Admin') {
    configs.push({ name: 'PasswordRamdom', label: 'Ramdom password', inputElement: ControlCopy })
  }
  return CreateFormUI<IPersonalInfo>({
    title: 'Personal information',
    description: 'Some info may be visible',
    configs,
    validate: new FormValidator({
      FirstName: { Rules: [{ rule: SingleRuleValidate.Required }] },
      LastName: { Rules: [{ rule: SingleRuleValidate.Required }] },
      DisplayName: { Rules: [{ rule: SingleRuleValidate.Required }] },
      Birthday: { Rules: [{ rule: SingleRuleValidate.Required }] },
      Address: { Rules: [{ rule: SingleRuleValidate.Required }] },
      PhoneNumber: { Rules: [{ rule: SingleRuleValidate.Required }, { rule: SingleRuleValidate.PhoneNumber }] }
    })
  })
}

export const FormUIBusinessInfo = CreateFormUI<IBusinessInfo>({
  title: 'Business information',
  configs: [
    { name: 'CompanyName', label: 'Company name' },
    { name: 'Position', label: 'Position' },
    { name: 'TaxId', label: 'Tax' },
    { name: 'Address', label: 'Business address' }
  ],
  validate: new FormValidator({
    CompanyName: { Rules: [{ rule: SingleRuleValidate.Required }] }
  })
})

export const FormUIPaymentInfo = CreateFormUI<IPaymentInfo>({
  title: 'Payment information',
  configs: [
    { name: 'AccountNumber', label: 'Account number' },
    { name: 'BankName', label: 'Bank name' },
    { name: 'IdentityCard', label: 'Identity card' },
    { name: 'SwiftNumber', label: 'Swift code' },
    { name: 'BeneficiaryName', label: 'Beneficiary name' },
    { name: 'BankAddress', label: 'Bank address' }
  ],
  validate: new FormValidator({
    AccountNumber: { Rules: [{ rule: SingleRuleValidate.Required }] },
    BankName: { Rules: [{ rule: SingleRuleValidate.Required }] },
    IdentityCard: { Rules: [{ rule: SingleRuleValidate.Required }] },
    SwiftNumber: { Rules: [{ rule: SingleRuleValidate.Required }] },
    BeneficiaryName: { Rules: [{ rule: SingleRuleValidate.Required }] },
    BankAddress: { Rules: [{ rule: SingleRuleValidate.Required }] }
  })
})

import { AppDispatch, RootState } from '@internal/redux'
import { IProfileDispatchRedux, IProfileStateRedux } from '../type'
import { fetchProfileThunk, fetchProfileUpdateThunk } from './Thunks'

export const mapStateToProps = (state: RootState): IProfileStateRedux => ({
  Status: state.ProfileSlice.Status,
  ProfileSlice: state.ProfileSlice
})

export const mapDispatchToProps = (dispatch: AppDispatch): IProfileDispatchRedux => {
  return {
    FetchData: () => {
      return dispatch(fetchProfileThunk())
      // return { abort: () => WindowScrollToTop() }
    },
    updateProfile: async (value) => {
      dispatch(fetchProfileUpdateThunk(value))
    }
  }
}

import { FetchDelay } from '@lib/Helpers'
import { IVideoStream } from '@shared/Types'
import { ServiceBase, CreateHttpService } from '@lib/Http'

class UploadServiceBase extends ServiceBase {
  constructor() {
    super(CreateHttpService(`${process.env.REACT_APP_API_URI}/api/v1/guest/Upload`))
  }
  _UrlGetVideoStream = '/GetVideoStream'
  GetVideoStream = (resourceId: string, signal?: AbortSignal) => {
    return FetchDelay(async () => {
      const url = `${this._UrlGetVideoStream}/${resourceId}`
      const data = await this.PushNotify(this.TryGet<IVideoStream[]>, url, {
        signal
      })
      return (data !== null ? data : []) as IVideoStream[]
    }, 500)
  }
}

const UploadService = new UploadServiceBase()
export default UploadService

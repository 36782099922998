import type { FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface QueryFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'Query', T, TVariables> {


    directive(name: string, args?: DirectiveArgs): QueryFetcher<T, TVariables>;


    mediaStore<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'MediaStoreGraphDbContext', X, XVariables>
    ): QueryFetcher<
        T & {readonly "mediaStore": X}, 
        TVariables & XVariables
    >;

    mediaStore<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "mediaStore", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'MediaStoreGraphDbContext', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"mediaStore", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): QueryFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: X} : 
                {readonly [key in XAlias]: X}
        ), 
        TVariables & XVariables & XDirectiveVariables
    >;


    auth<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'AuthenticationGraphDbContext', X, XVariables>
    ): QueryFetcher<
        T & {readonly "auth": X}, 
        TVariables & XVariables
    >;

    auth<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "auth", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'AuthenticationGraphDbContext', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"auth", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): QueryFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: X} : 
                {readonly [key in XAlias]: X}
        ), 
        TVariables & XVariables & XDirectiveVariables
    >;


    notification<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'NotificationGraphDbContext', X, XVariables>
    ): QueryFetcher<
        T & {readonly "notification": X}, 
        TVariables & XVariables
    >;

    notification<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "notification", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'NotificationGraphDbContext', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"notification", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): QueryFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: X} : 
                {readonly [key in XAlias]: X}
        ), 
        TVariables & XVariables & XDirectiveVariables
    >;
}

export const query$: QueryFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "Query", 
            "OBJECT", 
            [], 
            [
                {
                    category: "SCALAR", 
                    name: "mediaStore", 
                    targetTypeName: "MediaStoreGraphDbContext"
                }, 
                {
                    category: "SCALAR", 
                    name: "auth", 
                    targetTypeName: "AuthenticationGraphDbContext"
                }, 
                {
                    category: "SCALAR", 
                    name: "notification", 
                    targetTypeName: "NotificationGraphDbContext"
                }
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

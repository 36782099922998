import React, { Component } from 'react'
import { Container } from '@mui/material'
import { WindowScrollToTop } from '@lib/Helpers'
import UIStyleds from '@shared/UIStyleds'
import PdfViewer from '@lib-lab/PDFViewer'

class PrivacyPolicyPage extends Component {
  render() {
    return (
      <Container>
        <UIStyleds.WrapFullDisplay>
          <PdfViewer src='assets/example.pdf' options={{ navpanes: 0, pagemode: 'bookmarks', toolbar: 1, view: 'FitBH' }} />
        </UIStyleds.WrapFullDisplay>
      </Container>
    )
  }

  componentDidMount(): void {
    WindowScrollToTop()
  }
}
export default PrivacyPolicyPage

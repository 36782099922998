import { RootState } from '@internal/redux'
import { IMediaDelivery } from '@shared/Types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { MergeObjects, TableQueryParam } from '@lib/Helpers'
import { MapTableData, MapTableInfo, TableReduxType } from '@lib/Table'
import MediaDeliveryService, { MediaDeliveryMapping } from '@internal/services/MediaDeliveryService'
import { DefaultTableInfo } from './redux.slice'

interface IParamsThunk extends TableReduxType.ThunkParam<IMediaDelivery> {}
interface IResponseThunk extends TableReduxType.ThunkReponse<IMediaDelivery> {}
export const fetchDeliveryThunk = createAsyncThunk<IResponseThunk, IParamsThunk | undefined>('fetchDeliveryThunk', async (param, thunkAPI) => {
  const state = thunkAPI.getState() as RootState
  const tableInfoState = MergeObjects(state.DeliverySlice.tableInfo, param?.tableInfo)
  const tableInfo = MapTableInfo(tableInfoState, param?.tableChange)

  const filter = MediaDeliveryMapping.tableInfoToFilter(tableInfo)
  const res = await MediaDeliveryService.allGraphQL(filter, thunkAPI.signal)

  const data = MapTableData(tableInfo, { Data: MediaDeliveryMapping.deliveries(res?.items ?? []), Total: res?.totalItems })
  TableQueryParam.setParam(data, DefaultTableInfo)
  return { tableInfo: MapTableData(tableInfo, { Data: MediaDeliveryMapping.deliveries(res?.items ?? []), Total: res?.totalItems }) }
})

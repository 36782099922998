import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteKey } from '@internal/route'
import { TableFormater } from '@lib/Helpers'
import { CreateHocLazy } from '@lib/ReduxBase'
import { IMediaDelivery } from '@shared/Types'
import { CreateRoutePath } from '@lib/RouteBase'
import { INavigateProps, withNavigate } from '@lib/Hocs'
import { ActionPannel, CreateTableTemplate, CRUDPannel, MapOprators } from '@lib/Table'
import { ButtonDeliveryStatus, DeliveryBtnDetail, DeliveryForms, DeliveryStatusCell } from '@shared/Pages/Delivery'
import UIStyleds from '@shared/UIStyleds'
import { IDeliveryReduxProps } from './redux.types'
import { mapDispatchToProps, mapStateToProps } from './redux.map'
import { IMediaQueryParam } from '@shared/Services/QueryParamService'

const TableInstance = CreateTableTemplate<IMediaDelivery>('Redux', {
  getRowId: (x) => x.Id,
  config: {
    Title: { type: 'string', flex: 2, minWidth: 200, sortable: false },
    Email: { type: 'string', flex: 1, minWidth: 150, sortable: false },
    Description: { type: 'string', flex: 1, minWidth: 150 },
    DateCreated: { type: 'string', minWidth: 175, filterable: false, renderCell: (params) => TableFormater.tooltipDate(params.value) },
    Status: {
      type: 'string',
      minWidth: 140,
      filterable: false,
      renderCell: (param) => <DeliveryStatusCell data={(param.row as IMediaDelivery).Status} />
    }
  },
  filterOperators: MapOprators, //server mode,
  minWidthColumnActions: 120
})

interface IProps extends IDeliveryReduxProps, INavigateProps {}
class ViewBase extends Component<IProps> {
  render() {
    return (
      <UIStyleds.WrapProfile>
        <TableInstance
          ReduxOption={this.props.stateRedux.tableInfo}
          onChange={this.props.onTableChange}
          CRUDPannel={() => (
            <CRUDPannel
              Title='Deliveries'
              Create={
                <DeliveryForms.FormCreate
                  onLinkRepoSubmit={async (value) => {
                    await this.props.CRUD.Create(value)
                  }}
                  onUploadMediaSubmit={async (value) => {
                    const res = await this.props.CRUD.Create(value)
                    if (!res?.Id) return
                    this.props.navigate(
                      CreateRoutePath([RouteKey.Profile, RouteKey.Delivery], { query: { deliveryId: res?.Id }, modal: RouteKey.DeliveryDetail })
                    )
                  }}
                />
              }
            />
          )}
          ActionPannel={(p) => {
            const data = p.data as IMediaDelivery
            return <ActionPannel Buttons={{ ExtendBefore: this.renderButtonDetail(data) }} />
          }}
        />
      </UIStyleds.WrapProfile>
    )
  }

  renderButtonDetail = (data: IMediaDelivery) => {
    const path = CreateRoutePath<IMediaQueryParam>([RouteKey.Profile, RouteKey.DeliveryDetail], {
      query: { deliveryId: data.Id, type: data.ContentType }
    })
    return [<DeliveryBtnDetail to={path} type={data.ContentType} />]
  }
}

export * from './redux.thunks'
export { default as DeliverySlice } from './redux.slice'

const HocLazyInstace = CreateHocLazy(withNavigate(ViewBase))
export const DeliveryMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstace)

interface IBtnDeliveryStatusProps extends IDeliveryReduxProps {
  data?: Partial<IMediaDelivery>
  onChangeValue?: (id: string, value: Partial<IMediaDelivery>) => Promise<void>
}
const DeliveryStatusBtn: React.FC<IBtnDeliveryStatusProps> = (p) => (
  <ButtonDeliveryStatus
    data={p.data}
    onChangeValue={async (i, v) => {
      await p.CRUD.Update(i, v)
      p.onChangeValue && (await p.onChangeValue(i, v))
    }}
  />
)
export const DeliveryStatusBtnMapRedux = connect(mapStateToProps, mapDispatchToProps)(DeliveryStatusBtn)
